import React from "react";
import { LogoRebound } from "./Header&Footer/Navbar";
// import blogpost from "../Assets/Images/blogpost.jpeg";
// import RightIconButton from "../Components/RightIconButton";
// import ellipse10 from "../Assets/Images/Ellipse10.png";
// import { useLocation, useParams } from "react-router-dom";
// import { NavItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCurrentDimension } from "Utils/Helper";
// import { useNavigate } from "react-router-dom";
function TermsAndCondition({ navigation }) {
    const{t}=useTranslation()
    const dimensions = getCurrentDimension();

    return (

        <div className="blogpostmaincontainer terms-and-condition">
            {/* <img src={ellipse10} alt="ellipse10" className="blogpostellipse" /> */}
            <div className="blogpostinnercontainer">
                <div className="blogpostheadingcontainer">
                    <div className="pointer" style={{ marginBottom: 30 }}>
                        <LogoRebound style={{
                            // width:220,
                            // height:45
                            width:dimensions.width<=766? 130: 220,
                            height: dimensions.width <=766? 30: 45

                        }}/>
                    </div>
                    <p className="blogpostheadingfont" style={{ color: '#325ea8', fontSize: 25 }}>{t('TermsAndCondition')}</p>
                </div>


                <p>This Website is owned and operated by Rebound Limited, incorporated under the laws of the
                Abu  Dhabi Global Market, Abu  Dhabi, U.A.E. with company number 000006694.</p>
                <p>
                At Rebound, we are on a mission to keep plastics out of the environment and in the economy, being part of the solution to reducing plastic pollution.</p>
                <p>
                Through our  trading platform Rebound Platform along with our  advisory & consultancy services, Rebound Solutions we act as a neutral facilitator to address the challenges and barriers in the value chain, increasing transparency and making a significant impact in the realms of plastic waste recycling, plastic waste management, and sustainability initiatives.
                </p>

                <p>This  document and the other documents that we reference below make up the terms and conditions governing Your use of the Services (collectively, the “Terms”). The Terms are a legally binding contract between You and Rebound. In short, Your use of our Services means You agree with and intend to be bound by these Terms.
                </p>

                <p class="title">
                    <div>1)</div>
                    <div className="title-column-two">Definitions.   The  following   words,   when  used  in   these  Terms,   have  the following meanings:</div>
                </p>

                <ol>
                    <li>“Agreement” means the agreement between Rebound and You for the provision of the Services and includes these Terms (including all documents referenced in these Terms).</li>
                    <li>“Content” means any information You upload or post to the Services and any information provided by You to Rebound in connection with the Services, including, without limitation, Personal Information.</li>
                    <li>“Intellectual  Property” means all systems, applications, software code (in any form, including source  code, executable or  object code), algorithms, technology, programs, databases,   designs, diagrams, documentation,  drawings,  charts,  ideas,  inventions, trademarks   (whether  registered  or   not),   brand  names,  logos,  slogans,  methods, techniques, models, procedures, processes, and includes all copyrights, rights associated with works of authorship, trademark rights, trade name rights, trade secret rights, patent and industrial property rights, and other property rights in each of the foregoing.</li>
                    <li>“Personal Information” means any information about an identifiable individual, including, without limitation, data or  information entered into the Services by  You or  by  anybody authorized by You to use Your account for the Services (for example, Your name, buyer’s or seller’s name, phone number, address, email address, and rating).</li>
                    <li>“Rebound ” or “Us” or “We” or “Our” means or refers to The Rebound Limited.</li>
                    <li>“Services”  means each of  the following:  the reboundplastic.com  website,  the online Trading Platform  which  can be accessed from reboundplastic.com  website,  any other website controlled by Rebound, and any other services provided by Rebound from time to time, except to the extent that such other services expressly contain their own  terms and  conditions. “Services”   includes any  updates,   upgrades,  patches,  technology, 
                    enhancements, data, features, and contents, as it may be added or removed by Rebound from time to time and including all  written information,  documentation,  and materials provided to You in respect of same.</li>
                    <li>“You”  or  “Your”  refers to the party entering into  this  Agreement with  Rebound and includes the person, entity, or organization having control of the use of the Services and any successor of same.</li>
                </ol>
                <p className="title">
                    <div>2)</div>
                    <div className="title-column-two">Use and License. You  will  only  use the Services in  accordance with the following rules:</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">2.1</div>
                        <div className="terms-column-two">You will use the Services only for the company that you are legally authorized to do so.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.2</div>
                        <div className="terms-column-two">You will not use the Services as a service for any third party.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.3</div>
                        <div className="terms-column-two">Your license to use the Services is limited. You are not granted any license or right to use, reproduce,  translate,  rearrange,  modify,  enhance,  display,  sell,  lease,  sublicense or otherwise distribute, transfer or  dispose of  the Services, in whole or  in part,  except  as expressly provided by this Agreement.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.4</div>
                        <div className="terms-column-two">You will not modify, copy, create derivative works from, reverse engineer, decompile or disassemble the Services. You  will not “crawl,” “scrape,” or  “spider” any page of  the Services or attempt to obtain the source code of the Services. You will not interfere with or  try to disrupt the  Services, for  example  by  distributing a  virus or  other harmful computer code.
                        </div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.5</div>
                        <div className="terms-column-two">You  will not  download or  use the Services (and if downloaded You  will immediately uninstall   and  destroy the  Services)   if  such  download,   installation   or   use  of the Services is prohibited under applicable.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.6</div>
                        <div className="terms-column-two">You will not violate any laws in connection with Your use of the Services.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.7</div>
                        <div className="terms-column-two">You  are responsible for  paying all  fees that You  owe to Rebound.  You are also solely responsible for collecting and/or paying any applicable taxes for any purchases or sales You make through our  Services.</div>
                    </li>
                </ul>
                <p className="title">
                    <div>3)</div>
                    <div className="title-column-two">Your  Privacy.</div>
                </p>
                <p>
                    Our  privacy policy details how  Your  information is  used and processed when You  use the Services. By using the Services, You agree that we can use and process. Both Rebound and members (including but not limited to sellers / buyers) process users of the Services’ Personal Information and  are  therefore considered separate  and  independent data  controllers  of Personal Information under EU law. That means that each party is responsible for the Personal Information it processes in providing the Services. For example, if a seller accidentally discloses a buyer’s name and email address when fulfilling another buyer’s order, the seller, not Rebound, 
                    will be responsible for that unauthorised disclosure. If, however, Rebound and sellers are found to be joint data controllers of buyers’ personal information, and if Rebound is sued, fined, or otherwise incurs expenses  because of  something that You  did as a joint  data controller  of Personal Information, You agree to indemnify Rebound for the expenses (including legal fees) it occurs in connection with Your processing of Personal Information.
                </p>

                <p className="title">
                    <div>4)</div>
                    <div className="title-column-two">Your  Responsibilities.</div>
                </p>
                <p>
                    You  are responsible for  all  of  Your  use of, and access to,  the Services, including where such use and  / or access is  by   others  who   are authorized  by   You  to  use  the  Services. You acknowledge and agree:
                </p>

                <ol>
                    <li>That  You  are 18  years old  or  older.  If You  are under 18  years of  age,  You  are not authorized to use the Services.</li>
                    <li>To take reasonable actions to prevent unauthorized access to,  or  use of, the Services, and to notify Rebound promptly of any unauthorized access or use.</li>
                    <li>To provide complete and accurate information about Yourself.</li>

                    <li>That  You  are responsible for  Your  accounts and any activity on  Your  accounts.  If You share an account with  other people, then the person whose billing information is on  the account will ultimately be responsible for all activity. If You are registered as a business entity, You personally guarantee that You have the authority to agree to the Terms on behalf of the business.</li>
                    <li>That Your accounts are not transferable.</li>

                    <li>That You are responsible for  all Content. All Content belongs to You, including anything You  post  using the  Services (such as  names,  profile  pictures, listing  photos,  listing descriptions, reviews, comments,  videos, usernames, and Personal Information). You represent that You  have all  necessary  rights to Your  Content and that You’re  not infringing or violating any third party’s rights by posting it.</li>
                    <li>To protect Your password(s).</li>
                    <li>To comply with all applicable local, state, provincial, federal and foreign laws in using the Services,  including without  limitation  all  applicable  privacy  laws,  and laws  for   the protection of Personal Information.</li>
                    <li>Not to engage in fraud, theft, anti-competitive conduct, threatening conduct, or any other unlawful acts or crimes against Rebound , another Rebound user, or a third party.</li>
                    <li>Not to use the Services to engage  in  any deceptive,  misleading,  illegal  or  unethical marketing activities or activities that otherwise may be detrimental to Rebound . </li>
                    <li>Not to collect, use, or disclose any Personal Information in connection with  the Services, unless You have obtained all necessary consents under all applicable laws to do so.</li>
                    <li>Not to attempt to gain unauthorized access to the Services.</li>
                    <li>Not to upload to, or store within,  the Services any false, misleading, infringing, obscene, threatening, defamatory, fraudulent, abusive, or otherwise unlawful or tortious material, including material that is harmful to children or violates third party privacy rights.</li>
                </ol>

                <p className="title">
                    <div>5)</div>
                    <div style={{paddingLeft: '5px'}}>Prohibited Items/ Territory.</div>
                </p>

                <p>You will not use the Services to sell anything that violates any laws, or to sell any of the following types of prohibited items:</p>

                <ol>
                    <li>Illegal Items or items promoting illegal activity.</li>
                    <li>Alcohol, tobacco, drugs or drug paraphernalia.</li>
                    <li>Hazardous materials, recalled items and weapons.</li>
                    <li>Pornography and mature content.</li>
                    <li>Dangerous items.</li>
                    <li>Hate items, such as items that promote, support, or glorify hatred.</li>
                    <li>Animal products and human remains.</li>
                    <li>Internationally restricted items.</li>
                    <li>Violent Items and items that promote, support, or glorify violence.</li>
                </ol>

                <p>The above list is not intended to be exhaustive. We consider many factors before coming to a decision about what is best for Our  users and our  community. Ultimately, however, the decision as to whether or not an item is prohibited rests with Us.</p>

                <p>Rebound will NOT deal or provide any services or products to any of OFAC  (Office  of Foreign
                Assets Control) sanctions countries in accordance with the law of UAE.</p>

                <p className="title">
                    <div>6)</div>
                    <div className="title-column-two">
                        Ownership.
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">6.1</div>
                        <div className="terms-column-two">Your  Content. While you  own  Your Content, by posting Your Content through the Services, You  grant Rebound  a non-exclusive,  worldwide,  royalty-free,  irrevocable,  sub-licensable, perpetual  license  to use,  display,  edit,  modify,  reproduce,  distribute,  store,  and prepare derivative works of Your Content, both to provide the Services and to promote Rebound or the Services in  general,  in  any formats and through any channels,  including across  any other Services or third-party website or advertising medium.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">6.2</div>
                        <div className="terms-column-two">
                            <div>Our  Ownership.</div>
                            <ul className="terms-ul">
                                <li>
                                    <div className="terms-column-one-2">6.2.1</div>
                                    <div className="terms-column-two"> The Services and all Intellectual Property in the Services or made available or disclosed to
                                    You  in the provision of  the Services are and shall  remain the sole  and  exclusive property of 
                                    Rebound and except for the limited license to use the Intellectual Property as part of Your use of  the Services in accordance with  these Terms  (the “License”),  no  right,  title,  or  interest  is granted in the Intellectual Property. Rebound and its affiliates shall own  all rights, title and interest, including all Intellectual Property, in and to any improvements to the Services or any new programs, upgrades, modifications or enhancements thereto, even when such refinements and improvements result from Your requests or comments.</div>
                                </li>
                                <li>
                                    <div className="terms-column-one-2">6.2.2</div>
                                    <div className="terms-column-two">The names “Rebound”  and “Rebound  Plastic,”  and  the other Rebound trademarks, phrases,  slogans, stories, brands,  logos,  and designs that We use in  connection  with  the Services, are trademarks of Rebound (collectively, “Our Marks”). You are not authorized to use Our  Marks in any way except with  our  express consent, in our  sole discretion. In all cases, you may not use Our Marks except for purposes consistent with our core values, namely, to increase public awareness  on  the importance of  using products manufactured  with  recycled plastic coming from developing countries. Further, you will not, in any manner, represent that you have any ownership interest in Our  Marks.</div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>

                <p className="title">
                    <div>7)</div>
                    <div className="title-column-two">Reporting Unauthorised Content.</div>
                </p>
                <p>If  Content that You  own   or  have rights to has been posted to the Services  without Your permission and You  want it removed, You  must let  us know  and we will  use commercially reasonable  efforts  to  remove  such  Content.  If  Your   Content  infringes  another person’s intellectual  property,  or  if  we reasonably  believe  that Your  Content may infringe  another person’s intellectual property, we reserve the right to remove it. We’ll use reasonable efforts to notify You if that happens.</p>

                <p className="title">
                    <div>8)</div>
                    <div className="title-column-two">
                        Termination.
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">8.1</div>
                        <div className="terms-column-two">Termination  by   You. You   may  terminate  Your   account  with   Rebound at  any time. Terminating  Your  account may not affect the availability  of  some of  Your  Content that You posted through the Services prior to termination.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">8.2</div>
                        <div className="terms-column-two"><div>Termination by Us. We may:</div>
                            <ol>
                                <li>terminate or suspend Your  account (and any related accounts) and Your  access to the Services at any time, for any reason, and without advance notice.</li>
                                <li>  refuse service to anyone, at any time, for any reason.</li>
                                <li>change, suspend, or discontinue any of the Services at any time, for any reason and We will not be liable to You for the effect that any changes to the Services may have on You, including Your income or Your ability to generate revenue through the Services.
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <div className="terms-column-one">8.3</div>
                        <div className="terms-column-two">Effects of  Termination. If  You  or  Rebound terminate  Your  account,  You  may lose  any information associated  with  Your  account,  including Your  Content.  Further,  the Terms  will remain in effect even after Your access to the Services is terminated, or Your use of the Services ends.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>9)</div>
                    <div className="title-column-two">Membership Fees.</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">9.1</div>
                        <div className="terms-column-two">The Rebound online Trading Marketplace offers various membership plans with associated fees. By selecting a membership plan, you agree to pay the specified fees according to the billing terms provided.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.2</div>
                        <div className="terms-column-two">Membership fees are non-refundable unless otherwise specified in writing. Refunds will be done  only through the  Original Mode of Payment. Rebound  reserves  the  right to  change membership fees with prior notice.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.3</div>
                        <div className="terms-column-two">Failure to pay membership fees may result in the suspension or termination of your membership.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.4</div>
                        <div className="terms-column-two">Rebound accepts payments online using Visa and MasterCard credit/debit card in USD (or any other agreed currency). All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>10)</div>
                    <div className="title-column-two">Warranties and Limitation of Liability.</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">10.1</div>
                        <div className="terms-column-two">Items You Purchase. You understand and agree that Rebound does not manufacture or store, any of the items sold through our Services. We provide the venue only, and the items in our marketplaces are produced, listed, and sold directly by independent sellers. So, Rebound can’t and does not make any warranties about their quality, safety, or even their legality. Any legal claim related to an item You purchase must be brought directly against the seller of the item. You release Rebound from any claims related to items sold through our Services, including for defective items, misrepresentations by sellers, or items that caused physical injury (like product liability claims).</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.2</div>
                        <div className="terms-column-two">Content  You  Access.  You  may  come  across  materials  that  You  find  offensive  or inappropriate while using Our Services. We make no representations concerning any content posted by users through the Services. Rebound is not responsible for the accuracy, copyright compliance, legality, or decency of content posted by users that You accessed through the Services. You release us from all liability relating to that content.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.3</div>
                        <div className="terms-column-two">People You Interact With. If You use the Services to interact with other individuals, either online or in person, You do so on the understanding that we do not screen users of our Services, and You release us from all liability relating to Your interactions with other users.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.4</div>
                        <div className="terms-column-two">Third-Party Services. Our Services may contain links to third-party websites or services that we don’t own or control. You may also need to use a third party’s product or service in order to use some of our Services. When You access these third-party services, You do so at Your own risk. The third parties may require You to accept their own terms of use. Rebound is not a party to those agreements; they are solely between You and the third party.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.5</div>
                        <div className="terms-column-two">Rebound Rewards, Coupons and Promotions. Rebound may offer special promotions, rewards, coupons, or other promotions (collectively, “Rewards”). You acknowledge that Rebound  does not make any warranties with respect to Your Rewards and is not responsible for any unauthorised access to, or alteration, theft, or destruction of Rewards or Rewards codes that results from any action by You or a third party. You also acknowledge that We may suspend or prohibit use of Your Rewards if Your account has been reported or flagged as hacked or stolen, or if we believe Your account is being used suspiciously, fraudulently, or in an otherwise unauthorised manner. By participating in a special offer or promotion, You agree that You may not later claim that the rules of that special offer or promotion were ambiguous.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.6</div>
                        <div className="terms-column-two">Warranties. You understand and agree that the Services are provided “as is” and without any kind of warranty (express or  implied). We expressly disclaim any warranties of title, non- infringement, merchantability, and fitness for  a particular purpose, as well as any warranties implied by a course of performance, course of dealing, or usage of trade. We do not guarantee that: (i) the Services will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) the Services will be free of viruses or other harmful materials; or (iv) the results of using the Services will meet Your expectations. You use the Services solely at Your  own  risk. In jurisdictions that do  not allow limitations on  implied warranties, the above limitations may not apply to You.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.7</div>
                        <div className="terms-column-two">Limitations of  Liability. To the fullest extent permitted by law, neither Rebound, nor  our employees, directors, officers, shareholders, or  contractors shall be liable to You for  any lost profits or revenues, or for  any consequential, incidental, indirect, special, or punitive damages arising out of or  in connection with  the Services or  these Terms. In no  event shall Rebound ’s aggregate liability for  any damages exceed the greater of one hundred ($100) Dollars or  the amount You  paid Rebound in  the past twelve  months. In  jurisdictions  that do   not allow limitations on incidental or consequential damages, the above limitations may not apply to You.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>11)</div>
                    <div className="title-column-two">Indemnification.</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">11.1</div>
                        <div className="terms-column-two"> You agree to defend and indemnify Us against any claims  by  any third parties resulting from something that You did.  You will defend  Rebound (including any of our  employees) and hold Us harmless from any legal claim or demand (including reasonable attorney’s fees) that arises from Your  actions,  Your  use (or  misuse) of our  Services, Your  breach of the Terms, or Your account’s infringement of someone else’s rights.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">11.2</div>
                        <div className="terms-column-two">
                            We reserve the right  to handle  our  legal  defense however we see fit,  even if  You  are indemnifying us, in which  case You agree to cooperate with Us so we can execute our  strategy. Disputes with Other Users. If You find Yourself in a dispute with another user of Our  Services or  a third party, we encourage You to contact the other party and try to resolve the dispute amicably. Buyers and sellers who  are unable to resolve a dispute related to a transaction on
                            our   websites may participate in  our complaints system.  Rebound will  attempt to  help  You resolve disputes in good faith and based solely on our  interpretation of our  policies, in our  sole discretion;  we will  not make judgments  regarding legal  issues or  claims. Rebound has  no obligation to resolve  any disputes. You  release  Rebound from any claims, demands,  and damages arising out of disputes with other users or parties.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>12)</div>
                    <div className="title-column-two">
                        Disputes with  Rebound
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">12.1</div>
                        <div className="terms-column-two">Governing Law  and Forum.  The Terms  are governed by the federal laws  of Abu  Dhabi Global Markets (ADGM), Abu Dhabi, U.A.E., without regard to its conflict of laws rules. These laws will apply no matter where in the world You live.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">12.2</div>
                        <div className="terms-column-two">Arbitration, No Class Action, and No Jury. Any dispute or claim  arising from or relating to the Terms shall be finally settled by final and binding arbitration, using the English language, pursuant to the Arbitration Act [RSBC 1996] chapter 55 (the “Arbitration Act”). Arbitration will be handled by  a sole  arbitrator.  Judgment  on  the arbitration award may be entered in any 
                        court that has jurisdiction. Any arbitration under the Terms will take place on an individual basis and class arbitrations and class actions are not permitted. You understand that by agreeing to the Terms, You and Rebound are each waiving the right to trial by  jury or  to participate in a class action or class arbitration. Notwithstanding the foregoing, each party shall have the right to  bring an  action in  a  court of  proper  jurisdiction  for   injunctive  or   other  equitable  or conservatory relief, pending a final decision by the arbitrator.
                        </div>
                    </li>
                </ul>

                <p className="title">
                    <div>13)</div>
                    <div className="title-column-two">Changes to  the Terms.</div>
                </p>
                <p>
                    We  may, at our  discretion, update, replace or revise any part of these Terms from time to time. Any changes made to these Terms will take effect immediately upon being published on this Website. It is your  responsibility to check these Terms regularly for any changes or  updates. Your  continued use of  this Website following the amendment of  these Terms shall constitute your  acceptance of those changes. Nothing herein shall be construed to limit the right of Rebound to warn, suspend or terminate access to the Website of any user who breaches these Terms, provides  inadequate  authentication of  identity,  or  Rebound,  in  its discretion, determines that the users’ activities poses possible legal or business risk to anyone, including Rebound.
                </p>
                    

                <p className="title">
                    <div>14)</div>
                    <div className="title-column-two">General</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">14.1</div>
                        <div className="terms-column-two">Our   Relationship.  These  Terms  don’t  create  any  agency, partnership,  joint  venture, employment, or  franchisee relationship between You  and Rebound. You  will not represent Yourself as having any authority to act for or undertake any obligation on behalf of Rebound.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.2</div>
                        <div className="terms-column-two">Entire  Agreement. The Terms,  including  all  of  the policies  that  make up the Terms, supersede any other agreement between You and Rebound regarding the Services. If any part of  the Terms  is found to be unenforceable,  that part will  be limited  to the minimum extent necessary so that the Terms will otherwise remain in full force and effect. Our  failure to enforce any part of the Terms is not a waiver of our  right to later enforce that or any other part of the Terms.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.3</div>
                        <div className="terms-column-two">Assignment. We may assign any of  our   rights and obligations  under  the  Terms. This Agreement may not be transferred or assigned by You without Our prior written consent, in our sole discretion.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.4</div>
                        <div className="terms-column-two">Enurement. The terms and conditions of this Agreement shall enure to the benefit of and be enforceable by the parties hereto and their permitted successors and assigns.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.5</div>
                        <div className="terms-column-two">Notices  and Contact Information.  If You  have  any questions  about  the Terms, please email us at info@reboundplastic.com. Rebound may provide You with certain legal information in writing. You  are agreeing that we can send You  information electronically (by  email, etc.) instead of mailing You paper copies, and that Your  electronic agreement is the same as Your
                        Signature onpaper.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.6</div>
                        <div className="terms-column-two">Headings. The headings used in the Agreement are for  convenience and reference only and shall not affect the construction or interpretation of this Agreement.</div>
                    </li>
                </ul>


            </div>
        </div>
    );
}

export default TermsAndCondition;

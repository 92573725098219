import React from "react";
// import "./Brochures.css";
import ButtonFilledColor from "../../Components/RightIconButtonArabic";
import Downloadicon from "../../Assets/Images/u_arrow-to-bottom (1).png";
import backgroundimg from "../../Assets/Images/fgb2.png";
import { useTranslation } from "react-i18next";
import RightIconButtonArabic from "../../Components/RightIconButtonArabic";

function BrochuresArabic() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="container-brochures"
        style={{ marginTop: 35 }}
      >
        {/* <img src={backgroundimg}  style={{width:'100%', height:'auto'}} alt="Background" /> */}
        <div>
          <div className="center-content">
            <p className="blur-text">{t('brochures_description1')}</p>
          </div>

          <div
            className="brochurebutton"

          >
            <RightIconButtonArabic
              classname={'brochurebuttoncont'}
              paraclass={'brochurebuttonfont'}
              icon={Downloadicon}
              title={t('download_now')}
              width="30%"
              onClick={() => { }}
            />
          </div>
          <div className="center-content f_regular f-18 lh-sm"
            style={{ marginBottom: 40 }}
          >
           {t('brochures_description2')}
            <p>{t('brochures_description3')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrochuresArabic;

import React, { useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../Assets/Images";
import { getCurrentDimension } from "../../Utils/Helper";
import MacBook from "../../Assets/SVGs/icon/MacBook";

var margin_bottom = "2%";
var diff = 68;
var imageWidth = 20;

const PlatformOverView = ({ navigation }) => {
  const scrollerRef = useRef();

  const [width, setWidth] = useState(window?.innerWidth);
  const [scrollWidth, setScrollWidth] = useState(1);
  const dimensions = getCurrentDimension();
  const singleContainerWidth = dimensions.width / 4;

  const handlePreviousSlide = () => {
    if (scrollWidth > 1) {
      scrollerRef.current.scrollTo({
        left:
          scrollWidth -
          (singleContainerWidth + singleContainerWidth / 2 + 20 + imageWidth),
        behavior: "smooth",
      });
      setScrollWidth(
        scrollWidth -
          (singleContainerWidth + singleContainerWidth / 2 + 20 + imageWidth)
      );
    }
  };

  const handleNextSlide = () => {
    if (
      (singleContainerWidth + singleContainerWidth / 2 + 20 + imageWidth) * 4 >
      scrollWidth
    ) {
      scrollerRef.current.scrollTo({
        left:
          singleContainerWidth +
          singleContainerWidth / 2 +
          20 +
          imageWidth +
          scrollWidth,
        behavior: "smooth",
      });
      setScrollWidth(
        singleContainerWidth +
          singleContainerWidth / 2 +
          20 +
          imageWidth +
          scrollWidth
      );
    }
  };

  // if (1500 == dimensions.width <= 1800) {
  //   margin_bottom = "2%";
  //   diff = 145;
  //   imageWidth = 20;
  // }

  // if (1800 <= dimensions.width <= 2100) {
  //   margin_bottom = "2%";
  //   diff = 145;
  //   imageWidth = 20;
  // }
  // ......................................................
  console.log("dimensions.width", dimensions.width, dimensions.height);
  if (dimensions.width >= 390 && dimensions.height <= 884) {
    margin_bottom = "2%";
    diff = 15;
    imageWidth = 5;
  }

  if (dimensions.width >= 430 && dimensions.height <= 932) {
    margin_bottom = "2%";
    diff = 15;
    imageWidth = 5;
  }

  if (dimensions.width >= 412 && dimensions.height <= 915) {
    margin_bottom = "2%";
    diff = 15;
    imageWidth = 5;
  }

  if (dimensions.width >= 1024 && dimensions.width <= 1440) {
    margin_bottom = "2%";
    diff = 70;
    imageWidth = 20;
  }
  if (dimensions.width >= 320 && dimensions.width <= 360) {
    margin_bottom = "2%";
    diff = 12;
    imageWidth = 5;
  }
  // if (dimensions.width >= 1680 && dimensions.width<=2000) {
  //   margin_bottom = "2%";
  //   diff = 145;
  //   imageWidth = 20;
  // }

  if (dimensions.width >= 750 && dimensions.height <= 1334) {
    margin_bottom = "2%";
    diff = 35;
    imageWidth = 15;
  }
  if (dimensions.width >= 828 && dimensions.height <= 1792) {
    margin_bottom = "2%";
    diff = 35;
    imageWidth = 15;
  }
  if (dimensions.width >= 1080 && dimensions.height <= 1920) {
    margin_bottom = "2%";
    diff = 50;
    imageWidth = 18;
  }
  if (dimensions.width >= 1080 && dimensions.height <= 2340) {
    margin_bottom = "2%";
    diff = 50;
    imageWidth = 18;
  }
  if (dimensions.width >= 1125 && dimensions.height <= 2436) {
    margin_bottom = "2%";
    diff = 50;
    imageWidth = 18;
  }
  if (dimensions.width >= 1242 && dimensions.height <= 2688) {
    margin_bottom = "2%";
    diff = 50;
    imageWidth = 18;
  }
  if (dimensions.width >= 1170 && dimensions.width <= 2532) {
    margin_bottom = "2%";
    diff = 60;
    imageWidth = 18;
  }
  if (dimensions.width >= 1284 && dimensions.width <= 2778) {
    margin_bottom = "2%";
    diff = 60;
    imageWidth = 18;
  }
  if (dimensions.width >= 1440 && dimensions.width <= 1800) {
    margin_bottom = "2%";
    diff = 83;
    imageWidth = 20;
  }

  if (dimensions.width > 1800 && dimensions.width <= 2100) {
    margin_bottom = "2%";
    diff = 115;
    imageWidth = 20;
  }

  if (dimensions.width > 2100 && dimensions.width <= 2400) {
    margin_bottom = "2%";
    diff = 135;
    imageWidth = 20;
  }

  if (dimensions.width > 2400 && dimensions.width <= 2700) {
    margin_bottom = "2%";
    diff = 140;
    imageWidth = 20;
  }

  if (dimensions.width >= 2700 && dimensions.width <= 3000) {
    margin_bottom = "2%";
    diff = 175;
    imageWidth = 20;
  }

  if (dimensions.width >= 3000 && dimensions.width <= 3840) {
    margin_bottom = "2%";
    diff = 175;
    imageWidth = 20;
  }
  if (dimensions.width >= 3840 && dimensions.width <= 4000) {
    margin_bottom = "2%";
    diff = 216;
    imageWidth = 25;
  }

  if (dimensions.width >= 4000 && dimensions.width <= 4000) {
    margin_bottom = "2%";
    diff = 235;
    imageWidth = 40;
  }

  // // ......................................................

  // 2560
  // let margin_bottom = "2%";
  // let diff = 145;
  // let imageWidth = 20

  // 1440
  // let margin_bottom = "2%";
  // let diff = 145;
  // let imageWidth = 20

  // 1500   : 300
  // let margin_bottom = "2%";
  // let diff = 80;
  // let imageWidth = 20
  // let imageWidth = 20

  // 1800   : 300, 20
  // let margin_bottom = "2%";
  // let diff = 100;
  // let imageWidth = 20

  // 2100   : 300, 15
  // let margin_bottom = "2%";
  // let diff = 115;
  // let imageWidth = 20

  // 2400   : 300, 20
  // let margin_bottom = "2%";
  // let diff = 135;
  // let imageWidth = 20

  // 2700   : 300, 20
  // let margin_bottom = "2%";
  // let diff = 155;
  // let imageWidth = 20

  // 3000   : 300, 20
  // let margin_bottom = "2%";
  // let diff = 175;
  // let imageWidth = 20

  // 3840   : 300, 20, w:20
  // let margin_bottom = "2%";
  // let diff = 155;
  // let imageWidth = 40

  return (
    <>
      <div
        style={{
          width: dimensions.width,
          display: "flex",
          alignSelf: "flex-end",
          justifyContent: "center",
          flexDirection: "row",
          position: "relative",
          height: "100%",
          // height: singleContainerWidth * 2,
        }}
      >
        <div
          className="platform-container-row-flex"
          style={{ width: dimensions.width,
            direction:'ltr',
          }}
          ref={scrollerRef}
        >
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "flex-start",
              flexDirection: "row",

              zIndex: 90,
              width: dimensions.width,
            }}
          >
            {IMAGE_DATA.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      overflow: "hidden",
                      marginLeft: index === 0 ? singleContainerWidth + diff : 0,
                      marginRight:
                        IMAGE_DATA.length - 1 === index
                          ? singleContainerWidth + diff
                          : 0,
                      marginBottom: margin_bottom,

                    }}
                  >
                    <img
                      className="platform-image"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        width:
                          singleContainerWidth +
                          singleContainerWidth / 2 +
                          imageWidth,
                        // singleContainerWidth + singleContainerWidth / 2 + 20,
                      }}
                      src={item.src}
                      alt="Image"
                      layout="responsive"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          className="mac-book-image-container"
          style={{
            // width: "100%",
            // height: "100%",
            width: singleContainerWidth * 2,
            // height: singleContainerWidth ,
          }}
        >
          <img
            src={IMAGES.MacBookImage}
            alt="Image"
            className="mac-book-image"
          />
        </div>
      </div>

      <div className="materialstradedcontrols">
        <img
          className="materialstradedcontrolsimg"
          style={{
            height: 48,
            width: 48,
            marginRight: 10,
            transform: `rotate(${180}deg)`,
          }}
          src={IMAGES.forwordArrow}
          onClick={() => handleNextSlide()}
        />
        <img
          className="materialstradedcontrolsimg"
          style={{ height: 48, width: 48, marginLeft: 10 }}
          src={IMAGES.forwordArrow}
          onClick={() => handlePreviousSlide()}
        />
      </div>
    </>
  );
};

export default PlatformOverView;

const IMAGE_DATA = [
  {
    id: 1,
    src: IMAGES.platformImage1,
    isEmpty: true,
  },
  {
    id: 2,
    src: IMAGES.platformImage2,
  },
  {
    id: 3,
    src: IMAGES.platformImage3,
  },
  {
    id: 4,
    src: IMAGES.platformImage4,
  },
  {
    id: 5,
    src: IMAGES.platformImage5,
    isEmpty: true,
  },
];

function BGImage(props) {
  return (
    <div
      style={{
        background: "url(" + props.bg + ")",
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          backgroundColor: props.tint,
          zIndex: "100",
          opacity: "0.5",
        }}
      />
    </div>
  );
}

import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ArabicTopTabBar = ({ tabBarData, selected_tab, clickTab }) => {
  const [state, setState] = useState(tabBarData || []);
  const [colorChange, setColorchange] = useState(false);
  const {t}=useTranslation()

  const routeName = useLocation();

  useEffect(() => {
    setState(tabBarData);
  }, [tabBarData]);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  useEffect(() => {
    handleClick(selected_tab);
  }, [selected_tab]);

  const handleClick = (selectedItem) => {
    clickTab && clickTab(selectedItem);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem ? true : false,
      };
    });
    setState(new_data);
  };

  const renderList = () => {
    return state?.map((item, index) => {
      if (index === 0) {
        return (
          <div key={index} className="Arabic-list-container">
            <div>
              <p className="text_secondTb">{t(item.title)}</p>
            </div>
            <div
              className={
                routeName?.pathname === "/about-us" ? "down-div2" : "Arabic-down-div"
              }
              style={{
                background: colorChange
                  ? "#000"
                  : routeName?.pathname === "/about-us"
                  ? "#000"
                  : "#fff",
              }}
            />
          </div>
        );
      } else {
        return (
          <div
            key={index}
            style={{
              marginRight: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              handleClick(item?.id);
              clickTab && clickTab(item?.id,true);
            }}
          >
            <p
              style={{
                textDecoration: item.isSelected ? " underline" : "none",
                color:
                  item.isSelected && routeName?.pathname === "/about-us"
                    ? "#00578A"
                    : colorChange && item.isSelected
                    ? "#00578A"
                    : item.isSelected
                    ? "#FFFF"
                    : colorChange && !item.isSelected
                    ? "grey"
                    : "#D7D7D7",
                // #D7D7D7
                fontFamily: item.isSelected ? "nexa" : "nexa-regular",
                textTransform: "none",
              }}
              className="text_secondTb2"
            >
              {t(item.title)}
            </p>
          </div>
        );
      }
    });
  };
  return (
      <div
        className="Arabic-list-container1"
        style={{
          backgroundColor: colorChange ? "white" : "transparent",
        }}
      >
        {renderList()}
      </div>
    // </div>
  );
};

export default ArabicTopTabBar;

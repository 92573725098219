import React from "react";
import { IMAGES } from "../Assets/Images";
import { useTranslation } from "react-i18next";

const CountryCardResoucesArabic = ({heading,}) => {
  const{t}=useTranslation()
  
  return (
    <div className="Arabic-country-card-Container">
      <div>
        <img
          className="Arabic-country-Board-Image"
          src={IMAGES.countryImage}
          alt="country Image"
        />
        <div className="Arabic-country-name-and-details-Container">
          <p className="Arabic-countryName">
            {/* {t('Japan')} */}
            {heading}
            </p>
          <div className="Arabic-country-right-textContainer">
            <p className="Arabic-countryCard-right-text">
            <a href="mailto:info@reboundplastic.com" className="Arabic-countryCard-right-text">{t('email_for_detail')}</a>
              
              </p>

            <div className="Arabic-country-card-arrow-background">
              <img
                src={IMAGES.upLeftArrow}
                style={{ height: "80%", width: "80%",padding:'15%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryCardResoucesArabic;

import React, {  useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "Assets/Images";
import { useTranslation } from "react-i18next";
import GrowthAndEnergyDevelopment from '../../Assets/Images/GrowthAndEnergyDevelopment.jpg'

const ArabicEvent = () => {
  const { t } = useTranslation();
  const [itemsToShow, setItemsToShow] = useState(4);
  const [itemsToShow2, setItemsToShow2] = useState(4);
  const allItems = data;
  const allItems2 = data_us_events;

  const loadMore = () => {
    if (itemsToShow === 4) {
      setItemsToShow(itemsToShow + 8);
    } else {
      setItemsToShow(itemsToShow - 4);
    }
  };

  const loadMore2 = () => {
    if (itemsToShow2 === 4) {
      setItemsToShow2(itemsToShow2 + 8);
    } else {
      setItemsToShow2(itemsToShow2 - 4);
    }
  };
  return (
    <div>
      <div className="text_event_heading">
        {t("Explore_the_events_on_our_horizon_and_meet_us_there")}
      </div>
      <div className="text_event_top">{t("Rebound_upcoming_appearances")}</div>
      <Container>
        <Row>
          {
            // data.map((item, index) =>
            allItems.slice(0, itemsToShow).map((item, index) => (
              <Col sm={2} xs={12} md={6} lg={6} key={index}>
                <div className="tile_events">
                  <img
                    src={item?.image}
                    alt=""
                    style={{ height: 230, width: "100%" }}
                  />
                  <div className="adate_text_card">{t(item?.date)}</div>
                  <div className="aname_card_events">{t(item?.name)}</div>
                  <div className="atext_country_events">{t(item?.place)}</div>
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>

      <div className="text_button_contaner pointer">
        {data.length > 4 && (
          <div
            className="button_more"
            style={{ zIndex: 1000 }}
            onClick={() => loadMore()}
          >
            <p className="load_More_text">
              {itemsToShow > 4 ? t("load_less") : t("load_more")}
            </p>
            {itemsToShow > 4 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.0002 14.8303C16.8128 15.0166 16.5594 15.1211 16.2952 15.1211C16.031 15.1211 15.7776 15.0166 15.5902 14.8303L12.0002 11.2903L8.46019 14.8303C8.27283 15.0166 8.01938 15.1211 7.75519 15.1211C7.49101 15.1211 7.23756 15.0166 7.05019 14.8303C6.95646 14.7373 6.88207 14.6267 6.8313 14.5049C6.78053 14.383 6.75439 14.2523 6.75439 14.1203C6.75439 13.9883 6.78053 13.8576 6.8313 13.7357C6.88207 13.6139 6.95646 13.5033 7.05019 13.4103L11.2902 9.1703C11.3832 9.07657 11.4938 9.00218 11.6156 8.95141C11.7375 8.90064 11.8682 8.8745 12.0002 8.8745C12.1322 8.8745 12.2629 8.90064 12.3848 8.95141C12.5066 9.00218 12.6172 9.07657 12.7102 9.1703L17.0002 13.4103C17.0939 13.5033 17.1683 13.6139 17.2191 13.7357C17.2699 13.8576 17.296 13.9883 17.296 14.1203C17.296 14.2523 17.2699 14.383 17.2191 14.5049C17.1683 14.6267 17.0939 14.7373 17.0002 14.8303Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M17.4997 9.1697C17.3123 8.98345 17.0589 8.87891 16.7947 8.87891C16.5305 8.87891 16.2771 8.98345 16.0897 9.1697L12.4997 12.7097L8.9597 9.1697C8.77234 8.98345 8.51889 8.87891 8.2547 8.87891C7.99052 8.87891 7.73707 8.98345 7.5497 9.1697C7.45598 9.26266 7.38158 9.37326 7.33081 9.49512C7.28004 9.61698 7.25391 9.74769 7.25391 9.8797C7.25391 10.0117 7.28004 10.1424 7.33081 10.2643C7.38158 10.3861 7.45598 10.4967 7.5497 10.5897L11.7897 14.8297C11.8827 14.9234 11.9933 14.9978 12.1151 15.0486C12.237 15.0994 12.3677 15.1255 12.4997 15.1255C12.6317 15.1255 12.7624 15.0994 12.8843 15.0486C13.0061 14.9978 13.1167 14.9234 13.2097 14.8297L17.4997 10.5897C17.5934 10.4967 17.6678 10.3861 17.7186 10.2643C17.7694 10.1424 17.7955 10.0117 17.7955 9.8797C17.7955 9.74769 17.7694 9.61698 17.7186 9.49512C17.6678 9.37326 17.5934 9.26266 17.4997 9.1697Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        )}
        <div className="text_event_top">{t("catchupUmissed_up")}</div>
      </div>
      <div style={{}}>
        <Container>
          <Row>
            {allItems2.slice(0, itemsToShow2).map((item, index) => (
              <Col xs={12} md={6} lg={6} key={index}>
                <div className="tile_events" style={{height:"85%"}}>
                  <img src={item?.image} className="eventsimg" style={{ height:320}} alt=""/>
                  {/* <p className="date_text_card">{t("")}</p> */}
                  <div style={{ height: 50 }} />
                  <div className="aname_card_events">{t(item?.name)}</div>
                  <div className="atext_country_events">{t(item?.place)}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div
        className="button_more mt-4 mb-5  pointer"
        style={{
          marginTop: "-15%",
          zIndex: 1000,
        }}
        onClick={() => loadMore2()}
      >
        <p className="load_More_text">
          {itemsToShow2 > 4 ? t("load_less") : t("load_more")}
        </p>
        {itemsToShow2 > 4 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.0002 14.8303C16.8128 15.0166 16.5594 15.1211 16.2952 15.1211C16.031 15.1211 15.7776 15.0166 15.5902 14.8303L12.0002 11.2903L8.46019 14.8303C8.27283 15.0166 8.01938 15.1211 7.75519 15.1211C7.49101 15.1211 7.23756 15.0166 7.05019 14.8303C6.95646 14.7373 6.88207 14.6267 6.8313 14.5049C6.78053 14.383 6.75439 14.2523 6.75439 14.1203C6.75439 13.9883 6.78053 13.8576 6.8313 13.7357C6.88207 13.6139 6.95646 13.5033 7.05019 13.4103L11.2902 9.1703C11.3832 9.07657 11.4938 9.00218 11.6156 8.95141C11.7375 8.90064 11.8682 8.8745 12.0002 8.8745C12.1322 8.8745 12.2629 8.90064 12.3848 8.95141C12.5066 9.00218 12.6172 9.07657 12.7102 9.1703L17.0002 13.4103C17.0939 13.5033 17.1683 13.6139 17.2191 13.7357C17.2699 13.8576 17.296 13.9883 17.296 14.1203C17.296 14.2523 17.2699 14.383 17.2191 14.5049C17.1683 14.6267 17.0939 14.7373 17.0002 14.8303Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M17.4997 9.1697C17.3123 8.98345 17.0589 8.87891 16.7947 8.87891C16.5305 8.87891 16.2771 8.98345 16.0897 9.1697L12.4997 12.7097L8.9597 9.1697C8.77234 8.98345 8.51889 8.87891 8.2547 8.87891C7.99052 8.87891 7.73707 8.98345 7.5497 9.1697C7.45598 9.26266 7.38158 9.37326 7.33081 9.49512C7.28004 9.61698 7.25391 9.74769 7.25391 9.8797C7.25391 10.0117 7.28004 10.1424 7.33081 10.2643C7.38158 10.3861 7.45598 10.4967 7.5497 10.5897L11.7897 14.8297C11.8827 14.9234 11.9933 14.9978 12.1151 15.0486C12.237 15.0994 12.3677 15.1255 12.4997 15.1255C12.6317 15.1255 12.7624 15.0994 12.8843 15.0486C13.0061 14.9978 13.1167 14.9234 13.2097 14.8297L17.4997 10.5897C17.5934 10.4967 17.6678 10.3861 17.7186 10.2643C17.7694 10.1424 17.7955 10.0117 17.7955 9.8797C17.7955 9.74769 17.7694 9.61698 17.7186 9.49512C17.6678 9.37326 17.5934 9.26266 17.4997 9.1697Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default ArabicEvent;

const data = [
 
  // {
  //   image: IMAGES.events_img3,
  //   date: "eventsDate3",
  //   name: "eventName3",
  //   place: "event_Place3",
  // },
  // {
  //   image: IMAGES.events_img4,
  //   date: "eventsDate4",
  //   name: "eventName4",
  //   place: "event_Place4",
  // },
  // {
  //   image: IMAGES.events_img5,
  //   date: "eventsDate5",
  //   name: "eventName5",
  //   place: "event_Place5",
  // },
];
const data_us_events = [
  {
    image: IMAGES.COP28,
    date: "eventsDate3",
    name: "eventName3",
    place: "event_Place3",
  },
  {
    image: GrowthAndEnergyDevelopment,
    date: "eventsDate13",
    name: "eventName13",
    place: "event_Place13",
  },
{
    image: IMAGES.ArabPlast,
    date: "eventsDate5",
    name: "eventName5",
    place: "event_Place5",
  },
  {
    image: IMAGES.events_img1,
    date: "eventsDate1",
    name: "eventName1",
    place: "event_Place1",
  },
  {
    image: IMAGES.events_img2,
    date: "eventsDate2",
    name: "eventName2",
    place: "event_Place2",
  },
  {
    image: IMAGES.Innovation_forum,
    date: "event_Seconds_Date1",
    name: "event_SecondN_ame1",
    place: "event_Second__Place1",
  },

  {
    image: IMAGES.T_Plas,
    date: "event_Seconds_Date2",
    name: "event_SecondN_ame2",
    place: "event_Second__Place2",
  },
  {
    image: IMAGES.PRS_MENA,
    date: "event_Seconds_Date3",
    name: "event_SecondN_ame3",
    place: "event_Second__Place3",
  },
  {
    image: IMAGES.plastic_future,
    date: "event_Seconds_Date4",
    name: "event_SecondN_ame4",
    place: "event_Second__Place4",
  },
  {
    image: IMAGES.Plastic_world_expo,
    date: "event_Seconds_Date5",
    name: "event_SecondN_ame5",
    place: "event_Second__Place5",
  },
  {
    image: IMAGES.equiplast,
    date: "event_Seconds_Date6",
    name: "event_SecondN_ame6",
    place: "event_Second__Place6",
  },
  {
    image: IMAGES.GPCA,
    date: "event_Seconds_Date7",
    name: "event_SecondN_ame7",
    place: "event_Second__Place7",
  },
  {
    image: IMAGES.weseeequal,
    date: "event_Seconds_Date8",
    name: "event_SecondN_ame8",
    place: "event_Second__Place8",
  },
  {
    image: IMAGES.MPRA,
    date: "event_Seconds_Date9",
    name: "event_SecondN_ame9",
    place: "event_Second__Place9",
  },
  {
    image: IMAGES.plastindia,
    date: "event_Seconds_Date10",
    name: "event_SecondN_ame10",
    place: "event_Second__Place10",
  },
  {
    image: IMAGES.ecowaste,
    date: "event_Seconds_Date11",
    name: "event_SecondN_ame11",
    place: "event_Second__Place11",
  },

  {
    image: IMAGES.Recoup,
    date: "event_Seconds_Date12",
    name: "event_SecondN_ame12",
    place: "event_Second__Place12",
  },
];

// custume slider

{
  /* <div>
<div style={{ height: "55vh", overflow: "hidden" }}>
  <div className="Scrollmenu" ref={scrollElement}>
    {images.map((item, i) => (
      <img
        key={i}
        src={item.image}
        alt="image"
        style={{ width: "75%" }}
      />
    ))}
  </div>
</div>
<div className="bottom_row_slider">
  <img
    onClick={() => scrollL(width / 2 + 250)}
    src={IMAGES.Left_icon}
    alt="image"
    style={{
      display: "block",
      height: "4%",
      width: "4%",
    }}
  />
  <img
    onClick={() => scrollR(width / 2 + 250)}
    src={IMAGES.Right_icon}
    alt="image"
    style={{
      display: "block",
      height: "4%",
      width: "4%",
    }}
  />
</div>
</div> */
}

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "Assets/Images";
import ArabicSetsUpApart from "Components/ArabicSetsUpApart";
import { useTranslation } from "react-i18next";
import { BackSide, Flippy, FrontSide } from "react-flippy";


const ArabicWhatSetUsApart = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div style={{ marginTop: "0%" }}>
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent:"center",
            paddingInline:"12px"
          }}
        >
          <div className="materialtradedinnercontainer"style={{flexDirection:"column"}}>
            <p className="materialtradedheading">
              {t("whats_sets_us_apart_description1")}
            </p>

            <p className="materialtradedheading">
              {t("whats_sets_us_apart_description2")}
            </p>
          </div>
        </div>

        <Container>
          <Row style={{ width: "100%" }}>
            <Col>
              <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                className="whatsetupapartcards"
                style={{ marginBottom: 23 }}
              >
                <FrontSide
                  style={{
                    backgroundColor: "#00578A",
                  }}
                  animationDuration={1000}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Quality_Assurance"}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_award2}
                    isRotate={false}
                  />
                </FrontSide>

                <BackSide
                  animationDuration={1000}
                  style={{ backgroundColor: "#CAE5E6" }}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Quality_Assurance"}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_award}
                    isRotate={true}
                    title={"quality_Assurance_description"}
                  />
                </BackSide>
              </Flippy>

              <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                className="whatsetupapartcards"
                style={{ marginBottom: 15 }}
              >
                <FrontSide
                  style={{
                    backgroundColor: "#00578A",
                  }}
                  animationDuration={1000}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"golobal_market_access"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_map}
                    isRotate={false}
                  />
                </FrontSide>

                <BackSide
                  animationDuration={1000}
                  style={{ backgroundColor: "#CAE5E6" }}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"golobal_market_access"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_map2}
                    isRotate={true}
                    title={"golobal_market_access_Description"}
                  />
                </BackSide>
              </Flippy>
            </Col>
            <Col>
              <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                className="whatsetupapartcards"
                style={{ marginBottom: 23 }}
              >
                <FrontSide
                  style={{
                    backgroundColor: "#00578A",
                  }}
                  animationDuration={1000}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Time_&_Cost_Savings"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.doller}
                    isRotate={false}
                  />
                </FrontSide>

                <BackSide
                  animationDuration={1000}
                  style={{ backgroundColor: "#CAE5E6" }}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Time_&_Cost_Savings"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_dollar2}
                    isRotate={true}
                    title={"Time_&_Cost_Savings_description"}
                  />
                </BackSide>
              </Flippy>

              <Flippy
                flipOnHover={true}
                flipDirection="horizontal"
                className="whatsetupapartcards"
              >
                <FrontSide
                  style={{
                    backgroundColor: "#00578A",
                  }}
                  animationDuration={1000}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Secure_transactions"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_shield}
                    isRotate={false}
                  />
                </FrontSide>

                <BackSide
                  animationDuration={1000}
                  style={{ backgroundColor: "#CAE5E6" }}
                  className="whatsetupapartcards"
                >
                  <ArabicSetsUpApart
                    heading={"Secure_transactions"}
                    style={{ background: "#00578A", position: "relative" }}
                    titleLast="Hover_to_explore"
                    Icon={IMAGES.u_shield2}
                    isRotate={true}
                    title={"Secure_transactions_description"}
                  />
                </BackSide>
              </Flippy>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ArabicWhatSetUsApart ;
import doller from "../Images/doller.png";
import Bales from "../Images/Bales.png";
import Pellets from "../Images/Pellets.png";
import Flakes from "../Images/Flakes.png";
import imageCareer from "../Images/imageCareer.png";
import MacBookImage from "../Images/MacBookImage.png";
import DemoImg from "../Images/Demo-img.png";
import mapsImage from "../Images/mapsImage.png";
import backgroundImgEvent from "../Images/backgroundImgEvent.png";
import backgroundImgEvent2 from "../Images/backgroundImgEvent2.png";
import backgroundImgEvent3 from "../Images/backgroundImgEvent3.png";
import backgroundImgEvent4 from "../Images/backgroundImgEvent4.png";
import backgroundImgEvent5 from "../Images/backgroundImgEvent5.png";
import Left_icon from "../Images/Left_icon.png";
import Right_icon from "../Images/Right_icon.png";
import imageEvent from "../Images/imageEvent.png";
import test from "../Images/test.png";
import imageBlog1 from "../Images/imageBlog1.png";
import imageBlog2 from "../Images/imageBlog2.png";
import imageBlog3 from "../Images/imageBlog3.png";
import imageBlog4 from "../Images/imageBlog4.png";
import imageBlog5 from "../Images/imageBlog5.png";
import factory from "../Images/factory.png";
import houseboat from "../Images/houseboat.png";
import Bg_tango from "../Images/Bg_tango.png";

import sept20 from "../Images/Sept20.png";
import dec21 from "../Images/dec21.jpeg";
import Sarah from "../Images/sarah safi.jpeg";
import Sabab from "../Images/Shabab said mohd.jpeg";
import linkedin from "../Images/linkedin.png";
import youtube from "../Images/youtube.png";
import tiktok from "../Images/tiktok.png";
import Tapas from "../Images/Tapas sharma.jpeg";
import Frame from "../Images/Frame.png";
import Frame1 from "../Images/Frame1.png";
import Frame_2 from "../Images/Frame_2.png";
import Frame3 from "../Images/Frame3.png";
import Frame2 from "../Images/Frame2.png";
import blue_bg_tango from "../Images/blue_bg_tango.png";
import backgaungRebound from "../Images/backgaungRebound.png";
import benefit_from_services from "../Images/benefit_from_services.jpg";
import solusions_ouer_services from "../Images/solusions_ouer_services.png";
import solusions_ouer_clients from "../Images/solusions_over_clients.jpg";
import platform_Bg from "../Images/platform_Bg.png";

import backgraung_image_ouer_client from "../Images/backgraung_image_ouer_client.png";

import countryImage from "../Images/countryImage.png";
import upRightArrow from "../Images/upRightArrow.png";
import aboutUsShadow from "../Images/AboutUsShadow.png";
import events_img1 from "../Images/events_img1.jpg";
import events_img2 from "../Images/events_img2.png";
import events_img3 from "../Images/events_img3.png";
import events_img4 from "../Images/events_img4.png";
import events_img5 from "../Images/events_img5.png";
// Past event
import Innovation_forum from "../Past Events/1.--- Arabia CSR - October 3-4 2023 - Dubai, UAE.jpeg";
import T_Plas from "../Past Events/2.--- T-Plast - September 20-23 - Bangkok, Thailand.jpeg";
import PRS_MENA from "../Past Events/3.---PRS MENA - September 5-7 - Dubai, UAE.jpeg";
import plastic_future from "../Past Events/4.---Plastics Future___.png";
import Plastic_world_expo from "../Past Events/5.---Plastics Recycling World Expo - June 14-15 - Messe Essen, Germany.png";
import equiplast from "../Past Events/6.---Equiplast - May 30- June 2 - Barcelona, Spain.jpeg";
import GPCA from "../Past Events/7.---GPCA - May 16-17 - Al Khobar, Saudi Arabia.jpeg";
import weseeequal from "../Past Events/weseequal.jpeg";
import MPRA from "../Past Events/9.---MPRA - March 3 - Kuala Lampur, Malaysia.png";
import plastindia from "../Past Events/10.---PlastIndia - February 1-5 - New Delhi, India.jpeg";
import ecowaste from "../Past Events/11.---Ecowaste - January 16-18 2023 - Abu Dhabi, UAE.jpeg";
import Recoup from "../Past Events/12.---RECOUP - September 28-29 2022 - Peterborough, UK.jpg";

import benefit from "../Images/benefit.png";
import benefit2 from "../Images/benefit2.png";
import benefit3 from "../Images/benefit3.png";
import benefit4 from "../Images/benefit4.png";
import benefit5 from "../Images/benefit5.png";
import benefit6 from "../Images/benefit6.png";
import benefit7 from "../Images/benefit7.png";
import benefit8 from "../Images/benefit8.png";
import benefit9 from "../Images/benefit9.png";
import background_imag_news from "../Images/background-imag-news.png";
import ContactUs_bg_img from "../Images/ContactUs_bg_img.png";
import u_dollar2 from "../Images/u_dollar2.png";
import u_map from "../Images/u_map.png";
import u_map2 from "../Images/u_map2.png";
import u_shield2 from "../Images/u_shield2.png";
import u_shield from "../Images/u_shield.png";
import u_award from "../Images/u_award.png";
import u_award2 from "../Images/u_award2.png";
import sliderImage from "../Images/sliderImage.png";
import bubble from "../Images/bubble.png";
import bubble2 from "../Images/bubble2.png";
import bubble3 from "../Images/bubble3.png";
import bubble4 from "../Images/bubble4.png";
import bubble5 from "../Images/bubble5.png";
import bubble6 from "../Images/bubble6.png";
import bubble7 from "../Images/bubble7.png";
import backwordArrow from "../Images/backwordArrow.png";
import forwordArrow from "../Images/forwordArrow.png";

import Eventsupdated1 from "../Images/Eventsupdated1.jpeg";
import Eventsupdated2 from "../Images/Eventsupdated2.JPG";
import Eventsupdated3 from "../Images/Eventsupdated3.jpeg";
import EventsUpdated4 from "../Images/EventsUpdated4.jpeg";
import EventsUpdated5 from "../Images/EventsUpdated5.jpeg";
import EventsUpdated6 from "../Images/EventsUpdated6.png";
import EventsUpdated7 from "../Images/EventsUpdated7.jpeg";
import EventsUpdated8 from "../Images/EventsUpdated8.png";
import Eventsupdated9 from "../Images/Eventsupdated9.jpeg";
import EventsUpdated10 from "../Images/EventsUpdated10.jpeg";
import EventsUpdated11 from "../Images/EventsUpdated11.jpg";
import EventsUpdated12 from "../Images/EventsUpdated12.jpeg";

import platformImage1 from "../Images/platformImage1.png";
import platformImage2 from "../Images/platformImage2.png";
import platformImage3 from "../Images/platformImage3.png";
import platformImage4 from "../Images/platformImage4.png";
import platformImage5 from "../Images/platformImage5.png";
import upLeftArrow from "../Images/upLeftArrow.png"

import UNEA from "../Images/UNEA.png";
import Rebound_Platform_Launch from "../Images/Rebound_Platform_Launch.png";
import Rebound_2 from "../Images/Rebound_2.0.png";
import Rebound_Solutions_Launch from "../Images/ReboundSolutionsLaunch.png";
import ISO_Certification_to_be_edited_pdf from "../Images/isoCertificate.png";
import Establishment from "../Images/Establishment.png";
import Conception from "../Images/Conception.png";

import billionBlog from "../Images/billionBlog.jpg";
import impBlog from "../Images/impBlog.png";
import ArabPlast from "../Images/ArabPlast.jpeg";
import COP28 from "../Images/COP28.png";
import FastCompany from "../Images/FastCompany.jpg";
import Future from "../Images/Future_new.png";
import FastFutureComboEng from "../Images/Nov_2023_English_.png";
import FastFutureComboArab from "../Images/Nov_2023_Arabic_.png";
import MiddleEastWasteRecycling from "../Images/MiddleEastWasteRecycling.jpg";
import ReboundReflectionsReportThumbnail from "../Images/Rebound-Reflections-Report-Thumbnail.png";



export const IMAGES = {
  doller,
  u_award,
  Bales,
  Pellets,
  Flakes,
  imageCareer,
  MacBookImage,
  DemoImg,
  mapsImage,
  backgroundImgEvent,
  backgroundImgEvent2,
  backgroundImgEvent3,
  backgroundImgEvent4,
  backgroundImgEvent5,
  Right_icon,
  Left_icon,
  Bg_tango,
  imageEvent,
  test,
  imageBlog1,
  imageBlog2,
  imageBlog3,
  imageBlog4,
  imageBlog5,
  factory,
  houseboat,
  Frame,
  Frame1,
  Frame2,
  Frame3,
  Frame_2,
  blue_bg_tango,
  benefit_from_services,
  solusions_ouer_services,
  solusions_ouer_clients,
  platform_Bg,
  sept20,
  dec21,
  Sarah,
  Sabab,
  Tapas,
  countryImage,
  upRightArrow,
  linkedin,
  youtube,
  tiktok,
  aboutUsShadow,
  backgaungRebound,
  backgraung_image_ouer_client,
  events_img1,
  events_img2,
  events_img3,
  events_img4,
  events_img5,
  Innovation_forum,
  T_Plas,
  PRS_MENA,
  plastic_future,
  Plastic_world_expo,
  equiplast,
  GPCA,
  weseeequal,
  MPRA,
  plastindia,
  ecowaste,
  Recoup,
  benefit,
  benefit2,
  benefit3,
  benefit4,
  benefit5,
  benefit6,
  benefit7,
  benefit8,
  benefit9,
  background_imag_news,
  ContactUs_bg_img,
  u_dollar2,
  u_map,
  u_map2,
  u_shield2,
  u_shield,
  u_award2,
  sliderImage,
  bubble,
  bubble2,
  bubble3,
  bubble4,
  bubble5,
  bubble6,
  bubble7,
  Eventsupdated1,
  Eventsupdated2,
  Eventsupdated3,
  EventsUpdated4,
  EventsUpdated5,
  EventsUpdated6,
  EventsUpdated7,
  EventsUpdated8,
  Eventsupdated9,
  EventsUpdated10,
  EventsUpdated11,
  EventsUpdated12,
  backwordArrow,
  forwordArrow,

  platformImage1,
  platformImage2,
  platformImage3,
  platformImage4,
  platformImage5,

  UNEA,
  Rebound_Platform_Launch,
  Rebound_2,
  Rebound_Solutions_Launch,
  ISO_Certification_to_be_edited_pdf,
  Establishment,
  Conception,
  billionBlog,
  impBlog,
  upLeftArrow,
  ArabPlast,
  COP28,
  FastCompany,
  Future,
  MiddleEastWasteRecycling,
  FastFutureComboEng,
  FastFutureComboArab,
  ReboundReflectionsReportThumbnail,
};

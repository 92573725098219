import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "Assets/Images";
import RightIconButtonArabic from "Components/RightIconButtonArabic";
import TextSlideUp from "Components/TextSlideUp";
import Teamphoto from "Assets/Images/Team Photo.jpeg";
import { useTranslation } from "react-i18next";

const CareersArabic = ({ navigation }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  

  return (
    <div className="cereerMainContiner backgraung_image_news_gallery2">
      {/* <h1 className="textHeading">
        Careers at Rebound{" "}
      </h1> */}
      <TextSlideUp
      color="black"
      className={'textHeading'}
      Heading={'careers_at_rebound'}
      />
      <p className="textTitleCerar">
        {t('careers_at_rebound_description1')}
      </p>
      <p
        className="textTitleCerar margin-top-bottom-3"
      >
        {t('careers_at_rebound_description2')}
      </p>
      <RightIconButtonArabic title="apply_now"
      classname={'careersbutton'}
      paraclass={'careersbuttonfont'}
      width="12%" height={52} 
      onClick={() => navigate("/contact-us",{state:"CareersArabic"})}
      
      />
 

      <div className="bottom_image_carr">
      <img className="carrier-image"
        style={{ marginBottom: '3%'}}
        src={Teamphoto}
        alt="Image"
        layout="responsive"
        objectFit="contain"
        
      />

        <img className="carrier_image_BG"
          src={IMAGES.Bg_tango}
          alt="Image"
          layout="responsive"
          objectFit="contain"

        />

      </div>
      <img className="carrier_image_BG"
        src={IMAGES.Bg_tango}
        alt="Image"
        layout="responsive"
        objectFit="contain"
      />
    </div>

  );
};

export default CareersArabic;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, ToastContainer, Toast } from "react-bootstrap";
import { IMAGES } from "Assets/Images";
import BorderButton from "Components/BorderButton";
import ButtonFilledColor from "Components/ButtonFilledColor";
import ArabicPlatformAdnSolutionTopCard from "Components/ArabicPlatformAdnSolutionTopCard";
import HearItFromOurClients from "./HearItFromOurClients";
import ArabicTopTabBar from "Components/Arabic_TopTabBar";
import ArabicOurSevice from "Screens/ReboundSolutions/ArabicOurSevice";
import ArabicBenifitFromOurService from "Screens/ReboundSolutions/ArabicBenifitFromOurService";
import ArabicModals from "Screens/Arabic-Modal";

const ArabicReboundSolutions = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState("Our_services");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState(false);
  const [subject, setSubject] = useState();
  const handleClick = (selectedItem) => {
    setSelected(selectedItem);
  };

  function onRequestClose() {
    setIsOpen(false);
    setSubject()
    setTimeout(() => {
      setMessage(false);
    }, 3000);
  }

  function handleModal(item) {
    setSubject(item)
    setIsOpen(true);
  }

  return (
    <div className="reboundMainContiner backgraung_image_rebound">
      <ArabicPlatformAdnSolutionTopCard
        isSolutions={true}
        heading={t("rebound_solutions_heading")}
        title1={t("rebound_solutions_description1")}
        title2={t("rebound_solutions_description2")}
        image={
          selected === "Our_services"
            ? IMAGES.solusions_ouer_services
            : selected == "Hear_it_from_our_clients"
              ? IMAGES.solusions_ouer_clients
              : IMAGES.benefit_from_services
        }
        height={"80vh"}
        Joinbutton={() => navigate("/contact-us")}
      />
      <div className="second_bae_header">
        <ArabicTopTabBar
          tabBarData={TAB_DATA2}
          selected_tab={selected}
          clickTab={(item) => handleClick(item)}
        />
      </div>
      <div className="Arabic-button_container_rebound_solusion">
        {selected === "Our_services" ? (
          <div style={{ marginRight: 10 }}>
            <ButtonFilledColor
              buttonfilledcolorclasscontainer={
                "buttonfilledcolorclasscontainer"
              }
              buttonfilledcolorclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Our_services");
              }}
              title={"our_services"}
              btnColor={"#00578A"}
              height={"3vw"}
            />
          </div>
        ) : (
          <div style={{ marginRight: 10 }}>
            <BorderButton
              borderbuttonclasscontainer={"buttonfilledcolorclasscontainer"}
              borderbuttonclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Our_services");
              }}
              title={"our_services"}
              height={"3vw"}
            />
          </div>
        )}
        {selected === "Who_can_benefit_from_our_services" ? (
          <div style={{ marginRight: 10 }}>
            <ButtonFilledColor
              buttonfilledcolorclasscontainer={
                "buttonfilledcolorclasscontainer"
              }
              buttonfilledcolorclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Who_can_benefit_from_our_services");
              }}
              title={"who_can_benifit_from_our_services"}
              btnColor={"#00578A"}
              height={"3vw"}
            />
          </div>
        ) : (
          <div style={{ marginRight: 10 }}>
            <BorderButton
              borderbuttonclasscontainer={"buttonfilledcolorclasscontainer"}
              borderbuttonclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Who_can_benefit_from_our_services");
              }}
              title={"who_can_benifit_from_our_services"}
              height={"3vw"}
            />
          </div>
        )}

        {/* {selected === "Hear_it_from_our_clients" ? (
          <div style={{ marginRight: 10 }}>
            <ButtonFilledColor
              buttonfilledcolorclasscontainer={
                "buttonfilledcolorclasscontainer"
              }
              buttonfilledcolorclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Hear_it_from_our_clients");
              }}
              title={"here_it_from_our_clients"}
              btnColor={"#00578A"}
              height={"3vw"}
            />
          </div>
        ) : (
          <div style={{}}>
            <BorderButton
              borderbuttonclasscontainer={"buttonfilledcolorclasscontainer"}
              borderbuttonclass={'buttonfilledcolorclass'}
              onClick={() => {
                handleClick("Hear_it_from_our_clients");
              }}
              title={"here_it_from_our_clients"}
              height={"3vw"}
            />
          </div>
        )} */}
      </div>
      <div className="Arabic-button_contaner_benifit">
        {/* <RightIconButton title='Get in touch' width="79%" />. */}
        <ButtonFilledColor
          title="register_now"
          buttonfilledcolorclasscontainer={"reboundsolutionregisterbutton"}
          buttonfilledcolorclass={"reboundsolutionregisterbuttonfont"}
          width="43%"
          onClick={() =>
            navigate("/contact-us", { state: "Rebound_solutions" })
          }
        />
      </div>
      {selected === "Our_services" && (
        <ArabicOurSevice
          onClick={(item) => {
            handleModal(item);
          }}
        />
      )}

      {selected === "Who_can_benefit_from_our_services" && (
        <ArabicBenifitFromOurService

          onClick={() => {
            handleModal();
          }}
        />
      )}

      {selected === "Hear_it_from_our_clients" && <HearItFromOurClients />}

      <ArabicModals modalIsOpen={modalIsOpen} closeModal={onRequestClose} subject={subject} setMessage={setMessage}/>
      <ToastContainer position="top-start" className="p-3" style={{ zIndex: 9999999, top: '60px !important', position: 'fixed' }}>
        <Toast 
          onClose={() => setMessage(false)} 
          show={message} 
          delay={3000}
          style={{
            background: "#FF6700",
            color: "#fff",
          }}
        >
          <Toast.Header>
            <strong className="me-auto">نجاح</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>شكرًا على تقديمك. لقد استلمنا تفاصيلك بنجاح!</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* {message && (
        <div className="message_alert">
          <Alert
            dismissible
            style={{
              background: "#FF6700",
              color: "#fff",
              width: '70%',
              // width: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: '1.7vw'
            }}
            onClose={() => setMessage(false)}
          >
            {
              "“شكراً لتقديمك. لقد استلمنا طلبك بنجاح.”"
            }
          </Alert>
        </div>
      )} */}
    </div>
  );
};

export default ArabicReboundSolutions;

const TAB_DATA2 = [
  {
    id: "Rebound_Solutions",
    title: "rebound_solutions_heading",
    isSelected: false,
    isFirst: true,
  },
  {
    id: "Our_services",
    title: "our_services",
    isSelected: true,
  },
  {
    id: "Who_can_benefit_from_our_services",
    title: "who_can_benefit_from_our_services",
    isSelected: false,
  },
  // {
  //   id: "Hear_it_from_our_clients",
  //   title: "hear_it_from_our_clients",
  //   isSelected: false,
  // },
];

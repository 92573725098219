export const errorHadleContactUs = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }

    if (!formData.company) {
        validationErrors.company = "اسم الشركة مطلوب";
    }

    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (!formData?.extra_information) {
        validationErrors.extra_information = "معلومات اضافية مطلوبة";
    }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorHadleRegisterNow = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }

    if (!formData.company) {
        validationErrors.company = "اسم الشركة مطلوب";
    }

    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (!formData?.extra_information) {
        validationErrors.extra_information = "معلومات اضافية مطلوبة";
    }
    if (!formData?.industry_sector) {
        validationErrors.industry_sector = "الصناعة/القطاع مطلوب";
    }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorBlogEntries = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }

    if (!formData.company) {
        validationErrors.company = "اسم الشركة مطلوب";
    }

    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    } 
    // else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorCareers = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }

    if (!formData.headline) {
        validationErrors.headline = "اسم الشركة مطلوب";
    }

    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}

export const errorWhitePaper = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }

    if (!formData.submission_topic) {
        validationErrors.submission_topic = "موضوع التقديم مطلوب";
    }
    if (!formData.company) {
        validationErrors.company = "اسم الشركة مطلوب";
    }
    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    } 
    // else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}

export const errorNewsLetter = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "مطلوب اسم";
    }
    if (!formData.email) {
        validationErrors.email = "البريد الالكتروني مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "البريد الإلكتروني غير صالح";
    }

    if (!formData.phone) {
        validationErrors.phone = "رقم الهاتف مطلوب";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { getDatabase } from "firebase/database";


//AWS S3 Import
import { uploadFile } from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const s3Config = {
    bucketName: 'ladespensaasset',
    // dirName: 'photos', /* optional */
    region: 'eu-west-1',
    accessKeyId: 'AKIAUMUGEAWSFNVMQMUF',
    secretAccessKey: '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',
}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBrdN3Dn-ooakNo80Mt59YrviPypIqF4pU",
    authDomain: "rebound-6135a.firebaseapp.com",
    projectId: "rebound-6135a",
    storageBucket: "rebound-6135a.appspot.com",
    messagingSenderId: "1036162041887",
    appId: "1:1036162041887:web:cbce71df5527a323fa6d2a",
    measurementId: "G-RZZTWQ70JC",
    databaseURL: 'https://rebound-6135a-default-rtdb.firebaseio.com',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const realTimeDatabase = getDatabase(app);


export const uploadImageFireBase = (file, call_back, call_backPercent) => {
    const storageRef = ref(storage, `files/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
        (snapshot) => {
            const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            call_backPercent(progress);
        },
        (error) => {
            alert(error);
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                call_back(downloadURL)
            });
        }
    );
};

export const uploadImageS3 = (file, call_back, call_backPercent) => {
    const endpoint = 'https://admin.reboundsolution.com/api/handle-file-upload';
    
    // Create a FormData object to hold the file, assuming 'file' is a File object
    let formData = new FormData();
    formData.append('file', file);

    // Use fetch to POST the file to your local server
    fetch(endpoint, {
        method: 'POST',
        body: formData,
    })
    .then(response => response.json()) // Assuming the server responds with JSON
    .then(data => {
        call_back(data.location); // Assuming 'data.location' is the path or URL to the uploaded file
    })
    .catch(err => {
        // Optionally pass the error to your callback if it expects error handling
        // call_backPercent(err);
    });
};
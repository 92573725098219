// import './CaseStudies.css';
import EllipseImg from "../../Assets/Images/Ellipse10.png";
import React, { useState } from "react";
import RightIconButton from "../../Components/RightIconButton";
import ResourceMapSvg from "../../Assets/SVGs/icon/ResourceMapSvg";
import ResourceMapRigthSvg from "../../Assets/SVGs/icon/ResourceMapRigthSvg";
import { useTranslation } from "react-i18next";
import CountryCardResoucesArabic from "../../Components/CountryCardResoucesArabic";

const CaseStudiesArabic = () => {
  const { t, i18n } = useTranslation();
  const [hover, setHover] = useState();
  const openEmailClient = () => {
    const emailAddress = "info@reboundplastic.com";
    const emailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}`;
    window.open(emailLink, "_blank");
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="MainContainer">
      <div className="Container">
        <div className="paraContainer">
          <p className="case_text lh-sm"> {t("case_studies_description1")}</p>
          <p className="case_text lh-sm">
            {t("case_studies_description2")}
            <span
              className="color_tango text-decoration-underline"
              onClick={() => {
                scrollToTop();
              }}
            >
              <a
                href="mailto:info@reboundplastic.com"
                className="color_tango"
                style={{ marginLeft: "0.5%",marginRight:'0.5%' }}
              >
                {t("info@reboundplastic_com")}
              </a>

            </span>
            {/* {` specifying the case study you're keen on exploring further.`} */}
           { (t('case_study_span'))}
          </p>
        </div>
        {/* <div className="buttonContainer">
          <RightIconButton
            classname={"casestudiesbutton"}
            paraclass={"casestudiesbuttonfont"}
            padding={"11px"}
            title="Send Email"
          />
        </div> */}
      </div>

      <div className="EllipseContainer">
        <img className="EllipseContainerimg" src={EllipseImg} />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 40,
          marginBottom: 30,
          width: "100%",
        }}
      >
        <div style={{ width: "100%", height: "20%", position: "relative" }}>
          <ResourceMapSvg />

          {/* Kuwait */}
          <div
            style={{
              position: "absolute",
              right: "58%",
              bottom: "49%",
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ thirteen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.thirteen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 4,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('kuwait')} />
              </div>
            )}
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_2367_44286)">
                <path
                  d="M0.445312 0.957031H35.2268V12.5531H0.445312V0.957031Z"
                  fill="#007A3D"
                />
                <path
                  d="M0.445312 12.5527H35.2268V24.142H0.445312V12.5527Z"
                  fill="white"
                />
                <path
                  d="M0.445312 24.1426H35.2268V35.7387H0.445312V24.1426Z"
                  fill="#CE1126"
                />
                <path
                  d="M0.445312 7.07096C0.445312 3.69433 3.18261 0.957031 6.55924 0.957031C9.93588 0.957031 12.6732 3.69433 12.6732 7.07096V29.6246C12.6732 33.0012 9.93588 35.7385 6.55924 35.7385C3.18261 35.7385 0.445312 33.0012 0.445312 29.6246V7.07096Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44286">
                  <rect
                    x="0.445312"
                    y="0.957031"
                    width="34.7815"
                    height="34.7815"
                    rx="17.3907"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Bahrain */}
          <div
            style={{
              position: "absolute",
              right: "58%",
              bottom: "43%",
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ one: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.one && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 4,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('bahrain')} />
              </div>
            )}
            <svg
              width="36"
              height="35"
              viewBox="0 0 36 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79178)">
                <path
                  d="M18.1798 34.8557C27.7844 34.8557 35.5705 27.0696 35.5705 17.465C35.5705 7.86032 27.7844 0.0742188 18.1798 0.0742188C8.57516 0.0742188 0.789062 7.86032 0.789062 17.465C0.789062 27.0696 8.57516 34.8557 18.1798 34.8557Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M18.1794 0.0742188C14.6893 0.0742188 11.44 1.10306 8.7164 2.87291L13.189 5.8707L7.59375 8.76911L13.189 11.6675L7.59375 14.5659L13.189 17.464L7.59375 20.3624L13.189 23.2607L7.59375 26.1594L13.189 29.0578L8.71586 32.0565C11.4395 33.8267 14.6891 34.8557 18.1794 34.8557C27.784 34.8557 35.5702 27.0695 35.5702 17.465C35.5702 7.86038 27.784 0.0742188 18.1794 0.0742188V0.0742188Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79178">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.789062 0.0742188)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Qatar */}
          <div
            style={{
              position: "absolute",
              right: "52%",
              bottom: "43%",
              zIndex: 1,
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ two: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.two && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('qatar')} />
              </div>
            )}
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79181)">
                <path
                  d="M0.121094 0.595703H34.9026V35.3772H0.121094V0.595703Z"
                  fill="#8D1B3D"
                />
                <path
                  d="M0.121094 0.595703V35.3772H7.79748L14.8761 33.4479L7.79748 31.505L14.8761 29.5757L7.79748 27.6465L14.8761 25.7172L7.79748 23.7811L14.8761 21.8586L7.79748 19.9157L14.8761 17.9864L7.79748 16.0572L14.8761 14.1143L7.79748 12.1918L14.8761 10.2557L7.79748 8.32643L14.8761 6.39035L7.79748 4.46786L14.8761 2.53858L7.79748 0.595703H0.121094Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79181">
                  <rect
                    x="0.121094"
                    y="0.595703"
                    width="34.7815"
                    height="34.7815"
                    rx="17.3907"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Saudi Arabia */}
          <div
            style={{
              position: "absolute",
              right: "56%",
              bottom: "35%",
              zIndex: 4,
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ three: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.three && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('saudi_arabia')} />
              </div>
            )}

            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79172)">
                <path
                  d="M18.2521 35.551C27.8567 35.551 35.6428 27.7649 35.6428 18.1603C35.6428 8.55563 27.8567 0.769531 18.2521 0.769531C8.64743 0.769531 0.861328 8.55563 0.861328 18.1603C0.861328 27.7649 8.64743 35.551 18.2521 35.551Z"
                  fill="#6DA544"
                />
                <path
                  d="M10.6895 21.563C10.6895 22.8157 11.705 23.8313 12.9578 23.8313H19.7629C19.7629 24.8753 20.6092 25.7216 21.6532 25.7216H23.9215C24.9655 25.7216 25.8118 24.8753 25.8118 23.8313V21.563H10.6895Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M26.0005 10.5991V15.892C26.0005 16.7258 25.3222 17.4042 24.4883 17.4042V19.6725C26.5729 19.6725 28.2689 17.9765 28.2689 15.892V10.5991H26.0005Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M9.74467 15.892C9.74467 16.7258 9.06629 17.4042 8.23242 17.4042V19.6725C10.3171 19.6725 12.013 17.9765 12.013 15.892V10.5991H9.74467V15.892Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M22.5986 10.5991H24.867V15.892H22.5986V10.5991Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M19.1964 13.6236C19.1964 13.8321 19.0268 14.0017 18.8183 14.0017C18.6099 14.0017 18.4403 13.832 18.4403 13.6236V10.5991H16.172V13.6236C16.172 13.8321 16.0023 14.0017 15.7939 14.0017C15.5855 14.0017 15.4159 13.832 15.4159 13.6236V10.5991H13.1475V13.6236C13.1475 15.0829 14.3347 16.2701 15.7939 16.2701C16.3559 16.2701 16.8771 16.0934 17.3062 15.7934C17.7352 16.0934 18.2564 16.2701 18.8184 16.2701C18.9316 16.2701 19.0429 16.2621 19.1523 16.2482C18.9916 16.9105 18.3951 17.4042 17.6842 17.4042V19.6725C19.7688 19.6725 21.4648 17.9765 21.4648 15.892V13.6236V10.5991H19.1964V13.6236H19.1964Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M13.1475 17.4043H16.55V19.6726H13.1475V17.4043Z"
                  fill="#F0F0F0"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79172">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.861328 0.769531)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* UAE */}
          <div
            style={{
              position: "absolute",
              right: "50%",
              bottom: "35%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ four: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.four && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('uae')} />
              </div>
            )}
            <svg
              width="36"
              height="35"
              viewBox="0 0 36 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79180)">
                <path
                  d="M0.423828 0.130859H35.2053V11.727H0.423828V0.130859Z"
                  fill="#00732F"
                />
                <path
                  d="M0.423828 11.7271H35.2053V23.3163H0.423828V11.7271Z"
                  fill="white"
                />
                <path
                  d="M0.423828 23.3164H35.2053V34.9125H0.423828V23.3164Z"
                  fill="black"
                />
                <path
                  d="M0.423828 0.130859H12.6517V34.9123H0.423828V0.130859Z"
                  fill="#FF0000"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79180">
                  <rect
                    x="0.423828"
                    y="0.130859"
                    width="34.7815"
                    height="34.7815"
                    rx="17.3907"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Oman */}
          <div
            style={{
              position: "absolute",
              right: "54%",
              bottom: "29%",
              zIndex: 4,
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ six: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.six && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('oman')} />
              </div>
            )}
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79179)">
                <path
                  d="M17.5392 35.6057C27.1438 35.6057 34.9299 27.8196 34.9299 18.215C34.9299 8.61032 27.1438 0.824219 17.5392 0.824219C7.93454 0.824219 0.148438 8.61032 0.148438 18.215C0.148438 27.8196 7.93454 35.6057 17.5392 35.6057Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M0.148438 18.2152C0.148438 24.807 3.81612 30.5419 9.22185 33.4914V2.93896C3.81612 5.88846 0.148438 11.6233 0.148438 18.2152Z"
                  fill="#D80027"
                />
                <path
                  d="M33.8483 24.2643C34.5473 22.3804 34.9299 20.3427 34.9299 18.2154C34.9299 16.0882 34.5473 14.0504 33.8483 12.1665H1.2302C0.531102 14.0504 0.148438 16.0882 0.148438 18.2154C0.148438 20.3427 0.531102 22.3804 1.2302 24.2643L17.5392 25.7766L33.8483 24.2643Z"
                  fill="#D80027"
                />
                <path
                  d="M17.5394 35.605C25.0169 35.605 31.3913 30.8856 33.8486 24.2632H1.23047C3.68759 30.8856 10.0621 35.605 17.5394 35.605Z"
                  fill="#6DA544"
                />
                <path
                  d="M0.148438 18.216C0.148438 26.251 5.59806 33.012 13.0024 35.0076V1.42432C5.59806 3.4199 0.148438 10.181 0.148438 18.216Z"
                  fill="#D80027"
                />
                <path
                  d="M10.787 8.47651L9.18304 6.87256L8.11378 7.94195L7.04452 6.87256L5.44057 8.47651L6.50989 9.54584L5.44043 10.6152L7.04439 12.2192L8.11371 11.1498L9.18304 12.2192L10.787 10.6152L9.71767 9.54584L10.787 8.47651Z"
                  fill="#F0F0F0"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79179">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.148438 0.824219)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* India */}
          <div
            style={{
              position: "absolute",
              right: "41%",
              bottom: "40%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ fourteen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.fourteen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('india')} />
              </div>
            )}
            <svg
              className="bubbleDimen"
              width="36"
              height="35"
              viewBox="0 0 36 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2367_44272)">
                <path
                  d="M18.1837 34.9573C27.7884 34.9573 35.5745 27.1712 35.5745 17.5665C35.5745 7.96188 27.7884 0.175781 18.1837 0.175781C8.57907 0.175781 0.792969 7.96188 0.792969 17.5665C0.792969 27.1712 8.57907 34.9573 18.1837 34.9573Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M18.1827 0.175781C11.2879 0.175781 5.33053 4.18822 2.51758 10.0054H33.8477C31.0349 4.18822 25.0774 0.175781 18.1827 0.175781Z"
                  fill="#FF9811"
                />
                <path
                  d="M18.1827 34.9565C25.0774 34.9565 31.0349 30.9441 33.8478 25.127H2.51758C5.33053 30.9441 11.2879 34.9565 18.1827 34.9565Z"
                  fill="#6DA544"
                />
                <path
                  d="M18.1837 23.6154C21.5244 23.6154 24.2326 20.9072 24.2326 17.5665C24.2326 14.2258 21.5244 11.5176 18.1837 11.5176C14.843 11.5176 12.1348 14.2258 12.1348 17.5665C12.1348 20.9072 14.843 23.6154 18.1837 23.6154Z"
                  fill="#0052B4"
                />
                <path
                  d="M18.1829 21.3463C20.2709 21.3463 21.9635 19.6537 21.9635 17.5657C21.9635 15.4778 20.2709 13.7852 18.1829 13.7852C16.095 13.7852 14.4023 15.4778 14.4023 17.5657C14.4023 19.6537 16.095 21.3463 18.1829 21.3463Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M18.1847 12.9023L19.351 15.5475L22.2248 15.2349L20.5173 17.5675L22.2248 19.9001L19.351 19.5876L18.1847 22.2327L17.0184 19.5876L14.1445 19.9001L15.8521 17.5675L14.1445 15.2349L17.0184 15.5475L18.1847 12.9023Z"
                  fill="#0052B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44272">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.792969 0.175781)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Bangladesh */}
          <div
            style={{
              position: "absolute",
              right: "35%",
              bottom: "40%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ fifteen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.fifteen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('bangladesh')} />
              </div>
            )}
            <svg
              className="bubbleDimen"
              width="36"
              height="35"
              viewBox="0 0 36 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2367_44282)">
                <path
                  d="M17.6739 34.9573C27.2786 34.9573 35.0647 27.1712 35.0647 17.5665C35.0647 7.96188 27.2786 0.175781 17.6739 0.175781C8.0693 0.175781 0.283203 7.96188 0.283203 17.5665C0.283203 27.1712 8.0693 34.9573 17.6739 34.9573Z"
                  fill="#496E2D"
                />
                <path
                  d="M13.8932 25.1282C18.0691 25.1282 21.4544 21.7429 21.4544 17.567C21.4544 13.3911 18.0691 10.0059 13.8932 10.0059C9.71728 10.0059 6.33203 13.3911 6.33203 17.567C6.33203 21.7429 9.71728 25.1282 13.8932 25.1282Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44282">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.283203 0.175781)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Vietnam */}
          <div
            style={{
              position: "absolute",
              right: "28%",
              bottom: "30%",
              cursor: "pointer",
              zIndex: 8,
            }}
            onMouseOver={() => setHover({ seven: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.seven && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('vietnam')} />
              </div>
            )}
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79170)">
                <path
                  d="M17.3907 35.2287C26.9954 35.2287 34.7815 27.4426 34.7815 17.838C34.7815 8.23337 26.9954 0.447266 17.3907 0.447266C7.7861 0.447266 0 8.23337 0 17.838C0 27.4426 7.7861 35.2287 17.3907 35.2287Z"
                  fill="#D80027"
                />
                <path
                  d="M17.3907 9.521L19.2676 15.2972H25.3411L20.4274 18.8671L22.3043 24.6434L17.3907 21.0735L12.4772 24.6434L14.354 18.8671L9.44043 15.2972H15.5139L17.3907 9.521Z"
                  fill="#FFDA44"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79170">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0 0.447266)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* South Korea */}
          <div
            style={{
              position: "absolute",
              right: "22%",
              bottom: "53%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ seventeen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.seventeen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('south_korea')} />
              </div>
            )}
            <svg
              className="bubbleDimen"
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2367_44288)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.0351562 0.582031H34.8166V35.3635H0.0351562V0.582031Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.62695 12.7656L7.31707 7.23253L8.23925 7.84755L4.54914 13.3806L3.62695 12.7656ZM5.01023 13.6882L8.70034 8.15506L9.62252 8.77008L5.93241 14.3032L5.01023 13.6882ZM6.3935 14.6107L10.0836 9.07759L11.0058 9.69261L7.31568 15.2257L6.3935 14.6107Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.9141 26.2949L27.6042 20.7618L28.5264 21.3768L24.8362 26.9099L23.9141 26.2949ZM25.2973 27.2175L28.9874 21.6844L29.9096 22.2994L26.2195 27.8325L25.2973 27.2175ZM26.6806 28.14L30.3707 22.6069L31.2929 23.2219L27.6028 28.755L26.6806 28.14Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29.732 26.5634L25.1211 23.4883L25.4745 22.9584L30.0854 26.0335L29.732 26.5634Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9258 14.3066C12.9045 12.8392 14.426 11.8206 16.1557 11.475C17.8854 11.1293 19.6815 11.485 21.149 12.4637C22.6165 13.4423 23.635 14.9639 23.9807 16.6936C24.3263 18.4233 23.9706 20.2194 22.992 21.6869L11.9258 14.3066Z"
                  fill="#CD2E3A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9264 14.3048C10.9478 15.7723 10.5921 17.5684 10.9377 19.2981C11.2834 21.0278 12.302 22.5493 13.7694 23.528C15.2369 24.5067 17.033 24.8623 18.7627 24.5167C20.4924 24.1711 22.014 23.1525 22.9926 21.685C23.482 20.9513 23.6598 20.0532 23.487 19.1884C23.3142 18.3235 22.8049 17.5628 22.0711 17.0734C21.3374 16.5841 20.4393 16.4063 19.5745 16.5791C18.7096 16.7519 17.9489 17.2612 17.4595 17.9949L11.9264 14.3048Z"
                  fill="#0047A0"
                />
                <path
                  d="M17.4582 17.9958C18.4772 16.4679 18.0647 14.4032 16.5367 13.3842C15.0088 12.3652 12.9441 12.7778 11.9251 14.3057C10.9061 15.8336 11.3187 17.8983 12.8466 18.9173C14.3745 19.9363 16.4392 19.5238 17.4582 17.9958Z"
                  fill="#CD2E3A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.31641 28.7578L3.62629 23.2247L4.54847 22.6097L8.23859 28.1428L7.31641 28.7578ZM8.69968 27.8353L5.00956 22.3022L5.93175 21.6872L9.62186 27.2203L8.69968 27.8353ZM10.083 26.9128L6.39284 21.3797L7.31502 20.7646L11.0051 26.2977L10.083 26.9128Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.6055 15.2266L23.9154 9.69347L24.8375 9.07845L28.5277 14.6115L27.6055 15.2266ZM28.9887 14.304L25.2986 8.77094L26.2208 8.15592L29.9109 13.689L28.9887 14.304ZM30.372 13.3815L26.6819 7.84841L27.6041 7.23339L31.2942 12.7665L30.372 13.3815Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.18601 24.5638L6.80273 25.4863L6.44937 24.9565L7.83265 24.034L8.18601 24.5638ZM27.0907 11.9559L25.4769 13.0322L25.1236 12.5023L26.7374 11.4261L27.0907 11.9559ZM29.8573 10.1108L28.474 11.0334L28.1207 10.5035L29.5039 9.581L29.8573 10.1108Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44288">
                  <rect
                    x="0.0351562"
                    y="0.582031"
                    width="34.7815"
                    height="34.7815"
                    rx="17.3907"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Japan */}
          <div
            style={{
              position: "absolute",
              right: "15%",
              bottom: "53%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ eighteen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.eighteen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  // right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('japan')} />
              </div>
            )}
            <svg
              className="bubbleDimen"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2367_44279)">
                <path
                  d="M18.3204 35.2053C27.9251 35.2053 35.7112 27.4192 35.7112 17.8146C35.7112 8.20993 27.9251 0.423828 18.3204 0.423828C8.71579 0.423828 0.929688 8.20993 0.929688 17.8146C0.929688 27.4192 8.71579 35.2053 18.3204 35.2053Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M18.3209 25.3762C22.4969 25.3762 25.8821 21.991 25.8821 17.8151C25.8821 13.6392 22.4969 10.2539 18.3209 10.2539C14.145 10.2539 10.7598 13.6392 10.7598 17.8151C10.7598 21.991 14.145 25.3762 18.3209 25.3762Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44279">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.929688 0.423828)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Taiwan */}
          <div
            style={{
              position: "absolute",
              right: "23%",
              bottom: "40%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ sixteen: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.sixteen && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  zIndexL: 6,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('taiwan')} />
              </div>
            )}
            <svg
              className="bubbleDimen"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2367_44280)">
                <path
                  d="M34.926 17.5196C34.926 27.1242 27.1399 34.9104 17.5353 34.9104C7.93069 34.9104 0.144531 27.1242 0.144531 17.5196C6.11784 11.5463 11.1838 6.48033 17.5353 0.128906C27.1399 0.128906 34.926 7.91507 34.926 17.5196Z"
                  fill="#D80027"
                />
                <path
                  d="M17.5353 17.5196C17.5353 7.91507 17.5353 6.78277 17.5353 0.128906C7.93069 0.128906 0.144531 7.91507 0.144531 17.5196H17.5353Z"
                  fill="#0052B4"
                />
                <path
                  d="M15.2668 10.3059L13.1429 11.305L14.2738 13.362L11.9676 12.9207L11.6753 15.2505L10.069 13.537L8.46249 15.2505L8.17031 12.9207L5.864 13.3618L6.99494 11.3049L4.87109 10.3059L6.99501 9.30692L5.864 7.24992L8.17024 7.69114L8.46255 5.36133L10.069 7.07486L11.6754 5.36133L11.9676 7.69114L14.2739 7.24992L13.1429 9.30699L15.2668 10.3059Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M10.0692 13.5447C11.8571 13.5447 13.3064 12.0954 13.3064 10.3075C13.3064 8.51965 11.8571 7.07031 10.0692 7.07031C8.28137 7.07031 6.83203 8.51965 6.83203 10.3075C6.83203 12.0954 8.28137 13.5447 10.0692 13.5447Z"
                  fill="#0052B4"
                />
                <path
                  d="M10.0686 12.0319C9.1175 12.0319 8.34375 11.2581 8.34375 10.307C8.34375 9.35585 9.11757 8.58203 10.0686 8.58203C11.0197 8.58203 11.7936 9.35585 11.7936 10.307C11.7935 11.2581 11.0196 12.0319 10.0686 12.0319Z"
                  fill="#F0F0F0"
                />
              </g>
              <defs>
                <clipPath id="clip0_2367_44280">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.144531 0.128906)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Thailand */}
          <div
            style={{
              position: "absolute",
              right: "32%",
              bottom: "35%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ eight: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.eight && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                }}
              >
                <CountryCardResoucesArabic heading={t('thailand')} />
              </div>
            )}
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79176)">
                <path
                  d="M17.9103 35.6506C27.5149 35.6506 35.301 27.8645 35.301 18.2599C35.301 8.65524 27.5149 0.869141 17.9103 0.869141C8.30563 0.869141 0.519531 8.65524 0.519531 18.2599C0.519531 27.8645 8.30563 35.6506 17.9103 35.6506Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M34.2193 12.2109H1.60122C0.902128 14.0949 0.519531 16.1326 0.519531 18.2599C0.519531 20.3871 0.902128 22.4248 1.60122 24.3088H34.2194C34.9184 22.4248 35.301 20.3871 35.301 18.2599C35.301 16.1326 34.9184 14.0949 34.2193 12.2109Z"
                  fill="#0052B4"
                />
                <path
                  d="M17.9106 0.869141C12.6396 0.869141 7.91677 3.21444 4.72754 6.91806H31.0936C27.9044 3.21444 23.1816 0.869141 17.9106 0.869141Z"
                  fill="#D80027"
                />
                <path
                  d="M31.0937 29.6016H4.72754C7.91677 33.3052 12.6396 35.6505 17.9106 35.6505C23.1816 35.6505 27.9044 33.3052 31.0937 29.6016Z"
                  fill="#D80027"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79176">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.519531 0.869141)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Singapore */}
          <div
            style={{
              position: "absolute",
              right: "28%",
              bottom: "20%",
              cursor: "pointer",
              zIndex: 10,
            }}
            onMouseOver={() => setHover({ nine: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.nine && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                }}
              >
                <CountryCardResoucesArabic heading={t('singapore')} />
              </div>
            )}
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79173)">
                <path
                  d="M17.504 35.1975C27.1087 35.1975 34.8948 27.4114 34.8948 17.8068C34.8948 8.20212 27.1087 0.416016 17.504 0.416016C7.89938 0.416016 0.113281 8.20212 0.113281 17.8068C0.113281 27.4114 7.89938 35.1975 17.504 35.1975Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M0.113281 17.8068C0.113281 8.20218 7.89944 0.416016 17.504 0.416016C27.1086 0.416016 34.8948 8.20218 34.8948 17.8068"
                  fill="#D80027"
                />
                <path
                  d="M10.6991 9.48905C10.6991 6.9553 12.4798 4.83866 14.8577 4.31932C14.4922 4.23956 14.1131 4.19629 13.7235 4.19629C10.8003 4.19629 8.43066 6.56591 8.43066 9.48912C8.43066 12.4123 10.8003 14.782 13.7235 14.782C14.113 14.782 14.4922 14.7387 14.8577 14.6589C12.4798 14.1395 10.6991 12.0229 10.6991 9.48905Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M17.5041 4.57471L17.8795 5.7299H19.0942L18.1114 6.44394L18.4868 7.59913L17.5041 6.88523L16.5214 7.59913L16.8968 6.44394L15.9141 5.7299H17.1287L17.5041 4.57471Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M14.557 6.84277L14.9323 7.99803H16.147L15.1643 8.71201L15.5397 9.8672L14.557 9.1533L13.5741 9.8672L13.9496 8.71201L12.9668 7.99803H14.1815L14.557 6.84277Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M20.4504 6.84277L20.8258 7.99803H22.0405L21.0577 8.71201L21.4332 9.8672L20.4504 9.1533L19.4677 9.8672L19.8431 8.71201L18.8604 7.99803H20.075L20.4504 6.84277Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M19.3156 10.2451L19.691 11.4004H20.9056L19.9229 12.1143L20.2983 13.2695L19.3156 12.5556L18.3329 13.2695L18.7083 12.1143L17.7256 11.4004H18.9402L19.3156 10.2451Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M15.6917 10.2451L16.067 11.4004H17.2818L16.299 12.1143L16.6744 13.2695L15.6917 12.5556L14.7089 13.2695L15.0843 12.1143L14.1016 11.4004H15.3163L15.6917 10.2451Z"
                  fill="#F0F0F0"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79173">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.113281 0.416016)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Malaysia */}
          <div
            style={{
              position: "absolute",
              right: "20%",
              bottom: "28%",
              cursor: "pointer",
              zIndex: 4,
            }}
            onMouseOver={() => setHover({ ten: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.ten && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                }}
              >
                <CountryCardResoucesArabic heading={t('malaysia')} />
              </div>
            )}
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79168)">
                <path
                  d="M18.3419 35.6194C27.9466 35.6194 35.7327 27.8333 35.7327 18.2286C35.7327 8.62399 27.9466 0.837891 18.3419 0.837891C8.73727 0.837891 0.951172 8.62399 0.951172 18.2286C0.951172 27.8333 8.73727 35.6194 18.3419 35.6194Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M17.5859 18.2291H35.7328C35.7328 16.6594 35.5235 15.1389 35.1337 13.6924H17.5859V18.2291Z"
                  fill="#D80027"
                />
                <path
                  d="M17.5859 9.15539H33.1803C32.1157 7.41822 30.7547 5.88274 29.1676 4.61865H17.5859V9.15539Z"
                  fill="#D80027"
                />
                <path
                  d="M18.3421 35.6199C22.435 35.6199 26.1969 34.2053 29.1677 31.8394H7.5166C10.4874 34.2053 14.2493 35.6199 18.3421 35.6199Z"
                  fill="#D80027"
                />
                <path
                  d="M3.50418 27.3024H33.1807C34.0353 25.9078 34.6981 24.3834 35.1341 22.7656H1.55078C1.9867 24.3834 2.64952 25.9078 3.50418 27.3024Z"
                  fill="#D80027"
                />
                <path
                  d="M18.3419 18.2286C18.3419 8.62405 18.3419 7.49175 18.3419 0.837891C8.73733 0.837891 0.951172 8.62405 0.951172 18.2286H18.3419Z"
                  fill="#0052B4"
                />
                <path
                  d="M12.5152 15.724C10.1402 15.724 8.21481 13.7986 8.21481 11.4236C8.21481 9.04863 10.1402 7.12322 12.5152 7.12322C13.2557 7.12322 13.9525 7.31051 14.5609 7.64012C13.6065 6.70693 12.3014 6.13086 10.8612 6.13086C7.93812 6.13086 5.56836 8.50055 5.56836 11.4237C5.56836 14.3468 7.93812 16.7165 10.8612 16.7165C12.3014 16.7165 13.6065 16.1405 14.5609 15.2073C13.9525 15.5367 13.2558 15.724 12.5152 15.724Z"
                  fill="#FFDA44"
                />
                <path
                  d="M13.7273 8.39941L14.4908 9.99597L16.215 9.59761L15.4429 11.1899L16.8296 12.2895L15.1031 12.6787L15.1079 14.4484L13.7273 13.3412L12.3467 14.4484L12.3514 12.6787L10.625 12.2895L12.0116 11.1899L11.2395 9.59761L12.9638 9.99597L13.7273 8.39941Z"
                  fill="#FFDA44"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79168">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.951172 0.837891)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Indonesia */}
          <div
            style={{
              position: "absolute",
              right: "20%",
              bottom: "23%",
              zIndex: 4,
              cursor: "pointer",
            }}
            onMouseOver={() => setHover({ eleven: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.eleven && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                }}
              >
                <CountryCardResoucesArabic heading={t('indonesia')} />
              </div>
            )}
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79169)">
                <path
                  d="M18.3419 35.1975C27.9466 35.1975 35.7327 27.4114 35.7327 17.8068C35.7327 8.20212 27.9466 0.416016 18.3419 0.416016C8.73727 0.416016 0.951172 8.20212 0.951172 17.8068C0.951172 27.4114 8.73727 35.1975 18.3419 35.1975Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M0.951172 17.8068C0.951172 8.20218 8.73733 0.416016 18.3419 0.416016C27.9465 0.416016 35.7327 8.20218 35.7327 17.8068"
                  fill="#A2001D"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79169">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.951172 0.416016)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          {/* Philippnes */}
          <div
            style={{
              position: "absolute",
              right: "12%",
              bottom: "20%",
              zIndex: 4,
              cursor: "pointer",
              zIndex: 20,
            }}
            onMouseOver={() => setHover({ twelve: true })}
            onMouseLeave={() => setHover()}
          >
            {hover?.twelve && (
              <div
                style={{
                  position: "absolute",
                  bottom: 15,
                  right: 0,
                }}
              >
                <CountryCardResoucesArabic heading={t('philipines')} />
              </div>
            )}
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bubbleDimen"
            >
              <g clip-path="url(#clip0_280_79171)">
                <path
                  d="M18.3517 35.1545C27.9563 35.1545 35.7424 27.3684 35.7424 17.7638C35.7424 8.15915 27.9563 0.373047 18.3517 0.373047C8.74704 0.373047 0.960938 8.15915 0.960938 17.7638C0.960938 27.3684 8.74704 35.1545 18.3517 35.1545Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M18.3518 0.373047V17.7638L6.05469 30.0609C9.2018 33.208 13.5495 35.1545 18.3518 35.1545C27.9564 35.1545 35.7426 27.3684 35.7426 17.7638C35.7426 8.15921 18.3518 0.373047 18.3518 0.373047Z"
                  fill="#D80027"
                />
                <path
                  d="M18.3518 0.373047C13.5495 0.373047 9.2018 2.31959 6.05469 5.46663C10.3215 9.73348 11.8362 11.2482 18.3518 17.7638H35.7426C35.7426 8.15921 27.9564 0.373047 18.3518 0.373047Z"
                  fill="#0052B4"
                />
                <path
                  d="M12.8694 17.7639L10.7455 16.7649L11.8765 14.708L9.57021 15.1492L9.27796 12.8193L7.67149 14.5329L6.06509 12.8193L5.77278 15.1492L3.46654 14.7079L4.59754 16.7649L2.47363 17.7639L4.59754 18.763L3.46654 20.8199L5.77298 20.3787L6.06502 22.7086L7.67149 20.995L9.27796 22.7086L9.57021 20.3787L11.8764 20.82L10.7454 18.763L12.8694 17.7639Z"
                  fill="#FFDA44"
                />
                <path
                  d="M5.5997 7.17822L6.30647 8.16617L7.46438 7.7994L6.74307 8.77681L7.44984 9.76476L6.2973 9.38087L5.57606 10.3582L5.58509 9.14358L4.43262 8.75963L5.59073 8.39286L5.5997 7.17822Z"
                  fill="#FFDA44"
                />
                <path
                  d="M5.5997 25.1694L6.30647 26.1574L7.46438 25.7905L6.74307 26.768L7.44984 27.756L6.2973 27.3721L5.57606 28.3494L5.58509 27.1348L4.43262 26.7508L5.59073 26.3841L5.5997 25.1694Z"
                  fill="#FFDA44"
                />
                <path
                  d="M15.6791 16.1743L14.9724 17.1623L13.8145 16.7954L14.5358 17.7728L13.829 18.7609L14.9815 18.3769L15.7028 19.3542L15.6937 18.1397L16.8462 17.7557L15.6882 17.389L15.6791 16.1743Z"
                  fill="#FFDA44"
                />
              </g>
              <defs>
                <clipPath id="clip0_280_79171">
                  <rect
                    width="34.7815"
                    height="34.7815"
                    fill="white"
                    transform="translate(0.960938 0.373047)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div style={{ width: "20%" }}>
          <ResourceMapRigthSvg />
        </div>
      </div>
      {/* <div className='MapContainer'>
         <img src={MapImage} className='worldmapimg'></img>

      </div> */}
    </div>
  );
};

export default CaseStudiesArabic;

import React from "react";
// import "./Whitepaper.css";
import background from "../../Assets/Images/backgrouond.png";
import RightIconButtonArabic from "../../Components/RightIconButtonArabic";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../Assets/Images";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";


const WhitePapersArabic = () => {
  const { t, i18n } = useTranslation();
  const navigate=useNavigate()
  const renderHeight = (i) => {
      return "40vh";
    };
    

  return (
    <>
      <div className="Container Container-whitepaper">
        
        <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 600: 2, 1000: 3 }}
            style={{ width: "100%" }}
          >
          <Masonry gutter="1%">
            {images.map((item, i) =>
              i !== 4 ? (
                <div
                  key={i}
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/rebound-reflections-report-2023");
                  }}
                >
                  <img
                    className="blogimage"
                    key={i}
                    src={item.image}
                    alt="image"
                    style={{
                      height: renderHeight(i),
                      display: "block",
                      width: "100%",
                      marginBottom: 10,
                      borderRadius: 5,
                    }}
                  />
                  <p className="titleBlogs_image">{t(item.title)}</p>
                </div>
              ) : (
                <></>
              )
            )}
          </Masonry>
        </ResponsiveMasonry>  
        
        <div className="InnerContainer">
          <div className="text1">
            <p className="whitepaperdesp f_regular f-18 mt-4 mb-4 lh-sm">
              {t('white_papers_description1')}
            </p>
          </div>

          <div className="text3">
            <p className="f_regular f-18 mb-4 lh-sm">
              {t('white_papers_description2')}
            </p>
          </div>
          <div
          className="whitepapericon"
           >
            <RightIconButtonArabic width="36%" 
            classname={'whitepapericonbutton'}
            paraclass={'whitepapericonfont'}
            title={t('submit_your_whitepaper')}
            onClick={()=>navigate('/contact-us',{state:"White_Paper"})}
            />
            
          </div>
        </div>
      </div>
    </>
  );
};

export default WhitePapersArabic;
const images = [
    {
      image: IMAGES.ReboundReflectionsReportThumbnail,
      title:"reflectiontitle",
      description: [],
    },
  ];

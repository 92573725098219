import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpArrow from "../Assets/Images/u_arrow-up-right.png";
const RightIconButtonArabic = ({
  title = "Send Email",
  onClick,
  btnColor = "#FF6700",
  width = "100%",
  height = 50,
  icon = UpArrow,
  padding = 10,
  styleText,
  fontSize = "1.0vw",
  classname,
  paraclass,
  type,
  style,
  scrollTop = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      type={type}
      // className={classname}
      style={{
        display: "flex",
        // flexDirection:'row-reverse',
        justifyContent: "space-between",
        alignItems: "center",
        // height: height,
        // width: width,
        flexDirection: "row-reverse",
        padding: "10px 15px",
        position: "relative",
        background: isHovered ? "white" : btnColor,
        border: `1px solid ${btnColor}`,
        borderRadius: 50,
        // transition: "background-color 1.3s",
        cursor: "pointer",
        zIndex: 123,
        ...style,
      }}
      onClick={() => {
        // onClick && onClick();
        onClick ? onClick() : Navigate("/contact-us");
        scrollTop && scrollToTop();
      }}
    >
      {
        <div
          style={{
            border: isHovered ? `1px solid ${btnColor}` : "none",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // transition: "border 0.1s",
            background: "white",
            maxHeight: 36,
            padding: 5,
            marginRight: 5,
            maxWidth: 36,
            // marginLeft: 10,
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#FF2F00"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.16444 5.59756C5.24562 5.40208 5.40096 5.24674 5.59644 5.16556C5.69262 5.12457 5.7959 5.10283 5.90044 5.10156H13.9004C14.1126 5.10156 14.3161 5.18585 14.4661 5.33588C14.6162 5.48591 14.7004 5.68939 14.7004 5.90156C14.7004 6.11374 14.6162 6.31722 14.4661 6.46725C14.3161 6.61728 14.1126 6.70156 13.9004 6.70156H7.82844L14.4684 13.3336C14.5434 13.4079 14.6029 13.4964 14.6436 13.5939C14.6842 13.6914 14.7051 13.796 14.7051 13.9016C14.7051 14.0072 14.6842 14.1117 14.6436 14.2092C14.6029 14.3067 14.5434 14.3952 14.4684 14.4696C14.3941 14.5445 14.3056 14.6041 14.2081 14.6447C14.1106 14.6853 14.006 14.7062 13.9004 14.7062C13.7948 14.7062 13.6903 14.6853 13.5928 14.6447C13.4953 14.6041 13.4068 14.5445 13.3324 14.4696L6.70044 7.82956V13.9016C6.70044 14.1137 6.61615 14.3172 6.46613 14.4672C6.3161 14.6173 6.11261 14.7016 5.90044 14.7016C5.68827 14.7016 5.48478 14.6173 5.33475 14.4672C5.18472 14.3172 5.10044 14.1137 5.10044 13.9016V5.90156C5.1017 5.79702 5.12345 5.69374 5.16444 5.59756Z"
              fill="url(#paint0_linear_716_22720)"
            />
            <path
              d="M5.16444 5.59756C5.24562 5.40208 5.40096 5.24674 5.59644 5.16556C5.69262 5.12457 5.7959 5.10283 5.90044 5.10156H13.9004C14.1126 5.10156 14.3161 5.18585 14.4661 5.33588C14.6162 5.48591 14.7004 5.68939 14.7004 5.90156C14.7004 6.11374 14.6162 6.31722 14.4661 6.46725C14.3161 6.61728 14.1126 6.70156 13.9004 6.70156H7.82844L14.4684 13.3336C14.5434 13.4079 14.6029 13.4964 14.6436 13.5939C14.6842 13.6914 14.7051 13.796 14.7051 13.9016C14.7051 14.0072 14.6842 14.1117 14.6436 14.2092C14.6029 14.3067 14.5434 14.3952 14.4684 14.4696C14.3941 14.5445 14.3056 14.6041 14.2081 14.6447C14.1106 14.6853 14.006 14.7062 13.9004 14.7062C13.7948 14.7062 13.6903 14.6853 13.5928 14.6447C13.4953 14.6041 13.4068 14.5445 13.3324 14.4696L6.70044 7.82956V13.9016C6.70044 14.1137 6.61615 14.3172 6.46613 14.4672C6.3161 14.6173 6.11261 14.7016 5.90044 14.7016C5.68827 14.7016 5.48478 14.6173 5.33475 14.4672C5.18472 14.3172 5.10044 14.1137 5.10044 13.9016V5.90156C5.1017 5.79702 5.12345 5.69374 5.16444 5.59756Z"
              fill="url(#paint1_radial_716_22720)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_716_22720"
                x1="12.8336"
                y1="6.01965"
                x2="6.42461"
                y2="12.9936"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF2F00" />
                <stop offset="1" stop-color="#FF7050" />
              </linearGradient>
              <radialGradient
                id="paint1_radial_716_22720"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(8.86108 10.5395) rotate(56.1292) scale(3.10457 2.92826)"
              >
                <stop stop-color="white" stop-opacity="0.3" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      }
      <p
        className={paraclass}
        style={{
          margin: 0,
          padding: 0,
          paddingRight: 10,
          color: "#fff",
          ...styleText,
          color: isHovered ? btnColor : "white",
          textAlign: "center",
          fontFamily: "nexa-regular",
          fontSize: fontSize,
          // transition: "color 1.3s",
        }}
      >
        {t(title)}
      </p>
    </div>
  );
};

export default RightIconButtonArabic;

import { t, use } from "i18next";
import React from "react";
import { IMAGES } from "../Assets/Images";
import RightIconButton from "./RightIconButton";
import TextAnimation from "./TextAnimation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RightIconButtonArabic from "./RightIconButtonArabic";

const ArabicPlatformAdnSolutionTopCard = ({
  height = "70vh",
  heading,
  title1,
  title2,
  isSolutions = false,
  image,
  Joinbutton,
}) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="Arabic-image-container_common"
      style={{
        backgroundImage: `linear-gradient(-180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url(${image})`,
        height: height,
        backgroundAttachment: "fixed",
        paddingTop: "6%",
      }}
    >
      <div>
        {/* <TextAnimation
          text={heading}
          classNameText='text_top_header_rebound'
        /> */}
        <p
        className="Arabic-text_top_header_rebound"
        >
          {heading}
        </p>
      </div>
      <div
        className="Arabic-reboundmainimagecontainer"
        style={{
          display: "flex",
          justifyContent: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="reboundmainimageheading" style={{ width: "60%" }}>
          <div className="style fade-in">
            {title1 && <p className="text_title_top_rebound mt-4 Text-align-end">{title1}</p>}
          </div>
          <div className="style fade-in">
            {title2 && <p className="text_title_top_rebound mt-3 Text-align-end">{title2}</p>}
          </div>
        </div>
        <div className="button_rebound_header">
          {
            <RightIconButtonArabic
              paraclass={"rebound_button_font"}
              // classname={"button_rebound_button"}
              title={
                isSolutions
                  ? "register_now_to_rebound_solutions"
                  : "join_the_rebound_platform"
              }
              onClick={() => {
                isSolutions
                  ? navigate("/contact-us", {
                      state: "Rebound_solutions",
                    })
                  : window.open(
                      "https://rpx.reboundplasticexchange.com/register",
                      "_blank"
                    );
              }}
            />
          }
          {!isSolutions && (
            <p className="text_signIn_rebound pointer">
              {t("already_a_member?")}
              <span
                className="text_signIn_rebound2"
                onClick={() =>
                  window.open(
                    "https://rpx.reboundplasticexchange.com",
                    "_blank"
                  )
                }
              >
                {t("sign_in")}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArabicPlatformAdnSolutionTopCard;
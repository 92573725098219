import React from "react";
import ellipse10 from "Assets/Images/Ellipse10.png";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BlogPosts } from "./Resources/BlogPosts";

function Blogpost({ navigation }) {
  // const navigate = useNavigate();
  const params = useLocation();
  const routeParams = useParams();
  console.log("params", params?.state);
  const Data = BlogPosts.find((item) => item.slug === routeParams.slug);
const{t}=useTranslation()

  return (
    <div className="blogpostmaincontainer">
      <img src={ellipse10} alt="ellipse10" className="blogpostellipse" />
      <div className="blogpostinnercontainer">
        <div className="blogpostheadingcontainer">
          <p className="blogpostheadingfont">Blog Post</p>
          <p className="blogpostheadingfont">{t(Data?.date)}</p>
        </div>
        {/* <div
        className='blogpostimg'
        /> */}
        <div className="blogpostimg">
          <img
          className="blogpostimgmain"
          src={Data?.image} width="830px" height="458px" />
        </div>
        <div>
          <p className="blogpostimgheading">
            {/* Lorem ipsum dolor sit amet consectetur. Massa proin morbi facilisis */}
            {t(Data?.title)}
          </p>
          <p className="contributorname">By - {t(Data?.name)}</p>
        </div>
        <div style={{ paddingBottom: 40 }}>
          { Data?.description?.map((text, index) => {
            return (
              <React.Fragment key={index}>
                <p className="blogpostparagraph">{t(text)}</p>
              </React.Fragment>
            );
          })}
        </div>
        {/* <div>
        <p
        className='blogpostparagraph'
        >
        We at Rebound firmly believe that our most valuable resource is our team. Our commitment to building a sustainable future starts with our dedicated team, united by a shared passion for driving change and making a difference. We’re not just a company; we’re a community of innovators, environmentalists, and forward-thinkers, working together to shape a sustainable world.
        </p>
        <p
        className='blogpostparagraph'
        >
        Whether you’re an experienced professional seeking new horizons or a university student with a thirst for knowledge, our Careers Page is your gateway to exploring exciting job openings, understanding our vibrant company culture and values, and discovering internship opportunities for fresh graduates. Join us on this journey as we continue to lead the charge in reducing plastic pollution, advancing sustainability, and building a brighter future for all.
        </p>
        <p
        className='blogpostparagraph'
        >
        Lorem ipsum dolor sit amet consectetur. Massa proin morbi facilisis id vel vitae ipsum at. Diam risus ornare amet ut at nibh nec. Imperdiet risus sed non ut. Dui ipsum vitae euismod cursus ipsum. Nunc sed at cras diam in nunc lorem vehicula. Ut diam accumsan massa massa fames. In praesent volutpat dictumst aliquet aenean et nunc egestas. Eu mauris et mi pellentesque sed blandit commodo molestie neque. Elit ullamcorper eu risus tortor cum. Felis amet posuere metus adipiscing eget justo nec. Aliquam nulla amet eu id purus. Non pulvinar elit elementum nulla quam rhoncus. 
        </p>
        <p
        className='blogpostparagraph'
        >
        Quam enim gravida scelerisque id sit ante vitae tempor senectus. Ut elementum ut condimentum eget cras pellentesque semper egestas tristique. Imperdiet at in cras donec maecenas interdum. Dui enim risus nisi orci posuere morbi feugiat nibh. Semper consequat eu adipiscing turpis vitae massa id. Sit tristique vitae lorem ullamcorper scelerisque adipiscing. Vitae auctor nisl elementum nunc non viverra nec felis ut. Pretium lectus auctor feugiat amet. Vestibulum ut sagittis sollicitudin ut in aliquam. Fusce ac enim nunc donec turpis at. Curabitur mauris pellentesque quis pharetra pharetra elit dui. Vulputate arcu ipsum quam etiam leo sagittis facilisis consectetur pulvinar. 
        </p>
        <p
        className='blogpostparagraph'
        >
        Purus eget aliquam dignissim nunc nisi mattis. Molestie nisi non tristique posuere scelerisque aliquet. Diam facilisis mauris at amet volutpat iaculis. Venenatis platea est non faucibus diam nisi pulvinar vel sed. Ac eu non odio faucibus nunc feugiat suspendisse. Praesent at risus quis eget enim gravida arcu. Enim ornare gravida nunc in. Ultricies mi pellentesque non amet congue lectus laoreet sit facilisis. Quis lobortis sem ipsum sit massa viverra. Cras vitae vel nibh feugiat elementum. Sed id mattis proin ac amet ac orci imperdiet mauris. Ut nibh feugiat ridiculus faucibus bibendum lobortis scelerisque proin ac. Posuere orci viverra nunc id. Ut tempor urna vel odio adipiscing et fringilla. Diam sed sed venenatis sed in bibendum. 
        </p>
       </div> */}
        {/* <div
       className='blogpostbutton'
       >
        <RightIconButton
        onClick={()=>navigate("/contact-us")}
        />
       </div> */}
      </div>
    </div>
  );
}

export default Blogpost;

import React from "react";
import { useTranslation } from "react-i18next";

const SetsUpApart = ({ heading, title, Icon, style, textStyle, titleLast, isRotate=false }) => {
const {t}=useTranslation()

  return (
    <div className={`quality-assurance ${isRotate && 'rotate_text_card'}`} style={{ background: isRotate ? '#CAE5E6' : "#00578A" }}>
      <img
        src={Icon}
        alt="Image"
        layout="responsive"
        objectfit="contain"
        className={`ht-wt-30px`}
      />

      <div className={`text-container mt-1`}>
        <h4 className={!isRotate ? 'QualityText' : 'QualityText2'}>
          {t(heading)}
        </h4>
        <p className="text_Quality">{t(title)}</p>
      </div>
      {!isRotate && (
        <div className="flex_bottom_card">
          <div className="back-ground-div" />
          <p className="text_Quality3 mb-0">{t(titleLast)}</p>
        </div>
      )}
    </div>
  );
};
export default SetsUpApart;

import { IMAGES } from "../../Assets/Images";

export const BlogPosts = [
    {
        image: IMAGES.impBlog,
        name: "blog1_contributor_name",
        date: "blog1date",
        title: "blog1title",
        slug: "the-importance-of-recycling-plastic",

        description: [
            "blog1_para_1",
            "blog1_para_4",
            "blog1_para_5",
            "blog1_para_6",
            "blog1_para_7",
            "blog1_para_8",
            "blog1_para_9"
        ],
    },
    {
        image: IMAGES.billionBlog,
        name: "blog2_contributor_name",
        date: "blog2date",
        title: "blog2title",
        slug: "the-$6-billion-gcc-recycling-stronghold",
        description: [
            "blog2_para_1",
            "blog2_para_2",

            "blog2_para_3",

            "blog2_para_4",

            "blog2_para_5",
        ],
    },
    // {
    //   image: IMAGES.imageBlog4,
    //   title: "How plastic can be made into various innovative products.",
    // },
    // {
    //   image: IMAGES.imageBlog2,
    //   title: "How to recycle for beginners- Guide 101.",
    // },
    // {
    //   image: IMAGES.imageBlog5,
    //   title: "4",
    // },
    // {
    //   image: IMAGES.imageBlog5,
    //   title:
    //     "How plastic can be fantastic for Hong Kong’s innovation and technology hub ambitions.",
    // },
];


import { t } from "i18next";
import Carousel from "react-bootstrap/Carousel";
import ButtonFilledColor from "../../Components/ButtonFilledColor";

function HearItFromOurClients() {
  const NextIcon = () => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6638 23.003L23.2969 17.636C23.1093 17.4485 23.004 17.1941 23.004 16.9289C23.004 16.6637 23.1093 16.4094 23.2969 16.2218C23.4844 16.0343 23.7388 15.9289 24.004 15.9289C24.2692 15.9289 24.5235 16.0343 24.7111 16.2218L31.7822 23.2929C31.8734 23.3864 31.9455 23.4969 31.9943 23.6182C32.0953 23.8627 32.0953 24.1373 31.9943 24.3818C31.9455 24.5031 31.8734 24.6136 31.7822 24.7071L24.7111 31.7782C24.5235 31.9657 24.2692 32.0711 24.004 32.0711C23.7388 32.0711 23.4844 31.9657 23.2969 31.7782C23.1093 31.5906 23.004 31.3363 23.004 31.0711C23.004 30.8059 23.1093 30.5515 23.2969 30.364L28.6638 24.997L16.9329 25.0041C16.8009 25.0046 16.6701 24.979 16.548 24.9288C16.426 24.8785 16.315 24.8046 16.2217 24.7112C16.1284 24.6179 16.0544 24.507 16.0041 24.3849C15.9539 24.2628 15.9283 24.132 15.9288 24C15.9283 23.868 15.9539 23.7372 16.0041 23.6151C16.0544 23.493 16.1284 23.3821 16.2217 23.2888C16.315 23.1954 16.426 23.1215 16.548 23.0712C16.6701 23.021 16.8009 22.9954 16.9329 22.9959L28.6638 23.003Z"
        fill="black"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        transform="matrix(-1 0 0 1 47 0)"
        stroke="black"
      />
    </svg>
  );

  const PrevIcon = () => (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3362 23.003L24.7031 17.636C24.8907 17.4485 24.996 17.1941 24.996 16.9289C24.996 16.6637 24.8907 16.4094 24.7031 16.2218C24.5156 16.0343 24.2612 15.9289 23.996 15.9289C23.7308 15.9289 23.4765 16.0343 23.2889 16.2218L16.2178 23.2929C16.1266 23.3864 16.0545 23.4969 16.0057 23.6182C15.9047 23.8627 15.9047 24.1373 16.0057 24.3818C16.0545 24.5031 16.1266 24.6136 16.2178 24.7071L23.2889 31.7782C23.4765 31.9657 23.7308 32.0711 23.996 32.0711C24.2612 32.0711 24.5156 31.9657 24.7031 31.7782C24.8907 31.5906 24.996 31.3363 24.996 31.0711C24.996 30.8059 24.8907 30.5515 24.7031 30.364L19.3362 24.997L31.0671 25.0041C31.1991 25.0046 31.3299 24.979 31.452 24.9288C31.574 24.8785 31.685 24.8046 31.7783 24.7112C31.8716 24.6179 31.9456 24.507 31.9959 24.3849C32.0461 24.2628 32.0717 24.132 32.0712 24C32.0717 23.868 32.0461 23.7372 31.9959 23.6151C31.9456 23.493 31.8716 23.3821 31.7783 23.2888C31.685 23.1954 31.574 23.1215 31.452 23.0712C31.3299 23.021 31.1991 22.9954 31.0671 22.9959L19.3362 23.003Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="black" />
    </svg>
  );

  return (
    <div className="backgraung_image_ouer_client">
      <div className="testinomials_Container">
        <div className="text_haire_top mb-0">{t("Testimonials")}</div>
        <div className="text_haire_top_title">
          {t("Hear_what_our_community_hasto_say")}
        </div>
        <Carousel
          className="carousel_box_benifit"
          data-bs-theme="dark"
          prevIcon={<PrevIcon />}
          nextIcon={<NextIcon />}
          interval={1500}
        >
          {data.map((item, i) => (
            <Carousel.Item key={item.id}>
              <p className="text_title_carousel mb-3 mt-0 mb-3">
                {t(item.title)}
              </p>
              <p className="mb-0 testimonialTitle">{t(item.name)}</p>
              <p className="f_regular mb-5">{t(item.position)}</p>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default HearItFromOurClients;

const data = [
  {
    id: 1,
    title: "testimonialDescription1",
    name: "testimonialName1",
    position: "TestimonialPosition1",
  },
  {
    id: 2,
    title: "testimonialDescription2",
    name: "testimonialName2",
    position: "TestimonialPosition2",
  },
  {
    id: 3,
    title: "testimonialDescription3",
    name: "testimonialName3",
    position: "TestimonialPosition3",
  },
  {
    id: 4,
    title: "testimonialDescription4",
    name: "testimonialName4",
    position: "TestimonialPosition4",
  },
  {
    id: 5,
    title: "testimonialDescription5",
    name: "testimonialName5",
    position: "TestimonialPosition5",
  },
  {
    id: 6,
    title: "testimonialDescription6",
    name: "testimonialName6",
    position: "TestimonialPosition6",
  },
];

import React, { useEffect, useRef, useState } from "react";
import Sept20 from "../../Assets/Images/Sept20.png";
import { IMAGES } from "../../Assets/Images";
import { getCurrentDimension } from "../../Utils/Helper";
import bg_img from "../../Assets/Images/Sept20.png";
import { useTranslation } from "react-i18next";

function TimeLine() {
  const [selectedDate, setSelectedDate] = useState(0);
  const dimensions = getCurrentDimension();
  const { t } = useTranslation();

  const scrollerRef = useRef();
  const elRef = useRef();

  const handleButtonClick = (id) => {
    scrollerRef.current.scrollTo({
      left: ((dimensions.width / 2) * 1.2 + 40) * id,
      behavior: "smooth",
    });
    setSelectedDate(id);
  };

  return (
    <div>
      <div className="timelineheadingcontainer">
        <div className="scrollContainer" id="container" ref={elRef}>
          <p className="timelineheading">{t("A_TIME_INFO_OF_OUT")}</p>
        </div>

        <div
          className="timelineimages"
          ref={scrollerRef}
          style={{
            width: dimensions.width,
          }}
        >
          {date.map((item, idx) => {
            return (
              <div
                key={idx}
                onMouseEnter={() =>
                  setTimeout(() => {
                    handleButtonClick(idx);
                  }, 200)
                }
                style={
                  {
                    // height:"58vh"
                  }
                }
              >
                {
                  <div
                    className={
                      selectedDate !== idx ? "imag_slide" : "Sept20img"
                    }
                    id={"d" + item?.id}
                    style={{
                      width: (dimensions.width / 2) * 1.2,
                      backgroundImage: `linear-gradient(0deg, rgb(0, 0, 0) -10.65%, rgba(0, 0, 0, 0) ${item?.id === 8 ? '10%' : '147.57%'}),
                url(${item.images})`,
                      marginLeft: idx === 0 ? dimensions.width / 5.5 : 0,
                      marginRight:
                        idx === date?.length - 1 ? dimensions.width / 4.5 : 0,
                    }}
                    // width="500px"
                    // height="700px"
                  >
                    <div className="timelineparagraphcontainer">
                      <p className="timelineparagraphheading">{t(item.heading)}</p>
                      <p className="timelineparagraph f_regular">
                        {/* The idea behind Rebound is conceived to address
                        challenges in the global value chain. */}

                        {t(item.desc)}
                      </p>
                    </div>
                    <p className="timelinedate f_extra_b">{t(item?.date)}</p>
                  </div>
                }
              </div>
            );
          })}
        </div>
      </div>

      <div className="date_container">
        <div className="timelinemapoutcontainer2">
          {date.map((item, index) => (
            <div
              key={index}
              className="timelinemapincontainer"
              style={{
                color: selectedDate === index ? "#FF6700" : "black",
                fontSize: selectedDate === index ? 24 : 16,
              }}
              onClick={() => {
                handleButtonClick(index);
              }}
            >
              <div
                className="dateverticalline"
                style={{
                  height: selectedDate === index ? 80 : 50,
                }}
              />
              <p className="datefont">{t(item.date)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TimeLine;



const date = [
  {
    id: 1,
    date: "TimeLinedate1",
    heading: 'conception',
    images: IMAGES.Conception,
    desc: "timeDesc1",
  },
  {
    id: 2,
    date: "TimeLinedate2",
    heading: 'Establishment',
    images: IMAGES.Establishment,
    desc: "timeDesc2",
  },
  {
    id: 3,
    date: "TimeLinedate3",
    heading: 'UNEA_5_2',
    images: IMAGES.UNEA,
    desc: "timeDesc3",
  },
  {
    id: 4,
    date: "TimeLinedate4",
    heading: 'Rebound_Platform_Launch',
    images: IMAGES.Rebound_Platform_Launch,
    desc: "timeDesc4",
  },
  {
    id: 5,
    date: "TimeLinedate5",
    heading: 'Rebound_Solutions_Launch',
    images: IMAGES.Rebound_Solutions_Launch,
    desc: "timeDesc5",
  },
  {
    id: 6,
    date: "TimeLinedate6",
    heading: 'ISO_9001_2015',
    images: IMAGES.ISO_Certification_to_be_edited_pdf,
    desc: "timeDesc6",
  },
  // {
  //   id: 7,
  //   date: "TimeLinedate7",
  //   heading: 'Rebound_2_0',
  //   images: IMAGES.Rebound_2,
  //   desc: "timeDesc7",
  // },
  // {
  //   id: 8,
  //   date: "TimeLinedate8",
  //   heading: 'Technology_Innovation_of_the_Year_2023',
  //   images: IMAGES.FastCompany,
  //   desc: "timeDesc8",
  // },
  // {
  //   id: 9,
  //   date: "TimeLinedate9",
  //   heading: 'Most Innovative Companies of the Middle East 2023 ',
  //   images: IMAGES.MiddleEastWasteRecycling,
  //   desc: "timeDesc9",
  // },
  {
    id: 8,
    date: "TimeLinedate8",
    heading: '',
    images: IMAGES.FastFutureComboEng,
    desc: "",
  },
  {
    id: 10,
    date: "TimeLinedate10",
    heading: 'Future 100 ',
    images: IMAGES.Future,
    desc: "timeDesc10",
  },
];

import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTranslation } from "react-i18next";

// Images
import Hia_Magazine_November_2023 from "../../Assets/Images/Hia_Magazine_November_2023.jpg";
import MariyamImages from '../../Assets/Images/MariyamMansoor.jpg'

const News = ({ navigation }) => {
  const { t, i18n } = useTranslation();

  const randerHeight = (i) => {
    if (i === 7) {
      return "57vh";
    } else if (i === 1) {
      return "51vh";
    } else if (i === 4) {
      return "51vh";
    } else if (i === 2) {
      // return "31vh";
    } else {
      return "auto";
      // return "25vh";
    }
    return "auto";
  };
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 600: 2, 1000: 3 }}
      style={{ width: "100%" }}
    >
      <Masonry gutter="10px">
        {images.map((item, i) =>
          i !== 3 ? (
            <div
              key={i}
              style={{ position: "relative " }}
              className="gallery_news_gradiant"
            >
              <img
                key={i}
                src={item.image}
                alt="image"
                style={{
                 //height: randerHeight(i),
                  display: "block",
                  width: "100%",
                  position: "relative",
                  zIndex: -1,
                }}
              />
              <div className="titleEvent ">
                <p
                  className=" menu__link"
                  onClick={() => window.open(item?.link, "_blank")}
                >
                  {t(item.title)}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="388"
                    height="100"
                    viewBox="0 0 503 118"
                    fill="none"
                  >
                    <path
                      d="M321.99 20.7633C337.576 20.4906 385.846 23.2973 419.176 27.8536C452.176 32.3648 494.615 42.8211 498.763 59.4871C501.87 71.9656 475.116 95.1434 376.162 104.756C289.135 113.21 213.074 115.391 153.2 113.21C93.3247 111.028 -38.3222 95.0422 17.4832 49.3971C77.7561 0.0978867 298.385 1.86093 350.502 5.76467C390.171 8.73604 455.425 21.036 479.946 27.8536"
                      stroke="#FF6700"
                      strokeWidth="8"
                      strokeLinecap="round"
                    />
                  </svg>
                </p>
              </div>

              <div className="bottom_date">
                <p>{t(item?.Meta)}</p>

                <p>{t(item?.date)}</p>
              </div>
            </div>
          ) : (
            <></>
          )
        )}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default News;

const images = [

  //   "newsDate13":"Feb 28, 2023",
  // "newsMeta13":"SCMP",
  // "newsTitle13": "How plastic can be fantastic for Hong Kong’s innovation and technology hub ambitions",
{
    id: 24,
    image: MariyamImages,
    link: "https://marieclairearabia.com/%D9%85%D9%86%D9%88%D8%B9%D8%A7%D8%AA/%D9%85%D8%AC%D8%AA%D9%85%D8%B9/%D8%B1%D8%B3%D8%A7%D9%84%D8%A9-%D8%A3%D9%85%D9%84-%D9%81%D9%8A-%D9%8A%D9%88%D9%85-%D8%A7%D9%84%D8%A3%D8%B1%D8%B6-%D9%85%D8%B9-%D9%85%D8%B1%D9%8A%D9%85-%D8%A7%D9%84%D9%85%D9%86%D8%B5%D9%88%D8%B1%D9%8A",
    date: "newsDate24",
    Meta: "newsMeta24",
    title:
      "newsTitle24",
  },
  {
    id: 23,
    image:
      "https://businessnewsme.com/wp-content/uploads/2024/01/image005-800x445.jpg",
    link: "https://businessnewsme.com/2024/01/17/%D8%B1%D9%8A%D8%A8%D8%A7%D9%88%D9%86%D8%AF-%D9%88%D8%AC%D9%85%D8%B9%D9%8A%D8%A9-%D8%A7%D9%84%D8%AA%D8%BA%D9%84%D9%8A%D9%81-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%AF%D8%A7%D9%85-%D8%AA%D8%AA%D8%B9%D8%A7/",
    date: "newsDate23",
    Meta: "newsMeta23",
    title:
      "newsTitle23",
  },
  {
    id: 22,
    image:
      "https://static.zawya.com/view/acePublic/alias/contentid/M2Q4OGMyMmMtYzcwYi00/0/reboundcpa-jpg.webp?f=3%3A2&q=0.75&w=1200",
    link: "https://www.zawya.com/en/press-release/companies-news/rebound-and-circular-packaging-association-partner-to-advance-sustainable-packaging-solutions-uq6emk30",
    date: "newsDate22",
    Meta: "newsMeta22",
    title:
      "newsTitle22",
  },
  {
    id: 21,
    image:
      "https://gulfbusiness.com/wp-content/uploads/2023/07/Heatwave-GettyImages-1386597035-1.jpg",
    link: "https://gulfbusiness.com/how-women-can-power-climate-action-in-the-region/",
    date: "newsDate21",
    Meta: "newsMeta21",
    title:
      "newsTitle21",
  },
  {
    id: 20,
    image:
      "https://www.cm-today.com/uploads/docs/plastic_bottles11%20(1).png",
    link: "https://www.wasterecyclingmag.com/expert-talk/cop28/pioneering-solutions-at-cop28-charting-a-sustainable-future-through-collaboration-and-innovation-br-small-by-maryam-al-mansoori-small",
    date: "newsDate20",
    Meta: "newsMeta20",
    title:
      "newsTitle20",
  },
  {
    id: 19,
    image:
      "https://assets.wam.ae/resource/7dl09e1b1k71fy0pn.png",
    link: "https://wam.ae/article/apyuswj-masdar%E2%80%99s-youth-sustainability-announces-cop28",
    date: "newsDate19",
    Meta: "newsMeta19",
    title:
      "newsTitle19",
  },
  {
    id: 18,
    image:
      "https://admin.reboundsolution.com/storage/hazeem-sultan-linkedin-circular-economy-partnership-rebound.png",
    link: "https://www.linkedin.com/posts/borouge_borouge-rebound-activity-7143599591853133824-oGjp/?utm_source=share&utm_medium=member_desktop",
    date: "newsDate18",
    Meta: "newsMeta18",
    title:
      "newsTitle18",
  },
  {
    id: 17,
    image:
      "https://eu-images.contentstack.com/v3/assets/blt4a147f9e36f0754a/blt7419d3406a1036ec/651ffa5531b612d2d3e91008/plastics5feat.png?width=850&auto=webp&quality=95&format=jpg&disable=upscale",
    link: "https://www.waste360.com/waste-recycling/rebound-plastic-exchange-to-act-as-a-key-differentiator-for-the-british-recycling-roadmap",
    date: "newsDate17",
    Meta: "newsMeta17",
    title:
      "newsTitle17",
  },
  {
    id: 16,
    image: Hia_Magazine_November_2023,
    link: "https://magazine.hiamag.com/books/eeks/#p=72",
    date: "newsDate16",
    Meta: "newsMeta16",
    title:
      "newsTitle16",
  },
  {
    id: 1,
    image: "https://plastechmag.com/galorg/RE.jpg/RE.jpg",
    link: "https://plastechmag.com/newsdetails.aspx?id=1540",
    date: "newsDate1",
    Meta: "newsMeta1",
    title:
      "newsTitle1",
  },
  {
    id: 2,
    image: "https://www.cm-today.com/uploads/docs/july-aug-cover-2023.jpg",
    // link: "https://plastechmag.com/newsdetails.aspx?id=1540",
    date: "newsDate2",
    Meta: "newsMeta2",
    link: "https://www.wasterecyclingmea.com/top-stories/plastic-recycling/closing-the-plastics-loop-a-path-filled-with-challenges-br-small-by-r-keerthana-small",
    title: "newsTitle2",
  },
  {
    id: 3,
    image: "https://www.arabnews.jp/wp-content/uploads/2023/06/jeplan.jpg",
    // link: "https://www.arabnews.jp/article/business/article_93198/",
    date: "newsDate3",
    Meta: "newsMeta3",
    link: "https://www.arabnews.jp/article/business/article_93198/",
    title:
      "newsTitle3",
  },


  {
    id: 4,
    image:
      "https://imagevars.gulfnews.com/2016/4/6/1_16a082b6f88.1705476_1091098786_16a082b6f88_large.jpg",
    // link: "https://gulfnews.com/business/analysis/plastic-recycling-presents-an-economic-opportunity-too-for-gulf-states-1.96176400",
    date: "newsDate4",
    Meta: "newsMeta4",
    link: "https://gulfnews.com/business/analysis/plastic-recycling-presents-an-economic-opportunity-too-for-gulf-states-1.96176400",
    title:
      "newsTitle3",
  },
  {
    id: 5,
    image:
      "https://static.zawya.com/view/acePublic/alias/contentid/YjFlMjU2YjEtODJiYi00/0/horizontechnologies-jpeg.webp?f=3%3A2&q=0.75&w=1200",
    link: "https://www.zawya.com/en/press-release/companies-news/rebound-plastic-exchange-member-horizon-technologies-officially-recognized-by-moiat-uae-for-its-food-grade-rpet-ikui26h9",
    date: "newsDate5",
    Meta: "newsMeta5",
    title:
      "newsTitle5",
  },
  {
    id: 6,
    image:
      "https://www.albayan.ae/polopoly_fs/1.4652162.1682277846!/image/image.jpg",
    link: "https://www.albayan.ae/economy/uae/2023-04-24-1.4652202",
    date: "newsDate6",
    Meta: "newsMeta6",
    title:
      "newsTitle6",
  },
  {
    id: 7,
    image:
      "https://www.alkhaleej.ae/sites/default/files/styles/d10_standard/public/2023-04/5033056.webp?h=aa8169f8&itok=eHWZ1c7B",
    link: "https://www.alkhaleej.ae/2023-04-06/%D8%B1%D9%8A%D8%A8%D8%A7%D9%88%D9%86%D8%AF-%D9%85%D9%86%D8%B5%D8%A9-%D8%B9%D8%A7%D9%84%D9%85%D9%8A%D8%A9-%D9%84%D8%A5%D8%B9%D8%A7%D8%AF%D8%A9-%D8%A7%D9%84%D8%AA%D8%AF%D9%88%D9%8A%D8%B1/%D9%85%D8%AC%D9%84%D8%A9-%D8%A7%D9%84%D8%B5%D8%A7%D8%A6%D9%85",
    date: "newsDate7",
    Meta: "newsMeta7",
    title: "newsTitle7",
  },
  {
    id: 8,
    image:
      "https://thenational-the-national-prod.cdn.arcpublishing.com/resizer/Zqa_q9Hgj00XGww2WV1u1qG7eUo=/800x0/filters:format(jpg):quality(70):focal(1969x786:1979x796)/cloudfront-eu-central-1.images.arcpublishing.com/thenational/QG2VTBAVOV3GLY2WNT7NEUYRLE.jpg",
    link: "https://www.thenationalnews.com/opinion/2023/04/11/recycling-in-the-uae-waste-segregation/",
    date: "newsDate8",
    Meta: "nesMeta8",
    title: "newsTitle8",
  },

  {
    id: 9,
    image:
      "https://www.albayan.ae/polopoly_fs/1.4637991.1679780730!/image/image.jpg",
    link: "https://www.albayan.ae/economy/uae/2023-03-26-1.4638056",
    date: "newsDate9",
    Meta: "newsMeta9",
    title:
      "newsTitle9",
  },

  {
    id: 10,
    image: "https://trendsmena.com/wp-content/uploads/2023/03/maryam2.jpg",
    link: "https://trendsmena.com/business/women-leadership-key-to-sustainable-future/",
    date: "newsDate10",
    Meta: "newsMeta10",
    title: "newsTitle10",
  },

  {
    id: 11,
    image:
      "https://vid.alarabiya.net/images/2018/03/28/9e3f0ea4-2f25-4df2-ad48-9be1da7abec4/9e3f0ea4-2f25-4df2-ad48-9be1da7abec4_16x9_1200x676.jpg?width=1120&format=jpg",
    link: "https://english.alarabiya.net/views/2023/03/10/Empower-a-circular-economy-transition-through-public-private-collaboration",
    date: "newsDate11",
    Meta: "newsMeta11",
    title:
      "newsTitle11",
  },

  {
    id: 12,
    image:
      "https://www.edgemiddleeast.com/cloud/2023/03/08/THE-GAMECHANGERS-1920-%C3%97-1280px-4.jpg",
    link: "https://www.edgemiddleeast.com/business/the-gamechangers-female-leaders-shaping-the-tech-industry",
    date: "newsDate12",
    Meta: "newsMeta12",
    title: "newsTitle12",
  },

  {
    id: 13,
    image:
      "https://cdn.i-scmp.com/sites/default/files/styles/1200x800/public/d8/images/canvas/2023/02/27/12a2a2b3-77bc-48ea-b8ce-1d30b62a1438_3a152b00.jpg?itok=NURIBFpR&v=1677490157",
    link: "https://www.scmp.com/comment/opinion/article/3211461/how-plastic-can-be-fantastic-hong-kongs-innovation-and-technology-hub-ambitions",
    date: "newsDate13",
    Meta: "newsMeta13",
    title:
      "newsTitle13",
  },

  {
    id: 14,
    image:
      "https://static.zawya.com/view/acePublic/alias/contentid/NWFmMDM2M2EtNDkwZC00/2/1072191142.webp?f=3%3A2&q=0.75&w=1200",
    date: "newsDate14",
    Meta: "newsMeta14",
    link: "https://www.zawya.com/en/projects/industry/uae-based-plastics-trading-exchange-rpx-eyes-net-zero-transition-opportunities-bxb9pquu",
    title:
      "newsTitle14",
  },

  {
    id: 15,
    image:
      "https://static.zawya.com/view/acePublic/alias/contentid/NWFmMDM2M2EtNDkwZC00/2/1072191142.webp?f=3%3A2&q=0.75&w=1200",
    link: "https://www.zawya.com/en/press-release/companies-news/rebound-ltd-bioeconomy-and-acx-sign-a-mou-forming-a-net-zero-carbon-and-plastics-alliance-jggg4hpd?amp=1",
    date: "newsDate15",
    Meta: "newsMeta15",
    title:
      "newsTitle15",
  },
];

import React from "react";
// import "../Components/Timeline.css";
import img from "../../Assets/Images/dollar.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { t } from "i18next";
function ArabicWhatWeDo({ navigation }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="Arabic-whatwedocontainer">
      <div className="hoverCanburn">
        <img src={img} className="whatwedoimage" />
      </div>
      <div className="whatwedodetailcontainer">
        <div>
          <p className="Arabic-whatwedotitle f_extra_b">{t("what_we_do_heading")}</p>
        </div>
        <div className="whatwedoparagraphcontainer">
          <p className="Arabic-whatwedoparagraph">
            {t(
              "At_Rebound_we_are_on_a_mission_to_keep_plastics_out_of_the_environment_and_in"
            )}
            <p className="Arabic-whatwedoparagraph">
              {t("Through_our_trading_platform")}
              <span
                className="whatwedospan"
                onClick={() => {
                  navigate("/rebound-platform");
                  scrollToTop();
                }}
              >
                {" "}
                {t("rebound_platform_heading")}{" "}
              </span>{" "}
              {t("along_with_our_advisory_&_consultancy_services")}
              <span
                className="whatwedospan"
                onClick={() => {
                  navigate("/rebound-solution");
                  scrollToTop();
                }}
              >
                {t("rebound_solutions_heading")}
              </span>
              {t("we_act_as")}
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArabicWhatWeDo;

import React, { useEffect, useRef, useState } from "react";
import { getCurrentDimension } from "../Utils/Helper";
import newVideo from "Assets/newVideo.mp4";
import { IMAGES } from "Assets/Images";
import { useTranslation } from "react-i18next";
import { getDatabase, ref, onValue } from 'firebase/database';

function Home() {
  const [data, setData] = useState(null);
  const videoRef = useRef(null);
  const scrollSectionRef = useRef(null);
  const [hasLoaded, setLoaded] = useState(false);
  const dimensions = getCurrentDimension();
  const [isVisible, setIsVisible] = useState(true);
  const [scroll, setScroll] = useState(0);
  const div1 = useRef(null);
  const [imageOpacity, setImageOpacity] = useState(0);
  const [bubbleTime, setBubbleTime] = useState({});
  const [topText, setTopText] = useState("");
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   setLoaded(true);
  //   const playbackConst = 100; // Adjust the constant as needed
  //   // Use requestAnimationFrame for smooth playback
  //   function scrollPlay() {
  //     if (videoRef.current) {
  //       const frameNumber = window.pageYOffset / playbackConst;
  //       videoRef.current.currentTime = frameNumber;
  //       // console.log('frameNumber',frameNumber);

  //     }
  //     window.requestAnimationFrame(scrollPlay);
  //   }

  //   window.requestAnimationFrame(scrollPlay);
  // }, []);

  // useEffect(() => {
  //   const video = videoRef.current;

  //   video.addEventListener("loadedmetadata", () => {
  //     const { duration } = video;
  //     const playbackConst = 2000;
  //     const scrollSection = scrollSectionRef.current;
  //     console.log("scrollSection", videoRef.current.duration);
  //     if (videoRef?.current) {
  //       console.log("playbackConst", playbackConst);
  //       scrollSection.style.height =
  //         // Math.floor(duration) * playbackConst + "px";
  //         '900px'
  //     }
  //     console.log(duration); // Output: video duration in seconds
  //   });

  //   return () => {
  //     video.removeEventListener("loadedmetadata", () => { });
  //   };
  // }, []);

  // useEffect(() => {
  //   setY(window.scrollY);

  //   window.addEventListener("scroll", (e) => handleNavigation(e));
  // }, []);

  // useEffect(() => {
  //   const database = getDatabase();
  //   const myRef = ref(database, "rebound/value");
  //   onValue(myRef, (snapshot) => {
  //     const data = snapshot.val();
  //     if (!!data) {
  //       setData(data === 'success' ? true : false)
  //     } else {
  //       console.log('Data not found');
  //     }
  //   }, {
  //     onlyOnce: true
  //   });
  // }, []);

  const onScroll = () => {
    setScroll(div1.current.scrollTop);
    if (div1.current.scrollTop >= dimensions.height + 100) {
      setIsVisible(false);
    } else {
      // setIsVisible(true)
    }
  };

  useEffect(() => {
    videoRef.current.playbackRate = 1.6;
  }, []);
  useEffect(() => {
    let flag;
    setTimeout(() => {
      setBubbleTime({ ...bubbleTime, one: true });
      flag = { one: true };
    }, 1200);
    setTimeout(() => {
      setBubbleTime({ ...flag, two: true });
      flag = { ...flag, two: true };
    }, 1500);
    setTimeout(() => {
      setBubbleTime({ ...flag, three: true });
      flag = { ...flag, three: true };
    }, 1800);
    setTimeout(() => {
      setBubbleTime({ ...flag, four: true });
      flag = { ...flag, four: true };
    }, 2100);
    setTimeout(() => {
      setBubbleTime({ ...flag, five: true });
      flag = { ...flag, five: true };
    }, 2400);
    setTimeout(() => {
      setBubbleTime({ ...flag, six: true });
      flag = { ...flag, six: true };
    }, 2700);
    setTimeout(() => {
      setBubbleTime({ ...flag, seven: true });
      flag = { ...flag, seven: true };
    }, 3000);
  }, []);

  const rendarBubble = () => {
    setTimeout(() => {
      setTopText("The Global Hub for all Things Recycled Plastics");
    }, 1000);




    return (
      <>
        {
          data === 'success' &&
          <div class="loader"></div>
        }
        {
          <div className="bubbleContainer">
            {bubbleTime?.one && (
              <img src={IMAGES.bubble} className="bubble_style1 bubbleSize" />
            )}
            {bubbleTime?.two && (
              <img src={IMAGES.bubble2} className="bubble_style2 bubbleSize" />
            )}
            {bubbleTime?.three && (
              <img src={IMAGES.bubble3} className="bubble_style3 bubbleSize" />
            )}
            {bubbleTime?.four && (
              <img src={IMAGES.bubble4} className="bubble_style4 bubbleSize" />
            )}
            {bubbleTime?.five && (
              <img src={IMAGES.bubble5} className="bubble_style5 bubbleSize" />
            )}
            {bubbleTime?.six && (
              <img src={IMAGES.bubble6} className="bubble_style6 bubbleSize" />
            )}
            {bubbleTime?.seven && (
              <img src={IMAGES.bubble7} className="bubble_style7 bubbleSize" />
            )}
          </div>
        }
      </>
    );
  };

  return (
    <div style={{ background: "#000" }}>
      <div
        style={{
          position: "relative",
          background: "#000",
        }}
      >
        {rendarBubble()}
        <video
          id="custom-video"
          ref={videoRef}
          // id={y >=dimensions.height ? "v1" : "v0"}
          // id={"v0"}
          preload="preload"
          style={{
            // height: dimensions.height,
            width: dimensions.width,
            objectfit: "cover",
            // position: "relative"
          }}
          autoPlay
          loop
          muted
          playsInline
        // controls={true}
        // autoPlay={true}
        // play={true}
        >
          <source
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            // src="https://www.apple.com/media/us/mac-pro/2013/16C1b6b5-1d91-4fef-891e-ff2fc1c1bb58/videos/macpro_main_desktop.mp4"
            src={newVideo}
          ></source>
        </video>
        {/*  <div ref={scrollSectionRef} id="scrollSection" >
          <div className="text_scroll">
            <div className="home_text" style={{ height: dimensions.height, marginTop: -dimensions.height + 50 }}>The Global Hub for all Things Recycled Plastics</div>
            <div className="home_text2" style={{ height: dimensions.height }}>Our mission is to keep plastics out of the environment and in the economy, being part of the solution to reducing plastic pollution.</div>
            <div>
            </div>
          </div>
        </div> */}
        {
          data === 'success' &&
          <div class="loader"></div>
        }
        <div
          style={{
            position: "absolute",
            // top: dimensions.width > 768 ? "125px" : "78px",
            width: "100%",
            top: 0,
            width: "100%",
            height: "26%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          {topText && (
            <div
              className="home_text"
              style={{
                opacity: scroll > 0 ? 0 : 1,
                height: '100%',
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                flexDirection: "column",
              }}
            >
              <h1 className="jt home_text_heading">
                <span className="jt__row">
                  <span className="jt__text">{t("opening_statement")}</span>
                </span>
                <span className="jt__row jt__row--sibling" aria-hidden="true">
                  <span className="jt__text">{t("opening_statement")}</span>
                </span>
                <span className="jt__row jt__row--sibling" aria-hidden="true">
                  <span className="jt__text">{t("opening_statement")}</span>
                </span>
                <span className="jt__row jt__row--sibling" aria-hidden="true">
                  <span className="jt__text">{t("opening_statement")}</span>
                </span>
                <span className="jt__row jt__row--sibling" aria-hidden="true">
                  <span className="jt__text">{t("opening_statement")}</span>
                </span>
              </h1>
            </div>
          )}

          <div
            className="home_text2"
            style={{
              opacity: scroll > 0 ? 1 : 0,
              height: "26%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <h1 className="jt2">
              <span className="jt__row_out">
                <span className="jt__text_out">{t("mission")}</span>
              </span>
              <span className="jt__row_out jt__row_out--sibling" aria-hidden="true">
                <span className="jt__text_out">{t("mission")}</span>
              </span>
              <span className="jt__row_out jt__row_out--sibling" aria-hidden="true">
                <span className="jt__text_out">{t("mission")}</span>
              </span>
              <span className="jt__row_out jt__row_out--sibling" aria-hidden="true">
                <span className="jt__text_out">{t("mission")}</span>
              </span>
            </h1>
          </div>
        </div>

        {isVisible && (
          <div
            className="scroll_Show"
            ref={div1}
            onScroll={onScroll}
            onTouchMove={onScroll}
          >
            <div
              style={{
                width: "100%",
                height: dimensions.height,
                overflowY: "scroll",
              }}
            ></div>
            <div style={{ height: dimensions.height, width: "100%" }}></div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Home;

import React from "react";
import Rebound from "../../Assets/Images/rebound.png";
import { getCurrentDimension } from "../../Utils/Helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoRebound } from "./Navbar";

function ArabicFooters() {
  const dimensions = getCurrentDimension();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
    
      <div className="footer">
        {/* <img src={Rebound} className="rebound1" /> */}
       <div
       className="Arabicfooterrebound"
       >
       <LogoRebound
          style={{
            width: '134px',
            height: '30px',
            
            
            
          }}
        />
       </div>
        <div className="Arabicfootercomponent">
          <div className="footergap">
            <p className="Arabicfooterheading">{t('quick_links')}</p>

            <div className="Arabicfootercontent1">
            <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => {
                  navigate("/news-and-media-gallery");
                  scrollToTop();
                }}
              >
                {t('news_&_events')}
              </p>
              <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => navigate("/about-us")}
              >
                {t('about_us_heading')}
              </p>
              <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => {
                  navigate("/rebound-platform");
                  scrollToTop();
                }}
              >
                {t('rebound_platform_heading')}
              </p>
              <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => {navigate("/rebound-solution");
                scrollToTop();
              }}
              >
                {t('rebound_solutions_heading')}

              </p>
              
            </div>
            <div className="Arabifootercontent1 pointer">
              
              {/* <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => {
                  navigate("/impact");
                  scrollToTop();
                }}
              >
                {t('impact')}
              </p> */}
              <p
                className="f_regular Arabic-f_s-16 pointer"
                onClick={() => {
                  navigate("/careers");
                  scrollToTop();
                }
                }
              >
                {t('careers')}
              </p>
              <p
                className="f_regular f_s-16 pointer"
                onClick={() => {
                  navigate("/TermsAndCondition");
                  scrollToTop();
                }
                }
              >
                {t('TermsAndCondition')}
              </p>
            </div>
            <p className="Arabicfootercopyright">
             {t('©2023_Rebound_all_rights_reserved')}
            </p>
          </div>
          <div className="footergap1 ">
            <p className="Arabifooterheading">{t('location')}</p>
            <div className="footercontent2">
              {dimensions.width <= 766 ? (
                <p className="text_footer_adressMobile"
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/Rebound+Ltd./@24.5020691,54.3879619,18.77z/data=!4m14!1m7!3m6!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!2sRebound+Ltd.!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr!3m5!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr?entry=ttu",
                    // "https://www.google.com/maps/dir/22.7249243,75.8843732/rebound+limited/@24.0262737,51.8889484,8z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!2m2!1d54.3887213!2d24.5020971?entry=ttu",
                    
                    "_blank"
                  )
                }}
                >
                  {/* Al Sarab Tower, Al Maryah Island, Abu Dhabi, UAE */}
                  {t('address_at_footer')}
                </p>
              ) : (
                <p
                  className="Arabictext_footer_adress"
                  onClick={() => {
                    window.open(
                      "https://www.google.com/maps/place/Rebound+Ltd./@24.5020691,54.3879619,18.77z/data=!4m14!1m7!3m6!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!2sRebound+Ltd.!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr!3m5!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr?entry=ttu",
                      // "https://www.google.com/maps/dir/22.7249243,75.8843732/rebound+limited/@24.0262737,51.8889484,8z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!2m2!1d54.3887213!2d24.5020971?entry=ttu",
                      
                      "_blank"
                    )
                  }}
                >
                  {t('address_at_footer')}
                </p>
              )}
              {/* <p className="text_footer_adress">
                Al Sarab Tower, Al Maryah Island, Abu Dhabi, UAE
              </p> */}
              <p className="f_regular Arabic-f_s-16 footercontactus" onClick={() => {
                navigate("/contact-us");
                scrollToTop();
              }
              }
                style={{
                  cursor: 'pointer'
                }}
              >{t('contact_us_footer')}</p>
              {/* <p className="f_regular Arabic-f_s-16">Sign up to our Newsletter</p> */}
              <p
                className="f_regular Arabic-f_s-16 pointer footernewsletter"
                onClick={() => {
                  navigate("/contact-us", { state: "Newsletters" })
                  scrollToTop();
                }}
                style={
                  dimensions.width <= 766
                    ? {
                      paddingRight: 0,
                    }
                    : {
                      // paddingRight: '12%',
                    }
                }
              >
                {t('sign_up_to_our_Newsletter')}
              </p>

              <>
              {dimensions.width<=766?
                 <p
                 className="f_regular f_s-16 pointer footernewsletter"
                 onClick={() => {
                   navigate("/TermsAndCondition");
                   scrollToTop();
                 }
                 }
               >
                 {t('TermsAndCondition')}
               </p>
              
              :<></>
}
              </>
              <p
              className="copyright2"
              >
              {t('©2023_Rebound_all_rights_reserved')} 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArabicFooters;
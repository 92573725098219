import React from "react";
import { useTranslation } from "react-i18next";

const ArabicSetsUpApart = ({ heading, title, Icon, style, textStyle, titleLast, isRotate=false }) => {
const {t}=useTranslation()

  return (
    <div className={`Arabicquality-assurance ${isRotate && 'rotate_text_card'}`} style={{ background: isRotate ? "#CAE5E6" : "#00578A"}}>
      <img
        src={Icon}
        alt="Image"
        layout="responsive"
        objectFit="contain"
        className={`ht-wt-30px`}
      />

      <div className={`text-container mt-1`}>
        <h4 className={!isRotate ? 'Arabic-QualityText' : 'QualityText2'}>
          {t(heading)}
        </h4>
        <p className="text_Quality">{t(title)}</p>
      </div>
      {!isRotate && (
        <div className=" Arabicflex_bottom_card ">
          <div className="back-ground-div" />
          <p className="Arabictext_Quality3">{t(titleLast)}</p>
        </div>
      )}
    </div>
  );
};
export default ArabicSetsUpApart;

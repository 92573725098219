import { React, useState, useRef, useEffect } from "react";
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import TextSlideUp from "Components/TextSlideUp";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import ButtonFilledColor from "Components/ButtonFilledColor";
import importedPdf from '../../Assets/Reports/Rebound Reflections - Summary End of Year Report 2023.pdf';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

function WhitepaperReport() {

    const { t, i18n } = useTranslation();

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    const isPdfVisible = useRef(false);
    const showPopup = useRef(true);
    const lastPageRef = useRef(null);
    const secondLastPageRef = useRef(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    function closeModal() {
        setModalIsOpen(false);
    }

    useEffect(() => {
        const lastPageObserver = new IntersectionObserver((entries, observer) => {
            const entry = entries[0];
            if (entry.isIntersecting && isPdfVisible.current && showPopup.current) {
                setModalIsOpen(true);
                showPopup.current = false;
            }
        });
        lastPageObserver.observe(lastPageRef.current);

        const secondLastPageObserver = new IntersectionObserver((entries, observer) => {
            const entry = entries[0];
            if (entry.isIntersecting && isPdfVisible.current) {
                showPopup.current = true;
            }
        });
        secondLastPageObserver.observe(secondLastPageRef.current);

        // Cleanup function
        return () => {
            setModalIsOpen(false);
            lastPageObserver.disconnect(); // Disconnect the lastPageObserver when component unmounts
        };
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        isPdfVisible.current = true;
    }

    return <>
        <div className="blogpostmaincontainer" style={{display: 'flex', flexDirection: 'column', paddingTop: '90px'}}>
            
        <ul style={{display: 'flex', justifyContent: 'center'}}>
                <li style={{display:'flex', padding:'0px 10px',listStyle:"none"}}>
                    <Link style={{padding: '8px 16px', border: '1px solid rgb(0, 0, 0)', borderRadius: '50px', color: '#000', textDecoration: 'none'}} to="/resources" >
                        {t('GoBackToResources')}
                    </Link>
                </li>
            </ul>
        
            <TextSlideUp
                color="black"
                className={"textHeadingContectUs"}
                Heading={"reflectiontitle"}
            />
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Document file={importedPdf} onLoadSuccess={onDocumentLoadSuccess} style={{display: 'flex', justifyContent: 'center'}} >
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={1} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={2} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={3} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={4} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={5} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={6} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={7} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={8} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={9} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={10} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                    
                </Document>
                
                <a href="#" ref={secondLastPageRef} style={{display: 'flex', justifyContent: 'center'}} ></a>
                
                <Document file={importedPdf} onLoadSuccess={onDocumentLoadSuccess} style={{display: 'flex', justifyContent: 'center'}} >
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={11} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                </Document>

                <a href="#" ref={lastPageRef} style={{display: 'flex', justifyContent: 'center'}} ></a>

                <Document file={importedPdf} onLoadSuccess={onDocumentLoadSuccess} style={{display: 'flex', justifyContent: 'center'}}>
                    <Page className="pdf-canvas" style={{border:'1px solid #333'}} pageNumber={12} renderTextLayer={false} scale={1} width={1000} renderAnnotationLayer={false} />
                </Document>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Purchase Report Modal"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('ReflectionsReportPurchasePopupTitle')}</h5>
                        <button onClick={closeModal} type="button" className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p style={{marginTop: '10px' }}>{t('ReflectionsReportPurchaseMessage')} <a href="mailto:info@reboundplastic.com">info@reboundplastic.com</a></p>
                    </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <ButtonFilledColor
                            buttonfilledcolorclasscontainer={
                                "buttonfilledcolorclasscontainer"
                            }
                            buttonfilledcolorclass={'buttonfilledcolorclass'}
                            onClick={closeModal}
                            title={"Close"}
                            btnColor={"#00578A"}
                            height={"3vw"}
                            />
                    </div>
                </div>
            </Modal>

        </div>
    </>;
}

export default WhitepaperReport;
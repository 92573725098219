import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import enTranslation from "Constants/Language/En.json";
import arTranslation from "Constants/Language/Ar.json";
import AboutUs from "Screens/AboutUs/AboutUs";
import Home from "Screens/Home";
import Resources from "Screens/Resources/Resources";
import ReboundPlatform from "Screens/ReboundPlatform/ReboundPlatform";
import Modal from "Screens/Modal";
import ReboundSolutions from "Screens/ReboundSolutions/ReboundSolutions";
import ContactUs from "Screens/ContactUs";
import Navbar from "Screens/Header&Footer/Navbar";
import Drawer from "Screens/Drawer";
import Footers from "Screens/Header&Footer/Footers";
import Careers from "Screens/Careers";
import NewsAndMediaGallery from "Screens/NewsAndMediaGallery/NewsAndMediaGallery";
import Blogpost from "Screens/Blogpost";
import Arabicreboundplatform from "Screens/ReboundPlatform/Arabic-rebound-platform";
import ArabicNewsAndMediaGallery from "Screens/NewsAndMediaGallery/ArabicNewsAndMediaGallery";
import Arabic_AboutUs from "Screens/AboutUs/Arabic_AboutUs";
import ArabicContactUs from "Screens/ArabicContactus";
import ArabicImpact from "Screens/ArabicImpact";
import ArabicReboundSolutions from "Screens/ReboundSolutions/ArabicReboundSolution";
import ArabicBlogpost from "Screens/ArabicBlogpost";
import ArabicFooters from "Screens/Header&Footer/ArabicFooter";
import ResourcesArabic from "Screens/Resources/ResourcesArabic";
import CareersArabic from "Screens/CareersArabic";
import ArabicNavbar from "Screens/Header&Footer/ArabicNavbar";
import TermsAndCondition from "Screens/TermsAndCondition";
import TermsAndConditionArabic from "Screens/TermsAndConditionArabic";
import { getDatabase, ref, onValue } from 'firebase/database';
import WhitepaperReport from "Screens/Resources/WhitepaperReport";

function App() {
  const [defaultLanguage, setdefaultLanguage] = useState("en");
  const [state, setState] = React.useState(false);
  const [data, setData] = useState(null);

  const ScrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top on route change
    return null; // Render nothing (it's just a utility component)
  };

  useEffect(() => {
    // debugger;
    // const database = getDatabase();
    // const myRef = ref(database, "rebound/value");
    // onValue(myRef, (snapshot) => {
    //   const data = snapshot.val();
    //   if (!!data) {
    //     setData(data === 'success' ? true : false)
    //     if (data !== 'success') {
          import("AppStyle.css")
          import("Responsive1440.css")
          import("Components/ComponentsStyles.css")
          import("Components/ImageScroll/Carousel.css")
          import("bootstrap/dist/css/bootstrap.min.css")
    //     }
    //   } else {
    //     console.log('Data not found');
    //   }
    // }, {
    //   onlyOnce: true
    // });
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || defaultLanguage;
    setdefaultLanguage(initialLanguage);

    if (window.self !== window.top) {
      window.top.location = window.self.location;
    }
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || defaultLanguage;
    setdefaultLanguage(initialLanguage);
    if (initialLanguage == "ar") {
      import("Arabic.css");

      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
  }, [defaultLanguage]);

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    lng: defaultLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
  const checkLanguage = () => {
    return defaultLanguage === "en";
  };
  // return(
  //   <>
  //   <Arabicreboundplatform/>
  //   </>
  // )

  return (
    <Router>
      <ScrollToTop />

      {/* <Navbar state={state} setState={setState} setdefaultLanguage={setdefaultLanguage}/> */}
      {checkLanguage() ? (
        <Navbar
          state={state}
          setState={setState}
          setdefaultLanguage={setdefaultLanguage}
        />
      ) : (
        <ArabicNavbar
          state={state}
          setState={setState}
          setdefaultLanguage={setdefaultLanguage}
        />
      )}
      {state && (
        <div className="mainAppDrawer">
          <Drawer state={state} setState={setState} />
        </div>
      )}
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route
          exact
          path="/about-us"
          element={checkLanguage() ? <AboutUs /> : <Arabic_AboutUs />}
        ></Route>
        <Route
          exact
          path="/rebound-platform"
          element={
            checkLanguage() ? <ReboundPlatform /> : <Arabicreboundplatform />
          }
        ></Route>
        <Route
          exact
          path="/rebound-solution"
          element={
            checkLanguage() ? <ReboundSolutions /> : <ArabicReboundSolutions />
          }
        ></Route>
        <Route
          exact
          path="/news-and-media-gallery"
          element={
            checkLanguage() ? (
              <NewsAndMediaGallery />
            ) : (
              <ArabicNewsAndMediaGallery />
            )
          }
        ></Route>
        {/* <Route
          exact
          path="/news-and-media-gallery"
          element={<NewsAndMediaGallery />}
        ></Route> */}
        <Route
          exact
          path="/resources"
          element={checkLanguage() ? <Resources /> : <ResourcesArabic />}
        ></Route>
        {/* <Route exact path="/resources" element={<Resources />}></Route> */}
        {/* <Route
          exact
          path="/impact"
          element={checkLanguage() ? <Impact /> : <ArabicImpact />}
        ></Route> */}
        {/* <Route exact path="/impact" element={<Impact />}></Route> */}
        <Route
          exact
          path="/careers"
          element={checkLanguage() ? <Careers /> : <CareersArabic />}
        ></Route>
        <Route
          exact
          path="/contact-us"
          element={checkLanguage() ? <ContactUs /> : <ArabicContactUs />}
        ></Route>
        <Route exact path="/modal" element={<Modal />}></Route>
        <Route
          exact
          path="/blog/:slug"
          element={checkLanguage() ? <Blogpost /> : <ArabicBlogpost />}
        ></Route>
        <Route
          exact
          path="/rebound-reflections-report-2023"
          element={checkLanguage() ? <WhitepaperReport /> : <WhitepaperReport />}
        ></Route>
        <Route exact path="*" element={<Home />}></Route>
        {/* <Route exact path="/footers" element={<ArabicFooters />}></Route> */}
        <Route
          exact
          path="/TermsAndCondition"
          element={checkLanguage() ? <TermsAndCondition/>: <TermsAndConditionArabic/> }
       />
      </Routes>
      {checkLanguage() ? <Footers /> : <ArabicFooters />}


      {/* <Footers /> */}

  
    </Router>
  );
  // return (
  //   <div className="App">
  //     <NavigatorContainer />
  //   </div>
  // );
}

export default App;

import React from "react";
import { useTranslation } from "react-i18next";

const InputField = (props) => {
  const { t } = useTranslation();

  const { label, type, id, value, onChange, placeholder, name, min, inputmode, pattern, title } = props;

  return (
    <div>
      <div className="textLebel">{label}</div>
      <div className="input-container">
        <input
          id={id}
          type={type}
          className="input-field"
          placeholder={t(placeholder)}
          onChange={onChange}
          name={name}
          value={t(value)}
          min={min}
          inputMode={inputmode}
          pattern={pattern}
          title={title}
        />
      </div>
    </div>
  );
};

export default InputField;

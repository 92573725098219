import React from "react";

const color1 = "#ff6700"
const color3 = "black"
const color2 = "blue"

function ResourceMapSvg() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1051 778" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M276.96 455.344H269.256L265.404 448.577L269.256 441.809H276.96L280.702 448.577L276.96 455.344Z" fill="#CAE5E6" />
    <path d="M262.539 463.914H254.945L251.093 457.034L254.945 450.267H262.539L266.391 457.034L262.539 463.914Z" fill="#CAE5E6" />
    <path d="M276.96 472.374H269.256L265.404 465.606L269.256 458.726H276.96L280.702 465.606L276.96 472.374Z" fill="#CAE5E6" />
    <path d="M291.265 463.914H283.671L279.819 457.034L283.671 450.267H291.265L295.118 457.034L291.265 463.914Z" fill="#CAE5E6" />
    <path d="M291.265 446.883H283.671L279.819 440.116L283.671 433.235H291.265L295.118 440.116L291.265 446.883Z" fill="#CAE5E6" />
    <path d="M276.96 438.426H269.256L265.404 431.545L269.256 424.778H276.96L280.702 431.545L276.96 438.426Z" fill="#CAE5E6" />
    <path d="M262.539 446.883H254.945L251.093 440.116L254.945 433.235H262.539L266.391 440.116L262.539 446.883Z" fill="#CAE5E6" />
    <path d="M248.234 472.374H240.53L236.678 465.606L240.53 458.726H248.234L251.976 465.606L248.234 472.374Z" fill="#CAE5E6" />
    <path d="M262.539 480.834H254.945L251.093 474.066L254.945 467.299H262.539L266.391 474.066L262.539 480.834Z" fill="#CAE5E6" />
    <path d="M276.96 489.407H269.256L265.404 482.526L269.256 475.759H276.96L280.702 482.526L276.96 489.407Z" fill="#CAE5E6" />
    <path d="M291.265 480.832H283.671L279.819 474.064L283.671 467.297H291.265L295.118 474.064L291.265 480.832Z" fill="#CAE5E6" />
    <path d="M305.683 472.374H297.979L294.127 465.606L297.979 458.726H305.683L309.535 465.606L305.683 472.374Z" fill="#CAE5E6" />
    <path d="M305.683 455.344H297.979L294.127 448.577L297.979 441.809H305.683L309.535 448.577L305.683 455.344Z" fill="#CAE5E6" />
    <path d="M305.683 438.424H297.979L294.127 431.543L297.979 424.776H305.683L309.535 431.543L305.683 438.424Z" fill="#CAE5E6" />
    <path d="M291.265 429.851H283.671L279.819 423.083L283.671 416.315H291.265L295.118 423.083L291.265 429.851Z" fill="#CAE5E6" />
    <path d="M276.96 421.391H269.256L265.404 414.623L269.256 407.743H276.96L280.702 414.623L276.96 421.391Z" fill="#CAE5E6" />
    <path d="M262.539 429.851H254.945L251.093 423.083L254.945 416.315H262.539L266.391 423.083L262.539 429.851Z" fill="#CAE5E6" />
    <path d="M248.234 438.426H240.53L236.678 431.545L240.53 424.778H248.234L251.976 431.545L248.234 438.426Z" fill="#CAE5E6" />
    <path d="M248.234 455.344H240.53L236.678 448.577L240.53 441.809H248.234L251.976 448.577L248.234 455.344Z" fill="#CAE5E6" />
    <path d="M233.815 480.834H226.221L222.369 474.066L226.221 467.299H233.815L237.667 474.066L233.815 480.834Z" fill="#CAE5E6" />
    <path d="M248.234 489.407H240.53L236.678 482.526L240.53 475.759H248.234L251.976 482.526L248.234 489.407Z" fill="#CAE5E6" />
    <path d="M262.539 497.865H254.945L251.093 491.098L254.945 484.33H262.539L266.391 491.098L262.539 497.865Z" fill="#CAE5E6" />
    <path d="M276.96 506.325H269.256L265.404 499.558L269.256 492.79H276.96L280.702 499.558L276.96 506.325Z" fill="#CAE5E6" />
    <path d="M291.265 497.865H283.671L279.819 491.098L283.671 484.33H291.265L295.118 491.098L291.265 497.865Z" fill="#CAE5E6" />
    <path d="M305.683 489.407H297.979L294.127 482.526L297.979 475.759H305.683L309.535 482.526L305.683 489.407Z" fill="#CAE5E6" />
    <path d="M319.99 480.834H312.396L308.544 474.066L312.396 467.299H319.99L323.842 474.066L319.99 480.834Z" fill="#CAE5E6" />
    <path d="M319.99 463.914H312.396L308.544 457.034L312.396 450.267H319.99L323.842 457.034L319.99 463.914Z" fill="#CAE5E6" />
    <path d="M319.99 446.883H312.396L308.544 440.116L312.396 433.235H319.99L323.842 440.116L319.99 446.883Z" fill="#CAE5E6" />
    <path d="M319.99 429.851H312.396L308.544 423.083L312.396 416.315H319.99L323.842 423.083L319.99 429.851Z" fill="#CAE5E6" />
    <path d="M305.683 421.391H297.979L294.127 414.623L297.979 407.743H305.683L309.535 414.623L305.683 421.391Z" fill="#CAE5E6" />
    <path d="M291.265 412.933H283.671L279.819 406.053L283.671 399.285H291.265L295.118 406.053L291.265 412.933Z" fill="#CAE5E6" />
    <path d="M276.96 404.36H269.256L265.404 397.593L269.256 390.825H276.96L280.702 397.593L276.96 404.36Z" fill="#CAE5E6" />
    <path d="M262.539 412.933H254.945L251.093 406.053L254.945 399.285H262.539L266.391 406.053L262.539 412.933Z" fill="#CAE5E6" />
    <path d="M248.234 421.391H240.53L236.678 414.623L240.53 407.743H248.234L251.976 414.623L248.234 421.391Z" fill="#CAE5E6" />
    <path d="M233.815 429.851H226.221L222.369 423.083L226.221 416.315H233.815L237.667 423.083L233.815 429.851Z" fill="#CAE5E6" />
    <path d="M233.815 446.883H226.221L222.369 440.116L226.221 433.235H233.815L237.667 440.116L233.815 446.883Z" fill="#CAE5E6" />
    <path d="M233.815 463.914H226.221L222.369 457.034L226.221 450.267H233.815L237.667 457.034L233.815 463.914Z" fill="#CAE5E6" />
    <path d="M219.507 489.407H211.803L207.951 482.526L211.803 475.759H219.507L223.249 482.526L219.507 489.407Z" fill="#CAE5E6" />
    <path d="M233.815 497.865H226.221L222.369 491.098L226.221 484.33H233.815L237.667 491.098L233.815 497.865Z" fill="#CAE5E6" />
    <path d="M248.234 506.325H240.53L236.678 499.558L240.53 492.79H248.234L251.976 499.558L248.234 506.325Z" fill="#CAE5E6" />
    <path d="M262.539 514.895H254.945L251.093 508.128L254.945 501.248H262.539L266.391 508.128L262.539 514.895Z" fill="#CAE5E6" />
    <path d="M276.96 523.355H269.256L265.404 516.588L269.256 509.82H276.96L280.702 516.588L276.96 523.355Z" fill="#CAE5E6" />
    <path d="M291.265 514.895H283.671L279.819 508.128L283.671 501.248H291.265L295.118 508.128L291.265 514.895Z" fill="#CAE5E6" />
    <path d="M305.683 506.325H297.979L294.127 499.558L297.979 492.79H305.683L309.535 499.558L305.683 506.325Z" fill="#CAE5E6" />
    <path d="M319.99 497.865H312.396L308.544 491.098L312.396 484.33H319.99L323.842 491.098L319.99 497.865Z" fill="#CAE5E6" />
    <path d="M334.409 489.407H326.705L322.853 482.526L326.705 475.759H334.409L338.261 482.526L334.409 489.407Z" fill="#CAE5E6" />
    <path d="M334.409 472.374H326.705L322.853 465.606L326.705 458.726H334.409L338.261 465.606L334.409 472.374Z" fill="#CAE5E6" />
    <path d="M334.409 455.344H326.705L322.853 448.577L326.705 441.809H334.409L338.261 448.577L334.409 455.344Z" fill="#CAE5E6" />
    <path d="M334.409 438.426H326.705L322.853 431.545L326.705 424.778H334.409L338.261 431.545L334.409 438.426Z" fill="#CAE5E6" />
    <path d="M334.409 421.391H326.705L322.853 414.623L326.705 407.743H334.409L338.261 414.623L334.409 421.391Z" fill="#CAE5E6" />
    <path d="M319.99 412.933H312.396L308.544 406.053L312.396 399.285H319.99L323.842 406.053L319.99 412.933Z" fill="#CAE5E6" />
    <path d="M305.683 404.36H297.979L294.237 397.593L297.979 390.825H305.683L309.425 397.593L305.683 404.36Z" fill="#CAE5E6" />
    <path d="M291.267 395.788H283.673L279.931 389.133L283.673 382.366H291.267L295.009 389.133L291.267 395.788Z" fill="#CAE5E6" />
    <path d="M276.96 387.327H269.256L265.404 380.56L269.256 373.792H276.96L280.702 380.56L276.96 387.327Z" fill="#CAE5E6" />
    <path d="M262.539 395.9H254.945L251.093 389.132L254.945 382.252H262.539L266.391 389.132L262.539 395.9Z" fill="#CAE5E6" />
    <path d="M248.234 404.36H240.53L236.678 397.593L240.53 390.825H248.234L251.976 397.593L248.234 404.36Z" fill="#CAE5E6" />
    <path d="M233.815 412.933H226.221L222.369 406.053L226.221 399.285H233.815L237.667 406.053L233.815 412.933Z" fill="#CAE5E6" />
    <path d="M219.507 421.391H211.803L207.951 414.623L211.803 407.743H219.507L223.249 414.623L219.507 421.391Z" fill="#CAE5E6" />
    <path d="M219.507 438.424H211.803L207.951 431.543L211.803 424.776H219.507L223.249 431.543L219.507 438.424Z" fill="#CAE5E6" />
    <path d="M219.507 455.344H211.803L207.951 448.577L211.803 441.809H219.507L223.249 448.577L219.507 455.344Z" fill="#CAE5E6" />
    <path d="M219.507 472.374H211.803L207.951 465.606L211.803 458.726H219.507L223.249 465.606L219.507 472.374Z" fill="#CAE5E6" />
    <path d="M205.091 497.865H197.387L193.645 491.098L197.387 484.33H205.091L208.943 491.098L205.091 497.865Z" fill="#CAE5E6" />
    <path d="M219.507 506.325H211.803L207.951 499.558L211.803 492.79H219.507L223.249 499.558L219.507 506.325Z" fill="#CAE5E6" />
    <path d="M233.815 514.895H226.221L222.369 508.128L226.221 501.248H233.815L237.667 508.128L233.815 514.895Z" fill="#CAE5E6" />
    <path d="M248.234 523.355H240.53L236.678 516.588L240.53 509.82H248.234L251.976 516.588L248.234 523.355Z" fill="#CAE5E6" />
    <path d="M262.539 531.928H254.945L251.093 525.047L254.945 518.28H262.539L266.391 525.047L262.539 531.928Z" fill="#CAE5E6" />
    <path d="M276.96 540.388H269.256L265.404 533.621L269.256 526.74H276.96L280.702 533.621L276.96 540.388Z" fill="#CAE5E6" />
    <path d="M291.265 531.928H283.671L279.819 525.047L283.671 518.28H291.265L295.118 525.047L291.265 531.928Z" fill="#CAE5E6" />
    <path d="M305.683 523.355H297.979L294.127 516.588L297.979 509.82H305.683L309.535 516.588L305.683 523.355Z" fill="#CAE5E6" />
    <path d="M319.99 514.895H312.396L308.544 508.128L312.396 501.248H319.99L323.842 508.128L319.99 514.895Z" fill="#CAE5E6" />
    <path d="M334.409 506.325H326.705L322.853 499.558L326.705 492.79H334.409L338.261 499.558L334.409 506.325Z" fill="#CAE5E6" />
    <path d="M348.719 497.865H341.125L337.272 491.098L341.125 484.33H348.719L352.571 491.098L348.719 497.865Z" fill="#CAE5E6" />
    <path d="M348.719 480.834H341.125L337.272 474.066L341.125 467.299H348.719L352.571 474.066L348.719 480.834Z" fill="#CAE5E6" />
    <path d="M348.719 463.914H341.125L337.272 457.034L341.125 450.267H348.719L352.571 457.034L348.719 463.914Z" fill="#CAE5E6" />
    <path d="M348.719 446.883H341.125L337.272 440.116L341.125 433.235H348.719L352.571 440.116L348.719 446.883Z" fill="#CAE5E6" />
    <path d="M348.719 429.851H341.125L337.272 423.083L341.125 416.315H348.719L352.571 423.083L348.719 429.851Z" fill="#CAE5E6" />
    <path d="M348.719 412.933H341.125L337.272 406.053L341.125 399.285H348.719L352.571 406.053L348.719 412.933Z" fill="#CAE5E6" />
    <path d="M333.968 403.798H327.034L323.622 397.595L327.034 391.391H333.968L337.489 397.595L333.968 403.798Z" fill="#CAE5E6" />
    <path d="M288.514 374.02H286.423L285.322 372.103L286.423 370.185H288.514L289.615 372.103L288.514 374.02Z" fill="#CAE5E6" />
    <path d="M275.415 367.814H270.682L268.371 363.641L270.682 359.354H275.415L277.836 363.641L275.415 367.814Z" fill="#CAE5E6" />
    <path d="M262.428 378.645H255.054L251.312 372.103L255.054 365.561H262.428L266.06 372.103L262.428 378.645Z" fill="#CAE5E6" />
    <path d="M248.234 387.327H240.53L236.678 380.56L240.53 373.792H248.234L251.976 380.56L248.234 387.327Z" fill="#CAE5E6" />
    <path d="M233.815 395.9H226.221L222.369 389.132L226.221 382.252H233.815L237.667 389.132L233.815 395.9Z" fill="#CAE5E6" />
    <path d="M219.507 404.36H211.803L207.951 397.593L211.803 390.825H219.507L223.249 397.593L219.507 404.36Z" fill="#CAE5E6" />
    <path d="M205.091 412.933H197.387L193.645 406.053L197.387 399.285H205.091L208.943 406.053L205.091 412.933Z" fill="#CAE5E6" />
    <path d="M205.091 429.851H197.387L193.645 423.083L197.387 416.315H205.091L208.943 423.083L205.091 429.851Z" fill="#CAE5E6" />
    <path d="M205.091 446.883H197.387L193.645 440.116L197.387 433.235H205.091L208.943 440.116L205.091 446.883Z" fill="#CAE5E6" />
    <path d="M205.091 463.914H197.387L193.645 457.034L197.387 450.267H205.091L208.943 457.034L205.091 463.914Z" fill="#CAE5E6" />
    <path d="M205.091 480.832H197.387L193.645 474.064L197.387 467.297H205.091L208.943 474.064L205.091 480.832Z" fill="#CAE5E6" />
    <path d="M190.781 506.325H183.077L179.225 499.558L183.077 492.79H190.781L194.523 499.558L190.781 506.325Z" fill="#CAE5E6" />
    <path d="M205.091 514.895H197.387L193.645 508.128L197.387 501.248H205.091L208.943 508.128L205.091 514.895Z" fill="#CAE5E6" />
    <path d="M219.507 523.355H211.803L207.951 516.588L211.803 509.82H219.507L223.249 516.588L219.507 523.355Z" fill="#CAE5E6" />
    <path d="M233.815 531.928H226.221L222.369 525.047L226.221 518.28H233.815L237.667 525.047L233.815 531.928Z" fill="#CAE5E6" />
    <path d="M248.234 540.388H240.53L236.678 533.621L240.53 526.74H248.234L251.976 533.621L248.234 540.388Z" fill="#CAE5E6" />
    <path d="M262.539 548.846H254.945L251.093 542.079L254.945 535.311H262.539L266.391 542.079L262.539 548.846Z" fill="#CAE5E6" />
    <path d="M274.867 553.696H271.345L269.584 550.538L271.345 547.38H274.867L276.628 550.538L274.867 553.696Z" fill="#CAE5E6" />
    <path d="M291.265 548.846H283.671L279.819 542.079L283.671 535.311H291.265L295.118 542.079L291.265 548.846Z" fill="#CAE5E6" />
    <path d="M305.683 540.388H297.979L294.127 533.621L297.979 526.74H305.683L309.535 533.621L305.683 540.388Z" fill="#CAE5E6" />
    <path d="M319.99 531.928H312.396L308.544 525.047L312.396 518.28H319.99L323.842 525.047L319.99 531.928Z" fill="#CAE5E6" />
    <path d="M334.409 523.355H326.705L322.853 516.588L326.705 509.82H334.409L338.261 516.588L334.409 523.355Z" fill="#CAE5E6" />
    <path d="M348.719 514.895H341.125L337.272 508.128L341.125 501.248H348.719L352.571 508.128L348.719 514.895Z" fill="#CAE5E6" />
    <path d="M363.135 506.325H355.431L351.579 499.558L355.431 492.79H363.135L366.987 499.558L363.135 506.325Z" fill="#CAE5E6" />
    <path d="M363.135 489.407H355.431L351.579 482.526L355.431 475.759H363.135L366.987 482.526L363.135 489.407Z" fill="#CAE5E6" />
    <path d="M363.135 472.374H355.431L351.579 465.606L355.431 458.726H363.135L366.987 465.606L363.135 472.374Z" fill="#CAE5E6" />
    <path d="M363.135 455.344H355.431L351.579 448.577L355.431 441.809H363.135L366.987 448.577L363.135 455.344Z" fill="#CAE5E6" />
    <path d="M363.135 438.426H355.431L351.579 431.545L355.431 424.778H363.135L366.987 431.545L363.135 438.426Z" fill="#CAE5E6" />
    <path d="M363.135 421.391H355.431L351.579 414.623L355.431 407.743H363.135L366.987 414.623L363.135 421.391Z" fill="#CAE5E6" />
    <path d="M362.914 404.023H355.65L352.019 397.594L355.65 391.052H362.914L366.546 397.594L362.914 404.023Z" fill="#CAE5E6" />
    <path d="M346.626 392.179H343.214L341.453 389.133L343.214 385.975H346.626L348.387 389.133L346.626 392.179Z" fill="#CAE5E6" />
    <path d="M302.824 365.447H300.843L299.742 363.643L300.843 361.725H302.824L303.814 363.643L302.824 365.447Z" fill="#CAE5E6" />
    <path d="M275.199 350.332H271.017L268.926 346.609L271.017 342.887H275.199L277.29 346.609L275.199 350.332Z" fill="#CAE5E6" />
    <path d="M233.153 377.631H226.879L223.798 372.104L226.879 366.578H233.153L236.234 372.104L233.153 377.631Z" fill="#CAE5E6" />
    <path d="M219.397 387.328H211.803L208.061 380.56L211.803 373.905H219.397L223.249 380.56L219.397 387.328Z" fill="#CAE5E6" />
    <path d="M205.091 395.9H197.387L193.645 389.132L197.387 382.252H205.091L208.943 389.132L205.091 395.9Z" fill="#CAE5E6" />
    <path d="M189.24 401.654H184.618L182.307 397.594L184.618 393.533H189.24L191.441 397.594L189.24 401.654Z" fill="#CAE5E6" />
    <path d="M190.668 421.168H183.184L179.442 414.626L183.184 407.972H190.668L194.3 414.626L190.668 421.168Z" fill="#CAE5E6" />
    <path d="M190.781 438.426H183.077L179.225 431.545L183.077 424.778H190.781L194.523 431.545L190.781 438.426Z" fill="#CAE5E6" />
    <path d="M190.781 455.344H183.077L179.225 448.577L183.077 441.809H190.781L194.523 448.577L190.781 455.344Z" fill="#CAE5E6" />
    <path d="M190.781 472.374H183.077L179.225 465.606L183.077 458.726H190.781L194.523 465.606L190.781 472.374Z" fill="#CAE5E6" />
    <path d="M190.781 489.407H183.077L179.225 482.526L183.077 475.759H190.781L194.523 482.526L190.781 489.407Z" fill="#CAE5E6" />
    <path d="M176.367 514.895H168.663L164.921 508.128L168.663 501.248H176.367L180.219 508.128L176.367 514.895Z" fill="#CAE5E6" />
    <path d="M190.781 523.355H183.077L179.225 516.588L183.077 509.82H190.781L194.523 516.588L190.781 523.355Z" fill="#CAE5E6" />
    <path d="M205.091 531.928H197.387L193.645 525.047L197.387 518.28H205.091L208.943 525.047L205.091 531.928Z" fill="#CAE5E6" />
    <path d="M219.507 540.388H211.803L207.951 533.621L211.803 526.74H219.507L223.249 533.621L219.507 540.388Z" fill="#CAE5E6" />
    <path d="M233.375 548.058H226.552L223.25 542.08L226.552 536.102H233.375L236.787 542.08L233.375 548.058Z" fill="#CAE5E6" />
    <path d="M245.482 552.569H243.281L242.181 550.539L243.281 548.621H245.482L246.583 550.539L245.482 552.569Z" fill="#CAE5E6" />
    <path d="M274.534 570.053H271.672L270.241 567.572L271.672 565.09H274.534L275.964 567.572L274.534 570.053Z" fill="#CAE5E6" />
    <path d="M291.265 565.879H283.671L279.819 559.111L283.671 552.231H291.265L295.118 559.111L291.265 565.879Z" fill="#CAE5E6" />
    <path d="M305.683 557.419H297.979L294.127 550.539L297.979 543.771H305.683L309.535 550.539L305.683 557.419Z" fill="#CAE5E6" />
    <path d="M319.99 548.846H312.396L308.544 542.079L312.396 535.311H319.99L323.842 542.079L319.99 548.846Z" fill="#CAE5E6" />
    <path d="M334.409 540.388H326.705L322.853 533.621L326.705 526.74H334.409L338.261 533.621L334.409 540.388Z" fill="#CAE5E6" />
    <path d="M348.719 531.928H341.125L337.272 525.047L341.125 518.28H348.719L352.571 525.047L348.719 531.928Z" fill="#CAE5E6" />
    <path d="M363.135 523.355H355.431L351.579 516.588L355.431 509.82H363.135L366.987 516.588L363.135 523.355Z" fill="#CAE5E6" />
    <path d="M377.438 514.895H369.844L365.992 508.128L369.844 501.248H377.438L381.29 508.128L377.438 514.895Z" fill="#CAE5E6" />
    <path d="M377.44 497.865H369.846L365.994 491.098L369.846 484.33H377.44L381.292 491.098L377.44 497.865Z" fill="#CAE5E6" />
    <path d="M377.438 480.832H369.844L365.992 474.064L369.844 467.297H377.438L381.29 474.064L377.438 480.832Z" fill="#CAE5E6" />
    <path d="M377.44 463.914H369.846L365.994 457.034L369.846 450.267H377.44L381.292 457.034L377.44 463.914Z" fill="#CAE5E6" />
    <path d="M377.44 446.883H369.846L365.994 440.116L369.846 433.235H377.44L381.292 440.116L377.44 446.883Z" fill="#CAE5E6" />
    <path d="M377.44 429.851H369.846L365.994 423.083L369.846 416.315H377.44L381.292 423.083L377.44 429.851Z" fill="#CAE5E6" />
    <path d="M377.44 412.933H369.846L365.994 406.053L369.846 399.285H377.44L381.292 406.053L377.44 412.933Z" fill="#CAE5E6" />
    <path d="M347.284 376.388H342.551L340.13 372.102L342.551 367.816H347.284L349.705 372.102L347.284 376.388Z" fill="#CAE5E6" />
    <path d="M332.869 367.814H328.246L325.825 363.641L328.246 359.354H332.869L335.29 363.641L332.869 367.814Z" fill="#CAE5E6" />
    <path d="M303.041 348.641H300.62L299.52 346.611L300.62 344.468H303.041L304.142 346.611L303.041 348.641Z" fill="#CAE5E6" />
    <path d="M288.516 340.069H286.314L285.214 338.039L286.314 336.122H288.516L289.726 338.039L288.516 340.069Z" fill="#CAE5E6" />
    <path d="M275.855 334.429H270.352L267.601 329.579L270.352 324.729H275.855L278.606 329.579L275.855 334.429Z" fill="#CAE5E6" />
    <path d="M233.596 361.5H226.442L222.811 355.071L226.442 348.755H233.596L237.228 355.071L233.596 361.5Z" fill="#CAE5E6" />
    <path d="M218.517 368.717H212.684L209.822 363.642L212.684 358.453H218.517L221.488 363.642L218.517 368.717Z" fill="#CAE5E6" />
    <path d="M202.008 373.456H200.467L199.807 372.103L200.467 370.749H202.008L202.778 372.103L202.008 373.456Z" fill="#CAE5E6" />
    <path d="M174.713 426.918H170.421L168.22 423.083L170.421 419.248H174.713L176.804 423.083L174.713 426.918Z" fill="#CAE5E6" />
    <path d="M176.367 446.883H168.663L164.921 440.116L168.663 433.235H176.367L180.219 440.116L176.367 446.883Z" fill="#CAE5E6" />
    <path d="M176.367 463.914H168.663L164.921 457.034L168.663 450.267H176.367L180.219 457.034L176.367 463.914Z" fill="#CAE5E6" />
    <path d="M176.367 480.834H168.663L164.921 474.066L168.663 467.299H176.367L180.219 474.066L176.367 480.834Z" fill="#CAE5E6" />
    <path d="M176.367 497.865H168.663L164.921 491.098L168.663 484.33H176.367L180.219 491.098L176.367 497.865Z" fill="#CAE5E6" />
    <path d="M158.973 517.941H157.432L156.662 516.588L157.432 515.234H158.973L159.634 516.588L158.973 517.941Z" fill="#CAE5E6" />
    <path d="M176.367 531.816H168.773L165.031 525.048L168.773 518.394H176.367L180.109 525.048L176.367 531.816Z" fill="#CAE5E6" />
    <path d="M190.781 540.388H183.077L179.225 533.621L183.077 526.74H190.781L194.523 533.621L190.781 540.388Z" fill="#CAE5E6" />
    <path d="M205.091 548.846H197.387L193.645 542.079L197.387 535.311H205.091L208.943 542.079L205.091 548.846Z" fill="#CAE5E6" />
    <path d="M218.3 555.277H213.017L210.266 550.54L213.017 545.802H218.3L220.941 550.54L218.3 555.277Z" fill="#CAE5E6" />
    <path d="M276.298 590.242H269.914L266.723 584.602L269.914 578.962H276.298L279.489 584.602L276.298 590.242Z" fill="#CAE5E6" />
    <path d="M291.265 582.909H283.671L279.819 576.029L283.671 569.261H291.265L295.118 576.029L291.265 582.909Z" fill="#CAE5E6" />
    <path d="M305.683 574.339H297.979L294.127 567.571L297.979 560.804H305.683L309.535 567.571L305.683 574.339Z" fill="#CAE5E6" />
    <path d="M319.99 565.879H312.396L308.544 559.111L312.396 552.231H319.99L323.842 559.111L319.99 565.879Z" fill="#CAE5E6" />
    <path d="M334.409 557.421H326.705L322.853 550.541L326.705 543.773H334.409L338.261 550.541L334.409 557.421Z" fill="#CAE5E6" />
    <path d="M348.719 548.846H341.125L337.272 542.079L341.125 535.311H348.719L352.571 542.079L348.719 548.846Z" fill="#CAE5E6" />
    <path d="M363.135 540.388H355.431L351.579 533.621L355.431 526.74H363.135L366.987 533.621L363.135 540.388Z" fill="#CAE5E6" />
    <path d="M377.44 531.928H369.846L365.994 525.047L369.846 518.28H377.44L381.292 525.047L377.44 531.928Z" fill="#CAE5E6" />
    <path d="M391.862 523.355H384.158L380.306 516.588L384.158 509.82H391.862L395.714 516.588L391.862 523.355Z" fill="#CAE5E6" />
    <path d="M391.862 506.325H384.158L380.306 499.558L384.158 492.79H391.862L395.714 499.558L391.862 506.325Z" fill="#CAE5E6" />
    <path d="M391.862 489.407H384.158L380.306 482.526L384.158 475.759H391.862L395.714 482.526L391.862 489.407Z" fill="#CAE5E6" />
    <path d="M391.862 472.374H384.158L380.306 465.606L384.158 458.726H391.862L395.714 465.606L391.862 472.374Z" fill="#CAE5E6" />
    <path d="M391.862 455.344H384.158L380.306 448.577L384.158 441.809H391.862L395.714 448.577L391.862 455.344Z" fill="#CAE5E6" />
    <path d="M391.862 438.424H384.158L380.306 431.543L384.158 424.776H391.862L395.714 431.543L391.862 438.424Z" fill="#CAE5E6" />
    <path d="M391.862 421.391H384.158L380.306 414.623L384.158 407.743H391.862L395.714 414.623L391.862 421.391Z" fill="#CAE5E6" />
    <path d="M390.648 402.329H385.365L382.613 397.591L385.365 392.854H390.648L393.399 397.591L390.648 402.329Z" fill="#CAE5E6" />
    <path d="M388.666 381.691H387.345L386.795 380.563L387.345 379.436H388.666L389.216 380.563L388.666 381.691Z" fill="#CAE5E6" />
    <path d="M375.68 375.599H371.608L369.627 372.102L371.608 368.493H375.68L377.661 372.102L375.68 375.599Z" fill="#CAE5E6" />
    <path d="M362.914 370.07H355.65L352.019 363.641L355.65 357.099H362.914L366.546 363.641L362.914 370.07Z" fill="#CAE5E6" />
    <path d="M346.294 357.551H343.542L342.111 355.07L343.542 352.588H346.294L347.724 355.07L346.294 357.551Z" fill="#CAE5E6" />
    <path d="M334.295 353.154H326.811L323.179 346.612L326.811 339.957H334.295L337.927 346.612L334.295 353.154Z" fill="#CAE5E6" />
    <path d="M318.454 342.101H313.941L311.63 338.041L313.941 334.093H318.454L320.655 338.041L318.454 342.101Z" fill="#CAE5E6" />
    <path d="M303.702 332.85H299.96L298.089 329.579L299.96 326.308H303.702L305.463 329.579L303.702 332.85Z" fill="#CAE5E6" />
    <path d="M291.265 327.888H283.671L279.819 321.121L283.671 314.24H291.265L295.118 321.121L291.265 327.888Z" fill="#CAE5E6" />
    <path d="M276.96 319.431H269.256L265.404 312.55L269.256 305.783H276.96L280.702 312.55L276.96 319.431Z" fill="#CAE5E6" />
    <path d="M261.881 326.646H255.608L252.526 321.119L255.608 315.592H261.881L264.963 321.119L261.881 326.646Z" fill="#CAE5E6" />
    <path d="M247.242 334.656H241.519L238.657 329.58L241.519 324.504H247.242L249.993 329.58L247.242 334.656Z" fill="#CAE5E6" />
    <path d="M233.815 344.919H226.221L222.369 338.039L226.221 331.271H233.815L237.667 338.039L233.815 344.919Z" fill="#CAE5E6" />
    <path d="M219.507 353.379H211.803L207.951 346.611L211.803 339.844H219.507L223.249 346.611L219.507 353.379Z" fill="#CAE5E6" />
    <path d="M205.091 361.836H197.387L193.645 355.069L197.387 348.301H205.091L208.943 355.069L205.091 361.836Z" fill="#CAE5E6" />
    <path d="M159.746 451.395H156.554L155.014 448.576L156.554 445.756H159.746L161.397 448.576L159.746 451.395Z" fill="#CAE5E6" />
    <path d="M161.616 471.81H154.682L151.16 465.607L154.682 459.403H161.616L165.138 465.607L161.616 471.81Z" fill="#CAE5E6" />
    <path d="M161.616 488.728H154.682L151.16 482.524L154.682 476.434H161.616L165.138 482.524L161.616 488.728Z" fill="#CAE5E6" />
    <path d="M161.616 505.761H154.682L151.16 499.557L154.682 493.354H161.616L165.138 499.557L161.616 505.761Z" fill="#CAE5E6" />
    <path d="M173.171 543.208H171.96L171.3 542.08L171.96 540.952H173.171L173.831 542.08L173.171 543.208Z" fill="#CAE5E6" />
    <path d="M188.253 552.908H185.612L184.291 550.54L185.612 548.171H188.253L189.574 550.54L188.253 552.908Z" fill="#CAE5E6" />
    <path d="M291.265 599.83H283.671L279.819 593.062L283.671 586.294H291.265L295.118 593.062L291.265 599.83Z" fill="#CAE5E6" />
    <path d="M305.683 591.371H297.979L294.127 584.604L297.979 577.724H305.683L309.535 584.604L305.683 591.371Z" fill="#CAE5E6" />
    <path d="M319.99 582.909H312.396L308.544 576.029L312.396 569.261H319.99L323.842 576.029L319.99 582.909Z" fill="#CAE5E6" />
    <path d="M334.409 574.339H326.705L322.853 567.571L326.705 560.804H334.409L338.261 567.571L334.409 574.339Z" fill="#CAE5E6" />
    <path d="M348.719 565.879H341.125L337.272 559.111L341.125 552.231H348.719L352.571 559.111L348.719 565.879Z" fill="#CAE5E6" />
    <path d="M363.135 557.421H355.431L351.579 550.541L355.431 543.773H363.135L366.987 550.541L363.135 557.421Z" fill="#CAE5E6" />
    <path d="M377.44 548.846H369.846L365.994 542.079L369.846 535.311H377.44L381.292 542.079L377.44 548.846Z" fill="#CAE5E6" />
    <path d="M391.862 540.388H384.158L380.306 533.621L384.158 526.74H391.862L395.714 533.621L391.862 540.388Z" fill="#CAE5E6" />
    <path d="M406.165 531.928H398.571L394.719 525.047L398.571 518.28H406.165L410.017 525.047L406.165 531.928Z" fill="#CAE5E6" />
    <path d="M406.165 514.895H398.571L394.719 508.128L398.571 501.248H406.165L410.017 508.128L406.165 514.895Z" fill="#CAE5E6" />
    <path d="M406.165 497.865H398.571L394.719 491.098L398.571 484.33H406.165L410.017 491.098L406.165 497.865Z" fill="#CAE5E6" />
    <path d="M406.165 480.834H398.571L394.719 474.066L398.571 467.299H406.165L410.017 474.066L406.165 480.834Z" fill="#CAE5E6" />
    <path d="M406.165 463.689H398.571L394.829 457.035L398.571 450.38H406.165L409.907 457.035L406.165 463.689Z" fill="#CAE5E6" />
    <path d="M403.636 442.258H401.105L399.895 440.115L401.105 437.859H403.636L404.847 440.115L403.636 442.258Z" fill="#CAE5E6" />
    <path d="M406.165 429.851H398.571L394.719 423.083L398.571 416.315H406.165L410.017 423.083L406.165 429.851Z" fill="#CAE5E6" />
    <path d="M406.165 412.933H398.571L394.719 406.053L398.571 399.285H406.165L410.017 406.053L406.165 412.933Z" fill="#CAE5E6" />
    <path d="M406.165 395.9H398.571L394.719 389.132L398.571 382.252H406.165L410.017 389.132L406.165 395.9Z" fill="#CAE5E6" />
    <path d="M406.165 378.87H398.571L394.719 372.102L398.571 365.334H406.165L410.017 372.102L406.165 378.87Z" fill="#CAE5E6" />
    <path d="M391.748 370.299H384.264L380.522 363.644L384.264 356.877H391.748L395.49 363.644L391.748 370.299Z" fill="#CAE5E6" />
    <path d="M377.438 361.836H369.844L365.992 355.069L369.844 348.301H377.438L381.29 355.069L377.438 361.836Z" fill="#CAE5E6" />
    <path d="M363.135 353.379H355.431L351.579 346.611L355.431 339.844H363.135L366.987 346.611L363.135 353.379Z" fill="#CAE5E6" />
    <path d="M348.608 344.694H341.234L337.492 338.04L341.234 331.498H348.608L352.35 338.04L348.608 344.694Z" fill="#CAE5E6" />
    <path d="M334.409 336.349H326.705L322.853 329.581L326.705 322.813H334.409L338.261 329.581L334.409 336.349Z" fill="#CAE5E6" />
    <path d="M319.99 327.888H312.396L308.544 321.121L312.396 314.24H319.99L323.842 321.121L319.99 327.888Z" fill="#CAE5E6" />
    <path d="M305.463 319.088H298.089L294.457 312.546L298.089 306.004H305.463L309.205 312.546L305.463 319.088Z" fill="#CAE5E6" />
    <path d="M291.265 310.855H283.671L279.819 304.088L283.671 297.32H291.265L295.118 304.088L291.265 310.855Z" fill="#CAE5E6" />
    <path d="M276.96 302.395H269.256L265.404 295.628L269.256 288.748H276.96L280.702 295.628L276.96 302.395Z" fill="#CAE5E6" />
    <path d="M262.539 310.855H254.945L251.093 304.088L254.945 297.32H262.539L266.391 304.088L262.539 310.855Z" fill="#CAE5E6" />
    <path d="M248.234 319.431H240.53L236.678 312.55L240.53 305.783H248.234L251.976 312.55L248.234 319.431Z" fill="#CAE5E6" />
    <path d="M233.485 327.324H226.441L222.919 321.12L226.441 314.804H233.485L237.006 321.12L233.485 327.324Z" fill="#CAE5E6" />
    <path d="M219.507 336.349H211.803L207.951 329.581L211.803 322.813H219.507L223.249 329.581L219.507 336.349Z" fill="#CAE5E6" />
    <path d="M205.091 344.919H197.387L193.645 338.039L197.387 331.271H205.091L208.943 338.039L205.091 344.919Z" fill="#CAE5E6" />
    <path d="M290.934 616.296H284L280.479 610.093L284 603.889H290.934L294.456 610.093L290.934 616.296Z" fill="#CAE5E6" />
    <path d="M305.683 608.402H297.979L294.127 601.522L297.979 594.754H305.683L309.535 601.522L305.683 608.402Z" fill="#CAE5E6" />
    <path d="M319.99 599.83H312.396L308.544 593.062L312.396 586.294H319.99L323.842 593.062L319.99 599.83Z" fill="#CAE5E6" />
    <path d="M334.409 591.369H326.705L322.853 584.601L326.705 577.721H334.409L338.261 584.601L334.409 591.369Z" fill="#CAE5E6" />
    <path d="M348.719 582.909H341.125L337.272 576.029L341.125 569.261H348.719L352.571 576.029L348.719 582.909Z" fill="#CAE5E6" />
    <path d="M363.135 574.339H355.431L351.579 567.571L355.431 560.804H363.135L366.987 567.571L363.135 574.339Z" fill="#CAE5E6" />
    <path d="M377.44 565.879H369.846L365.994 559.111L369.846 552.231H377.44L381.292 559.111L377.44 565.879Z" fill="#CAE5E6" />
    <path d="M391.862 557.419H384.158L380.306 550.539L384.158 543.771H391.862L395.714 550.539L391.862 557.419Z" fill="#CAE5E6" />
    <path d="M406.165 548.846H398.571L394.719 542.079L398.571 535.311H406.165L410.017 542.079L406.165 548.846Z" fill="#CAE5E6" />
    <path d="M420.583 540.388H412.879L409.027 533.621L412.879 526.74H420.583L424.436 533.621L420.583 540.388Z" fill="#CAE5E6" />
    <path d="M420.583 523.355H412.879L409.027 516.588L412.879 509.82H420.583L424.436 516.588L420.583 523.355Z" fill="#CAE5E6" />
    <path d="M420.583 506.325H412.879L409.027 499.558L412.879 492.79H420.583L424.436 499.558L420.583 506.325Z" fill="#CAE5E6" />
    <path d="M419.374 487.263H414.092L411.45 482.526L414.092 477.901H419.374L422.016 482.526L419.374 487.263Z" fill="#CAE5E6" />
    <path d="M417.501 466.85H415.96L415.3 465.609L415.96 464.256H417.501L418.161 465.609L417.501 466.85Z" fill="#CAE5E6" />
    <path d="M420.585 455.344H412.881L409.139 448.577L412.881 441.809H420.585L424.327 448.577L420.585 455.344Z" fill="#CAE5E6" />
    <path d="M420.583 438.426H412.879L409.027 431.545L412.879 424.778H420.583L424.436 431.545L420.583 438.426Z" fill="#CAE5E6" />
    <path d="M420.583 421.391H412.879L409.027 414.623L412.879 407.743H420.583L424.436 414.623L420.583 421.391Z" fill="#CAE5E6" />
    <path d="M420.583 404.36H412.879L409.027 397.593L412.879 390.825H420.583L424.436 397.593L420.583 404.36Z" fill="#CAE5E6" />
    <path d="M420.583 387.327H412.879L409.027 380.56L412.879 373.792H420.583L424.436 380.56L420.583 387.327Z" fill="#CAE5E6" />
    <path d="M420.583 370.41H412.879L409.027 363.642L412.879 356.762H420.583L424.436 363.642L420.583 370.41Z" fill="#CAE5E6" />
    <path d="M406.165 361.839H398.571L394.719 355.072L398.571 348.304H406.165L410.017 355.072L406.165 361.839Z" fill="#CAE5E6" />
    <path d="M391.862 353.379H384.158L380.306 346.611L384.158 339.844H391.862L395.714 346.611L391.862 353.379Z" fill="#CAE5E6" />
    <path d="M377.44 344.919H369.846L365.994 338.039L369.846 331.271H377.44L381.292 338.039L377.44 344.919Z" fill="#CAE5E6" />
    <path d="M363.135 336.349H355.431L351.579 329.581L355.431 322.813H363.135L366.987 329.581L363.135 336.349Z" fill="#CAE5E6" />
    <path d="M348.719 327.888H341.125L337.272 321.121L341.125 314.24H348.719L352.571 321.121L348.719 327.888Z" fill="#CAE5E6" />
    <path d="M334.409 319.431H326.705L322.853 312.55L326.705 305.783H334.409L338.261 312.55L334.409 319.431Z" fill="#CAE5E6" />
    <path d="M319.99 310.855H312.396L308.544 304.088L312.396 297.32H319.99L323.842 304.088L319.99 310.855Z" fill="#CAE5E6" />
    <path d="M305.683 302.395H297.979L294.127 295.628L297.979 288.748H305.683L309.535 295.628L305.683 302.395Z" fill="#CAE5E6" />
    <path d="M291.265 293.938H283.671L279.819 287.058L283.671 280.29H291.265L295.118 287.058L291.265 293.938Z" fill="#CAE5E6" />
    <path d="M276.96 285.365H269.256L265.404 278.597L269.256 271.83H276.96L280.702 278.597L276.96 285.365Z" fill="#CAE5E6" />
    <path d="M262.539 293.938H254.945L251.093 287.058L254.945 280.29H262.539L266.391 287.058L262.539 293.938Z" fill="#CAE5E6" />
    <path d="M248.234 302.395H240.53L236.678 295.628L240.53 288.748H248.234L251.976 295.628L248.234 302.395Z" fill="#CAE5E6" />
    <path d="M233.705 310.629H226.331L222.589 304.087L226.331 297.545H233.705L237.337 304.087L233.705 310.629Z" fill="#CAE5E6" />
    <path d="M202.339 322.926H200.248L199.147 321.122L200.248 319.204H202.339L203.33 321.122L202.339 322.926Z" fill="#CAE5E6" />
    <path d="M130.139 483.766H128.819L128.158 482.525L128.819 481.397H130.139L130.8 482.525L130.139 483.766Z" fill="#CAE5E6" />
    <path d="M289.944 631.524H284.991L282.46 627.012L284.991 622.613H289.944L292.475 627.012L289.944 631.524Z" fill="#CAE5E6" />
    <path d="M305.683 625.32H297.979L294.127 618.552L297.979 611.785H305.683L309.535 618.552L305.683 625.32Z" fill="#CAE5E6" />
    <path d="M319.99 616.86H312.396L308.544 610.092L312.396 603.212H319.99L323.842 610.092L319.99 616.86Z" fill="#CAE5E6" />
    <path d="M334.409 608.402H326.705L322.853 601.522L326.705 594.754H334.409L338.261 601.522L334.409 608.402Z" fill="#CAE5E6" />
    <path d="M348.719 599.83H341.125L337.272 593.062L341.125 586.294H348.719L352.571 593.062L348.719 599.83Z" fill="#CAE5E6" />
    <path d="M363.135 591.369H355.431L351.579 584.601L355.431 577.721H363.135L366.987 584.601L363.135 591.369Z" fill="#CAE5E6" />
    <path d="M377.44 582.909H369.846L365.994 576.029L369.846 569.261H377.44L381.292 576.029L377.44 582.909Z" fill="#CAE5E6" />
    <path d="M391.862 574.339H384.158L380.306 567.571L384.158 560.804H391.862L395.714 567.571L391.862 574.339Z" fill="#CAE5E6" />
    <path d="M406.165 565.879H398.571L394.719 559.111L398.571 552.231H406.165L410.017 559.111L406.165 565.879Z" fill="#CAE5E6" />
    <path d="M420.583 557.421H412.879L409.027 550.541L412.879 543.773H420.583L424.436 550.541L420.583 557.421Z" fill="#CAE5E6" />
    <path d="M434.891 548.846H427.297L423.445 542.079L427.297 535.311H434.891L438.743 542.079L434.891 548.846Z" fill="#CAE5E6" />
    <path d="M434.891 531.928H427.297L423.445 525.047L427.297 518.28H434.891L438.743 525.047L434.891 531.928Z" fill="#CAE5E6" />
    <path d="M434.892 514.785H427.298L423.556 508.13L427.298 501.363H434.892L438.634 508.13L434.892 514.785Z" fill="#CAE5E6" />
    <path d="M432.36 493.354H429.828L428.508 491.098L429.828 488.729H432.36L433.681 491.098L432.36 493.354Z" fill="#CAE5E6" />
    <path d="M434.562 480.156H427.738L424.326 474.065L427.738 467.974H434.562L437.973 474.065L434.562 480.156Z" fill="#CAE5E6" />
    <path d="M434.891 463.914H427.297L423.445 457.034L427.297 450.267H434.891L438.743 457.034L434.891 463.914Z" fill="#CAE5E6" />
    <path d="M434.891 446.883H427.297L423.445 440.116L427.297 433.235H434.891L438.743 440.116L434.891 446.883Z" fill="#CAE5E6" />
    <path d="M434.891 429.851H427.297L423.445 423.083L427.297 416.315H434.891L438.743 423.083L434.891 429.851Z" fill="#CAE5E6" />
    <path d="M434.891 412.933H427.297L423.445 406.053L427.297 399.285H434.891L438.743 406.053L434.891 412.933Z" fill="#CAE5E6" />
    <path d="M434.891 395.9H427.297L423.445 389.132L427.297 382.252H434.891L438.743 389.132L434.891 395.9Z" fill="#CAE5E6" />
    <path d="M434.891 378.87H427.297L423.445 372.102L427.297 365.334H434.891L438.743 372.102L434.891 378.87Z" fill="#CAE5E6" />
    <path d="M434.891 361.839H427.297L423.445 355.072L427.297 348.304H434.891L438.743 355.072L434.891 361.839Z" fill="#CAE5E6" />
    <path d="M420.583 353.379H412.879L409.027 346.611L412.879 339.844H420.583L424.436 346.611L420.583 353.379Z" fill="#CAE5E6" />
    <path d="M406.165 344.919H398.571L394.719 338.039L398.571 331.271H406.165L410.017 338.039L406.165 344.919Z" fill="#CAE5E6" />
    <path d="M391.862 336.349H384.158L380.306 329.581L384.158 322.813H391.862L395.714 329.581L391.862 336.349Z" fill="#CAE5E6" />
    <path d="M377.44 327.888H369.846L365.994 321.121L369.846 314.24H377.44L381.292 321.121L377.44 327.888Z" fill="#CAE5E6" />
    <path d="M363.135 319.431H355.431L351.579 312.55L355.431 305.783H363.135L366.987 312.55L363.135 319.431Z" fill="#CAE5E6" />
    <path d="M348.719 310.855H341.125L337.272 304.088L341.125 297.32H348.719L352.571 304.088L348.719 310.855Z" fill="#CAE5E6" />
    <path d="M334.409 302.395H326.705L322.853 295.628L326.705 288.748H334.409L338.261 295.628L334.409 302.395Z" fill="#CAE5E6" />
    <path d="M319.99 293.938H312.396L308.544 287.058L312.396 280.29H319.99L323.842 287.058L319.99 293.938Z" fill="#CAE5E6" />
    <path d="M305.683 285.365H297.979L294.127 278.597L297.979 271.83H305.683L309.535 278.597L305.683 285.365Z" fill="#CAE5E6" />
    <path d="M291.265 276.905H283.671L279.819 270.137L283.671 263.257H291.265L295.118 270.137L291.265 276.905Z" fill="#CAE5E6" />
    <path d="M276.96 268.445H269.256L265.404 261.565L269.256 254.797H276.96L280.702 261.565L276.96 268.445Z" fill="#CAE5E6" />
    <path d="M262.539 276.905H254.945L251.093 270.137L254.945 263.257H262.539L266.391 270.137L262.539 276.905Z" fill="#CAE5E6" />
    <path d="M248.234 285.365H240.53L236.678 278.597L240.53 271.83H248.234L251.976 278.597L248.234 285.365Z" fill="#CAE5E6" />
    <path d="M233.815 293.826H226.221L222.369 287.058L226.221 280.403H233.815L237.557 287.058L233.815 293.826Z" fill="#CAE5E6" />
    <path d="M218.957 301.492H212.354L209.052 295.627L212.354 289.762H218.957L222.259 295.627L218.957 301.492Z" fill="#CAE5E6" />
    <path d="M305.683 642.351H297.979L294.127 635.583L297.979 628.816H305.683L309.535 635.583L305.683 642.351Z" fill="#CAE5E6" />
    <path d="M319.99 633.895H312.396L308.544 627.015L312.396 620.247H319.99L323.842 627.015L319.99 633.895Z" fill="#CAE5E6" />
    <path d="M334.409 625.32H326.705L322.853 618.552L326.705 611.785H334.409L338.261 618.552L334.409 625.32Z" fill="#CAE5E6" />
    <path d="M348.719 616.86H341.125L337.272 610.092L341.125 603.212H348.719L352.571 610.092L348.719 616.86Z" fill="#CAE5E6" />
    <path d="M363.135 608.402H355.431L351.579 601.522L355.431 594.754H363.135L366.987 601.522L363.135 608.402Z" fill="#CAE5E6" />
    <path d="M377.438 599.83H369.844L365.992 593.062L369.844 586.294H377.438L381.29 593.062L377.438 599.83Z" fill="#CAE5E6" />
    <path d="M391.862 591.371H384.158L380.306 584.604L384.158 577.724H391.862L395.714 584.604L391.862 591.371Z" fill="#CAE5E6" />
    <path d="M406.165 582.909H398.571L394.719 576.029L398.571 569.261H406.165L410.017 576.029L406.165 582.909Z" fill="#CAE5E6" />
    <path d="M420.583 574.339H412.879L409.027 567.571L412.879 560.804H420.583L424.436 567.571L420.583 574.339Z" fill="#CAE5E6" />
    <path d="M434.891 565.879H427.297L423.445 559.111L427.297 552.231H434.891L438.743 559.111L434.891 565.879Z" fill="#CAE5E6" />
    <path d="M449.31 557.421H441.606L437.754 550.541L441.606 543.773H449.31L453.162 550.541L449.31 557.421Z" fill="#CAE5E6" />
    <path d="M449.31 540.388H441.606L437.754 533.621L441.606 526.74H449.31L453.162 533.621L449.31 540.388Z" fill="#CAE5E6" />
    <path d="M449.308 523.355H441.604L437.862 516.588L441.604 509.82H449.308L453.05 516.588L449.308 523.355Z" fill="#CAE5E6" />
    <path d="M448.98 505.873H441.937L438.305 499.557L441.937 493.241H448.98L452.612 499.557L448.98 505.873Z" fill="#CAE5E6" />
    <path d="M449.31 489.407H441.606L437.754 482.526L441.606 475.759H449.31L453.162 482.526L449.31 489.407Z" fill="#CAE5E6" />
    <path d="M449.31 472.374H441.606L437.754 465.606L441.606 458.726H449.31L453.162 465.606L449.31 472.374Z" fill="#CAE5E6" />
    <path d="M449.31 455.344H441.606L437.754 448.577L441.606 441.809H449.31L453.162 448.577L449.31 455.344Z" fill="#CAE5E6" />
    <path d="M449.31 438.426H441.606L437.754 431.545L441.606 424.778H449.31L453.162 431.545L449.31 438.426Z" fill="#CAE5E6" />
    <path d="M449.31 421.391H441.606L437.754 414.623L441.606 407.743H449.31L453.162 414.623L449.31 421.391Z" fill="#CAE5E6" />
    <path d="M449.31 404.36H441.606L437.754 397.593L441.606 390.825H449.31L453.162 397.593L449.31 404.36Z" fill="#CAE5E6" />
    <path d="M449.31 387.327H441.606L437.754 380.56L441.606 373.792H449.31L453.162 380.56L449.31 387.327Z" fill="#CAE5E6" />
    <path d="M449.31 370.41H441.606L437.754 363.642L441.606 356.762H449.31L453.162 363.642L449.31 370.41Z" fill="#CAE5E6" />
    <path d="M449.31 353.379H441.606L437.754 346.611L441.606 339.844H449.31L453.162 346.611L449.31 353.379Z" fill="#CAE5E6" />
    <path d="M434.891 344.919H427.297L423.445 338.039L427.297 331.271H434.891L438.743 338.039L434.891 344.919Z" fill="#CAE5E6" />
    <path d="M420.583 336.349H412.879L409.027 329.581L412.879 322.813H420.583L424.436 329.581L420.583 336.349Z" fill="#CAE5E6" />
    <path d="M406.165 327.888H398.571L394.719 321.121L398.571 314.24H406.165L410.017 321.121L406.165 327.888Z" fill="#CAE5E6" />
    <path d="M391.862 319.428H384.158L380.306 312.548L384.158 305.78H391.862L395.714 312.548L391.862 319.428Z" fill="#CAE5E6" />
    <path d="M377.438 310.855H369.844L365.992 304.088L369.844 297.32H377.438L381.29 304.088L377.438 310.855Z" fill="#CAE5E6" />
    <path d="M363.135 302.395H355.431L351.579 295.628L355.431 288.748H363.135L366.987 295.628L363.135 302.395Z" fill="#CAE5E6" />
    <path d="M348.719 293.938H341.125L337.272 287.058L341.125 280.29H348.719L352.571 287.058L348.719 293.938Z" fill="#CAE5E6" />
    <path d="M334.409 285.365H326.705L322.853 278.597L326.705 271.83H334.409L338.261 278.597L334.409 285.365Z" fill="#CAE5E6" />
    <path d="M319.99 276.905H312.396L308.544 270.137L312.396 263.257H319.99L323.842 270.137L319.99 276.905Z" fill="#CAE5E6" />
    <path d="M305.683 268.445H297.979L294.127 261.565L297.979 254.797H305.683L309.535 261.565L305.683 268.445Z" fill="#CAE5E6" />
    <path d="M291.265 259.874H283.671L279.819 253.106L283.671 246.339H291.265L295.118 253.106L291.265 259.874Z" fill="#CAE5E6" />
    <path d="M276.628 250.85H269.584L265.952 244.646L269.584 238.33H276.628L280.15 244.646L276.628 250.85Z" fill="#CAE5E6" />
    <path d="M260.779 256.83H256.707L254.616 253.107L256.707 249.385H260.779L262.871 253.107L260.779 256.83Z" fill="#CAE5E6" />
    <path d="M244.931 262.696H243.72L243.17 261.568L243.72 260.553H244.931L245.591 261.568L244.931 262.696Z" fill="#CAE5E6" />
    <path d="M233.815 276.792H226.221L222.369 270.138L226.221 263.37H233.815L237.557 270.138L233.815 276.792Z" fill="#CAE5E6" />
    <path d="M216.425 279.951H214.884L214.004 278.597L214.884 277.131H216.425L217.196 278.597L216.425 279.951Z" fill="#CAE5E6" />
    <path d="M289.728 665.138H285.216L282.904 661.077L285.216 657.017H289.728L292.039 661.077L289.728 665.138Z" fill="#CAE5E6" />
    <path d="M305.683 659.383H297.979L294.127 652.616L297.979 645.735H305.683L309.535 652.616L305.683 659.383Z" fill="#CAE5E6" />
    <path d="M319.99 650.813H312.396L308.544 644.046L312.396 637.278H319.99L323.842 644.046L319.99 650.813Z" fill="#CAE5E6" />
    <path d="M334.409 642.351H326.705L322.853 635.583L326.705 628.816H334.409L338.261 635.583L334.409 642.351Z" fill="#CAE5E6" />
    <path d="M348.719 633.895H341.125L337.272 627.015L341.125 620.247H348.719L352.571 627.015L348.719 633.895Z" fill="#CAE5E6" />
    <path d="M363.135 625.32H355.431L351.579 618.552L355.431 611.785H363.135L366.987 618.552L363.135 625.32Z" fill="#CAE5E6" />
    <path d="M377.44 616.86H369.846L365.994 610.092L369.846 603.212H377.44L381.292 610.092L377.44 616.86Z" fill="#CAE5E6" />
    <path d="M391.862 608.402H384.158L380.306 601.522L384.158 594.754H391.862L395.714 601.522L391.862 608.402Z" fill="#CAE5E6" />
    <path d="M406.165 599.83H398.571L394.719 593.062L398.571 586.294H406.165L410.017 593.062L406.165 599.83Z" fill="#CAE5E6" />
    <path d="M420.583 591.369H412.879L409.027 584.601L412.879 577.721H420.583L424.436 584.601L420.583 591.369Z" fill="#CAE5E6" />
    <path d="M434.671 582.46H427.517L423.995 576.031L427.517 569.714H434.671L438.303 576.031L434.671 582.46Z" fill="#CAE5E6" />
    <path d="M447.549 571.406H443.367L441.166 567.571L443.367 563.736H447.549L449.751 567.571L447.549 571.406Z" fill="#CAE5E6" />
    <path d="M463.175 547.945H456.571L453.27 542.08L456.571 536.214H463.175L466.477 542.08L463.175 547.945Z" fill="#CAE5E6" />
    <path d="M463.62 531.928H456.026L452.174 525.047L456.026 518.28H463.62L467.472 525.047L463.62 531.928Z" fill="#CAE5E6" />
    <path d="M461.526 511.061H458.114L456.463 508.128L458.114 505.083H461.526L463.177 508.128L461.526 511.061Z" fill="#CAE5E6" />
    <path d="M463.618 497.865H456.024L452.282 491.098L456.024 484.33H463.618L467.47 491.098L463.618 497.865Z" fill="#CAE5E6" />
    <path d="M463.618 480.832H456.024L452.172 474.064L456.024 467.297H463.618L467.47 474.064L463.618 480.832Z" fill="#CAE5E6" />
    <path d="M463.62 463.914H456.026L452.174 457.034L456.026 450.267H463.62L467.472 457.034L463.62 463.914Z" fill="#CAE5E6" />
    <path d="M463.62 446.883H456.026L452.174 440.116L456.026 433.235H463.62L467.472 440.116L463.62 446.883Z" fill="#CAE5E6" />
    <path d="M463.62 429.851H456.026L452.174 423.083L456.026 416.315H463.62L467.472 423.083L463.62 429.851Z" fill="#CAE5E6" />
    <path d="M463.62 412.933H456.026L452.174 406.053L456.026 399.285H463.62L467.472 406.053L463.62 412.933Z" fill="#CAE5E6" />
    <path d="M463.618 395.9H456.024L452.172 389.132L456.024 382.252H463.618L467.47 389.132L463.618 395.9Z" fill="#CAE5E6" />
    <path d="M463.62 378.87H456.026L452.174 372.102L456.026 365.334H463.62L467.472 372.102L463.62 378.87Z" fill="#CAE5E6" />
    <path d="M463.618 361.836H456.024L452.172 355.069L456.024 348.301H463.618L467.47 355.069L463.618 361.836Z" fill="#CAE5E6" />
    <path d="M463.62 344.919H456.026L452.174 338.039L456.026 331.271H463.62L467.472 338.039L463.62 344.919Z" fill="#CAE5E6" />
    <path d="M449.31 336.349H441.606L437.754 329.581L441.606 322.813H449.31L453.162 329.581L449.31 336.349Z" fill="#CAE5E6" />
    <path d="M434.891 327.888H427.297L423.445 321.121L427.297 314.24H434.891L438.743 321.121L434.891 327.888Z" fill="#CAE5E6" />
    <path d="M420.583 319.431H412.879L409.027 312.55L412.879 305.783H420.583L424.436 312.55L420.583 319.431Z" fill="#CAE5E6" />
    <path d="M406.165 310.855H398.571L394.719 304.088L398.571 297.32H406.165L410.017 304.088L406.165 310.855Z" fill="#CAE5E6" />
    <path d="M391.862 302.395H384.158L380.306 295.628L384.158 288.748H391.862L395.714 295.628L391.862 302.395Z" fill="#CAE5E6" />
    <path d="M377.44 293.938H369.846L365.994 287.058L369.846 280.29H377.44L381.292 287.058L377.44 293.938Z" fill="#CAE5E6" />
    <path d="M363.135 285.365H355.431L351.579 278.597L355.431 271.83H363.135L366.987 278.597L363.135 285.365Z" fill="#CAE5E6" />
    <path d="M348.719 276.905H341.125L337.272 270.137L341.125 263.257H348.719L352.571 270.137L348.719 276.905Z" fill="#CAE5E6" />
    <path d="M334.409 268.445H326.705L322.853 261.565L326.705 254.797H334.409L338.261 261.565L334.409 268.445Z" fill="#CAE5E6" />
    <path d="M319.99 259.874H312.396L308.544 253.106L312.396 246.339H319.99L323.842 253.106L319.99 259.874Z" fill="#CAE5E6" />
    <path d="M304.362 249.155H299.3L296.658 244.644L299.3 240.019H304.362L307.004 244.644L304.362 249.155Z" fill="#CAE5E6" />
    <path d="M289.395 239.571H285.433L283.452 236.075L285.433 232.578H289.395L291.376 236.075L289.395 239.571Z" fill="#CAE5E6" />
    <path d="M275.526 231.788H270.683L268.262 227.614L270.683 223.328H275.526L277.837 227.614L275.526 231.788Z" fill="#CAE5E6" />
    <path d="M233.815 259.874H226.221L222.369 253.106L226.221 246.339H233.815L237.667 253.106L233.815 259.874Z" fill="#CAE5E6" />
    <path d="M219.507 268.445H211.803L207.951 261.565L211.803 254.797H219.507L223.249 261.565L219.507 268.445Z" fill="#CAE5E6" />
    <path d="M290.935 684.198H284.002L280.59 678.107L284.002 672.016H290.935L294.347 678.107L290.935 684.198Z" fill="#CAE5E6" />
    <path d="M305.683 676.414H297.979L294.127 669.534L297.979 662.766H305.683L309.535 669.534L305.683 676.414Z" fill="#CAE5E6" />
    <path d="M319.99 667.844H312.396L308.544 661.076L312.396 654.309H319.99L323.842 661.076L319.99 667.844Z" fill="#CAE5E6" />
    <path d="M334.409 659.383H326.705L322.853 652.616L326.705 645.735H334.409L338.261 652.616L334.409 659.383Z" fill="#CAE5E6" />
    <path d="M348.719 650.813H341.125L337.272 644.046L341.125 637.278H348.719L352.571 644.046L348.719 650.813Z" fill="#CAE5E6" />
    <path d="M363.135 642.351H355.431L351.579 635.583L355.431 628.816H363.135L366.987 635.583L363.135 642.351Z" fill="#CAE5E6" />
    <path d="M377.438 633.893H369.844L365.992 627.013L369.844 620.245H377.438L381.29 627.013L377.438 633.893Z" fill="#CAE5E6" />
    <path d="M391.862 625.32H384.158L380.306 618.552L384.158 611.785H391.862L395.714 618.552L391.862 625.32Z" fill="#CAE5E6" />
    <path d="M406.165 616.86H398.571L394.719 610.092L398.571 603.212H406.165L410.017 610.092L406.165 616.86Z" fill="#CAE5E6" />
    <path d="M420.475 608.29H412.991L409.249 601.522L412.991 594.868H420.475L424.327 601.522L420.475 608.29Z" fill="#CAE5E6" />
    <path d="M476.055 519.861H472.313L470.552 516.59L472.313 513.319H476.055L477.926 516.59L476.055 519.861Z" fill="#CAE5E6" />
    <path d="M478.035 489.407H470.331L466.589 482.526L470.331 475.759H478.035L481.887 482.526L478.035 489.407Z" fill="#CAE5E6" />
    <path d="M478.035 472.374H470.331L466.589 465.606L470.331 458.726H478.035L481.887 465.606L478.035 472.374Z" fill="#CAE5E6" />
    <path d="M478.035 455.344H470.331L466.589 448.577L470.331 441.809H478.035L481.887 448.577L478.035 455.344Z" fill="#CAE5E6" />
    <path d="M478.035 438.311H470.331L466.589 431.543L470.331 424.776H478.035L481.777 431.543L478.035 438.311Z" fill="#CAE5E6" />
    <path d="M475.284 416.428H473.192L472.092 414.624L473.192 412.706H475.284L476.274 414.624L475.284 416.428Z" fill="#CAE5E6" />
    <path d="M478.035 404.36H470.331L466.589 397.593L470.331 390.825H478.035L481.887 397.593L478.035 404.36Z" fill="#CAE5E6" />
    <path d="M478.035 387.327H470.331L466.589 380.56L470.331 373.792H478.035L481.887 380.56L478.035 387.327Z" fill="#CAE5E6" />
    <path d="M478.035 370.41H470.331L466.589 363.642L470.331 356.762H478.035L481.887 363.642L478.035 370.41Z" fill="#CAE5E6" />
    <path d="M478.035 353.379H470.331L466.589 346.611L470.331 339.844H478.035L481.887 346.611L478.035 353.379Z" fill="#CAE5E6" />
    <path d="M478.035 336.349H470.331L466.589 329.581L470.331 322.813H478.035L481.887 329.581L478.035 336.349Z" fill="#CAE5E6" />
    <path d="M463.62 327.888H456.026L452.174 321.121L456.026 314.24H463.62L467.472 321.121L463.62 327.888Z" fill="#CAE5E6" />
    <path d="M449.31 319.431H441.606L437.754 312.55L441.606 305.783H449.31L453.162 312.55L449.31 319.431Z" fill="#CAE5E6" />
    <path d="M434.891 310.855H427.297L423.445 304.088L427.297 297.32H434.891L438.743 304.088L434.891 310.855Z" fill="#CAE5E6" />
    <path d="M420.583 302.395H412.879L409.027 295.628L412.879 288.748H420.583L424.436 295.628L420.583 302.395Z" fill="#CAE5E6" />
    <path d="M406.165 293.938H398.571L394.719 287.058L398.571 280.29H406.165L410.017 287.058L406.165 293.938Z" fill="#CAE5E6" />
    <path d="M391.862 285.365H384.158L380.306 278.597L384.158 271.83H391.862L395.714 278.597L391.862 285.365Z" fill="#CAE5E6" />
    <path d="M377.438 276.905H369.844L365.992 270.137L369.844 263.257H377.438L381.29 270.137L377.438 276.905Z" fill="#CAE5E6" />
    <path d="M363.135 268.445H355.431L351.579 261.565L355.431 254.797H363.135L366.987 261.565L363.135 268.445Z" fill="#CAE5E6" />
    <path d="M348.719 259.874H341.125L337.272 253.106L341.125 246.339H348.719L352.571 253.106L348.719 259.874Z" fill="#CAE5E6" />
    <path d="M334.409 251.414H326.705L322.853 244.646L326.705 237.766H334.409L338.261 244.646L334.409 251.414Z" fill="#CAE5E6" />
    <path d="M305.683 234.382H297.979L294.237 227.614L297.979 220.847H305.683L309.425 227.614L305.683 234.382Z" fill="#CAE5E6" />
    <path d="M291.267 225.811H283.673L279.931 219.044L283.673 212.389H291.267L295.009 219.044L291.267 225.811Z" fill="#CAE5E6" />
    <path d="M276.96 217.351H269.256L265.404 210.583L269.256 203.816H276.96L280.702 210.583L276.96 217.351Z" fill="#CAE5E6" />
    <path d="M260.12 221.523H257.369L256.048 219.041L257.369 216.673H260.12L261.441 219.041L260.12 221.523Z" fill="#CAE5E6" />
    <path d="M232.272 240.246H227.649L225.338 236.073L227.649 232.012H232.272L234.583 236.073L232.272 240.246Z" fill="#CAE5E6" />
    <path d="M217.75 248.369H213.567L211.366 244.647L213.567 240.812H217.75L219.841 244.647L217.75 248.369Z" fill="#CAE5E6" />
    <path d="M204.539 258.858H198.045L194.854 253.106L198.045 247.354H204.539L207.73 253.106L204.539 258.858Z" fill="#CAE5E6" />
    <path d="M188.471 264.384H185.389L183.849 261.564L185.389 258.857H188.471L190.012 261.564L188.471 264.384Z" fill="#CAE5E6" />
    <path d="M305.683 693.334H297.979L294.127 686.566L297.979 679.799H305.683L309.535 686.566L305.683 693.334Z" fill="#CAE5E6" />
    <path d="M319.99 684.876H312.396L308.544 678.108L312.396 671.228H319.99L323.842 678.108L319.99 684.876Z" fill="#CAE5E6" />
    <path d="M334.409 676.416H326.705L322.853 669.536L326.705 662.769H334.409L338.261 669.536L334.409 676.416Z" fill="#CAE5E6" />
    <path d="M348.719 667.844H341.125L337.272 661.076L341.125 654.309H348.719L352.571 661.076L348.719 667.844Z" fill="#CAE5E6" />
    <path d="M363.135 659.383H355.431L351.579 652.616L355.431 645.735H363.135L366.987 652.616L363.135 659.383Z" fill="#CAE5E6" />
    <path d="M377.44 650.813H369.846L365.994 644.046L369.846 637.278H377.44L381.292 644.046L377.44 650.813Z" fill="#CAE5E6" />
    <path d="M391.862 642.351H384.158L380.306 635.583L384.158 628.816H391.862L395.714 635.583L391.862 642.351Z" fill="#CAE5E6" />
    <path d="M406.165 633.895H398.571L394.719 627.015L398.571 620.247H406.165L410.017 627.015L406.165 633.895Z" fill="#CAE5E6" />
    <path d="M420.475 625.323H412.991L409.139 618.555L412.991 611.9H420.475L424.327 618.555L420.475 625.323Z" fill="#CAE5E6" />
    <path d="M492.233 480.722H484.859L481.117 474.067L484.859 467.412H492.233L495.975 474.067L492.233 480.722Z" fill="#CAE5E6" />
    <path d="M492.342 463.914H484.748L480.896 457.034L484.748 450.267H492.342L496.194 457.034L492.342 463.914Z" fill="#CAE5E6" />
    <path d="M492.233 446.657H484.859L481.117 440.115L484.859 433.46H492.233L495.975 440.115L492.233 446.657Z" fill="#CAE5E6" />
    <path d="M491.682 428.612H485.409L482.327 423.085L485.409 417.559H491.682L494.764 423.085L491.682 428.612Z" fill="#CAE5E6" />
    <path d="M492.342 412.933H484.748L480.896 406.053L484.748 399.285H492.342L496.194 406.053L492.342 412.933Z" fill="#CAE5E6" />
    <path d="M492.342 395.9H484.748L480.896 389.132L484.748 382.252H492.342L496.194 389.132L492.342 395.9Z" fill="#CAE5E6" />
    <path d="M492.342 378.87H484.748L480.896 372.102L484.748 365.334H492.342L496.194 372.102L492.342 378.87Z" fill="#CAE5E6" />
    <path d="M492.342 361.836H484.748L480.896 355.069L484.748 348.301H492.342L496.194 355.069L492.342 361.836Z" fill="#CAE5E6" />
    <path d="M492.342 344.919H484.748L480.896 338.039L484.748 331.271H492.342L496.194 338.039L492.342 344.919Z" fill="#CAE5E6" />
    <path d="M492.342 327.888H484.748L480.896 321.121L484.748 314.24H492.342L496.194 321.121L492.342 327.888Z" fill="#CAE5E6" />
    <path d="M478.035 319.428H470.331L466.589 312.548L470.331 305.78H478.035L481.887 312.548L478.035 319.428Z" fill="#CAE5E6" />
    <path d="M463.618 310.855H456.024L452.172 304.088L456.024 297.32H463.618L467.47 304.088L463.618 310.855Z" fill="#CAE5E6" />
    <path d="M449.31 302.395H441.606L437.754 295.628L441.606 288.748H449.31L453.162 295.628L449.31 302.395Z" fill="#CAE5E6" />
    <path d="M434.891 293.938H427.297L423.445 287.058L427.297 280.29H434.891L438.743 287.058L434.891 293.938Z" fill="#CAE5E6" />
    <path d="M420.583 285.365H412.879L409.027 278.597L412.879 271.83H420.583L424.436 278.597L420.583 285.365Z" fill="#CAE5E6" />
    <path d="M406.165 276.905H398.571L394.719 270.137L398.571 263.257H406.165L410.017 270.137L406.165 276.905Z" fill="#CAE5E6" />
    <path d="M391.862 268.445H384.158L380.306 261.565L384.158 254.797H391.862L395.714 261.565L391.862 268.445Z" fill="#CAE5E6" />
    <path d="M377.44 259.874H369.846L365.994 253.106L369.846 246.339H377.44L381.292 253.106L377.44 259.874Z" fill="#CAE5E6" />
    <path d="M363.135 251.414H355.431L351.579 244.646L355.431 237.766H363.135L366.987 244.646L363.135 251.414Z" fill="#CAE5E6" />
    <path d="M348.719 242.844H341.125L337.272 236.076L341.125 229.309H348.719L352.571 236.076L348.719 242.844Z" fill="#CAE5E6" />
    <path d="M334.188 234.045H326.924L323.292 227.616L326.924 221.187H334.188L337.82 227.616L334.188 234.045Z" fill="#CAE5E6" />
    <path d="M316.911 220.395H315.48L314.71 219.041L315.48 217.801H316.911L317.682 219.041L316.911 220.395Z" fill="#CAE5E6" />
    <path d="M305.683 217.351H297.979L294.127 210.583L297.979 203.816H305.683L309.535 210.583L305.683 217.351Z" fill="#CAE5E6" />
    <path d="M291.265 208.893H283.671L279.819 202.125L283.671 195.245H291.265L295.118 202.125L291.265 208.893Z" fill="#CAE5E6" />
    <path d="M276.96 200.431H269.256L265.404 193.551L269.256 186.783H276.96L280.702 193.551L276.96 200.431Z" fill="#CAE5E6" />
    <path d="M261.991 207.875H255.498L252.196 202.123L255.498 196.371H261.991L265.183 202.123L261.991 207.875Z" fill="#CAE5E6" />
    <path d="M219.397 234.269H211.803L208.061 227.614L211.803 220.847H219.397L223.249 227.614L219.397 234.269Z" fill="#CAE5E6" />
    <path d="M59.0406 595.544H56.1791L54.8584 593.063L56.1791 590.581H59.0406L60.4714 593.063L59.0406 595.544Z" fill="#CAE5E6" />
    <path d="M305.572 710.252H298.088L294.346 703.598L298.088 696.943H305.572L309.314 703.598L305.572 710.252Z" fill="#CAE5E6" />
    <path d="M319.99 701.907H312.396L308.544 695.027L312.396 688.259H319.99L323.842 695.027L319.99 701.907Z" fill="#CAE5E6" />
    <path d="M334.409 693.334H326.705L322.853 686.566L326.705 679.799H334.409L338.261 686.566L334.409 693.334Z" fill="#CAE5E6" />
    <path d="M348.719 684.876H341.125L337.272 678.108L341.125 671.228H348.719L352.571 678.108L348.719 684.876Z" fill="#CAE5E6" />
    <path d="M363.135 676.416H355.431L351.579 669.536L355.431 662.769H363.135L366.987 669.536L363.135 676.416Z" fill="#CAE5E6" />
    <path d="M377.438 667.844H369.844L365.992 661.076L369.844 654.309H377.438L381.29 661.076L377.438 667.844Z" fill="#CAE5E6" />
    <path d="M391.862 659.383H384.158L380.306 652.616L384.158 645.735H391.862L395.714 652.616L391.862 659.383Z" fill="#CAE5E6" />
    <path d="M406.165 650.813H398.571L394.719 644.046L398.571 637.278H406.165L410.017 644.046L406.165 650.813Z" fill="#CAE5E6" />
    <path d="M420.583 642.351H412.879L409.027 635.583L412.879 628.816H420.583L424.436 635.583L420.583 642.351Z" fill="#CAE5E6" />
    <path d="M506.428 471.698H499.495L495.973 465.607L499.495 459.403H506.428L509.84 465.607L506.428 471.698Z" fill="#CAE5E6" />
    <path d="M506.316 454.667H499.493L496.081 448.576L499.493 442.485H506.316L509.728 448.576L506.316 454.667Z" fill="#CAE5E6" />
    <path d="M505.989 437.071H499.826L496.744 431.544L499.826 426.13H505.989L509.071 431.544L505.989 437.071Z" fill="#CAE5E6" />
    <path d="M506.76 421.391H499.055L495.313 414.623L499.055 407.743H506.76L510.612 414.623L506.76 421.391Z" fill="#CAE5E6" />
    <path d="M506.76 404.36H499.055L495.313 397.593L499.055 390.825H506.76L510.612 397.593L506.76 404.36Z" fill="#CAE5E6" />
    <path d="M506.76 387.327H499.055L495.313 380.56L499.055 373.792H506.76L510.612 380.56L506.76 387.327Z" fill="#CAE5E6" />
    <path d="M506.76 370.41H499.055L495.313 363.642L499.055 356.762H506.76L510.612 363.642L506.76 370.41Z" fill="#CAE5E6" />
    <path d="M506.76 353.379H499.055L495.313 346.611L499.055 339.844H506.76L510.612 346.611L506.76 353.379Z" fill="#CAE5E6" />
    <path d="M506.76 336.349H499.055L495.313 329.581L499.055 322.813H506.76L510.612 329.581L506.76 336.349Z" fill="#CAE5E6" />
    <path d="M506.76 319.431H499.055L495.313 312.55L499.055 305.783H506.76L510.612 312.55L506.76 319.431Z" fill="#CAE5E6" />
    <path d="M492.342 310.855H484.748L480.896 304.088L484.748 297.32H492.342L496.194 304.088L492.342 310.855Z" fill="#CAE5E6" />
    <path d="M478.035 302.395H470.331L466.589 295.628L470.331 288.748H478.035L481.887 295.628L478.035 302.395Z" fill="#CAE5E6" />
    <path d="M463.62 293.938H456.026L452.174 287.058L456.026 280.29H463.62L467.472 287.058L463.62 293.938Z" fill="#CAE5E6" />
    <path d="M449.31 285.365H441.606L437.754 278.597L441.606 271.83H449.31L453.162 278.597L449.31 285.365Z" fill="#CAE5E6" />
    <path d="M434.891 276.905H427.297L423.445 270.137L427.297 263.257H434.891L438.743 270.137L434.891 276.905Z" fill="#CAE5E6" />
    <path d="M420.583 268.445H412.879L409.027 261.565L412.879 254.797H420.583L424.436 261.565L420.583 268.445Z" fill="#CAE5E6" />
    <path d="M406.165 259.874H398.571L394.719 253.106L398.571 246.339H406.165L410.017 253.106L406.165 259.874Z" fill="#CAE5E6" />
    <path d="M391.862 251.414H384.158L380.306 244.646L384.158 237.766H391.862L395.714 244.646L391.862 251.414Z" fill="#CAE5E6" />
    <path d="M377.438 242.842H369.844L365.992 236.074L369.844 229.307H377.438L381.29 236.074L377.438 242.842Z" fill="#CAE5E6" />
    <path d="M363.135 234.382H355.431L351.579 227.614L355.431 220.847H363.135L366.987 227.614L363.135 234.382Z" fill="#CAE5E6" />
    <path d="M348.719 225.923H341.125L337.272 219.043L341.125 212.275H348.719L352.571 219.043L348.719 225.923Z" fill="#CAE5E6" />
    <path d="M305.683 200.431H297.979L294.127 193.551L297.979 186.783H305.683L309.535 193.551L305.683 200.431Z" fill="#CAE5E6" />
    <path d="M291.265 191.861H283.671L279.819 185.093L283.671 178.326H291.265L295.118 185.093L291.265 191.861Z" fill="#CAE5E6" />
    <path d="M275.857 181.596H270.244L267.492 176.633L270.244 171.67H275.857L278.718 176.633L275.857 181.596Z" fill="#CAE5E6" />
    <path d="M259.46 186.446H258.029L257.259 185.093L258.029 183.739H259.46L260.23 185.093L259.46 186.446Z" fill="#CAE5E6" />
    <path d="M216.427 211.937H214.886L214.115 210.583L214.886 209.23H216.427L217.087 210.583L216.427 211.937Z" fill="#CAE5E6" />
    <path d="M32.7407 599.83H25.0366L21.1846 593.062L25.0366 586.294H32.7407L36.5928 593.062L32.7407 599.83Z" fill="#CAE5E6" />
    <path d="M47.0437 608.402H39.4497L35.5977 601.522L39.4497 594.754H47.0437L50.8958 601.522L47.0437 608.402Z" fill="#CAE5E6" />
    <path d="M61.4637 616.86H53.7596L50.0176 610.092L53.7596 603.212H61.4637L65.3157 610.092L61.4637 616.86Z" fill="#CAE5E6" />
    <path d="M304.802 725.932H298.749L295.777 720.518L298.749 715.217H304.802L307.884 720.518L304.802 725.932Z" fill="#CAE5E6" />
    <path d="M319.99 718.825H312.396L308.544 712.057L312.396 705.29H319.99L323.842 712.057L319.99 718.825Z" fill="#CAE5E6" />
    <path d="M334.409 710.365H326.705L322.853 703.597L326.705 696.717H334.409L338.261 703.597L334.409 710.365Z" fill="#CAE5E6" />
    <path d="M348.719 701.907H341.125L337.272 695.027L341.125 688.259H348.719L352.571 695.027L348.719 701.907Z" fill="#CAE5E6" />
    <path d="M363.135 693.334H355.431L351.579 686.566L355.431 679.799H363.135L366.987 686.566L363.135 693.334Z" fill="#CAE5E6" />
    <path d="M377.44 684.876H369.846L365.994 678.108L369.846 671.228H377.44L381.292 678.108L377.44 684.876Z" fill="#CAE5E6" />
    <path d="M391.862 676.414H384.158L380.306 669.534L384.158 662.766H391.862L395.714 669.534L391.862 676.414Z" fill="#CAE5E6" />
    <path d="M406.165 667.844H398.571L394.719 661.076L398.571 654.309H406.165L410.017 661.076L406.165 667.844Z" fill="#CAE5E6" />
    <path d="M420.585 659.271H412.991L409.139 652.617L412.991 645.849H420.585L424.327 652.617L420.585 659.271Z" fill="#CAE5E6" />
    <path d="M518.425 442.034H516.224L515.014 440.116L516.224 438.086H518.425L519.526 440.116L518.425 442.034Z" fill="#CAE5E6" />
    <path d="M521.068 429.851H513.474L509.622 423.083L513.474 416.315H521.068L524.92 423.083L521.068 429.851Z" fill="#CAE5E6" />
    <path d="M521.068 412.933H513.474L509.622 406.053L513.474 399.285H521.068L524.92 406.053L521.068 412.933Z" fill="#CAE5E6" />
    <path d="M521.068 395.9H513.474L509.622 389.132L513.474 382.252H521.068L524.92 389.132L521.068 395.9Z" fill="#CAE5E6" />
    <path d="M521.068 378.87H513.474L509.622 372.102L513.474 365.334H521.068L524.92 372.102L521.068 378.87Z" fill="#CAE5E6" />
    <path d="M521.068 361.839H513.474L509.622 355.072L513.474 348.304H521.068L524.92 355.072L521.068 361.839Z" fill="#CAE5E6" />
    <path d="M521.068 344.919H513.474L509.622 338.039L513.474 331.271H521.068L524.92 338.039L521.068 344.919Z" fill="#CAE5E6" />
    <path d="M521.068 327.888H513.474L509.622 321.121L513.474 314.24H521.068L524.92 321.121L521.068 327.888Z" fill="#CAE5E6" />
    <path d="M521.068 310.855H513.474L509.622 304.088L513.474 297.32H521.068L524.92 304.088L521.068 310.855Z" fill="#CAE5E6" />
    <path d="M506.76 302.395H499.055L495.313 295.628L499.055 288.748H506.76L510.612 295.628L506.76 302.395Z" fill="#CAE5E6" />
    <path d="M492.342 293.938H484.748L480.896 287.058L484.748 280.29H492.342L496.194 287.058L492.342 293.938Z" fill="#CAE5E6" />
    <path d="M478.035 285.365H470.331L466.589 278.597L470.331 271.83H478.035L481.887 278.597L478.035 285.365Z" fill="#CAE5E6" />
    <path d="M463.618 276.905H456.024L452.172 270.137L456.024 263.257H463.618L467.47 270.137L463.618 276.905Z" fill="#CAE5E6" />
    <path d="M449.31 268.445H441.606L437.754 261.565L441.606 254.797H449.31L453.162 261.565L449.31 268.445Z" fill="#CAE5E6" />
    <path d="M434.891 259.874H427.297L423.445 253.106L427.297 246.339H434.891L438.743 253.106L434.891 259.874Z" fill="#CAE5E6" />
    <path d="M420.583 251.414H412.879L409.027 244.646L412.879 237.766H420.583L424.436 244.646L420.583 251.414Z" fill="#CAE5E6" />
    <path d="M406.165 242.844H398.571L394.719 236.076L398.571 229.309H406.165L410.017 236.076L406.165 242.844Z" fill="#CAE5E6" />
    <path d="M391.862 234.382H384.158L380.306 227.614L384.158 220.847H391.862L395.714 227.614L391.862 234.382Z" fill="#CAE5E6" />
    <path d="M377.44 225.923H369.846L365.994 219.043L369.846 212.275H377.44L381.292 219.043L377.44 225.923Z" fill="#CAE5E6" />
    <path d="M363.024 217.236H355.54L351.688 210.581L355.54 203.927H363.024L366.766 210.581L363.024 217.236Z" fill="#CAE5E6" />
    <path d="M348.717 208.778H341.123L337.381 202.123L341.123 195.356H348.717L352.459 202.123L348.717 208.778Z" fill="#CAE5E6" />
    <path d="M334.409 200.431H326.705L322.853 193.551L326.705 186.783H334.409L338.261 193.551L334.409 200.431Z" fill="#CAE5E6" />
    <path d="M318.341 188.928H314.049L311.848 185.093L314.049 181.258H318.341L320.542 185.093L318.341 188.928Z" fill="#CAE5E6" />
    <path d="M305.683 183.4H297.979L294.127 176.632L297.979 169.752H305.683L309.535 176.632L305.683 183.4Z" fill="#CAE5E6" />
    <path d="M291.046 174.601H283.782L280.15 168.059L283.782 161.63H291.046L294.678 168.059L291.046 174.601Z" fill="#CAE5E6" />
    <path d="M18.3172 608.402H10.7231L6.87109 601.522L10.7231 594.754H18.3172L22.1693 601.522L18.3172 608.402Z" fill="#CAE5E6" />
    <path d="M32.7407 616.86H25.0366L21.1846 610.092L25.0366 603.212H32.7407L36.5928 610.092L32.7407 616.86Z" fill="#CAE5E6" />
    <path d="M47.0437 625.32H39.4497L35.5977 618.552L39.4497 611.785H47.0437L50.8958 618.552L47.0437 625.32Z" fill="#CAE5E6" />
    <path d="M61.1366 633.441H54.0929L50.4609 627.012L54.0929 620.696H61.1366L64.7686 627.012L61.1366 633.441Z" fill="#CAE5E6" />
    <path d="M303.153 740.03H300.402L299.081 737.549L300.402 735.18H303.153L304.584 737.549L303.153 740.03Z" fill="#CAE5E6" />
    <path d="M319.99 735.855H312.396L308.544 729.088L312.396 722.208H319.99L323.842 729.088L319.99 735.855Z" fill="#CAE5E6" />
    <path d="M334.409 727.398H326.705L322.853 720.518L326.705 713.75H334.409L338.261 720.518L334.409 727.398Z" fill="#CAE5E6" />
    <path d="M348.719 718.825H341.125L337.272 712.057L341.125 705.29H348.719L352.571 712.057L348.719 718.825Z" fill="#CAE5E6" />
    <path d="M363.135 710.365H355.431L351.579 703.597L355.431 696.717H363.135L366.987 703.597L363.135 710.365Z" fill="#CAE5E6" />
    <path d="M377.44 701.907H369.846L365.994 695.027L369.846 688.259H377.44L381.292 695.027L377.44 701.907Z" fill="#CAE5E6" />
    <path d="M391.862 693.334H384.158L380.306 686.566L384.158 679.799H391.862L395.714 686.566L391.862 693.334Z" fill="#CAE5E6" />
    <path d="M405.506 683.521H399.342L396.261 678.107L399.342 672.58H405.506L408.477 678.107L405.506 683.521Z" fill="#CAE5E6" />
    <path d="M417.832 671.454H415.631L414.53 669.536L415.631 667.619H417.832L418.933 669.536L417.832 671.454Z" fill="#CAE5E6" />
    <path d="M463.065 649.797H456.571L453.27 644.044L456.571 638.292H463.065L466.366 644.044L463.065 649.797Z" fill="#CAE5E6" />
    <path d="M535.485 438.313H527.891L524.039 431.545L527.891 424.778H535.485L539.337 431.545L535.485 438.313Z" fill="#CAE5E6" />
    <path d="M535.485 421.391H527.781L524.039 414.623L527.781 407.743H535.485L539.337 414.623L535.485 421.391Z" fill="#CAE5E6" />
    <path d="M535.485 404.36H527.781L524.039 397.593L527.781 390.825H535.485L539.337 397.593L535.485 404.36Z" fill="#CAE5E6" />
    <path d="M535.485 387.327H527.781L524.039 380.56L527.781 373.792H535.485L539.337 380.56L535.485 387.327Z" fill="#CAE5E6" />
    <path d="M535.485 370.41H527.781L524.039 363.642L527.781 356.762H535.485L539.337 363.642L535.485 370.41Z" fill="#CAE5E6" />
    <path d="M535.485 353.379H527.781L524.039 346.611L527.781 339.844H535.485L539.337 346.611L535.485 353.379Z" fill="#CAE5E6" />
    <path d="M535.485 336.349H527.781L524.039 329.581L527.781 322.813H535.485L539.337 329.581L535.485 336.349Z" fill="#CAE5E6" />
    <path d="M535.485 319.431H527.781L524.039 312.55L527.781 305.783H535.485L539.337 312.55L535.485 319.431Z" fill="#CAE5E6" />
    <path d="M535.485 302.395H527.781L524.039 295.628L527.781 288.748H535.485L539.337 295.628L535.485 302.395Z" fill="#CAE5E6" />
    <path d="M521.068 293.938H513.474L509.622 287.058L513.474 280.29H521.068L524.92 287.058L521.068 293.938Z" fill="#CAE5E6" />
    <path d="M506.76 285.365H499.055L495.313 278.597L499.055 271.83H506.76L510.612 278.597L506.76 285.365Z" fill="#CAE5E6" />
    <path d="M492.342 276.905H484.748L480.896 270.137L484.748 263.257H492.342L496.194 270.137L492.342 276.905Z" fill="#CAE5E6" />
    <path d="M478.035 268.445H470.331L466.589 261.565L470.331 254.797H478.035L481.887 261.565L478.035 268.445Z" fill="#CAE5E6" />
    <path d="M463.62 259.874H456.026L452.174 253.106L456.026 246.339H463.62L467.472 253.106L463.62 259.874Z" fill="#CAE5E6" />
    <path d="M449.31 251.414H441.606L437.754 244.646L441.606 237.766H449.31L453.162 244.646L449.31 251.414Z" fill="#CAE5E6" />
    <path d="M434.891 242.844H427.297L423.445 236.076L427.297 229.309H434.891L438.743 236.076L434.891 242.844Z" fill="#CAE5E6" />
    <path d="M420.583 234.382H412.879L409.027 227.614L412.879 220.847H420.583L424.436 227.614L420.583 234.382Z" fill="#CAE5E6" />
    <path d="M406.165 225.923H398.571L394.719 219.043L398.571 212.275H406.165L410.017 219.043L406.165 225.923Z" fill="#CAE5E6" />
    <path d="M391.862 217.351H384.158L380.306 210.583L384.158 203.816H391.862L395.714 210.583L391.862 217.351Z" fill="#CAE5E6" />
    <path d="M377.44 208.893H369.846L365.994 202.125L369.846 195.245H377.44L381.292 202.125L377.44 208.893Z" fill="#CAE5E6" />
    <path d="M363.135 200.431H355.431L351.579 193.551L355.431 186.783H363.135L366.987 193.551L363.135 200.431Z" fill="#CAE5E6" />
    <path d="M348.719 191.861H341.125L337.272 185.093L341.125 178.326H348.719L352.571 185.093L348.719 191.861Z" fill="#CAE5E6" />
    <path d="M332.427 180.018H328.575L326.704 176.634L328.575 173.138H332.427L334.408 176.634L332.427 180.018Z" fill="#CAE5E6" />
    <path d="M319.99 174.942H312.396L308.544 168.062L312.396 161.294H319.99L323.842 168.062L319.99 174.942Z" fill="#CAE5E6" />
    <path d="M305.683 166.367H297.979L294.127 159.6L297.979 152.832H305.683L309.535 159.6L305.683 166.367Z" fill="#CAE5E6" />
    <path d="M17.6597 590.129H11.3863L8.30469 584.602L11.3863 579.075H17.6597L20.7413 584.602L17.6597 590.129Z" fill="#CAE5E6" />
    <path d="M4.01611 616.86H-3.68799L-7.54004 610.092L-3.68799 603.212H4.01611L7.86816 610.092L4.01611 616.86Z" fill="#CAE5E6" />
    <path d="M18.3172 625.32H10.7231L6.87109 618.552L10.7231 611.785H18.3172L22.1693 618.552L18.3172 625.32Z" fill="#CAE5E6" />
    <path d="M32.7407 633.893H25.0366L21.1846 627.013L25.0366 620.245H32.7407L36.5928 627.013L32.7407 633.893Z" fill="#CAE5E6" />
    <path d="M47.0437 642.351H39.4497L35.5977 635.583L39.4497 628.816H47.0437L50.8958 635.583L47.0437 642.351Z" fill="#CAE5E6" />
    <path d="M319.99 752.891H312.396L308.544 746.01L312.396 739.243H319.99L323.842 746.01L319.99 752.891Z" fill="#CAE5E6" />
    <path d="M334.409 744.317H326.705L322.853 737.55L326.705 730.782H334.409L338.261 737.55L334.409 744.317Z" fill="#CAE5E6" />
    <path d="M348.719 735.855H341.125L337.272 729.088L341.125 722.208H348.719L352.571 729.088L348.719 735.855Z" fill="#CAE5E6" />
    <path d="M363.135 727.398H355.431L351.579 720.518L355.431 713.75H363.135L366.987 720.518L363.135 727.398Z" fill="#CAE5E6" />
    <path d="M377.438 718.825H369.844L365.992 712.057L369.844 705.29H377.438L381.29 712.057L377.438 718.825Z" fill="#CAE5E6" />
    <path d="M391.862 710.365H384.158L380.306 703.597L384.158 696.717H391.862L395.714 703.597L391.862 710.365Z" fill="#CAE5E6" />
    <path d="M402.976 696.156H401.765L401.215 695.028L401.765 694.013H402.976L403.526 695.028L402.976 696.156Z" fill="#CAE5E6" />
    <path d="M449.31 676.416H441.606L437.754 669.536L441.606 662.769H449.31L453.162 669.536L449.31 676.416Z" fill="#CAE5E6" />
    <path d="M463.618 667.844H456.024L452.172 661.076L456.024 654.309H463.618L467.47 661.076L463.618 667.844Z" fill="#CAE5E6" />
    <path d="M548.472 444.515H543.52L540.988 440.116L543.52 435.604H548.472L551.004 440.116L548.472 444.515Z" fill="#CAE5E6" />
    <path d="M549.9 429.851H542.196L538.344 423.083L542.196 416.315H549.9L553.642 423.083L549.9 429.851Z" fill="#CAE5E6" />
    <path d="M549.9 412.933H542.196L538.344 406.053L542.196 399.285H549.9L553.642 406.053L549.9 412.933Z" fill="#CAE5E6" />
    <path d="M549.9 395.9H542.196L538.344 389.132L542.196 382.252H549.9L553.642 389.132L549.9 395.9Z" fill="#CAE5E6" />
    <path d="M549.9 378.87H542.196L538.344 372.102L542.196 365.334H549.9L553.642 372.102L549.9 378.87Z" fill="#CAE5E6" />
    <path d="M549.9 361.836H542.196L538.344 355.069L542.196 348.301H549.9L553.642 355.069L549.9 361.836Z" fill="#CAE5E6" />
    <path d="M549.9 344.919H542.196L538.344 338.039L542.196 331.271H549.9L553.642 338.039L549.9 344.919Z" fill="#CAE5E6" />
    <path d="M549.9 327.888H542.196L538.344 321.121L542.196 314.24H549.9L553.642 321.121L549.9 327.888Z" fill="#CAE5E6" />
    <path d="M549.9 310.855H542.196L538.344 304.088L542.196 297.32H549.9L553.642 304.088L549.9 310.855Z" fill="#CAE5E6" />
    <path d="M549.9 293.938H542.196L538.344 287.058L542.196 280.29H549.9L553.642 287.058L549.9 293.938Z" fill="#CAE5E6" />
    <path d="M535.485 285.365H527.781L524.039 278.597L527.781 271.83H535.485L539.337 278.597L535.485 285.365Z" fill="#CAE5E6" />
    <path d="M521.068 276.905H513.474L509.622 270.137L513.474 263.257H521.068L524.92 270.137L521.068 276.905Z" fill="#CAE5E6" />
    <path d="M506.76 268.445H499.055L495.313 261.565L499.055 254.797H506.76L510.612 261.565L506.76 268.445Z" fill="#CAE5E6" />
    <path d="M492.342 259.874H484.748L480.896 253.106L484.748 246.339H492.342L496.194 253.106L492.342 259.874Z" fill="#CAE5E6" />
    <path d="M478.035 251.414H470.331L466.589 244.646L470.331 237.766H478.035L481.887 244.646L478.035 251.414Z" fill="#CAE5E6" />
    <path d="M463.618 242.842H456.024L452.172 236.074L456.024 229.307H463.618L467.47 236.074L463.618 242.842Z" fill="#CAE5E6" />
    <path d="M449.31 234.382H441.606L437.754 227.614L441.606 220.847H449.31L453.162 227.614L449.31 234.382Z" fill="#CAE5E6" />
    <path d="M434.891 225.923H427.297L423.445 219.043L427.297 212.275H434.891L438.743 219.043L434.891 225.923Z" fill="#CAE5E6" />
    <path d="M420.583 217.351H412.879L409.027 210.583L412.879 203.816H420.583L424.436 210.583L420.583 217.351Z" fill="#CAE5E6" />
    <path d="M406.165 208.893H398.571L394.719 202.125L398.571 195.245H406.165L410.017 202.125L406.165 208.893Z" fill="#CAE5E6" />
    <path d="M391.862 200.431H384.158L380.306 193.551L384.158 186.783H391.862L395.714 193.551L391.862 200.431Z" fill="#CAE5E6" />
    <path d="M377.438 191.861H369.844L365.992 185.093L369.844 178.326H377.438L381.29 185.093L377.438 191.861Z" fill="#CAE5E6" />
    <path d="M363.135 183.4H355.431L351.579 176.632L355.431 169.752H363.135L366.987 176.632L363.135 183.4Z" fill="#CAE5E6" />
    <path d="M348.719 174.942H341.125L337.272 168.062L341.125 161.294H348.719L352.571 168.062L348.719 174.942Z" fill="#CAE5E6" />
    <path d="M334.297 166.37H326.813L322.961 159.603L326.813 152.835H334.297L338.149 159.603L334.297 166.37Z" fill="#CAE5E6" />
    <path d="M319.99 157.909H312.396L308.544 151.142L312.396 144.261H319.99L323.842 151.142L319.99 157.909Z" fill="#CAE5E6" />
    <path d="M304.804 147.759H298.861L295.999 142.571L298.861 137.382H304.804L307.665 142.571L304.804 147.759Z" fill="#CAE5E6" />
    <path d="M2.47372 580.204H-2.14872L-4.45996 576.03L-2.14872 571.97H2.47372L4.78495 576.03L2.47372 580.204Z" fill="#CAE5E6" />
    <path d="M4.01611 599.83H-3.68799L-7.54004 593.062L-3.68799 586.294H4.01611L7.86816 593.062L4.01611 599.83Z" fill="#CAE5E6" />
    <path d="M4.01611 633.895H-3.68799L-7.54004 627.015L-3.68799 620.247H4.01611L7.86816 627.015L4.01611 633.895Z" fill="#CAE5E6" />
    <path d="M18.3172 642.351H10.7231L6.87109 635.583L10.7231 628.816H18.3172L22.1693 635.583L18.3172 642.351Z" fill="#CAE5E6" />
    <path d="M32.7407 650.813H25.0366L21.1846 644.046L25.0366 637.278H32.7407L36.5928 644.046L32.7407 650.813Z" fill="#CAE5E6" />
    <path d="M46.385 658.143H40.1116L36.9199 652.616L40.1116 646.976H46.385L49.5767 652.616L46.385 658.143Z" fill="#CAE5E6" />
    <path d="M319.549 768.907H312.835L309.533 763.042L312.835 757.177H319.549L322.85 763.042L319.549 768.907Z" fill="#CAE5E6" />
    <path d="M334.409 761.346H326.705L322.853 754.578L326.705 747.811H334.409L338.261 754.578L334.409 761.346Z" fill="#CAE5E6" />
    <path d="M348.719 752.891H341.125L337.272 746.01L341.125 739.243H348.719L352.571 746.01L348.719 752.891Z" fill="#CAE5E6" />
    <path d="M363.135 744.317H355.431L351.579 737.55L355.431 730.782H363.135L366.987 737.55L363.135 744.317Z" fill="#CAE5E6" />
    <path d="M377.44 735.855H369.846L365.994 729.088L369.846 722.208H377.44L381.292 729.088L377.44 735.855Z" fill="#CAE5E6" />
    <path d="M388.667 721.759H387.347L386.576 720.518L387.347 719.277H388.667L389.438 720.518L388.667 721.759Z" fill="#CAE5E6" />
    <path d="M432.582 697.621H429.611L428.18 695.027L429.611 692.545H432.582L434.013 695.027L432.582 697.621Z" fill="#CAE5E6" />
    <path d="M449.31 693.334H441.606L437.754 686.566L441.606 679.799H449.31L453.162 686.566L449.31 693.334Z" fill="#CAE5E6" />
    <path d="M463.397 684.422H456.244L452.722 678.106L456.244 671.677H463.397L467.029 678.106L463.397 684.422Z" fill="#CAE5E6" />
    <path d="M563.992 455.004H556.728L553.096 448.575L556.728 442.146H563.992L567.623 448.575L563.992 455.004Z" fill="#CAE5E6" />
    <path d="M564.212 438.424H556.508L552.766 431.543L556.508 424.776H564.212L568.064 431.543L564.212 438.424Z" fill="#CAE5E6" />
    <path d="M564.214 421.391H556.51L552.768 414.623L556.51 407.743H564.214L568.066 414.623L564.214 421.391Z" fill="#CAE5E6" />
    <path d="M564.212 404.36H556.508L552.766 397.593L556.508 390.825H564.212L568.064 397.593L564.212 404.36Z" fill="#CAE5E6" />
    <path d="M564.214 387.327H556.51L552.768 380.56L556.51 373.792H564.214L568.066 380.56L564.214 387.327Z" fill="#CAE5E6" />
    <path d="M564.214 370.41H556.51L552.768 363.642L556.51 356.762H564.214L568.066 363.642L564.214 370.41Z" fill="#CAE5E6" />
    <path d="M564.212 353.379H556.508L552.766 346.611L556.508 339.844H564.212L568.064 346.611L564.212 353.379Z" fill="#CAE5E6" />
    <path d="M564.214 336.349H556.51L552.768 329.581L556.51 322.813H564.214L568.066 329.581L564.214 336.349Z" fill="#CAE5E6" />
    <path d="M564.212 319.428H556.508L552.766 312.548L556.508 305.78H564.212L568.064 312.548L564.212 319.428Z" fill="#CAE5E6" />
    <path d="M564.214 302.395H556.51L552.768 295.628L556.51 288.748H564.214L568.066 295.628L564.214 302.395Z" fill="#CAE5E6" />
    <path d="M564.212 285.365H556.508L552.766 278.597L556.508 271.83H564.212L568.064 278.597L564.212 285.365Z" fill="#CAE5E6" />
    <path d="M549.9 276.905H542.196L538.344 270.137L542.196 263.257H549.9L553.642 270.137L549.9 276.905Z" fill="#CAE5E6" />
    <path d="M535.485 268.445H527.781L524.039 261.565L527.781 254.797H535.485L539.337 261.565L535.485 268.445Z" fill="#CAE5E6" />
    <path d="M521.068 259.874H513.474L509.622 253.106L513.474 246.339H521.068L524.92 253.106L521.068 259.874Z" fill="#CAE5E6" />
    <path d="M506.76 251.414H499.055L495.313 244.646L499.055 237.766H506.76L510.612 244.646L506.76 251.414Z" fill="#CAE5E6" />
    <path d="M492.342 242.842H484.748L480.896 236.074L484.748 229.307H492.342L496.194 236.074L492.342 242.842Z" fill="#CAE5E6" />
    <path d="M478.035 234.382H470.331L466.589 227.614L470.331 220.847H478.035L481.887 227.614L478.035 234.382Z" fill="#CAE5E6" />
    <path d="M463.62 225.923H456.026L452.174 219.043L456.026 212.275H463.62L467.472 219.043L463.62 225.923Z" fill="#CAE5E6" />
    <path d="M449.31 217.351H441.606L437.754 210.583L441.606 203.816H449.31L453.162 210.583L449.31 217.351Z" fill="#CAE5E6" />
    <path d="M434.891 208.893H427.297L423.445 202.125L427.297 195.245H434.891L438.743 202.125L434.891 208.893Z" fill="#CAE5E6" />
    <path d="M420.583 200.431H412.879L409.027 193.551L412.879 186.783H420.583L424.436 193.551L420.583 200.431Z" fill="#CAE5E6" />
    <path d="M406.165 191.861H398.571L394.719 185.093L398.571 178.326H406.165L410.017 185.093L406.165 191.861Z" fill="#CAE5E6" />
    <path d="M390.979 181.935H385.036L381.954 176.634L385.036 171.22H390.979L394.061 176.634L390.979 181.935Z" fill="#CAE5E6" />
    <path d="M377.44 174.942H369.846L365.994 168.062L369.846 161.294H377.44L381.292 168.062L377.44 174.942Z" fill="#CAE5E6" />
    <path d="M363.135 166.37H355.431L351.579 159.603L355.431 152.835H363.135L366.987 159.603L363.135 166.37Z" fill="#CAE5E6" />
    <path d="M348.719 157.909H341.125L337.272 151.142L341.125 144.261H348.719L352.571 151.142L348.719 157.909Z" fill="#CAE5E6" />
    <path d="M334.409 149.45H326.705L322.853 142.569L326.705 135.802H334.409L338.261 142.569L334.409 149.45Z" fill="#CAE5E6" />
    <path d="M319.55 140.088H312.837L309.425 134.11L312.837 128.132H319.55L322.962 134.11L319.55 140.088Z" fill="#CAE5E6" />
    <path d="M175.813 173.814H169.32L166.128 168.062L169.32 162.422H175.813L179.005 168.062L175.813 173.814Z" fill="#CAE5E6" />
    <path d="M161.619 182.724H154.685L151.273 176.633L154.685 170.429H161.619L165.141 176.633L161.619 182.724Z" fill="#CAE5E6" />
    <path d="M4.01611 650.813H-3.68799L-7.54004 644.046L-3.68799 637.278H4.01611L7.86816 644.046L4.01611 650.813Z" fill="#CAE5E6" />
    <path d="M18.3172 659.383H10.7231L6.87109 652.616L10.7231 645.735H18.3172L22.1693 652.616L18.3172 659.383Z" fill="#CAE5E6" />
    <path d="M32.7407 667.844H25.0366L21.1846 661.076L25.0366 654.309H32.7407L36.5928 661.076L32.7407 667.844Z" fill="#CAE5E6" />
    <path d="M44.1854 671.113H42.4244L41.5439 669.534L42.4244 668.067H44.1854L44.9558 669.534L44.1854 671.113Z" fill="#CAE5E6" />
    <path d="M332.647 775.224H328.465L326.374 771.501L328.465 767.892H332.647L334.739 771.501L332.647 775.224Z" fill="#CAE5E6" />
    <path d="M347.946 768.34H341.892L338.921 763.039L341.892 757.738H347.946L350.917 763.039L347.946 768.34Z" fill="#CAE5E6" />
    <path d="M363.023 761.123H355.539L351.797 754.582L355.539 747.927H363.023L366.765 754.582L363.023 761.123Z" fill="#CAE5E6" />
    <path d="M374.358 747.249H372.927L372.267 746.009L372.927 744.881H374.358L375.018 746.009L374.358 747.249Z" fill="#CAE5E6" />
    <path d="M432.47 714.539H429.719L428.288 712.058L429.719 709.576H432.47L433.901 712.058L432.47 714.539Z" fill="#CAE5E6" />
    <path d="M449.31 710.365H441.606L437.754 703.597L441.606 696.717H449.31L453.162 703.597L449.31 710.365Z" fill="#CAE5E6" />
    <path d="M462.188 699.314H457.455L455.034 695.028L457.455 690.854H462.188L464.609 695.028L462.188 699.314Z" fill="#CAE5E6" />
    <path d="M575.877 493.127H573.566L572.355 491.097L573.566 488.954H575.877L577.088 491.097L575.877 493.127Z" fill="#CAE5E6" />
    <path d="M578.406 480.495H571.142L567.51 474.066L571.142 467.637H578.406L582.038 474.066L578.406 480.495Z" fill="#CAE5E6" />
    <path d="M578.628 463.914H570.924L567.072 457.034L570.924 450.267H578.628L582.37 457.034L578.628 463.914Z" fill="#CAE5E6" />
    <path d="M578.628 446.883H570.924L567.072 440.116L570.924 433.235H578.628L582.37 440.116L578.628 446.883Z" fill="#CAE5E6" />
    <path d="M578.628 429.851H570.924L567.072 423.083L570.924 416.315H578.628L582.37 423.083L578.628 429.851Z" fill="#CAE5E6" />
    <path d="M578.628 412.933H570.924L567.072 406.053L570.924 399.285H578.628L582.37 406.053L578.628 412.933Z" fill="#CAE5E6" />
    <path d="M578.628 395.9H570.924L567.072 389.132L570.924 382.252H578.628L582.37 389.132L578.628 395.9Z" fill="#CAE5E6" />
    <path d="M578.628 378.87H570.924L567.072 372.102L570.924 365.334H578.628L582.37 372.102L578.628 378.87Z" fill="#CAE5E6" />
    <path d="M578.628 361.836H570.924L567.072 355.069L570.924 348.301H578.628L582.37 355.069L578.628 361.836Z" fill="#CAE5E6" />
    <path d="M578.628 344.919H570.924L567.072 338.039L570.924 331.271H578.628L582.37 338.039L578.628 344.919Z" fill="#CAE5E6" />
    <path d="M578.628 327.888H570.924L567.072 321.121L570.924 314.24H578.628L582.37 321.121L578.628 327.888Z" fill="#CAE5E6" />
    <path d="M578.628 310.855H570.924L567.072 304.088L570.924 297.32H578.628L582.37 304.088L578.628 310.855Z" fill="#CAE5E6" />
    <path d="M578.628 293.938H570.924L567.072 287.058L570.924 280.29H578.628L582.37 287.058L578.628 293.938Z" fill="#CAE5E6" />
    <path d="M578.628 276.905H570.924L567.072 270.137L570.924 263.257H578.628L582.37 270.137L578.628 276.905Z" fill="#CAE5E6" />
    <path d="M564.214 268.445H556.51L552.768 261.565L556.51 254.797H564.214L568.066 261.565L564.214 268.445Z" fill="#CAE5E6" />
    <path d="M549.9 259.874H542.196L538.344 253.106L542.196 246.339H549.9L553.642 253.106L549.9 259.874Z" fill="#CAE5E6" />
    <path d="M535.485 251.414H527.781L524.039 244.646L527.781 237.766H535.485L539.337 244.646L535.485 251.414Z" fill="#CAE5E6" />
    <path d="M521.068 242.844H513.474L509.622 236.076L513.474 229.309H521.068L524.92 236.076L521.068 242.844Z" fill="#CAE5E6" />
    <path d="M506.76 234.382H499.055L495.313 227.614L499.055 220.847H506.76L510.612 227.614L506.76 234.382Z" fill="#CAE5E6" />
    <path d="M492.342 225.923H484.748L480.896 219.043L484.748 212.275H492.342L496.194 219.043L492.342 225.923Z" fill="#CAE5E6" />
    <path d="M478.035 217.351H470.331L466.589 210.583L470.331 203.816H478.035L481.887 210.583L478.035 217.351Z" fill="#CAE5E6" />
    <path d="M463.62 208.893H456.026L452.174 202.125L456.026 195.245H463.62L467.472 202.125L463.62 208.893Z" fill="#CAE5E6" />
    <path d="M449.31 200.431H441.606L437.754 193.551L441.606 186.783H449.31L453.162 193.551L449.31 200.431Z" fill="#CAE5E6" />
    <path d="M434.891 191.861H427.297L423.445 185.093L427.297 178.326H434.891L438.743 185.093L434.891 191.861Z" fill="#CAE5E6" />
    <path d="M420.583 183.4H412.879L409.027 176.632L412.879 169.752H420.583L424.436 176.632L420.583 183.4Z" fill="#CAE5E6" />
    <path d="M405.944 174.489H398.79L395.269 168.06L398.79 161.743H405.944L409.576 168.06L405.944 174.489Z" fill="#CAE5E6" />
    <path d="M377.438 157.91H369.844L365.992 151.142L369.844 144.375H377.438L381.29 151.142L377.438 157.91Z" fill="#CAE5E6" />
    <path d="M363.135 149.45H355.431L351.579 142.569L355.431 135.802H363.135L366.987 142.569L363.135 149.45Z" fill="#CAE5E6" />
    <path d="M348.719 140.877H341.125L337.272 134.109L341.125 127.342H348.719L352.571 134.109L348.719 140.877Z" fill="#CAE5E6" />
    <path d="M333.858 131.518H327.255L323.953 125.653L327.255 119.675H333.858L337.16 125.653L333.858 131.518Z" fill="#CAE5E6" />
    <path d="M161.505 165.467H154.901L151.6 159.602L154.901 153.736H161.505L164.807 159.602L161.505 165.467Z" fill="#CAE5E6" />
    <path d="M147.529 174.83H140.045L136.303 168.062L140.045 161.408H147.529L151.381 168.062L147.529 174.83Z" fill="#CAE5E6" />
    <path d="M4.01611 667.844H-3.68799L-7.54004 661.076L-3.68799 654.309H4.01611L7.86816 661.076L4.01611 667.844Z" fill="#CAE5E6" />
    <path d="M18.3172 676.414H10.7231L6.87109 669.534L10.7231 662.766H18.3172L22.1693 669.534L18.3172 676.414Z" fill="#CAE5E6" />
    <path d="M32.7407 684.876H25.0366L21.1846 678.108L25.0366 671.228H32.7407L36.5928 678.108L32.7407 684.876Z" fill="#CAE5E6" />
    <path d="M447.22 723.563H443.808L442.047 720.518L443.808 717.585H447.22L448.871 720.518L447.22 723.563Z" fill="#CAE5E6" />
    <path d="M590.737 519.407H587.545L585.895 516.587L587.545 513.767H590.737L592.278 516.587L590.737 519.407Z" fill="#CAE5E6" />
    <path d="M592.935 506.325H585.341L581.489 499.558L585.341 492.79H592.935L596.787 499.558L592.935 506.325Z" fill="#CAE5E6" />
    <path d="M592.935 489.407H585.231L581.489 482.526L585.231 475.759H592.935L596.787 482.526L592.935 489.407Z" fill="#CAE5E6" />
    <path d="M592.935 472.374H585.231L581.489 465.606L585.231 458.726H592.935L596.787 465.606L592.935 472.374Z" fill="#CAE5E6" />
    <path d="M592.935 455.344H585.231L581.489 448.577L585.231 441.809H592.935L596.787 448.577L592.935 455.344Z" fill="#CAE5E6" />
    <path d="M592.935 438.426H585.231L581.489 431.545L585.231 424.778H592.935L596.787 431.545L592.935 438.426Z" fill="#CAE5E6" />
    <path d="M592.935 421.391H585.231L581.489 414.623L585.231 407.743H592.935L596.787 414.623L592.935 421.391Z" fill="#CAE5E6" />
    <path d="M592.935 404.36H585.231L581.489 397.593L585.231 390.825H592.935L596.787 397.593L592.935 404.36Z" fill="#CAE5E6" />
    <path d="M592.935 387.327H585.231L581.489 380.56L585.231 373.792H592.935L596.787 380.56L592.935 387.327Z" fill="#CAE5E6" />
    <path d="M592.935 370.41H585.231L581.489 363.642L585.231 356.762H592.935L596.787 363.642L592.935 370.41Z" fill="#CAE5E6" />
    <path d="M592.935 353.379H585.231L581.489 346.611L585.231 339.844H592.935L596.787 346.611L592.935 353.379Z" fill="#CAE5E6" />
    <path d="M592.935 336.349H585.231L581.489 329.581L585.231 322.813H592.935L596.787 329.581L592.935 336.349Z" fill="#CAE5E6" />
    <path d="M592.935 319.431H585.231L581.489 312.55L585.231 305.783H592.935L596.787 312.55L592.935 319.431Z" fill="#CAE5E6" />
    <path d="M592.935 302.395H585.231L581.489 295.628L585.231 288.748H592.935L596.787 295.628L592.935 302.395Z" fill="#CAE5E6" />
    <path d="M592.935 285.365H585.231L581.489 278.597L585.231 271.83H592.935L596.787 278.597L592.935 285.365Z" fill="#CAE5E6" />
    <path d="M592.935 268.445H585.231L581.489 261.565L585.231 254.797H592.935L596.787 261.565L592.935 268.445Z" fill="#CAE5E6" />
    <path d="M578.628 259.874H570.924L567.072 253.106L570.924 246.339H578.628L582.37 253.106L578.628 259.874Z" fill="#CAE5E6" />
    <path d="M564.214 251.414H556.51L552.768 244.646L556.51 237.766H564.214L568.066 244.646L564.214 251.414Z" fill="#CAE5E6" />
    <path d="M549.9 242.842H542.196L538.344 236.074L542.196 229.307H549.9L553.642 236.074L549.9 242.842Z" fill="#CAE5E6" />
    <path d="M535.485 234.382H527.781L524.039 227.614L527.781 220.847H535.485L539.337 227.614L535.485 234.382Z" fill="#CAE5E6" />
    <path d="M521.068 225.923H513.474L509.622 219.043L513.474 212.275H521.068L524.92 219.043L521.068 225.923Z" fill="#CAE5E6" />
    <path d="M506.76 217.351H499.055L495.313 210.583L499.055 203.816H506.76L510.612 210.583L506.76 217.351Z" fill="#CAE5E6" />
    <path d="M492.342 208.893H484.748L480.896 202.125L484.748 195.245H492.342L496.194 202.125L492.342 208.893Z" fill="#CAE5E6" />
    <path d="M478.035 200.431H470.331L466.589 193.551L470.331 186.783H478.035L481.887 193.551L478.035 200.431Z" fill="#CAE5E6" />
    <path d="M463.618 191.861H456.024L452.172 185.093L456.024 178.326H463.618L467.47 185.093L463.618 191.861Z" fill="#CAE5E6" />
    <path d="M449.31 183.4H441.606L437.754 176.632L441.606 169.752H449.31L453.162 176.632L449.31 183.4Z" fill="#CAE5E6" />
    <path d="M434.891 174.942H427.297L423.445 168.062L427.297 161.294H434.891L438.743 168.062L434.891 174.942Z" fill="#CAE5E6" />
    <path d="M420.475 166.255H412.991L409.249 159.6L412.991 152.945H420.475L424.217 159.6L420.475 166.255Z" fill="#CAE5E6" />
    <path d="M405.946 157.457H398.792L395.16 151.141L398.792 144.712H405.946L409.578 151.141L405.946 157.457Z" fill="#CAE5E6" />
    <path d="M391.641 149.112H384.377L380.745 142.57L384.377 136.141H391.641L395.273 142.57L391.641 149.112Z" fill="#CAE5E6" />
    <path d="M376.669 139.524H370.616L367.534 134.11L370.616 128.583H376.669L379.751 134.11L376.669 139.524Z" fill="#CAE5E6" />
    <path d="M362.364 131.064H356.201L353.119 125.65L356.201 120.123H362.364L365.446 125.65L362.364 131.064Z" fill="#CAE5E6" />
    <path d="M347.176 121.138H342.664L340.353 117.078L342.664 113.017H347.176L349.487 117.078L347.176 121.138Z" fill="#CAE5E6" />
    <path d="M145.657 154.413H141.915L140.154 151.142L141.915 147.871H145.657L147.528 151.142L145.657 154.413Z" fill="#CAE5E6" />
    <path d="M44.9546 213.517H41.6528L40.002 210.584L41.6528 207.651H44.9546L46.6055 210.584L44.9546 213.517Z" fill="#CAE5E6" />
    <path d="M4.01611 684.876H-3.68799L-7.54004 678.108L-3.68799 671.228H4.01611L7.86816 678.108L4.01611 684.876Z" fill="#CAE5E6" />
    <path d="M18.3172 693.334H10.7231L6.87109 686.566L10.7231 679.799H18.3172L22.1693 686.566L18.3172 693.334Z" fill="#CAE5E6" />
    <path d="M32.7407 701.907H25.0366L21.1846 695.027L25.0366 688.259H32.7407L36.5928 695.027L32.7407 701.907Z" fill="#CAE5E6" />
    <path d="M606.253 530.011H600.64L597.889 525.048L600.64 520.085H606.253L609.115 525.048L606.253 530.011Z" fill="#CAE5E6" />
    <path d="M607.353 514.895H599.649L595.797 508.128L599.649 501.248H607.353L611.095 508.128L607.353 514.895Z" fill="#CAE5E6" />
    <path d="M607.353 497.865H599.649L595.797 491.098L599.649 484.33H607.353L611.095 491.098L607.353 497.865Z" fill="#CAE5E6" />
    <path d="M607.353 480.834H599.649L595.797 474.066L599.649 467.299H607.353L611.095 474.066L607.353 480.834Z" fill="#CAE5E6" />
    <path d="M607.353 463.914H599.649L595.797 457.034L599.649 450.267H607.353L611.095 457.034L607.353 463.914Z" fill="#CAE5E6" />
    <path d="M607.353 446.883H599.649L595.797 440.116L599.649 433.235H607.353L611.095 440.116L607.353 446.883Z" fill="#CAE5E6" />
    <path d="M607.353 429.851H599.649L595.797 423.083L599.649 416.315H607.353L611.095 423.083L607.353 429.851Z" fill="#CAE5E6" />
    <path d="M607.353 412.933H599.649L595.797 406.053L599.649 399.285H607.353L611.095 406.053L607.353 412.933Z" fill="#CAE5E6" />
    <path d="M607.353 395.9H599.649L595.797 389.132L599.649 382.252H607.353L611.095 389.132L607.353 395.9Z" fill="#CAE5E6" />
    <path d="M607.353 378.87H599.649L595.797 372.102L599.649 365.334H607.353L611.095 372.102L607.353 378.87Z" fill="#CAE5E6" />
    <path d="M607.353 361.839H599.649L595.797 355.072L599.649 348.304H607.353L611.095 355.072L607.353 361.839Z" fill="#CAE5E6" />
    <path d="M607.353 344.919H599.649L595.797 338.039L599.649 331.271H607.353L611.095 338.039L607.353 344.919Z" fill="#CAE5E6" />
    <path d="M607.353 327.888H599.649L595.797 321.121L599.649 314.24H607.353L611.095 321.121L607.353 327.888Z" fill="#CAE5E6" />
    <path d="M607.353 310.855H599.649L595.797 304.088L599.649 297.32H607.353L611.095 304.088L607.353 310.855Z" fill="#CAE5E6" />
    <path d="M607.353 293.938H599.649L595.797 287.058L599.649 280.29H607.353L611.095 287.058L607.353 293.938Z" fill="#CAE5E6" />
    <path d="M607.353 276.905H599.649L595.797 270.137L599.649 263.257H607.353L611.095 270.137L607.353 276.905Z" fill="#CAE5E6" />
    <path d="M607.353 259.874H599.649L595.797 253.106L599.649 246.339H607.353L611.095 253.106L607.353 259.874Z" fill="#CAE5E6" />
    <path d="M592.935 251.414H585.231L581.489 244.646L585.231 237.766H592.935L596.787 244.646L592.935 251.414Z" fill="#CAE5E6" />
    <path d="M578.628 242.842H570.924L567.072 236.074L570.924 229.307H578.628L582.37 236.074L578.628 242.842Z" fill="#CAE5E6" />
    <path d="M564.212 234.382H556.508L552.766 227.614L556.508 220.847H564.212L568.064 227.614L564.212 234.382Z" fill="#CAE5E6" />
    <path d="M549.9 225.923H542.196L538.344 219.043L542.196 212.275H549.9L553.642 219.043L549.9 225.923Z" fill="#CAE5E6" />
    <path d="M535.485 217.351H527.781L524.039 210.583L527.781 203.816H535.485L539.337 210.583L535.485 217.351Z" fill="#CAE5E6" />
    <path d="M521.068 208.893H513.474L509.622 202.125L513.474 195.245H521.068L524.92 202.125L521.068 208.893Z" fill="#CAE5E6" />
    <path d="M506.76 200.431H499.055L495.313 193.551L499.055 186.783H506.76L510.612 193.551L506.76 200.431Z" fill="#CAE5E6" />
    <path d="M492.342 191.861H484.748L480.896 185.093L484.748 178.326H492.342L496.194 185.093L492.342 191.861Z" fill="#CAE5E6" />
    <path d="M478.035 183.4H470.331L466.589 176.632L470.331 169.752H478.035L481.887 176.632L478.035 183.4Z" fill="#CAE5E6" />
    <path d="M463.62 174.942H456.026L452.174 168.062L456.026 161.294H463.62L467.472 168.062L463.62 174.942Z" fill="#CAE5E6" />
    <path d="M449.31 166.37H441.606L437.754 159.603L441.606 152.835H449.31L453.162 159.603L449.31 166.37Z" fill="#CAE5E6" />
    <path d="M433.574 155.429H428.621L426.2 151.143L428.621 146.744H433.574L435.995 151.143L433.574 155.429Z" fill="#CAE5E6" />
    <path d="M420.034 148.434H413.43L410.238 142.569L413.43 136.816H420.034L423.335 142.569L420.034 148.434Z" fill="#CAE5E6" />
    <path d="M305.683 81.4355H297.979L294.237 74.5551L297.979 67.7876H305.683L309.425 74.5551L305.683 81.4355Z" fill="#CAE5E6" />
    <path d="M289.835 70.2695H285.103L282.682 66.0962L285.103 61.9229H289.835L292.257 66.0962L289.835 70.2695Z" fill="#CAE5E6" />
    <path d="M274.756 60.5693H271.455L269.804 57.6367L271.455 54.7041H274.756L276.407 57.6367L274.756 60.5693Z" fill="#CAE5E6" />
    <path d="M58.4884 186.671H56.7275L55.957 185.092L56.7275 183.626H58.4884L59.3689 185.092L58.4884 186.671Z" fill="#CAE5E6" />
    <path d="M47.0437 200.431H39.4497L35.5977 193.551L39.4497 186.783H47.0437L50.8958 193.551L47.0437 200.431Z" fill="#CAE5E6" />
    <path d="M32.296 208.102H25.4724L22.0605 202.124L25.4724 196.033H32.296L35.7078 202.124L32.296 208.102Z" fill="#CAE5E6" />
    <path d="M4.01611 701.907H-3.68799L-7.54004 695.027L-3.68799 688.259H4.01611L7.86816 695.027L4.01611 701.907Z" fill="#CAE5E6" />
    <path d="M18.3172 710.365H10.7231L6.87109 703.598L10.7231 696.83H18.3172L22.1693 703.598L18.3172 710.365Z" fill="#CAE5E6" />
    <path d="M620.556 538.359H615.164L612.412 533.622L615.164 528.771H620.556L623.308 533.622L620.556 538.359Z" fill="#CAE5E6" />
    <path d="M621.112 488.391H614.508L611.316 482.526L614.508 476.773H621.112L624.413 482.526L621.112 488.391Z" fill="#CAE5E6" />
    <path d="M621.662 472.374H613.958L610.216 465.606L613.958 458.726H621.662L625.514 465.606L621.662 472.374Z" fill="#CAE5E6" />
    <path d="M621.662 455.344H613.958L610.216 448.577L613.958 441.809H621.662L625.514 448.577L621.662 455.344Z" fill="#CAE5E6" />
    <path d="M621.662 438.426H613.958L610.216 431.545L613.958 424.778H621.662L625.514 431.545L621.662 438.426Z" fill="#CAE5E6" />
    <path d="M621.662 421.391H613.958L610.216 414.623L613.958 407.743H621.662L625.514 414.623L621.662 421.391Z" fill="#CAE5E6" />
    <path d="M621.662 404.36H613.958L610.216 397.593L613.958 390.825H621.662L625.514 397.593L621.662 404.36Z" fill="#CAE5E6" />
    <path d="M621.662 387.327H613.958L610.216 380.56L613.958 373.792H621.662L625.514 380.56L621.662 387.327Z" fill="#CAE5E6" />
    <path d="M621.662 370.41H613.958L610.216 363.642L613.958 356.762H621.662L625.514 363.642L621.662 370.41Z" fill="#CAE5E6" />
    <path d="M621.662 353.379H613.958L610.216 346.611L613.958 339.844H621.662L625.514 346.611L621.662 353.379Z" fill="#CAE5E6" />
    <path d="M621.662 336.349H613.958L610.216 329.581L613.958 322.813H621.662L625.514 329.581L621.662 336.349Z" fill="#CAE5E6" />
    <path d="M621.662 319.431H613.958L610.216 312.55L613.958 305.783H621.662L625.514 312.55L621.662 319.431Z" fill="#CAE5E6" />
    <path d="M621.662 302.395H613.958L610.216 295.628L613.958 288.748H621.662L625.514 295.628L621.662 302.395Z" fill="#CAE5E6" />
    <path d="M621.662 285.365H613.958L610.216 278.597L613.958 271.83H621.662L625.514 278.597L621.662 285.365Z" fill="#CAE5E6" />
    <path d="M621.662 268.445H613.958L610.216 261.565L613.958 254.797H621.662L625.514 261.565L621.662 268.445Z" fill="#CAE5E6" />
    <path d="M621.662 251.414H613.958L610.216 244.646L613.958 237.766H621.662L625.514 244.646L621.662 251.414Z" fill="#CAE5E6" />
    <path d="M607.353 242.844H599.649L595.797 236.076L599.649 229.309H607.353L611.095 236.076L607.353 242.844Z" fill="#CAE5E6" />
    <path d="M592.935 234.382H585.231L581.489 227.614L585.231 220.847H592.935L596.787 227.614L592.935 234.382Z" fill="#CAE5E6" />
    <path d="M578.628 225.923H570.924L567.072 219.043L570.924 212.275H578.628L582.37 219.043L578.628 225.923Z" fill="#CAE5E6" />
    <path d="M564.214 217.351H556.51L552.768 210.583L556.51 203.816H564.214L568.066 210.583L564.214 217.351Z" fill="#CAE5E6" />
    <path d="M549.9 208.893H542.196L538.344 202.125L542.196 195.245H549.9L553.642 202.125L549.9 208.893Z" fill="#CAE5E6" />
    <path d="M535.485 200.431H527.781L524.039 193.551L527.781 186.783H535.485L539.337 193.551L535.485 200.431Z" fill="#CAE5E6" />
    <path d="M521.068 191.861H513.474L509.622 185.093L513.474 178.326H521.068L524.92 185.093L521.068 191.861Z" fill="#CAE5E6" />
    <path d="M506.76 183.4H499.055L495.313 176.632L499.055 169.752H506.76L510.612 176.632L506.76 183.4Z" fill="#CAE5E6" />
    <path d="M492.342 174.942H484.748L480.896 168.062L484.748 161.294H492.342L496.194 168.062L492.342 174.942Z" fill="#CAE5E6" />
    <path d="M478.035 166.367H470.331L466.589 159.6L470.331 152.832H478.035L481.887 159.6L478.035 166.367Z" fill="#CAE5E6" />
    <path d="M463.618 157.909H456.024L452.172 151.142L456.024 144.261H463.618L467.47 151.142L463.618 157.909Z" fill="#CAE5E6" />
    <path d="M448.099 147.193H442.927L440.285 142.568L442.927 138.057H448.099L450.631 142.568L448.099 147.193Z" fill="#CAE5E6" />
    <path d="M331.328 75.9092H329.787L329.017 74.5557L329.787 73.3149H331.328L332.098 74.5557L331.328 75.9092Z" fill="#CAE5E6" />
    <path d="M317.679 68.8022H314.597L313.057 66.0952L314.597 63.3882H317.679L319.33 66.0952L317.679 68.8022Z" fill="#CAE5E6" />
    <path d="M305.683 64.4052H297.979L294.127 57.6377L297.979 50.7573H305.683L309.535 57.6377L305.683 64.4052Z" fill="#CAE5E6" />
    <path d="M291.265 55.9447H283.671L279.819 49.0644L283.671 42.2969H291.265L295.118 49.0644L291.265 55.9447Z" fill="#CAE5E6" />
    <path d="M276.96 47.3744H269.256L265.404 40.6069L269.256 33.8394H276.96L280.702 40.6069L276.96 47.3744Z" fill="#CAE5E6" />
    <path d="M145.215 119.561H142.464L141.033 117.08L142.464 114.598H145.215L146.536 117.08L145.215 119.561Z" fill="#CAE5E6" />
    <path d="M131.789 129.711H127.166L124.745 125.65L127.166 121.477H131.789L134.1 125.65L131.789 129.711Z" fill="#CAE5E6" />
    <path d="M118.912 140.877H111.318L107.466 134.109L111.318 127.342H118.912L122.654 134.109L118.912 140.877Z" fill="#CAE5E6" />
    <path d="M104.497 149.45H96.9028L93.0508 142.569L96.9028 135.802H104.497L108.349 142.569L104.497 149.45Z" fill="#CAE5E6" />
    <path d="M90.1873 157.91H82.4832L78.7412 151.142L82.4832 144.375H90.1873L93.9294 151.142L90.1873 157.91Z" fill="#CAE5E6" />
    <path d="M75.5497 165.918H68.5059L64.874 159.602L68.5059 153.286H75.5497L79.0716 159.602L75.5497 165.918Z" fill="#CAE5E6" />
    <path d="M61.0225 174.151H54.1989L50.7871 168.061L54.1989 161.97H61.0225L64.5444 168.061L61.0225 174.151Z" fill="#CAE5E6" />
    <path d="M47.0437 183.4H39.4497L35.5977 176.632L39.4497 169.752H47.0437L50.8958 176.632L47.0437 183.4Z" fill="#CAE5E6" />
    <path d="M32.7407 191.861H25.0366L21.1846 185.093L25.0366 178.326H32.7407L36.5928 185.093L32.7407 191.861Z" fill="#CAE5E6" />
    <path d="M17.4407 198.741H11.6076L8.74609 193.552L11.6076 188.477H17.4407L20.3022 193.552L17.4407 198.741Z" fill="#CAE5E6" />
    <path d="M4.01611 718.825H-3.68799L-7.54004 712.057L-3.68799 705.29H4.01611L7.86816 712.057L4.01611 718.825Z" fill="#CAE5E6" />
    <path d="M633.546 476.434H630.905L629.584 474.065L630.905 471.697H633.546L634.867 474.065L633.546 476.434Z" fill="#CAE5E6" />
    <path d="M636.079 463.914H628.375L624.522 457.034L628.375 450.267H636.079L639.821 457.034L636.079 463.914Z" fill="#CAE5E6" />
    <path d="M636.079 446.883H628.375L624.522 440.116L628.375 433.235H636.079L639.821 440.116L636.079 446.883Z" fill="#CAE5E6" />
    <path d="M636.079 429.851H628.375L624.522 423.083L628.375 416.315H636.079L639.821 423.083L636.079 429.851Z" fill="#CAE5E6" />
    <path d="M636.079 412.933H628.375L624.522 406.053L628.375 399.285H636.079L639.821 406.053L636.079 412.933Z" fill="#CAE5E6" />
    <path d="M636.079 395.9H628.375L624.522 389.132L628.375 382.252H636.079L639.821 389.132L636.079 395.9Z" fill="#CAE5E6" />
    <path d="M636.079 378.87H628.375L624.522 372.102L628.375 365.334H636.079L639.821 372.102L636.079 378.87Z" fill="#CAE5E6" />
    <path d="M636.079 361.839H628.375L624.522 355.072L628.375 348.304H636.079L639.821 355.072L636.079 361.839Z" fill="#CAE5E6" />
    <path d="M636.079 344.919H628.375L624.522 338.039L628.375 331.271H636.079L639.821 338.039L636.079 344.919Z" fill="#CAE5E6" />
    <path d="M636.079 327.888H628.375L624.522 321.121L628.375 314.24H636.079L639.821 321.121L636.079 327.888Z" fill="#CAE5E6" />
    <path d="M636.079 310.855H628.375L624.522 304.088L628.375 297.32H636.079L639.821 304.088L636.079 310.855Z" fill="#CAE5E6" />
    <path d="M636.079 293.938H628.375L624.522 287.058L628.375 280.29H636.079L639.821 287.058L636.079 293.938Z" fill="#CAE5E6" />
    <path d="M636.079 276.905H628.375L624.522 270.137L628.375 263.257H636.079L639.821 270.137L636.079 276.905Z" fill="#CAE5E6" />
    <path d="M636.079 259.874H628.375L624.522 253.106L628.375 246.339H636.079L639.821 253.106L636.079 259.874Z" fill="#CAE5E6" />
    <path d="M636.079 242.844H628.375L624.522 236.076L628.375 229.309H636.079L639.821 236.076L636.079 242.844Z" fill="#CAE5E6" />
    <path d="M621.662 234.382H613.958L610.216 227.614L613.958 220.847H621.662L625.514 227.614L621.662 234.382Z" fill="#CAE5E6" />
    <path d="M607.353 225.923H599.649L595.797 219.043L599.649 212.275H607.353L611.095 219.043L607.353 225.923Z" fill="#CAE5E6" />
    <path d="M592.935 217.351H585.231L581.489 210.583L585.231 203.816H592.935L596.787 210.583L592.935 217.351Z" fill="#CAE5E6" />
    <path d="M578.628 208.893H570.924L567.072 202.125L570.924 195.245H578.628L582.37 202.125L578.628 208.893Z" fill="#CAE5E6" />
    <path d="M564.212 200.431H556.508L552.766 193.551L556.508 186.783H564.212L568.064 193.551L564.212 200.431Z" fill="#CAE5E6" />
    <path d="M549.9 191.861H542.196L538.344 185.093L542.196 178.326H549.9L553.642 185.093L549.9 191.861Z" fill="#CAE5E6" />
    <path d="M535.485 183.4H527.781L524.039 176.632L527.781 169.752H535.485L539.337 176.632L535.485 183.4Z" fill="#CAE5E6" />
    <path d="M521.068 174.942H513.474L509.622 168.062L513.474 161.294H521.068L524.92 168.062L521.068 174.942Z" fill="#CAE5E6" />
    <path d="M506.76 166.37H499.055L495.313 159.603L499.055 152.835H506.76L510.612 159.603L506.76 166.37Z" fill="#CAE5E6" />
    <path d="M492.342 157.909H484.748L480.896 151.142L484.748 144.261H492.342L496.194 151.142L492.342 157.909Z" fill="#CAE5E6" />
    <path d="M478.035 149.45H470.331L466.589 142.569L470.331 135.802H478.035L481.887 142.569L478.035 149.45Z" fill="#CAE5E6" />
    <path d="M460.976 136.254H458.665L457.454 134.111L458.665 131.968H460.976L462.187 134.111L460.976 136.254Z" fill="#CAE5E6" />
    <path d="M331.99 60.1176H329.128L327.808 57.6362L329.128 55.1548H331.99L333.311 57.6362L331.99 60.1176Z" fill="#CAE5E6" />
    <path d="M318.779 53.6909H313.607L311.075 49.0664L313.607 44.5547H318.779L321.311 49.0664L318.779 53.6909Z" fill="#CAE5E6" />
    <path d="M303.704 43.9878H299.851L297.98 40.604L299.851 37.2202H303.704L305.685 40.604L303.704 43.9878Z" fill="#CAE5E6" />
    <path d="M288.291 33.5005H286.641L285.87 32.147L286.641 30.6807H288.291L289.062 32.147L288.291 33.5005Z" fill="#CAE5E6" />
    <path d="M147.53 106.704H140.156L136.414 100.162L140.156 93.5068H147.53L151.162 100.162L147.53 106.704Z" fill="#CAE5E6" />
    <path d="M132.011 113.13H126.948L124.417 108.619L126.948 104.107H132.011L134.542 108.619L132.011 113.13Z" fill="#CAE5E6" />
    <path d="M118.033 122.379H112.09L109.118 117.078L112.09 111.776H118.033L121.115 117.078L118.033 122.379Z" fill="#CAE5E6" />
    <path d="M104.497 132.419H96.9028L93.0508 125.651L96.9028 118.771H104.497L108.349 125.651L104.497 132.419Z" fill="#CAE5E6" />
    <path d="M90.1873 140.877H82.4832L78.7412 134.109L82.4832 127.342H90.1873L94.0394 134.109L90.1873 140.877Z" fill="#CAE5E6" />
    <path d="M75.7723 149.45H68.1782L64.3262 142.569L68.1782 135.802H75.7723L79.6243 142.569L75.7723 149.45Z" fill="#CAE5E6" />
    <path d="M61.4637 157.909H53.7596L50.0176 151.142L53.7596 144.261H61.4637L65.3157 151.142L61.4637 157.909Z" fill="#CAE5E6" />
    <path d="M47.0437 166.37H39.4497L35.5977 159.603L39.4497 152.835H47.0437L50.8958 159.603L47.0437 166.37Z" fill="#CAE5E6" />
    <path d="M32.7407 174.942H25.0366L21.1846 168.062L25.0366 161.294H32.7407L36.5928 168.062L32.7407 174.942Z" fill="#CAE5E6" />
    <path d="M17.6597 182.16H11.3863L8.30469 176.633L11.3863 171.106H17.6597L20.7413 176.633L17.6597 182.16Z" fill="#CAE5E6" />
    <path d="M4.01611 735.855H-3.68799L-7.54004 729.088L-3.68799 722.208H4.01611L7.86816 729.088L4.01611 735.855Z" fill="#CAE5E6" />
    <path d="M650.389 455.344H642.794L638.942 448.577L642.794 441.809H650.389L654.241 448.577L650.389 455.344Z" fill="#CAE5E6" />
    <path d="M650.387 438.424H642.792L638.94 431.543L642.792 424.776H650.387L654.239 431.543L650.387 438.424Z" fill="#CAE5E6" />
    <path d="M650.389 421.391H642.794L638.942 414.623L642.794 407.743H650.389L654.241 414.623L650.389 421.391Z" fill="#CAE5E6" />
    <path d="M650.387 404.36H642.792L638.94 397.593L642.792 390.825H650.387L654.239 397.593L650.387 404.36Z" fill="#CAE5E6" />
    <path d="M650.389 387.327H642.794L638.942 380.56L642.794 373.792H650.389L654.241 380.56L650.389 387.327Z" fill="#CAE5E6" />
    <path d="M650.389 370.41H642.794L638.942 363.642L642.794 356.762H650.389L654.241 363.642L650.389 370.41Z" fill="#CAE5E6" />
    <path d="M650.387 353.379H642.792L638.94 346.611L642.792 339.844H650.387L654.239 346.611L650.387 353.379Z" fill="#CAE5E6" />
    <path d="M650.389 336.349H642.794L638.942 329.581L642.794 322.813H650.389L654.241 329.581L650.389 336.349Z" fill="#CAE5E6" />
    <path d="M650.387 319.428H642.792L638.94 312.548L642.792 305.78H650.387L654.239 312.548L650.387 319.428Z" fill="#CAE5E6" />
    <path d="M650.389 302.395H642.794L638.942 295.628L642.794 288.748H650.389L654.241 295.628L650.389 302.395Z" fill="#CAE5E6" />
    <path d="M650.387 285.365H642.792L638.94 278.597L642.792 271.83H650.387L654.239 278.597L650.387 285.365Z" fill="#CAE5E6" />
    <path d="M650.389 268.445H642.794L638.942 261.565L642.794 254.797H650.389L654.241 261.565L650.389 268.445Z" fill="#CAE5E6" />
    <path d="M650.389 251.414H642.794L638.942 244.646L642.794 237.766H650.389L654.241 244.646L650.389 251.414Z" fill="#CAE5E6" />
    <path d="M650.387 234.382H642.792L638.94 227.614L642.792 220.847H650.387L654.239 227.614L650.387 234.382Z" fill="#CAE5E6" />
    <path d="M636.079 225.923H628.375L624.522 219.043L628.375 212.275H636.079L639.821 219.043L636.079 225.923Z" fill="#CAE5E6" />
    <path d="M621.662 217.351H613.958L610.216 210.583L613.958 203.816H621.662L625.514 210.583L621.662 217.351Z" fill="#CAE5E6" />
    <path d="M607.353 208.893H599.649L595.797 202.125L599.649 195.245H607.353L611.095 202.125L607.353 208.893Z" fill="#CAE5E6" />
    <path d="M592.935 200.431H585.231L581.489 193.551L585.231 186.783H592.935L596.787 193.551L592.935 200.431Z" fill="#CAE5E6" />
    <path d="M578.628 191.861H570.924L567.072 185.093L570.924 178.326H578.628L582.37 185.093L578.628 191.861Z" fill="#CAE5E6" />
    <path d="M564.214 183.4H556.51L552.768 176.632L556.51 169.752H564.214L568.066 176.632L564.214 183.4Z" fill="#CAE5E6" />
    <path d="M549.9 174.942H542.196L538.344 168.062L542.196 161.294H549.9L553.642 168.062L549.9 174.942Z" fill="#CAE5E6" />
    <path d="M535.485 166.37H527.781L524.039 159.603L527.781 152.835H535.485L539.337 159.603L535.485 166.37Z" fill="#CAE5E6" />
    <path d="M521.068 157.909H513.474L509.622 151.142L513.474 144.261H521.068L524.92 151.142L521.068 157.909Z" fill="#CAE5E6" />
    <path d="M506.76 149.45H499.055L495.313 142.569L499.055 135.802H506.76L510.612 142.569L506.76 149.45Z" fill="#CAE5E6" />
    <path d="M492.342 140.877H484.748L480.896 134.109L484.748 127.342H492.342L496.194 134.109L492.342 140.877Z" fill="#CAE5E6" />
    <path d="M475.174 127.455H473.193L472.202 125.65L473.193 123.845H475.174L476.274 125.65L475.174 127.455Z" fill="#CAE5E6" />
    <path d="M463.065 122.943H456.571L453.27 117.078L456.571 111.326H463.065L466.366 117.078L463.065 122.943Z" fill="#CAE5E6" />
    <path d="M446.665 110.651H444.244L443.144 108.621L444.244 106.478H446.665L447.766 108.621L446.665 110.651Z" fill="#CAE5E6" />
    <path d="M333.75 46.2465H327.367L324.175 40.6069L327.367 34.9673H333.75L336.942 40.6069L333.75 46.2465Z" fill="#CAE5E6" />
    <path d="M319.44 37.8964H312.946L309.645 32.144L312.946 26.3916H319.44L322.631 32.144L319.44 37.8964Z" fill="#CAE5E6" />
    <path d="M158.975 76.0234H157.324L156.554 74.5571L157.324 73.0908H158.975L159.855 74.5571L158.975 76.0234Z" fill="#CAE5E6" />
    <path d="M147.64 89.7826H140.046L136.194 83.1279L140.046 76.3604H147.64L151.382 83.1279L147.64 89.7826Z" fill="#CAE5E6" />
    <path d="M133.331 98.3559H125.626L121.774 91.5883L125.626 84.8208H133.331L137.073 91.5883L133.331 98.3559Z" fill="#CAE5E6" />
    <path d="M118.912 106.926H111.208L107.466 100.159L111.208 93.2783H118.912L122.764 100.159L118.912 106.926Z" fill="#CAE5E6" />
    <path d="M104.497 115.386H96.9028L93.0508 108.619L96.9028 101.851H104.497L108.349 108.619L104.497 115.386Z" fill="#CAE5E6" />
    <path d="M90.1873 123.957H82.4832L78.7412 117.077L82.4832 110.309H90.1873L94.0394 117.077L90.1873 123.957Z" fill="#CAE5E6" />
    <path d="M75.7723 132.419H68.1782L64.3262 125.651L68.1782 118.771H75.7723L79.6243 125.651L75.7723 132.419Z" fill="#CAE5E6" />
    <path d="M61.4637 140.877H53.7596L50.0176 134.109L53.7596 127.342H61.4637L65.3157 134.109L61.4637 140.877Z" fill="#CAE5E6" />
    <path d="M47.0437 149.45H39.4497L35.5977 142.569L39.4497 135.802H47.0437L50.8958 142.569L47.0437 149.45Z" fill="#CAE5E6" />
    <path d="M32.7407 157.909H25.0366L21.1846 151.142L25.0366 144.261H32.7407L36.5928 151.142L32.7407 157.909Z" fill="#CAE5E6" />
    <path d="M18.3172 166.367H10.7231L6.87109 159.6L10.7231 152.832H18.3172L22.1693 159.6L18.3172 166.367Z" fill="#CAE5E6" />
    <path d="M1.80811 748.939H-1.49365L-3.14453 746.006L-1.49365 743.074H1.80811L3.45899 746.006L1.80811 748.939Z" fill="#CAE5E6" />
    <path d="M663.261 461.321H658.529L656.218 457.035L658.529 452.861H663.261L665.683 457.035L663.261 461.321Z" fill="#CAE5E6" />
    <path d="M664.803 446.883H657.099L653.247 440.116L657.099 433.235H664.803L668.545 440.116L664.803 446.883Z" fill="#CAE5E6" />
    <path d="M664.803 429.851H657.099L653.247 423.083L657.099 416.315H664.803L668.545 423.083L664.803 429.851Z" fill="#CAE5E6" />
    <path d="M664.803 412.933H657.099L653.247 406.053L657.099 399.285H664.803L668.545 406.053L664.803 412.933Z" fill="#CAE5E6" />
    <path d="M664.803 395.9H657.099L653.247 389.132L657.099 382.252H664.803L668.545 389.132L664.803 395.9Z" fill="#CAE5E6" />
    <path d="M664.803 378.87H657.099L653.247 372.102L657.099 365.334H664.803L668.545 372.102L664.803 378.87Z" fill="#CAE5E6" />
    <path d="M664.803 361.836H657.099L653.247 355.069L657.099 348.301H664.803L668.545 355.069L664.803 361.836Z" fill="#CAE5E6" />
    <path d="M664.803 344.919H657.099L653.247 338.039L657.099 331.271H664.803L668.545 338.039L664.803 344.919Z" fill="#CAE5E6" />
    <path d="M664.803 327.888H657.099L653.247 321.121L657.099 314.24H664.803L668.545 321.121L664.803 327.888Z" fill="#CAE5E6" />
    <path d="M664.803 310.855H657.099L653.247 304.088L657.099 297.32H664.803L668.545 304.088L664.803 310.855Z" fill="#CAE5E6" />
    <path d="M664.803 293.938H657.099L653.247 287.058L657.099 280.29H664.803L668.545 287.058L664.803 293.938Z" fill="#CAE5E6" />
    <path d="M664.803 276.905H657.099L653.247 270.137L657.099 263.257H664.803L668.545 270.137L664.803 276.905Z" fill="#CAE5E6" />
    <path d="M664.803 259.874H657.099L653.247 253.106L657.099 246.339H664.803L668.545 253.106L664.803 259.874Z" fill="#CAE5E6" />
    <path d="M664.803 242.842H657.099L653.247 236.074L657.099 229.307H664.803L668.545 236.074L664.803 242.842Z" fill="#CAE5E6" />
    <path d="M664.803 225.923H657.099L653.247 219.043L657.099 212.275H664.803L668.545 219.043L664.803 225.923Z" fill="#CAE5E6" />
    <path d="M650.389 217.351H642.794L638.942 210.583L642.794 203.816H650.389L654.241 210.583L650.389 217.351Z" fill="#CAE5E6" />
    <path d="M636.079 208.893H628.375L624.522 202.125L628.375 195.245H636.079L639.821 202.125L636.079 208.893Z" fill="#CAE5E6" />
    <path d="M621.662 200.431H613.958L610.216 193.551L613.958 186.783H621.662L625.514 193.551L621.662 200.431Z" fill="#CAE5E6" />
    <path d="M607.353 191.861H599.649L595.797 185.093L599.649 178.326H607.353L611.095 185.093L607.353 191.861Z" fill="#CAE5E6" />
    <path d="M592.935 183.4H585.231L581.489 176.632L585.231 169.752H592.935L596.787 176.632L592.935 183.4Z" fill="#CAE5E6" />
    <path d="M578.628 174.942H570.924L567.072 168.062L570.924 161.294H578.628L582.37 168.062L578.628 174.942Z" fill="#CAE5E6" />
    <path d="M564.212 166.367H556.508L552.766 159.6L556.508 152.832H564.212L568.064 159.6L564.212 166.367Z" fill="#CAE5E6" />
    <path d="M549.9 157.909H542.196L538.344 151.142L542.196 144.261H549.9L553.642 151.142L549.9 157.909Z" fill="#CAE5E6" />
    <path d="M535.485 149.45H527.781L524.039 142.569L527.781 135.802H535.485L539.337 142.569L535.485 149.45Z" fill="#CAE5E6" />
    <path d="M521.068 140.877H513.474L509.622 134.109L513.474 127.342H521.068L524.92 134.109L521.068 140.877Z" fill="#CAE5E6" />
    <path d="M504.67 128.695H501.148L499.387 125.65L501.148 122.492H504.67L506.43 125.65L504.67 128.695Z" fill="#CAE5E6" />
    <path d="M462.407 104.56H457.344L454.703 100.161L457.344 95.6494H462.407L464.939 100.161L462.407 104.56Z" fill="#CAE5E6" />
    <path d="M360.162 42.1831H358.401L357.521 40.604L358.401 39.0249H360.162L361.042 40.604L360.162 42.1831Z" fill="#CAE5E6" />
    <path d="M348.277 38.1254H341.563L338.151 32.1474L341.563 26.0566H348.277L351.689 32.1474L348.277 38.1254Z" fill="#CAE5E6" />
    <path d="M331.44 25.1548H329.679L328.799 23.5757L329.679 21.9966H331.44L332.321 23.5757L331.44 25.1548Z" fill="#CAE5E6" />
    <path d="M159.304 59.6679H156.993L155.893 57.6377L156.993 55.6074H159.304L160.515 57.6377L159.304 59.6679Z" fill="#CAE5E6" />
    <path d="M147.64 72.8632H139.936L136.194 66.0957L139.936 59.3281H147.64L151.493 66.0957L147.64 72.8632Z" fill="#CAE5E6" />
    <path d="M133.331 81.4355H125.626L121.774 74.5551L125.626 67.7876H133.331L137.073 74.5551L133.331 81.4355Z" fill="#CAE5E6" />
    <path d="M118.914 89.8935H111.21L107.468 83.1259L111.21 76.3584H118.914L122.766 83.1259L118.914 89.8935Z" fill="#CAE5E6" />
    <path d="M104.497 98.3559H96.9028L93.0508 91.5883L96.9028 84.8208H104.497L108.349 91.5883L104.497 98.3559Z" fill="#CAE5E6" />
    <path d="M90.1873 106.926H82.4832L78.7412 100.159L82.4832 93.2783H90.1873L94.0394 100.159L90.1873 106.926Z" fill="#CAE5E6" />
    <path d="M75.7723 115.386H68.1782L64.3262 108.619L68.1782 101.851H75.7723L79.6243 108.619L75.7723 115.386Z" fill="#CAE5E6" />
    <path d="M61.4637 123.957H53.7596L50.0176 117.077L53.7596 110.309H61.4637L65.3157 117.077L61.4637 123.957Z" fill="#CAE5E6" />
    <path d="M47.0437 132.419H39.4497L35.5977 125.651L39.4497 118.771H47.0437L50.8958 125.651L47.0437 132.419Z" fill="#CAE5E6" />
    <path d="M32.7407 140.877H25.0366L21.1846 134.109L25.0366 127.342H32.7407L36.5928 134.109L32.7407 140.877Z" fill="#CAE5E6" />
    <path d="M16.8892 146.856H12.1567L9.73535 142.57L12.1567 138.397H16.8892L19.3105 142.57L16.8892 146.856Z" fill="#CAE5E6" />
    <path d="M678.449 488.279H672.066L668.874 482.526L672.066 476.887H678.449L681.641 482.526L678.449 488.279Z" fill="#CAE5E6" />
    <path d="M679.113 472.374H671.519L667.667 465.606L671.519 458.726H679.113L682.965 465.606L679.113 472.374Z" fill="#CAE5E6" />
    <path d="M679.113 455.344H671.519L667.667 448.577L671.519 441.809H679.113L682.965 448.577L679.113 455.344Z" fill="#CAE5E6" />
    <path d="M679.113 438.424H671.519L667.667 431.543L671.519 424.776H679.113L682.965 431.543L679.113 438.424Z" fill="#CAE5E6" />
    <path d="M679.113 421.391H671.519L667.667 414.623L671.519 407.743H679.113L682.965 414.623L679.113 421.391Z" fill="#CAE5E6" />
    <path d="M679.113 404.36H671.519L667.667 397.593L671.519 390.825H679.113L682.965 397.593L679.113 404.36Z" fill="#CAE5E6" />
    <path d="M679.113 387.327H671.519L667.667 380.56L671.519 373.792H679.113L682.965 380.56L679.113 387.327Z" fill="#CAE5E6" />
    <path d="M679.113 370.41H671.519L667.667 363.642L671.519 356.762H679.113L682.965 363.642L679.113 370.41Z" fill="#CAE5E6" />
    <path d="M679.113 353.379H671.519L667.667 346.611L671.519 339.844H679.113L682.965 346.611L679.113 353.379Z" fill="#CAE5E6" />
    <path d="M679.113 336.349H671.519L667.667 329.581L671.519 322.813H679.113L682.965 329.581L679.113 336.349Z" fill="#CAE5E6" />
    <path d="M679.113 319.428H671.519L667.667 312.548L671.519 305.78H679.113L682.965 312.548L679.113 319.428Z" fill="#CAE5E6" />
    <path d="M679.113 302.395H671.519L667.667 295.628L671.519 288.748H679.113L682.965 295.628L679.113 302.395Z" fill="#CAE5E6" />
    <path d="M679.113 285.365H671.519L667.667 278.597L671.519 271.83H679.113L682.965 278.597L679.113 285.365Z" fill="#CAE5E6" />
    <path d="M679.113 268.445H671.519L667.667 261.565L671.519 254.797H679.113L682.965 261.565L679.113 268.445Z" fill="#CAE5E6" />
    <path d="M679.113 251.414H671.519L667.667 244.646L671.519 237.766H679.113L682.965 244.646L679.113 251.414Z" fill="#CAE5E6" />
    <path d="M679.113 234.382H671.519L667.667 227.614L671.519 220.847H679.113L682.965 227.614L679.113 234.382Z" fill="#CAE5E6" />
    <path d="M679.113 217.351H671.519L667.667 210.583L671.519 203.816H679.113L682.965 210.583L679.113 217.351Z" fill="#CAE5E6" />
    <path d="M664.803 208.893H657.099L653.247 202.125L657.099 195.245H664.803L668.545 202.125L664.803 208.893Z" fill="#CAE5E6" />
    <path d="M650.387 200.431H642.792L638.94 193.551L642.792 186.783H650.387L654.239 193.551L650.387 200.431Z" fill="#CAE5E6" />
    <path d="M636.079 191.861H628.375L624.522 185.093L628.375 178.326H636.079L639.821 185.093L636.079 191.861Z" fill="#CAE5E6" />
    <path d="M621.662 183.4H613.958L610.216 176.632L613.958 169.752H621.662L625.514 176.632L621.662 183.4Z" fill="#CAE5E6" />
    <path d="M607.353 174.942H599.649L595.797 168.062L599.649 161.294H607.353L611.095 168.062L607.353 174.942Z" fill="#CAE5E6" />
    <path d="M592.935 166.37H585.231L581.489 159.603L585.231 152.835H592.935L596.787 159.603L592.935 166.37Z" fill="#CAE5E6" />
    <path d="M578.628 157.909H570.924L567.072 151.142L570.924 144.261H578.628L582.37 151.142L578.628 157.909Z" fill="#CAE5E6" />
    <path d="M564.214 149.45H556.51L552.768 142.569L556.51 135.802H564.214L568.066 142.569L564.214 149.45Z" fill="#CAE5E6" />
    <path d="M549.681 140.54H542.417L538.785 134.111L542.417 127.682H549.681L553.203 134.111L549.681 140.54Z" fill="#CAE5E6" />
    <path d="M535.375 132.195H528.001L524.259 125.653L528.001 119.111H535.375L539.007 125.653L535.375 132.195Z" fill="#CAE5E6" />
    <path d="M520.74 123.283H513.806L510.395 117.079L513.806 110.988H520.74L524.262 117.079L520.74 123.283Z" fill="#CAE5E6" />
    <path d="M477.485 97.3398H470.991L467.689 91.5874L470.991 85.835H477.485L480.676 91.5874L477.485 97.3398Z" fill="#CAE5E6" />
    <path d="M460.426 84.2549H459.215L458.555 83.1269L459.215 81.999H460.426L461.086 83.1269L460.426 84.2549Z" fill="#CAE5E6" />
    <path d="M176.145 38.5771H168.881L165.249 32.1479L168.881 25.606H176.145L179.887 32.1479L176.145 38.5771Z" fill="#CAE5E6" />
    <path d="M161.726 47.0331H154.572L150.94 40.604L154.572 34.1748H161.726L165.358 40.604L161.726 47.0331Z" fill="#CAE5E6" />
    <path d="M147.64 55.9447H139.936L136.194 49.0644L139.936 42.2969H147.64L151.493 49.0644L147.64 55.9447Z" fill="#CAE5E6" />
    <path d="M133.331 64.4052H125.626L121.774 57.6377L125.626 50.7573H133.331L137.073 57.6377L133.331 64.4052Z" fill="#CAE5E6" />
    <path d="M118.914 72.8632H111.21L107.468 66.0957L111.21 59.3281H118.914L122.766 66.0957L118.914 72.8632Z" fill="#CAE5E6" />
    <path d="M104.497 81.4355H96.9028L93.0508 74.5551L96.9028 67.7876H104.497L108.349 74.5551L104.497 81.4355Z" fill="#CAE5E6" />
    <path d="M90.1873 89.8935H82.4832L78.7412 83.1259L82.4832 76.3584H90.1873L94.0394 83.1259L90.1873 89.8935Z" fill="#CAE5E6" />
    <path d="M75.7723 98.3559H68.1782L64.3262 91.5883L68.1782 84.8208H75.7723L79.6243 91.5883L75.7723 98.3559Z" fill="#CAE5E6" />
    <path d="M61.4637 106.926H53.7596L50.0176 100.159L53.7596 93.2783H61.4637L65.3157 100.159L61.4637 106.926Z" fill="#CAE5E6" />
    <path d="M47.0437 115.386H39.4497L35.5977 108.619L39.4497 101.851H47.0437L50.8958 108.619L47.0437 115.386Z" fill="#CAE5E6" />
    <path d="M32.7407 123.957H25.0366L21.1846 117.077L25.0366 110.309H32.7407L36.5928 117.077L32.7407 123.957Z" fill="#CAE5E6" />
    <path d="M17.991 131.743H11.0573L7.64551 125.652L11.0573 119.448H17.991L21.4028 125.652L17.991 131.743Z" fill="#CAE5E6" />
    <path d="M692.428 563.962H686.925L684.174 559.112L686.925 554.262H692.428L695.18 559.112L692.428 563.962Z" fill="#CAE5E6" />
    <path d="M691.988 546.143H687.366L685.055 542.082L687.366 538.021H691.988L694.19 542.082L691.988 546.143Z" fill="#CAE5E6" />
    <path d="M692.871 496.736H686.488L683.296 491.097L686.488 485.344H692.871L696.063 491.097L692.871 496.736Z" fill="#CAE5E6" />
    <path d="M693.527 480.834H685.823L681.971 474.066L685.823 467.299H693.527L697.269 474.066L693.527 480.834Z" fill="#CAE5E6" />
    <path d="M693.527 463.914H685.823L681.971 457.034L685.823 450.267H693.527L697.269 457.034L693.527 463.914Z" fill="#CAE5E6" />
    <path d="M693.527 446.883H685.823L681.971 440.116L685.823 433.235H693.527L697.269 440.116L693.527 446.883Z" fill="#CAE5E6" />
    <path d="M693.527 429.851H685.823L681.971 423.083L685.823 416.315H693.527L697.269 423.083L693.527 429.851Z" fill="#CAE5E6" />
    <path d="M693.527 412.933H685.823L681.971 406.053L685.823 399.285H693.527L697.269 406.053L693.527 412.933Z" fill="#CAE5E6" />
    <path d="M693.527 395.9H685.823L681.971 389.132L685.823 382.252H693.527L697.269 389.132L693.527 395.9Z" fill="#CAE5E6" />
    <path d="M693.527 378.87H685.823L681.971 372.102L685.823 365.334H693.527L697.269 372.102L693.527 378.87Z" fill="#CAE5E6" />
    <path d="M693.527 361.839H685.823L681.971 355.072L685.823 348.304H693.527L697.269 355.072L693.527 361.839Z" fill="#CAE5E6" />
    <path d="M693.527 344.919H685.823L681.971 338.039L685.823 331.271H693.527L697.269 338.039L693.527 344.919Z" fill="#CAE5E6" />
    <path d="M693.527 327.888H685.823L681.971 321.121L685.823 314.24H693.527L697.269 321.121L693.527 327.888Z" fill="#CAE5E6" />
    <path d="M693.527 310.855H685.823L681.971 304.088L685.823 297.32H693.527L697.269 304.088L693.527 310.855Z" fill="#CAE5E6" />
    <path d="M693.527 293.938H685.823L681.971 287.058L685.823 280.29H693.527L697.269 287.058L693.527 293.938Z" fill="#CAE5E6" />
    <path d="M693.527 276.905H685.823L681.971 270.137L685.823 263.257H693.527L697.269 270.137L693.527 276.905Z" fill="#CAE5E6" />
    <path d="M693.527 259.874H685.823L681.971 253.106L685.823 246.339H693.527L697.269 253.106L693.527 259.874Z" fill="#CAE5E6" />
    <path d="M693.527 242.844H685.823L681.971 236.076L685.823 229.309H693.527L697.269 236.076L693.527 242.844Z" fill="#CAE5E6" />
    <path d="M693.527 225.923H685.823L681.971 219.043L685.823 212.275H693.527L697.269 219.043L693.527 225.923Z" fill="#CAE5E6" />
    <path d="M693.527 208.893H685.823L681.971 202.125L685.823 195.245H693.527L697.269 202.125L693.527 208.893Z" fill="#CAE5E6" />
    <path d="M679.113 200.431H671.519L667.667 193.551L671.519 186.783H679.113L682.965 193.551L679.113 200.431Z" fill="#CAE5E6" />
    <path d="M664.803 191.861H657.099L653.247 185.093L657.099 178.326H664.803L668.545 185.093L664.803 191.861Z" fill="#CAE5E6" />
    <path d="M650.389 183.4H642.794L638.942 176.632L642.794 169.752H650.389L654.241 176.632L650.389 183.4Z" fill="#CAE5E6" />
    <path d="M636.079 174.942H628.375L624.522 168.062L628.375 161.294H636.079L639.821 168.062L636.079 174.942Z" fill="#CAE5E6" />
    <path d="M621.662 166.37H613.958L610.216 159.603L613.958 152.835H621.662L625.514 159.603L621.662 166.37Z" fill="#CAE5E6" />
    <path d="M607.353 157.909H599.649L595.797 151.142L599.649 144.261H607.353L611.095 151.142L607.353 157.909Z" fill="#CAE5E6" />
    <path d="M592.935 149.45H585.231L581.489 142.569L585.231 135.802H592.935L596.787 142.569L592.935 149.45Z" fill="#CAE5E6" />
    <path d="M578.628 140.877H570.924L567.072 134.109L570.924 127.342H578.628L582.37 134.109L578.628 140.877Z" fill="#CAE5E6" />
    <path d="M564.214 132.419H556.51L552.768 125.651L556.51 118.771H564.214L568.066 125.651L564.214 132.419Z" fill="#CAE5E6" />
    <path d="M548.139 120.802H543.956L541.865 117.08L543.956 113.47H548.139L550.12 117.08L548.139 120.802Z" fill="#CAE5E6" />
    <path d="M532.622 110.197H530.751L529.87 108.618L530.751 106.926H532.622L533.502 108.618L532.622 110.197Z" fill="#CAE5E6" />
    <path d="M491.241 87.7514H485.958L483.316 83.1269L485.958 78.3896H491.241L493.772 83.1269L491.241 87.7514Z" fill="#CAE5E6" />
    <path d="M160.734 28.1982H155.672L153.03 23.5737L155.672 19.062H160.734L163.266 23.5737L160.734 28.1982Z" fill="#CAE5E6" />
    <path d="M147.64 38.9145H139.936L136.194 32.1469L139.936 25.2666H147.64L151.493 32.1469L147.64 38.9145Z" fill="#CAE5E6" />
    <path d="M133.331 47.3725H125.626L121.774 40.6049L125.626 33.8374H133.331L137.073 40.6049L133.331 47.3725Z" fill="#CAE5E6" />
    <path d="M118.912 55.9447H111.208L107.466 49.0644L111.208 42.2969H118.912L122.764 49.0644L118.912 55.9447Z" fill="#CAE5E6" />
    <path d="M104.497 64.4052H96.9028L93.0508 57.6377L96.9028 50.7573H104.497L108.349 57.6377L104.497 64.4052Z" fill="#CAE5E6" />
    <path d="M90.1873 72.8632H82.4832L78.7412 66.0957L82.4832 59.3281H90.1873L94.0394 66.0957L90.1873 72.8632Z" fill="#CAE5E6" />
    <path d="M75.7723 81.4355H68.1782L64.3262 74.5551L68.1782 67.7876H75.7723L79.6243 74.5551L75.7723 81.4355Z" fill="#CAE5E6" />
    <path d="M61.4637 89.8954H53.7596L50.0176 83.1279L53.7596 76.3604H61.4637L65.3157 83.1279L61.4637 89.8954Z" fill="#CAE5E6" />
    <path d="M47.0437 98.3559H39.4497L35.5977 91.5883L39.4497 84.8208H47.0437L50.8958 91.5883L47.0437 98.3559Z" fill="#CAE5E6" />
    <path d="M32.7407 106.926H25.0366L21.1846 100.159L25.0366 93.2783H32.7407L36.5928 100.159L32.7407 106.926Z" fill="#CAE5E6" />
    <path d="M18.3185 115.273H10.7244L6.98242 108.619L10.7244 101.851H18.3185L22.0605 108.619L18.3185 115.273Z" fill="#CAE5E6" />
    <path d="M1.04208 118.545H-0.718868L-1.48926 117.079L-0.718868 115.612H1.04208L1.8125 117.079L1.04208 118.545Z" fill="#CAE5E6" />
    <path d="M706.515 589H701.563L699.031 584.602L701.563 580.09H706.515L709.047 584.602L706.515 589Z" fill="#CAE5E6" />
    <path d="M707.618 573.999H700.464L696.832 567.57L700.464 561.141H707.618L711.25 567.57L707.618 573.999Z" fill="#CAE5E6" />
    <path d="M705.194 552.684H702.882L701.672 550.541L702.882 548.511H705.194L706.404 550.541L705.194 552.684Z" fill="#CAE5E6" />
    <path d="M707.728 540.051H700.354L696.722 533.622L700.354 527.08H707.728L711.36 533.622L707.728 540.051Z" fill="#CAE5E6" />
    <path d="M705.194 518.618H702.882L701.672 516.588L702.882 514.558H705.194L706.294 516.588L705.194 518.618Z" fill="#CAE5E6" />
    <path d="M707.618 505.988H700.354L696.832 499.559L700.354 493.129H707.618L711.25 499.559L707.618 505.988Z" fill="#CAE5E6" />
    <path d="M707.837 489.407H700.243L696.391 482.526L700.243 475.759H707.837L711.689 482.526L707.837 489.407Z" fill="#CAE5E6" />
    <path d="M707.837 472.374H700.243L696.391 465.606L700.243 458.726H707.837L711.689 465.606L707.837 472.374Z" fill="#CAE5E6" />
    <path d="M707.837 455.344H700.243L696.391 448.577L700.243 441.809H707.837L711.689 448.577L707.837 455.344Z" fill="#CAE5E6" />
    <path d="M707.837 438.426H700.243L696.391 431.545L700.243 424.778H707.837L711.689 431.545L707.837 438.426Z" fill="#CAE5E6" />
    <path d="M707.837 421.391H700.243L696.391 414.623L700.243 407.743H707.837L711.689 414.623L707.837 421.391Z" fill="#CAE5E6" />
    <path d="M707.837 404.36H700.243L696.391 397.593L700.243 390.825H707.837L711.689 397.593L707.837 404.36Z" fill="#CAE5E6" />
    <path d="M707.837 387.327H700.243L696.391 380.56L700.243 373.792H707.837L711.689 380.56L707.837 387.327Z" fill="#CAE5E6" />
    <path d="M707.837 370.41H700.243L696.391 363.642L700.243 356.762H707.837L711.689 363.642L707.837 370.41Z" fill="#CAE5E6" />
    <path d="M707.837 353.379H700.243L696.391 346.611L700.243 339.844H707.837L711.689 346.611L707.837 353.379Z" fill="#CAE5E6" />
    <path d="M707.837 336.349H700.243L696.391 329.581L700.243 322.813H707.837L711.689 329.581L707.837 336.349Z" fill="#CAE5E6" />
    <path d="M707.837 319.431H700.243L696.391 312.55L700.243 305.783H707.837L711.689 312.55L707.837 319.431Z" fill="#CAE5E6" />
    <path d="M707.837 302.395H700.243L696.391 295.628L700.243 288.748H707.837L711.689 295.628L707.837 302.395Z" fill="#CAE5E6" />
    <path d="M707.837 285.365H700.243L696.391 278.597L700.243 271.83H707.837L711.689 278.597L707.837 285.365Z" fill="#CAE5E6" />
    <path d="M707.837 268.445H700.243L696.391 261.565L700.243 254.797H707.837L711.689 261.565L707.837 268.445Z" fill="#CAE5E6" />
    <path d="M707.837 251.414H700.243L696.391 244.646L700.243 237.766H707.837L711.689 244.646L707.837 251.414Z" fill="#CAE5E6" />
    <path d="M707.837 234.382H700.243L696.391 227.614L700.243 220.847H707.837L711.689 227.614L707.837 234.382Z" fill="#CAE5E6" />
    <path d="M707.837 217.351H700.243L696.391 210.583L700.243 203.816H707.837L711.689 210.583L707.837 217.351Z" fill="#CAE5E6" />
    <path d="M707.837 200.431H700.243L696.391 193.551L700.243 186.783H707.837L711.689 193.551L707.837 200.431Z" fill="#CAE5E6" />
    <path d="M693.527 191.861H685.823L681.971 185.093L685.823 178.326H693.527L697.269 185.093L693.527 191.861Z" fill="#CAE5E6" />
    <path d="M679.113 183.4H671.519L667.667 176.632L671.519 169.752H679.113L682.965 176.632L679.113 183.4Z" fill="#CAE5E6" />
    <path d="M664.803 174.942H657.099L653.247 168.062L657.099 161.294H664.803L668.545 168.062L664.803 174.942Z" fill="#CAE5E6" />
    <path d="M650.387 166.367H642.792L638.94 159.6L642.792 152.832H650.387L654.239 159.6L650.387 166.367Z" fill="#CAE5E6" />
    <path d="M636.079 157.909H628.375L624.522 151.142L628.375 144.261H636.079L639.821 151.142L636.079 157.909Z" fill="#CAE5E6" />
    <path d="M621.662 149.45H613.958L610.216 142.569L613.958 135.802H621.662L625.514 142.569L621.662 149.45Z" fill="#CAE5E6" />
    <path d="M607.353 140.877H599.649L595.797 134.109L599.649 127.342H607.353L611.095 134.109L607.353 140.877Z" fill="#CAE5E6" />
    <path d="M592.935 132.419H585.231L581.489 125.651L585.231 118.771H592.935L596.787 125.651L592.935 132.419Z" fill="#CAE5E6" />
    <path d="M578.408 123.732H571.034L567.292 117.077L571.034 110.535H578.408L582.15 117.077L578.408 123.732Z" fill="#CAE5E6" />
    <path d="M562.231 111.777H558.599L556.729 108.619L558.599 105.46H562.231L563.992 108.619L562.231 111.777Z" fill="#CAE5E6" />
    <path d="M506.32 80.5322H499.606L496.194 74.5542L499.606 68.689H506.32L509.621 74.5542L506.32 80.5322Z" fill="#CAE5E6" />
    <path d="M160.738 11.2797H155.565L152.924 6.65525L155.565 1.91797H160.738L163.379 6.65525L160.738 11.2797Z" fill="#CAE5E6" />
    <path d="M147.309 21.3154H140.375L136.854 15.1118L140.375 8.9082H147.309L150.721 15.1118L147.309 21.3154Z" fill="#CAE5E6" />
    <path d="M131.133 26.6201H127.721L126.07 23.5747L127.721 20.5293H131.133L132.894 23.5747L131.133 26.6201Z" fill="#CAE5E6" />
    <path d="M118.914 38.9115H111.21L107.468 32.144L111.21 25.2637H118.914L122.766 32.144L118.914 38.9115Z" fill="#CAE5E6" />
    <path d="M104.497 47.3725H96.9028L93.0508 40.6049L96.9028 33.8374H104.497L108.349 40.6049L104.497 47.3725Z" fill="#CAE5E6" />
    <path d="M90.1873 55.9447H82.4832L78.7412 49.0644L82.4832 42.2969H90.1873L94.0394 49.0644L90.1873 55.9447Z" fill="#CAE5E6" />
    <path d="M75.7723 64.4052H68.1782L64.3262 57.6377L68.1782 50.7573H75.7723L79.6243 57.6377L75.7723 64.4052Z" fill="#CAE5E6" />
    <path d="M61.4637 72.8632H53.7596L50.0176 66.0957L53.7596 59.3281H61.4637L65.3157 66.0957L61.4637 72.8632Z" fill="#CAE5E6" />
    <path d="M47.0437 81.4355H39.4497L35.5977 74.5551L39.4497 67.7876H47.0437L50.8958 74.5551L47.0437 81.4355Z" fill="#CAE5E6" />
    <path d="M32.7407 89.8935H25.0366L21.1846 83.1259L25.0366 76.3584H32.7407L36.5928 83.1259L32.7407 89.8935Z" fill="#CAE5E6" />
    <path d="M18.3172 98.3559H10.7231L6.87109 91.5883L10.7231 84.8208H18.3172L22.1693 91.5883L18.3172 98.3559Z" fill="#CAE5E6" />
    <path d="M1.59208 102.643H-1.26945L-2.7002 100.161L-1.26945 97.5669H1.59208L3.02283 100.161L1.59208 102.643Z" fill="#CAE5E6" />
    <path d="M722.033 599.491H714.769L711.137 593.062L714.769 586.632H722.033L725.555 593.062L722.033 599.491Z" fill="#CAE5E6" />
    <path d="M721.922 582.348H714.878L711.356 576.031L714.878 569.828H721.922L725.444 576.031L721.922 582.348Z" fill="#CAE5E6" />
    <path d="M720.052 561.932H716.75L715.1 559.112L716.75 556.18H720.052L721.703 559.112L720.052 561.932Z" fill="#CAE5E6" />
    <path d="M720.273 545.464H716.531L714.66 542.081L716.531 538.697H720.273L722.144 542.081L720.273 545.464Z" fill="#CAE5E6" />
    <path d="M722.253 514.895H714.549L710.697 508.128L714.549 501.248H722.253L726.105 508.128L722.253 514.895Z" fill="#CAE5E6" />
    <path d="M722.253 497.865H714.549L710.697 491.098L714.549 484.33H722.253L726.105 491.098L722.253 497.865Z" fill="#CAE5E6" />
    <path d="M722.253 480.832H714.549L710.697 474.064L714.549 467.297H722.253L726.105 474.064L722.253 480.832Z" fill="#CAE5E6" />
    <path d="M722.253 463.914H714.549L710.697 457.034L714.549 450.267H722.253L726.105 457.034L722.253 463.914Z" fill="#CAE5E6" />
    <path d="M722.253 446.883H714.549L710.697 440.116L714.549 433.235H722.253L726.105 440.116L722.253 446.883Z" fill="#CAE5E6" />
    <path d="M722.253 429.851H714.549L710.697 423.083L714.549 416.315H722.253L726.105 423.083L722.253 429.851Z" fill="#CAE5E6" />
    <path d="M722.253 412.933H714.549L710.697 406.053L714.549 399.285H722.253L726.105 406.053L722.253 412.933Z" fill="#CAE5E6" />
    <path d="M722.253 395.9H714.549L710.697 389.132L714.549 382.252H722.253L726.105 389.132L722.253 395.9Z" fill="#CAE5E6" />
    <path d="M722.253 378.87H714.549L710.697 372.102L714.549 365.334H722.253L726.105 372.102L722.253 378.87Z" fill="#CAE5E6" />
    <path d="M722.253 361.836H714.549L710.697 355.069L714.549 348.301H722.253L726.105 355.069L722.253 361.836Z" fill="#CAE5E6" />
    <path d="M722.253 344.919H714.549L710.697 338.039L714.549 331.271H722.253L726.105 338.039L722.253 344.919Z" fill="#CAE5E6" />
    <path d="M722.253 327.888H714.549L710.697 321.121L714.549 314.24H722.253L726.105 321.121L722.253 327.888Z" fill="#CAE5E6" />
    <path d="M722.253 310.855H714.549L710.697 304.088L714.549 297.32H722.253L726.105 304.088L722.253 310.855Z" fill="#CAE5E6" />
    <path d="M722.253 293.938H714.549L710.697 287.058L714.549 280.29H722.253L726.105 287.058L722.253 293.938Z" fill="#CAE5E6" />
    <path d="M722.253 276.905H714.549L710.697 270.137L714.549 263.257H722.253L726.105 270.137L722.253 276.905Z" fill="#CAE5E6" />
    <path d="M722.253 259.874H714.549L710.697 253.106L714.549 246.339H722.253L726.105 253.106L722.253 259.874Z" fill="#CAE5E6" />
    <path d="M722.253 242.842H714.549L710.697 236.074L714.549 229.307H722.253L726.105 236.074L722.253 242.842Z" fill="#CAE5E6" />
    <path d="M722.253 225.923H714.549L710.697 219.043L714.549 212.275H722.253L726.105 219.043L722.253 225.923Z" fill="#CAE5E6" />
    <path d="M722.253 208.893H714.549L710.697 202.125L714.549 195.245H722.253L726.105 202.125L722.253 208.893Z" fill="#CAE5E6" />
    <path d="M722.253 191.861H714.549L710.697 185.093L714.549 178.326H722.253L726.105 185.093L722.253 191.861Z" fill="#CAE5E6" />
    <path d="M707.837 183.4H700.243L696.391 176.632L700.243 169.752H707.837L711.689 176.632L707.837 183.4Z" fill="#CAE5E6" />
    <path d="M693.527 174.942H685.823L681.971 168.062L685.823 161.294H693.527L697.269 168.062L693.527 174.942Z" fill="#CAE5E6" />
    <path d="M679.113 166.367H671.519L667.667 159.6L671.519 152.832H679.113L682.965 159.6L679.113 166.367Z" fill="#CAE5E6" />
    <path d="M664.803 157.909H657.099L653.247 151.142L657.099 144.261H664.803L668.545 151.142L664.803 157.909Z" fill="#CAE5E6" />
    <path d="M650.389 149.45H642.794L638.942 142.569L642.794 135.802H650.389L654.241 142.569L650.389 149.45Z" fill="#CAE5E6" />
    <path d="M636.079 140.877H628.375L624.522 134.109L628.375 127.342H636.079L639.821 134.109L636.079 140.877Z" fill="#CAE5E6" />
    <path d="M621.662 132.419H613.958L610.216 125.651L613.958 118.771H621.662L625.514 125.651L621.662 132.419Z" fill="#CAE5E6" />
    <path d="M607.353 123.957H599.649L595.797 117.077L599.649 110.309H607.353L611.095 117.077L607.353 123.957Z" fill="#CAE5E6" />
    <path d="M592.935 115.386H585.231L581.489 108.619L585.231 101.851H592.935L596.787 108.619L592.935 115.386Z" fill="#CAE5E6" />
    <path d="M577.528 105.009H572.025L569.273 100.159L572.025 95.1958H577.528L580.279 100.159L577.528 105.009Z" fill="#CAE5E6" />
    <path d="M475.617 43.0864H472.755L471.435 40.605L472.755 38.1235H475.617L477.048 40.605L475.617 43.0864Z" fill="#CAE5E6" />
    <path d="M133.112 13.1967H125.738L121.996 6.65475L125.738 0H133.112L136.854 6.65475L133.112 13.1967Z" fill="#CAE5E6" />
    <path d="M118.692 21.5444H111.428L107.796 15.1152L111.428 8.68604H118.692L122.324 15.1152L118.692 21.5444Z" fill="#CAE5E6" />
    <path d="M104.497 30.4545H96.9028L93.0508 23.5742L96.9028 16.8066H104.497L108.349 23.5742L104.497 30.4545Z" fill="#CAE5E6" />
    <path d="M90.1873 38.9115H82.4832L78.7412 32.144L82.4832 25.2637H90.1873L94.0394 32.144L90.1873 38.9115Z" fill="#CAE5E6" />
    <path d="M75.7723 47.3744H68.1782L64.3262 40.6069L68.1782 33.8394H75.7723L79.6243 40.6069L75.7723 47.3744Z" fill="#CAE5E6" />
    <path d="M61.4637 55.9447H53.7596L50.0176 49.0644L53.7596 42.2969H61.4637L65.3157 49.0644L61.4637 55.9447Z" fill="#CAE5E6" />
    <path d="M47.0437 64.4052H39.4497L35.5977 57.6377L39.4497 50.7573H47.0437L50.8958 57.6377L47.0437 64.4052Z" fill="#CAE5E6" />
    <path d="M32.7407 72.8632H25.0366L21.1846 66.0957L25.0366 59.3281H32.7407L36.5928 66.0957L32.7407 72.8632Z" fill="#CAE5E6" />
    <path d="M18.3172 81.4355H10.7231L6.87109 74.5551L10.7231 67.7876H18.3172L22.1693 74.5551L18.3172 81.4355Z" fill="#CAE5E6" />
    <path d="M3.90206 89.6703H-3.58195L-7.21387 83.1284L-3.58195 76.4736H3.90206L7.53398 83.1284L3.90206 89.6703Z" fill="#CAE5E6" />
    <path d="M734.695 604.905H730.843L728.972 601.521L730.843 598.25H734.695L736.566 601.521L734.695 604.905Z" fill="#CAE5E6" />
    <path d="M736.346 522.793H729.192L725.67 516.589L729.192 510.273H736.346L739.868 516.589L736.346 522.793Z" fill="#CAE5E6" />
    <path d="M736.563 506.325H728.969L725.117 499.558L728.969 492.79H736.563L740.415 499.558L736.563 506.325Z" fill="#CAE5E6" />
    <path d="M736.563 489.407H728.969L725.117 482.526L728.969 475.759H736.563L740.415 482.526L736.563 489.407Z" fill="#CAE5E6" />
    <path d="M734.25 468.201H731.278L729.848 465.607L731.278 463.013H734.25L735.681 465.607L734.25 468.201Z" fill="#CAE5E6" />
    <path d="M736.563 455.344H728.969L725.117 448.577L728.969 441.809H736.563L740.415 448.577L736.563 455.344Z" fill="#CAE5E6" />
    <path d="M736.561 438.424H728.967L725.115 431.543L728.967 424.776H736.561L740.413 431.543L736.561 438.424Z" fill="#CAE5E6" />
    <path d="M736.563 421.391H728.969L725.117 414.623L728.969 407.743H736.563L740.415 414.623L736.563 421.391Z" fill="#CAE5E6" />
    <path d="M736.561 404.36H728.967L725.115 397.593L728.967 390.825H736.561L740.413 397.593L736.561 404.36Z" fill="#CAE5E6" />
    <path d="M736.563 387.327H728.969L725.117 380.56L728.969 373.792H736.563L740.415 380.56L736.563 387.327Z" fill="#CAE5E6" />
    <path d="M736.563 370.41H728.969L725.117 363.642L728.969 356.762H736.563L740.415 363.642L736.563 370.41Z" fill="#CAE5E6" />
    <path d="M736.561 353.379H728.967L725.115 346.611L728.967 339.844H736.561L740.413 346.611L736.561 353.379Z" fill="#CAE5E6" />
    <path d="M736.563 336.349H728.969L725.117 329.581L728.969 322.813H736.563L740.415 329.581L736.563 336.349Z" fill="#CAE5E6" />
    <path d="M736.561 319.428H728.967L725.115 312.548L728.967 305.78H736.561L740.413 312.548L736.561 319.428Z" fill="#CAE5E6" />
    <path d="M736.563 302.395H728.969L725.117 295.628L728.969 288.748H736.563L740.415 295.628L736.563 302.395Z" fill="#CAE5E6" />
    <path d="M736.561 285.365H728.967L725.115 278.597L728.967 271.83H736.561L740.413 278.597L736.561 285.365Z" fill="#CAE5E6" />
    <path d="M736.563 268.445H728.969L725.117 261.565L728.969 254.797H736.563L740.415 261.565L736.563 268.445Z" fill="#CAE5E6" />
    <path d="M736.563 251.414H728.969L725.117 244.646L728.969 237.766H736.563L740.415 244.646L736.563 251.414Z" fill="#CAE5E6" />
    <path d="M736.561 234.382H728.967L725.115 227.614L728.967 220.847H736.561L740.413 227.614L736.561 234.382Z" fill="#CAE5E6" />
    <path d="M736.563 217.351H728.969L725.117 210.583L728.969 203.816H736.563L740.415 210.583L736.563 217.351Z" fill="#CAE5E6" />
    <path d="M736.561 200.431H728.967L725.115 193.551L728.967 186.783H736.561L740.413 193.551L736.561 200.431Z" fill="#CAE5E6" />
    <path d="M736.563 183.4H728.969L725.117 176.632L728.969 169.752H736.563L740.415 176.632L736.563 183.4Z" fill="#CAE5E6" />
    <path d="M722.253 174.942H714.549L710.697 168.062L714.549 161.294H722.253L726.105 168.062L722.253 174.942Z" fill="#CAE5E6" />
    <path d="M707.837 166.37H700.243L696.391 159.603L700.243 152.835H707.837L711.689 159.603L707.837 166.37Z" fill="#CAE5E6" />
    <path d="M693.527 157.909H685.823L681.971 151.142L685.823 144.261H693.527L697.269 151.142L693.527 157.909Z" fill="#CAE5E6" />
    <path d="M679.113 149.45H671.519L667.667 142.569L671.519 135.802H679.113L682.965 142.569L679.113 149.45Z" fill="#CAE5E6" />
    <path d="M664.803 140.877H657.099L653.247 134.109L657.099 127.342H664.803L668.545 134.109L664.803 140.877Z" fill="#CAE5E6" />
    <path d="M650.389 132.419H642.794L638.942 125.651L642.794 118.771H650.389L654.241 125.651L650.389 132.419Z" fill="#CAE5E6" />
    <path d="M636.079 123.957H628.375L624.522 117.077L628.375 110.309H636.079L639.821 117.077L636.079 123.957Z" fill="#CAE5E6" />
    <path d="M621.662 115.386H613.958L610.216 108.619L613.958 101.851H621.662L625.514 108.619L621.662 115.386Z" fill="#CAE5E6" />
    <path d="M607.353 106.926H599.649L595.797 100.159L599.649 93.2783H607.353L611.095 100.159L607.353 106.926Z" fill="#CAE5E6" />
    <path d="M592.828 98.2411H585.344L581.712 91.5864L585.344 85.0444H592.828L596.57 91.5864L592.828 98.2411Z" fill="#CAE5E6" />
    <path d="M104.494 13.1967H97.0096L93.2676 6.65475L97.0096 0H104.494L108.125 6.65475L104.494 13.1967Z" fill="#CAE5E6" />
    <path d="M90.1873 21.8818H82.4832L78.7412 15.1142L82.4832 8.34668H90.1873L94.0394 15.1142L90.1873 21.8818Z" fill="#CAE5E6" />
    <path d="M75.7723 30.4545H68.1782L64.3262 23.5742L68.1782 16.8066H75.7723L79.6243 23.5742L75.7723 30.4545Z" fill="#CAE5E6" />
    <path d="M61.4637 38.9145H53.7596L50.0176 32.1469L53.7596 25.2666H61.4637L65.3157 32.1469L61.4637 38.9145Z" fill="#CAE5E6" />
    <path d="M47.0437 47.3744H39.4497L35.5977 40.6069L39.4497 33.8394H47.0437L50.8958 40.6069L47.0437 47.3744Z" fill="#CAE5E6" />
    <path d="M32.7407 55.9447H25.0366L21.1846 49.0644L25.0366 42.2969H32.7407L36.5928 49.0644L32.7407 55.9447Z" fill="#CAE5E6" />
    <path d="M18.3172 64.4052H10.7231L6.87109 57.6377L10.7231 50.7573H18.3172L22.1693 57.6377L18.3172 64.4052Z" fill="#CAE5E6" />
    <path d="M4.01611 72.8632H-3.68799L-7.54004 66.0957L-3.68799 59.3281H4.01611L7.86816 66.0957L4.01611 72.8632Z" fill="#CAE5E6" />
    <path d="M750.761 616.635H743.497L739.755 610.093L743.497 603.551H750.761L754.503 610.093L750.761 616.635Z" fill="#CAE5E6" />
    <path d="M749.771 580.77H744.488L741.847 576.033L744.488 571.408H749.771L752.412 576.033L749.771 580.77Z" fill="#CAE5E6" />
    <path d="M747.898 509.596H746.247L745.477 508.13L746.247 506.664H747.898L748.778 508.13L747.898 509.596Z" fill="#CAE5E6" />
    <path d="M748.451 493.467H745.81L744.379 491.098L745.81 488.617H748.451L749.882 491.098L748.451 493.467Z" fill="#CAE5E6" />
    <path d="M749.987 479.144H744.264L741.402 474.069L744.264 468.993H749.987L752.848 474.069L749.987 479.144Z" fill="#CAE5E6" />
    <path d="M750.759 463.577H743.495L739.863 457.035L743.495 450.606H750.759L754.391 457.035L750.759 463.577Z" fill="#CAE5E6" />
    <path d="M750.98 446.883H743.276L739.424 440.116L743.276 433.235H750.98L754.832 440.116L750.98 446.883Z" fill="#CAE5E6" />
    <path d="M750.98 429.851H743.276L739.424 423.083L743.276 416.315H750.98L754.832 423.083L750.98 429.851Z" fill="#CAE5E6" />
    <path d="M750.98 412.933H743.276L739.424 406.053L743.276 399.285H750.98L754.832 406.053L750.98 412.933Z" fill="#CAE5E6" />
    <path d="M750.98 395.9H743.276L739.424 389.132L743.276 382.252H750.98L754.832 389.132L750.98 395.9Z" fill="#CAE5E6" />
    <path d="M750.98 378.87H743.276L739.424 372.102L743.276 365.334H750.98L754.832 372.102L750.98 378.87Z" fill="#CAE5E6" />
    <path d="M750.98 361.836H743.276L739.424 355.069L743.276 348.301H750.98L754.832 355.069L750.98 361.836Z" fill="#CAE5E6" />
    <path d="M750.98 344.919H743.276L739.424 338.039L743.276 331.271H750.98L754.832 338.039L750.98 344.919Z" fill="#CAE5E6" />
    <path d="M750.98 327.888H743.276L739.424 321.121L743.276 314.24H750.98L754.832 321.121L750.98 327.888Z" fill="#CAE5E6" />
    <path d="M750.98 310.855H743.276L739.424 304.088L743.276 297.32H750.98L754.832 304.088L750.98 310.855Z" fill="#CAE5E6" />
    <path d="M750.98 293.938H743.276L739.424 287.058L743.276 280.29H750.98L754.832 287.058L750.98 293.938Z" fill="#CAE5E6" />
    <path d="M750.98 276.905H743.276L739.424 270.137L743.276 263.257H750.98L754.832 270.137L750.98 276.905Z" fill="#CAE5E6" />
    <path d="M750.98 259.874H743.276L739.424 253.106L743.276 246.339H750.98L754.832 253.106L750.98 259.874Z" fill="#CAE5E6" />
    <path d="M750.98 242.842H743.276L739.424 236.074L743.276 229.307H750.98L754.832 236.074L750.98 242.842Z" fill="#CAE5E6" />
    <path d="M750.98 225.923H743.276L739.424 219.043L743.276 212.275H750.98L754.832 219.043L750.98 225.923Z" fill="#CAE5E6" />
    <path d="M750.98 208.893H743.276L739.424 202.125L743.276 195.245H750.98L754.832 202.125L750.98 208.893Z" fill="#CAE5E6" />
    <path d="M750.98 191.861H743.276L739.424 185.093L743.276 178.326H750.98L754.832 185.093L750.98 191.861Z" fill="#CAE5E6" />
    <path d="M750.98 174.942H743.276L739.424 168.062L743.276 161.294H750.98L754.832 168.062L750.98 174.942Z" fill="#CAE5E6" />
    <path d="M736.561 166.367H728.967L725.115 159.6L728.967 152.832H736.561L740.413 159.6L736.561 166.367Z" fill="#CAE5E6" />
    <path d="M722.253 157.909H714.549L710.697 151.142L714.549 144.261H722.253L726.105 151.142L722.253 157.909Z" fill="#CAE5E6" />
    <path d="M707.837 149.45H700.243L696.391 142.569L700.243 135.802H707.837L711.689 142.569L707.837 149.45Z" fill="#CAE5E6" />
    <path d="M693.527 140.877H685.823L681.971 134.109L685.823 127.342H693.527L697.269 134.109L693.527 140.877Z" fill="#CAE5E6" />
    <path d="M679.113 132.419H671.519L667.667 125.651L671.519 118.771H679.113L682.965 125.651L679.113 132.419Z" fill="#CAE5E6" />
    <path d="M664.803 123.957H657.099L653.247 117.077L657.099 110.309H664.803L668.545 117.077L664.803 123.957Z" fill="#CAE5E6" />
    <path d="M650.387 115.386H642.792L638.94 108.619L642.792 101.851H650.387L654.239 108.619L650.387 115.386Z" fill="#CAE5E6" />
    <path d="M636.079 106.926H628.375L624.522 100.159L628.375 93.2783H636.079L639.821 100.159L636.079 106.926Z" fill="#CAE5E6" />
    <path d="M621.662 98.3559H613.958L610.216 91.5883L613.958 84.8208H621.662L625.514 91.5883L621.662 98.3559Z" fill="#CAE5E6" />
    <path d="M605.482 86.6235H601.41L599.429 83.1269L601.41 79.5176H605.482L607.463 83.1269L605.482 86.6235Z" fill="#CAE5E6" />
    <path d="M75.1082 12.0688H68.9449L65.8633 6.65475L68.9449 1.12793H75.1082L78.1898 6.65475L75.1082 12.0688Z" fill="#CAE5E6" />
    <path d="M60.6915 20.5287H54.6383L51.5566 15.1147L54.6383 9.70068H60.6915L63.6631 15.1147L60.6915 20.5287Z" fill="#CAE5E6" />
    <path d="M46.1641 28.8764H40.331L37.3594 23.5752L40.331 18.3867H46.1641L49.1357 23.5752L46.1641 28.8764Z" fill="#CAE5E6" />
    <path d="M32.7407 38.9115H25.0366L21.1846 32.144L25.0366 25.2637H32.7407L36.5928 32.144L32.7407 38.9115Z" fill="#CAE5E6" />
    <path d="M18.3172 47.3725H10.7231L6.87109 40.6049L10.7231 33.8374H18.3172L22.1693 40.6049L18.3172 47.3725Z" fill="#CAE5E6" />
    <path d="M4.01611 55.9447H-3.68799L-7.54004 49.0644L-3.68799 42.2969H4.01611L7.86816 49.0644L4.01611 55.9447Z" fill="#CAE5E6" />
    <path d="M765.067 743.864H757.913L754.392 737.548L757.913 731.344H765.067L768.589 737.548L765.067 743.864Z" fill="#CAE5E6" />
    <path d="M765.288 727.283H757.694L753.952 720.515L757.694 713.86H765.288L769.03 720.515L765.288 727.283Z" fill="#CAE5E6" />
    <path d="M763.306 706.755H759.674L757.913 703.597L759.674 700.326H763.306L765.067 703.597L763.306 706.755Z" fill="#CAE5E6" />
    <path d="M764.627 624.19H758.354L755.162 618.551L758.354 613.024H764.627L767.819 618.551L764.627 624.19Z" fill="#CAE5E6" />
    <path d="M765.288 591.371H757.694L753.842 584.604L757.694 577.724H765.288L769.14 584.604L765.288 591.371Z" fill="#CAE5E6" />
    <path d="M765.288 574.339H757.694L753.842 567.571L757.694 560.804H765.288L769.14 567.571L765.288 574.339Z" fill="#CAE5E6" />
    <path d="M762.423 552.117H760.552L759.672 550.538L760.552 548.958H762.423L763.304 550.538L762.423 552.117Z" fill="#CAE5E6" />
    <path d="M765.288 455.344H757.694L753.842 448.577L757.694 441.809H765.288L769.14 448.577L765.288 455.344Z" fill="#CAE5E6" />
    <path d="M765.288 438.424H757.694L753.842 431.543L757.694 424.776H765.288L769.14 431.543L765.288 438.424Z" fill="#CAE5E6" />
    <path d="M765.288 421.391H757.694L753.842 414.623L757.694 407.743H765.288L769.14 414.623L765.288 421.391Z" fill="#CAE5E6" />
    <path d="M765.288 404.36H757.694L753.842 397.593L757.694 390.825H765.288L769.14 397.593L765.288 404.36Z" fill="#CAE5E6" />
    <path d="M765.288 387.327H757.694L753.842 380.56L757.694 373.792H765.288L769.14 380.56L765.288 387.327Z" fill="#CAE5E6" />
    <path d="M765.288 370.41H757.694L753.842 363.642L757.694 356.762H765.288L769.14 363.642L765.288 370.41Z" fill="#CAE5E6" />
    <path d="M765.288 353.379H757.694L753.842 346.611L757.694 339.844H765.288L769.14 346.611L765.288 353.379Z" fill="#CAE5E6" />
    <path d="M765.288 336.349H757.694L753.842 329.581L757.694 322.813H765.288L769.14 329.581L765.288 336.349Z" fill="#CAE5E6" />
    <path d="M765.288 319.428H757.694L753.842 312.548L757.694 305.78H765.288L769.14 312.548L765.288 319.428Z" fill="#CAE5E6" />
    <path d="M765.288 302.395H757.694L753.842 295.628L757.694 288.748H765.288L769.14 295.628L765.288 302.395Z" fill="#CAE5E6" />
    <path d="M765.288 285.365H757.694L753.842 278.597L757.694 271.83H765.288L769.14 278.597L765.288 285.365Z" fill="#CAE5E6" />
    <path d="M765.288 268.445H757.694L753.842 261.565L757.694 254.797H765.288L769.14 261.565L765.288 268.445Z" fill="#CAE5E6" />
    <path d="M765.288 251.414H757.694L753.842 244.646L757.694 237.766H765.288L769.14 244.646L765.288 251.414Z" fill="#CAE5E6" />
    <path d="M765.288 234.382H757.694L753.842 227.614L757.694 220.847H765.288L769.14 227.614L765.288 234.382Z" fill="#CAE5E6" />
    <path d="M765.288 217.351H757.694L753.842 210.583L757.694 203.816H765.288L769.14 210.583L765.288 217.351Z" fill="#CAE5E6" />
    <path d="M765.288 200.431H757.694L753.842 193.551L757.694 186.783H765.288L769.14 193.551L765.288 200.431Z" fill="#CAE5E6" />
    <path d="M765.288 183.4H757.694L753.842 176.632L757.694 169.752H765.288L769.14 176.632L765.288 183.4Z" fill="#CAE5E6" />
    <path d="M765.288 166.367H757.694L753.842 159.6L757.694 152.832H765.288L769.14 159.6L765.288 166.367Z" fill="#CAE5E6" />
    <path d="M750.98 157.909H743.276L739.424 151.142L743.276 144.261H750.98L754.832 151.142L750.98 157.909Z" fill="#CAE5E6" />
    <path d="M736.563 149.45H728.969L725.117 142.569L728.969 135.802H736.563L740.415 142.569L736.563 149.45Z" fill="#CAE5E6" />
    <path d="M722.253 140.877H714.549L710.697 134.109L714.549 127.342H722.253L726.105 134.109L722.253 140.877Z" fill="#CAE5E6" />
    <path d="M707.837 132.419H700.243L696.391 125.651L700.243 118.771H707.837L711.689 125.651L707.837 132.419Z" fill="#CAE5E6" />
    <path d="M693.527 123.957H685.823L681.971 117.077L685.823 110.309H693.527L697.269 117.077L693.527 123.957Z" fill="#CAE5E6" />
    <path d="M679.113 115.386H671.519L667.667 108.619L671.519 101.851H679.113L682.965 108.619L679.113 115.386Z" fill="#CAE5E6" />
    <path d="M664.803 106.926H657.099L653.247 100.159L657.099 93.2783H664.803L668.545 100.159L664.803 106.926Z" fill="#CAE5E6" />
    <path d="M650.389 98.3559H642.794L638.942 91.5883L642.794 84.8208H650.389L654.241 91.5883L650.389 98.3559Z" fill="#CAE5E6" />
    <path d="M635.97 89.7807H628.376L624.634 83.1259L628.376 76.3584H635.97L639.822 83.1259L635.97 89.7807Z" fill="#CAE5E6" />
    <path d="M618.91 76.4731H616.709L615.719 74.5556L616.709 72.6382H618.91L620.011 74.5556L618.91 76.4731Z" fill="#CAE5E6" />
    <path d="M17.3315 28.5351H11.8286L9.07715 23.5722L11.8286 18.7222H17.3315L20.083 23.5722L17.3315 28.5351Z" fill="#CAE5E6" />
    <path d="M3.79033 38.5756H-3.47355L-7.10547 32.1464L-3.47355 25.7173H3.79033L7.42225 32.1464L3.79033 38.5756Z" fill="#CAE5E6" />
    <path d="M763.415 757.966H759.563L757.582 754.582L759.563 751.085H763.415L765.396 754.582L763.415 757.966Z" fill="#CAE5E6" />
    <path d="M779.705 752.891H772L768.148 746.01L772 739.243H779.705L783.557 746.01L779.705 752.891Z" fill="#CAE5E6" />
    <path d="M779.702 735.855H771.998L768.146 729.088L771.998 722.208H779.702L783.554 729.088L779.702 735.855Z" fill="#CAE5E6" />
    <path d="M779.705 718.825H772L768.148 712.057L772 705.29H779.705L783.557 712.057L779.705 718.825Z" fill="#CAE5E6" />
    <path d="M778.606 699.99H773.103L770.352 695.027L773.103 690.177H778.606L781.357 695.027L778.606 699.99Z" fill="#CAE5E6" />
    <path d="M779.488 599.491H772.224L768.592 593.062L772.224 586.632H779.488L783.119 593.062L779.488 599.491Z" fill="#CAE5E6" />
    <path d="M779.702 582.909H771.998L768.146 576.029L771.998 569.261H779.702L783.554 576.029L779.702 582.909Z" fill="#CAE5E6" />
    <path d="M779.702 565.879H771.998L768.146 559.111L771.998 552.231H779.702L783.554 559.111L779.702 565.879Z" fill="#CAE5E6" />
    <path d="M779.044 547.72H772.661L769.579 542.081L772.661 536.441H779.044L782.126 542.081L779.044 547.72Z" fill="#CAE5E6" />
    <path d="M779.378 446.207H772.335L768.923 440.116L772.335 433.913H779.378L782.79 440.116L779.378 446.207Z" fill="#CAE5E6" />
    <path d="M779.702 429.851H771.998L768.146 423.083L771.998 416.315H779.702L783.554 423.083L779.702 429.851Z" fill="#CAE5E6" />
    <path d="M779.702 412.933H771.998L768.146 406.053L771.998 399.285H779.702L783.554 406.053L779.702 412.933Z" fill="#CAE5E6" />
    <path d="M779.705 395.9H772L768.148 389.132L772 382.252H779.705L783.557 389.132L779.705 395.9Z" fill="#CAE5E6" />
    <path d="M779.702 378.87H771.998L768.146 372.102L771.998 365.334H779.702L783.554 372.102L779.702 378.87Z" fill="#CAE5E6" />
    <path d="M777.945 358.792H773.763L771.672 355.07L773.763 351.46H777.945L780.036 355.07L777.945 358.792Z" fill="#CAE5E6" />
    <path d="M779.702 344.919H771.998L768.146 338.039L771.998 331.271H779.702L783.554 338.039L779.702 344.919Z" fill="#CAE5E6" />
    <path d="M779.702 327.888H771.998L768.146 321.121L771.998 314.24H779.702L783.554 321.121L779.702 327.888Z" fill="#CAE5E6" />
    <path d="M779.705 310.855H772L768.148 304.088L772 297.32H779.705L783.557 304.088L779.705 310.855Z" fill="#CAE5E6" />
    <path d="M779.702 293.938H771.998L768.146 287.058L771.998 280.29H779.702L783.554 287.058L779.702 293.938Z" fill="#CAE5E6" />
    <path d="M779.705 276.905H772L768.148 270.137L772 263.257H779.705L783.557 270.137L779.705 276.905Z" fill="#CAE5E6" />
    <path d="M779.702 259.874H771.998L768.146 253.106L771.998 246.339H779.702L783.554 253.106L779.702 259.874Z" fill="#CAE5E6" />
    <path d="M779.705 242.842H772L768.148 236.074L772 229.307H779.705L783.557 236.074L779.705 242.842Z" fill="#CAE5E6" />
    <path d="M779.702 225.923H771.998L768.146 219.043L771.998 212.275H779.702L783.554 219.043L779.702 225.923Z" fill="#CAE5E6" />
    <path d="M779.702 208.893H771.998L768.146 202.125L771.998 195.245H779.702L783.554 202.125L779.702 208.893Z" fill="#CAE5E6" />
    <path d="M779.705 191.861H772L768.148 185.093L772 178.326H779.705L783.557 185.093L779.705 191.861Z" fill="#CAE5E6" />
    <path d="M779.702 174.942H771.998L768.146 168.062L771.998 161.294H779.702L783.554 168.062L779.702 174.942Z" fill="#CAE5E6" />
    <path d="M779.705 157.909H772L768.148 151.142L772 144.261H779.705L783.557 151.142L779.705 157.909Z" fill="#CAE5E6" />
    <path d="M765.288 149.45H757.694L753.842 142.569L757.694 135.802H765.288L769.14 142.569L765.288 149.45Z" fill="#CAE5E6" />
    <path d="M750.98 140.877H743.276L739.424 134.109L743.276 127.342H750.98L754.832 134.109L750.98 140.877Z" fill="#CAE5E6" />
    <path d="M736.563 132.419H728.969L725.117 125.651L728.969 118.771H736.563L740.415 125.651L736.563 132.419Z" fill="#CAE5E6" />
    <path d="M722.253 123.957H714.549L710.697 117.077L714.549 110.309H722.253L726.105 117.077L722.253 123.957Z" fill="#CAE5E6" />
    <path d="M707.837 115.386H700.243L696.391 108.619L700.243 101.851H707.837L711.689 108.619L707.837 115.386Z" fill="#CAE5E6" />
    <path d="M693.417 106.814H685.933L682.191 100.159L685.933 93.3916H693.417L697.159 100.159L693.417 106.814Z" fill="#CAE5E6" />
    <path d="M679.113 98.3564H671.519L667.777 91.5888L671.519 84.9341H679.113L682.855 91.5888L679.113 98.3564Z" fill="#CAE5E6" />
    <path d="M664.803 89.8935H657.099L653.247 83.1259L657.099 76.3584H664.803L668.545 83.1259L664.803 89.8935Z" fill="#CAE5E6" />
    <path d="M650.387 81.4355H642.792L638.94 74.5551L642.792 67.7876H650.387L654.239 74.5551L650.387 81.4355Z" fill="#CAE5E6" />
    <path d="M606.253 54.0278H600.64L597.889 49.0649L600.64 44.1021H606.253L609.115 49.0649L606.253 54.0278Z" fill="#CAE5E6" />
    <path d="M590.955 43.7656H587.323L585.452 40.6074L587.323 37.4492H590.955L592.716 40.6074L590.955 43.7656Z" fill="#CAE5E6" />
    <path d="M762.758 773.753H760.226L759.016 771.498L760.226 769.354H762.758L763.968 771.498L762.758 773.753Z" fill="#CAE5E6" />
    <path d="M779.702 769.808H771.998L768.146 763.04L771.998 756.273H779.702L783.554 763.04L779.702 769.808Z" fill="#CAE5E6" />
    <path d="M794.014 761.346H786.42L782.568 754.578L786.42 747.811H794.014L797.867 754.578L794.014 761.346Z" fill="#CAE5E6" />
    <path d="M794.014 744.317H786.42L782.568 737.55L786.42 730.782H794.014L797.867 737.55L794.014 744.317Z" fill="#CAE5E6" />
    <path d="M794.014 727.398H786.42L782.568 720.518L786.42 713.75H794.014L797.867 720.518L794.014 727.398Z" fill="#CAE5E6" />
    <path d="M794.014 710.365H786.42L782.568 703.597L786.42 696.717H794.014L797.867 703.597L794.014 710.365Z" fill="#CAE5E6" />
    <path d="M793.902 693.11H786.528L782.896 686.568L786.528 680.026H793.902L797.534 686.568L793.902 693.11Z" fill="#CAE5E6" />
    <path d="M790.931 670.775H789.5L788.84 669.535L789.5 668.407H790.931L791.591 669.535L790.931 670.775Z" fill="#CAE5E6" />
    <path d="M792.585 588.776H787.852L785.541 584.603L787.852 580.43H792.585L794.896 584.603L792.585 588.776Z" fill="#CAE5E6" />
    <path d="M791.484 569.714H788.953L787.742 567.571L788.953 565.428H791.484L792.695 567.571L791.484 569.714Z" fill="#CAE5E6" />
    <path d="M792.585 554.712H787.852L785.541 550.539L787.852 546.366H792.585L794.896 550.539L792.585 554.712Z" fill="#CAE5E6" />
    <path d="M792.143 451.959H788.291L786.42 448.576L788.291 445.192H792.143L794.014 448.576L792.143 451.959Z" fill="#CAE5E6" />
    <path d="M791.372 433.688H789.061L787.851 431.545L789.061 429.515H791.372L792.583 431.545L791.372 433.688Z" fill="#CAE5E6" />
    <path d="M793.682 420.714H786.749L783.227 414.623L786.749 408.42H793.682L797.204 414.623L793.682 420.714Z" fill="#CAE5E6" />
    <path d="M793.904 404.136H786.53L782.788 397.594L786.53 391.052H793.904L797.646 397.594L793.904 404.136Z" fill="#CAE5E6" />
    <path d="M790.822 381.691H789.611L788.951 380.563L789.611 379.436H790.822L791.482 380.563L790.822 381.691Z" fill="#CAE5E6" />
    <path d="M791.811 366.349H788.619L787.078 363.642L788.619 360.822H791.811L793.352 363.642L791.811 366.349Z" fill="#CAE5E6" />
    <path d="M793.57 352.588H786.856L783.555 346.61L786.856 340.632H793.57L796.872 346.61L793.57 352.588Z" fill="#CAE5E6" />
    <path d="M794.014 336.349H786.42L782.568 329.581L786.42 322.813H794.014L797.867 329.581L794.014 336.349Z" fill="#CAE5E6" />
    <path d="M794.014 319.431H786.42L782.568 312.55L786.42 305.783H794.014L797.867 312.55L794.014 319.431Z" fill="#CAE5E6" />
    <path d="M794.014 302.395H786.42L782.568 295.628L786.42 288.748H794.014L797.867 295.628L794.014 302.395Z" fill="#CAE5E6" />
    <path d="M794.014 285.365H786.42L782.568 278.597L786.42 271.83H794.014L797.867 278.597L794.014 285.365Z" fill="#CAE5E6" />
    <path d="M794.014 268.445H786.42L782.568 261.565L786.42 254.797H794.014L797.867 261.565L794.014 268.445Z" fill="#CAE5E6" />
    <path d="M794.014 251.414H786.42L782.568 244.646L786.42 237.766H794.014L797.867 244.646L794.014 251.414Z" fill="#CAE5E6" />
    <path d="M794.014 234.382H786.42L782.568 227.614L786.42 220.847H794.014L797.867 227.614L794.014 234.382Z" fill="#CAE5E6" />
    <path d="M794.014 217.351H786.42L782.568 210.583L786.42 203.816H794.014L797.867 210.583L794.014 217.351Z" fill="#CAE5E6" />
    <path d="M794.014 200.431H786.42L782.568 193.551L786.42 186.783H794.014L797.867 193.551L794.014 200.431Z" fill="#CAE5E6" />
    <path d="M794.014 183.4H786.42L782.568 176.632L786.42 169.752H794.014L797.867 176.632L794.014 183.4Z" fill="#CAE5E6" />
    <path d="M794.014 166.37H786.42L782.568 159.603L786.42 152.835H794.014L797.867 159.603L794.014 166.37Z" fill="#CAE5E6" />
    <path d="M794.014 149.45H786.42L782.568 142.569L786.42 135.802H794.014L797.867 142.569L794.014 149.45Z" fill="#CAE5E6" />
    <path d="M779.702 140.877H771.998L768.146 134.109L771.998 127.342H779.702L783.554 134.109L779.702 140.877Z" fill="#CAE5E6" />
    <path d="M765.288 132.419H757.694L753.842 125.651L757.694 118.771H765.288L769.14 125.651L765.288 132.419Z" fill="#CAE5E6" />
    <path d="M750.98 123.957H743.276L739.424 117.077L743.276 110.309H750.98L754.832 117.077L750.98 123.957Z" fill="#CAE5E6" />
    <path d="M736.561 115.386H728.967L725.115 108.619L728.967 101.851H736.561L740.303 108.619L736.561 115.386Z" fill="#CAE5E6" />
    <path d="M720.054 103.092H716.642L714.991 100.16L716.642 97.1143H720.054L721.815 100.16L720.054 103.092Z" fill="#CAE5E6" />
    <path d="M691.439 86.1733H687.917L686.156 83.1279L687.917 79.9697H691.439L693.2 83.1279L691.439 86.1733Z" fill="#CAE5E6" />
    <path d="M679.113 81.3232H671.519L667.667 74.5556L671.519 67.9009H679.113L682.855 74.5556L679.113 81.3232Z" fill="#CAE5E6" />
    <path d="M619.68 43.7656H616.048L614.287 40.6074L616.048 37.4492H619.68L621.441 40.6074L619.68 43.7656Z" fill="#CAE5E6" />
    <path d="M806.45 766.424H802.708L800.727 763.04L802.708 759.656H806.45L808.431 763.04L806.45 766.424Z" fill="#CAE5E6" />
    <path d="M808.428 752.891H800.724L796.872 746.01L800.724 739.243H808.428L812.28 746.01L808.428 752.891Z" fill="#CAE5E6" />
    <path d="M808.428 735.855H800.724L796.872 729.088L800.724 722.208H808.428L812.28 729.088L808.428 735.855Z" fill="#CAE5E6" />
    <path d="M808.428 718.825H800.724L796.872 712.057L800.724 705.29H808.428L812.28 712.057L808.428 718.825Z" fill="#CAE5E6" />
    <path d="M808.428 701.907H800.724L796.872 695.027L800.724 688.259H808.428L812.28 695.027L808.428 701.907Z" fill="#CAE5E6" />
    <path d="M808.428 684.876H800.724L796.872 678.108L800.724 671.228H808.428L812.28 678.108L808.428 684.876Z" fill="#CAE5E6" />
    <path d="M806.119 595.881H803.037L801.496 593.061L803.037 590.241H806.119L807.659 593.061L806.119 595.881Z" fill="#CAE5E6" />
    <path d="M808.103 582.46H801.06L797.428 576.031L801.06 569.714H808.103L811.735 576.031L808.103 582.46Z" fill="#CAE5E6" />
    <path d="M808.428 480.834H800.724L796.872 474.066L800.724 467.299H808.428L812.28 474.066L808.428 480.834Z" fill="#CAE5E6" />
    <path d="M807.219 359.808H801.936L799.295 355.07L801.936 350.446H807.219L809.861 355.07L807.219 359.808Z" fill="#CAE5E6" />
    <path d="M808.428 344.919H800.724L796.872 338.039L800.724 331.271H808.428L812.28 338.039L808.428 344.919Z" fill="#CAE5E6" />
    <path d="M808.428 327.888H800.724L796.872 321.121L800.724 314.24H808.428L812.28 321.121L808.428 327.888Z" fill="#CAE5E6" />
    <path d="M808.428 310.855H800.724L796.872 304.088L800.724 297.32H808.428L812.28 304.088L808.428 310.855Z" fill="#CAE5E6" />
    <path d="M808.428 293.938H800.724L796.872 287.058L800.724 280.29H808.428L812.28 287.058L808.428 293.938Z" fill="#CAE5E6" />
    <path d="M808.428 276.905H800.724L796.872 270.137L800.724 263.257H808.428L812.28 270.137L808.428 276.905Z" fill="#CAE5E6" />
    <path d="M808.428 259.874H800.724L796.872 253.106L800.724 246.339H808.428L812.28 253.106L808.428 259.874Z" fill="#CAE5E6" />
    <path d="M808.428 242.844H800.724L796.872 236.076L800.724 229.309H808.428L812.28 236.076L808.428 242.844Z" fill="#CAE5E6" />
    <path d="M808.428 225.923H800.724L796.872 219.043L800.724 212.275H808.428L812.28 219.043L808.428 225.923Z" fill="#CAE5E6" />
    <path d="M808.428 208.893H800.724L796.872 202.125L800.724 195.245H808.428L812.28 202.125L808.428 208.893Z" fill="#CAE5E6" />
    <path d="M808.428 191.861H800.724L796.872 185.093L800.724 178.326H808.428L812.28 185.093L808.428 191.861Z" fill="#CAE5E6" />
    <path d="M808.428 174.942H800.724L796.872 168.062L800.724 161.294H808.428L812.28 168.062L808.428 174.942Z" fill="#CAE5E6" />
    <path d="M808.428 157.909H800.724L796.872 151.142L800.724 144.261H808.428L812.28 151.142L808.428 157.909Z" fill="#CAE5E6" />
    <path d="M808.428 140.877H800.724L796.872 134.109L800.724 127.342H808.428L812.28 134.109L808.428 140.877Z" fill="#CAE5E6" />
    <path d="M794.014 132.419H786.42L782.568 125.651L786.42 118.771H794.014L797.867 125.651L794.014 132.419Z" fill="#CAE5E6" />
    <path d="M779.705 123.957H772L768.148 117.077L772 110.309H779.705L783.557 117.077L779.705 123.957Z" fill="#CAE5E6" />
    <path d="M763.637 112.341H759.345L757.254 108.619L759.345 104.896H763.637L765.728 108.619L763.637 112.341Z" fill="#CAE5E6" />
    <path d="M821.858 759.768H816.025L813.054 754.58L816.025 749.392H821.858L824.83 754.58L821.858 759.768Z" fill="#CAE5E6" />
    <path d="M822.738 744.317H815.144L811.292 737.55L815.144 730.782H822.738L826.59 737.55L822.738 744.317Z" fill="#CAE5E6" />
    <path d="M822.738 727.398H815.144L811.292 720.518L815.144 713.75H822.738L826.59 720.518L822.738 727.398Z" fill="#CAE5E6" />
    <path d="M822.738 710.365H815.144L811.292 703.597L815.144 696.717H822.738L826.59 703.597L822.738 710.365Z" fill="#CAE5E6" />
    <path d="M822.738 693.334H815.144L811.292 686.566L815.144 679.799H822.738L826.59 686.566L822.738 693.334Z" fill="#CAE5E6" />
    <path d="M822.738 676.414H815.144L811.292 669.534L815.144 662.766H822.738L826.59 669.534L822.738 676.414Z" fill="#CAE5E6" />
    <path d="M821.748 657.582H816.135L813.273 652.619L816.135 647.543H821.748L824.61 652.619L821.748 657.582Z" fill="#CAE5E6" />
    <path d="M820.425 519.185H817.453L816.022 516.59L817.453 513.996H820.425L821.856 516.59L820.425 519.185Z" fill="#CAE5E6" />
    <path d="M820.096 501.7H817.785L816.574 499.557L817.785 497.414H820.096L821.307 499.557L820.096 501.7Z" fill="#CAE5E6" />
    <path d="M822.626 370.072H815.362L811.62 363.643L815.362 357.101H822.626L826.258 363.643L822.626 370.072Z" fill="#CAE5E6" />
    <path d="M821.748 351.574H816.135L813.273 346.611L816.135 341.536H821.748L824.61 346.611L821.748 351.574Z" fill="#CAE5E6" />
    <path d="M822.738 336.349H815.144L811.292 329.581L815.144 322.813H822.738L826.59 329.581L822.738 336.349Z" fill="#CAE5E6" />
    <path d="M822.738 319.428H815.144L811.292 312.548L815.144 305.78H822.738L826.59 312.548L822.738 319.428Z" fill="#CAE5E6" />
    <path d="M822.738 302.395H815.144L811.292 295.628L815.144 288.748H822.738L826.59 295.628L822.738 302.395Z" fill="#CAE5E6" />
    <path d="M822.738 285.365H815.144L811.292 278.597L815.144 271.83H822.738L826.59 278.597L822.738 285.365Z" fill="#CAE5E6" />
    <path d="M822.738 268.445H815.144L811.292 261.565L815.144 254.797H822.738L826.59 261.565L822.738 268.445Z" fill="#CAE5E6" />
    <path d="M822.738 251.414H815.144L811.292 244.646L815.144 237.766H822.738L826.59 244.646L822.738 251.414Z" fill="#CAE5E6" />
    <path d="M822.738 234.382H815.144L811.292 227.614L815.144 220.847H822.738L826.59 227.614L822.738 234.382Z" fill="#CAE5E6" />
    <path d="M822.738 217.351H815.144L811.292 210.583L815.144 203.816H822.738L826.59 210.583L822.738 217.351Z" fill="#CAE5E6" />
    <path d="M822.738 200.431H815.144L811.292 193.551L815.144 186.783H822.738L826.59 193.551L822.738 200.431Z" fill="#CAE5E6" />
    <path d="M822.738 183.4H815.144L811.292 176.632L815.144 169.752H822.738L826.59 176.632L822.738 183.4Z" fill="#CAE5E6" />
    <path d="M822.738 166.367H815.144L811.292 159.6L815.144 152.832H822.738L826.59 159.6L822.738 166.367Z" fill="#CAE5E6" />
    <path d="M822.738 149.45H815.144L811.292 142.569L815.144 135.802H822.738L826.59 142.569L822.738 149.45Z" fill="#CAE5E6" />
    <path d="M822.738 132.419H815.144L811.292 125.651L815.144 118.771H822.738L826.59 125.651L822.738 132.419Z" fill="#CAE5E6" />
    <path d="M808.428 123.957H800.724L796.872 117.077L800.724 110.309H808.428L812.28 117.077L808.428 123.957Z" fill="#CAE5E6" />
    <path d="M792.36 112.341H788.068L785.977 108.619L788.068 104.896H792.36L794.451 108.619L792.36 112.341Z" fill="#CAE5E6" />
    <path d="M837.155 752.891H829.451L825.599 746.01L829.451 739.243H837.155L841.007 746.01L837.155 752.891Z" fill="#CAE5E6" />
    <path d="M837.155 735.855H829.451L825.599 729.088L829.451 722.208H837.155L841.007 729.088L837.155 735.855Z" fill="#CAE5E6" />
    <path d="M837.155 718.825H829.451L825.599 712.057L829.451 705.29H837.155L841.007 712.057L837.155 718.825Z" fill="#CAE5E6" />
    <path d="M837.155 701.907H829.451L825.599 695.027L829.451 688.259H837.155L841.007 695.027L837.155 701.907Z" fill="#CAE5E6" />
    <path d="M837.155 684.876H829.451L825.599 678.108L829.451 671.228H837.155L841.007 678.108L837.155 684.876Z" fill="#CAE5E6" />
    <path d="M837.155 667.844H829.451L825.599 661.076L829.451 654.309H837.155L841.007 661.076L837.155 667.844Z" fill="#CAE5E6" />
    <path d="M834.404 646.073H832.203L830.992 644.043L832.203 642.013H834.404L835.615 644.043L834.404 646.073Z" fill="#CAE5E6" />
    <path d="M837.046 514.67H829.562L825.93 508.128L829.562 501.474H837.046L840.678 508.128L837.046 514.67Z" fill="#CAE5E6" />
    <path d="M836.167 394.21H830.444L827.582 389.134L830.444 383.946H836.167L839.028 389.134L836.167 394.21Z" fill="#CAE5E6" />
    <path d="M833.854 373.117H832.753L832.093 372.102L832.753 371.086H833.854L834.514 372.102L833.854 373.117Z" fill="#CAE5E6" />
    <path d="M836.934 327.55H829.67L826.038 321.121L829.67 314.691H836.934L840.566 321.121L836.934 327.55Z" fill="#CAE5E6" />
    <path d="M837.155 310.855H829.451L825.599 304.088L829.451 297.32H837.155L841.007 304.088L837.155 310.855Z" fill="#CAE5E6" />
    <path d="M837.155 293.938H829.451L825.599 287.058L829.451 280.29H837.155L841.007 287.058L837.155 293.938Z" fill="#CAE5E6" />
    <path d="M837.155 276.905H829.451L825.599 270.137L829.451 263.257H837.155L841.007 270.137L837.155 276.905Z" fill="#CAE5E6" />
    <path d="M837.044 259.76H829.56L825.818 253.105L829.56 246.45H837.044L840.786 253.105L837.044 259.76Z" fill="#CAE5E6" />
    <path d="M835.835 240.585H830.773L828.241 236.074L830.773 231.675H835.835L838.367 236.074L835.835 240.585Z" fill="#CAE5E6" />
    <path d="M837.155 225.923H829.451L825.599 219.043L829.451 212.275H837.155L841.007 219.043L837.155 225.923Z" fill="#CAE5E6" />
    <path d="M837.155 208.893H829.451L825.599 202.125L829.451 195.245H837.155L841.007 202.125L837.155 208.893Z" fill="#CAE5E6" />
    <path d="M837.155 191.861H829.451L825.599 185.093L829.451 178.326H837.155L841.007 185.093L837.155 191.861Z" fill="#CAE5E6" />
    <path d="M837.155 174.942H829.451L825.599 168.062L829.451 161.294H837.155L841.007 168.062L837.155 174.942Z" fill="#CAE5E6" />
    <path d="M837.155 157.909H829.451L825.599 151.142L829.451 144.261H837.155L841.007 151.142L837.155 157.909Z" fill="#CAE5E6" />
    <path d="M837.155 140.877H829.451L825.599 134.109L829.451 127.342H837.155L841.007 134.109L837.155 140.877Z" fill="#CAE5E6" />
    <path d="M837.155 123.957H829.451L825.599 117.077L829.451 110.309H837.155L841.007 117.077L837.155 123.957Z" fill="#CAE5E6" />
    <path d="M822.738 115.386H815.144L811.292 108.619L815.144 101.851H822.738L826.59 108.619L822.738 115.386Z" fill="#CAE5E6" />
    <path d="M806.453 103.429H802.711L800.84 100.158L802.711 96.7744H806.453L808.324 100.158L806.453 103.429Z" fill="#CAE5E6" />
    <path d="M849.151 774.208H846.18L844.749 771.5L846.18 768.906H849.151L850.582 771.5L849.151 774.208Z" fill="#CAE5E6" />
    <path d="M851.465 761.346H843.871L840.019 754.578L843.871 747.811H851.465L855.317 754.578L851.465 761.346Z" fill="#CAE5E6" />
    <path d="M851.465 744.317H843.871L840.019 737.55L843.871 730.782H851.465L855.317 737.55L851.465 744.317Z" fill="#CAE5E6" />
    <path d="M851.465 727.398H843.871L840.019 720.518L843.871 713.75H851.465L855.317 720.518L851.465 727.398Z" fill="#CAE5E6" />
    <path d="M851.465 710.365H843.871L840.019 703.597L843.871 696.717H851.465L855.317 703.597L851.465 710.365Z" fill="#CAE5E6" />
    <path d="M851.465 693.334H843.871L840.019 686.566L843.871 679.799H851.465L855.317 686.566L851.465 693.334Z" fill="#CAE5E6" />
    <path d="M851.465 676.414H843.871L840.019 669.534L843.871 662.766H851.465L855.317 669.534L851.465 676.414Z" fill="#CAE5E6" />
    <path d="M851.465 659.383H843.871L840.019 652.616L843.871 645.735H851.465L855.317 652.616L851.465 659.383Z" fill="#CAE5E6" />
    <path d="M849.483 638.742H845.851L844.09 635.583L845.851 632.425H849.483L851.244 635.583L849.483 638.742Z" fill="#CAE5E6" />
    <path d="M849.812 588.324H845.52L843.429 584.602L845.52 580.767H849.812L851.903 584.602L849.812 588.324Z" fill="#CAE5E6" />
    <path d="M851.354 574.002H843.981L840.349 567.573L843.981 561.144H851.354L854.986 567.573L851.354 574.002Z" fill="#CAE5E6" />
    <path d="M851.244 386.878H844.09L840.568 380.561L844.09 374.245H851.244L854.766 380.561L851.244 386.878Z" fill="#CAE5E6" />
    <path d="M848.381 313.9H846.95L846.18 312.546L846.95 311.306H848.381L849.151 312.546L848.381 313.9Z" fill="#CAE5E6" />
    <path d="M851.463 302.283H843.869L840.127 295.628L843.869 288.861H851.463L855.315 295.628L851.463 302.283Z" fill="#CAE5E6" />
    <path d="M851.465 285.365H843.871L840.019 278.597L843.871 271.83H851.465L855.317 278.597L851.465 285.365Z" fill="#CAE5E6" />
    <path d="M851.463 268.332H843.869L840.127 261.564L843.869 254.91H851.463L855.205 261.564L851.463 268.332Z" fill="#CAE5E6" />
    <path d="M849.261 247.468H846.07L844.419 244.648L846.07 241.716H849.261L850.912 244.648L849.261 247.468Z" fill="#CAE5E6" />
    <path d="M851.354 217.126H843.98L840.238 210.584L843.98 204.042H851.354L855.096 210.584L851.354 217.126Z" fill="#CAE5E6" />
    <path d="M851.465 200.431H843.871L840.019 193.551L843.871 186.783H851.465L855.317 193.551L851.465 200.431Z" fill="#CAE5E6" />
    <path d="M851.465 183.4H843.871L840.019 176.632L843.871 169.752H851.465L855.317 176.632L851.465 183.4Z" fill="#CAE5E6" />
    <path d="M851.465 166.367H843.871L840.019 159.6L843.871 152.832H851.465L855.317 159.6L851.465 166.367Z" fill="#CAE5E6" />
    <path d="M851.465 149.45H843.871L840.019 142.569L843.871 135.802H851.465L855.317 142.569L851.465 149.45Z" fill="#CAE5E6" />
    <path d="M851.465 132.419H843.871L840.019 125.651L843.871 118.771H851.465L855.317 125.651L851.465 132.419Z" fill="#CAE5E6" />
    <path d="M807.993 89.1064H801.17L797.758 83.1284L801.17 77.0376H807.993L811.405 83.1284L807.993 89.1064Z" fill="#CAE5E6" />
    <path d="M793.242 79.9702H787.189L784.107 74.5561L787.189 69.2549H793.242L796.324 74.5561L793.242 79.9702Z" fill="#CAE5E6" />
    <path d="M865.881 769.808H858.177L854.325 763.04L858.177 756.273H865.881L869.733 763.04L865.881 769.808Z" fill="#CAE5E6" />
    <path d="M865.881 752.891H858.177L854.325 746.01L858.177 739.243H865.881L869.733 746.01L865.881 752.891Z" fill="#CAE5E6" />
    <path d="M865.881 735.855H858.177L854.325 729.088L858.177 722.208H865.881L869.733 729.088L865.881 735.855Z" fill="#CAE5E6" />
    <path d="M865.881 718.825H858.177L854.325 712.057L858.177 705.29H865.881L869.733 712.057L865.881 718.825Z" fill="#CAE5E6" />
    <path d="M865.881 701.907H858.177L854.325 695.027L858.177 688.259H865.881L869.733 695.027L865.881 701.907Z" fill="#CAE5E6" />
    <path d="M865.881 684.876H858.177L854.325 678.108L858.177 671.228H865.881L869.733 678.108L865.881 684.876Z" fill="#CAE5E6" />
    <path d="M865.881 667.844H858.177L854.325 661.076L858.177 654.309H865.881L869.733 661.076L865.881 667.844Z" fill="#CAE5E6" />
    <path d="M865.881 650.813H858.177L854.325 644.046L858.177 637.278H865.881L869.733 644.046L865.881 650.813Z" fill="#CAE5E6" />
    <path d="M865.881 378.87H858.177L854.325 372.102L858.177 365.334H865.881L869.733 372.102L865.881 378.87Z" fill="#CAE5E6" />
    <path d="M864.01 324.616H860.048L858.066 321.12L860.048 317.51H864.01L865.991 321.12L864.01 324.616Z" fill="#CAE5E6" />
    <path d="M863.239 289.2H860.818L859.607 287.057L860.818 284.914H863.239L864.45 287.057L863.239 289.2Z" fill="#CAE5E6" />
    <path d="M864.448 274.423H859.605L857.074 270.137L859.605 265.738H864.448L866.979 270.137L864.448 274.423Z" fill="#CAE5E6" />
    <path d="M864.892 258.294H859.169L856.197 253.106L859.169 247.917H864.892L867.863 253.106L864.892 258.294Z" fill="#CAE5E6" />
    <path d="M865.881 208.893H858.177L854.325 202.125L858.177 195.245H865.881L869.733 202.125L865.881 208.893Z" fill="#CAE5E6" />
    <path d="M865.881 191.861H858.177L854.325 185.093L858.177 178.326H865.881L869.733 185.093L865.881 191.861Z" fill="#CAE5E6" />
    <path d="M865.881 174.942H858.177L854.325 168.062L858.177 161.294H865.881L869.733 168.062L865.881 174.942Z" fill="#CAE5E6" />
    <path d="M865.881 157.909H858.177L854.325 151.142L858.177 144.261H865.881L869.733 151.142L865.881 157.909Z" fill="#CAE5E6" />
    <path d="M865.881 140.877H858.177L854.325 134.109L858.177 127.342H865.881L869.733 134.109L865.881 140.877Z" fill="#CAE5E6" />
    <path d="M863.018 118.882H861.037L860.047 117.078L861.037 115.386H863.018L864.009 117.078L863.018 118.882Z" fill="#CAE5E6" />
    <path d="M880.186 778.381H872.592L868.74 771.5L872.592 764.733H880.186L884.038 771.5L880.186 778.381Z" fill="#CAE5E6" />
    <path d="M880.188 761.346H872.594L868.742 754.578L872.594 747.811H880.188L884.04 754.578L880.188 761.346Z" fill="#CAE5E6" />
    <path d="M880.186 744.317H872.592L868.74 737.55L872.592 730.782H880.186L884.038 737.55L880.186 744.317Z" fill="#CAE5E6" />
    <path d="M880.186 727.398H872.592L868.74 720.518L872.592 713.75H880.186L884.038 720.518L880.186 727.398Z" fill="#CAE5E6" />
    <path d="M880.188 710.365H872.594L868.742 703.597L872.594 696.717H880.188L884.04 703.597L880.188 710.365Z" fill="#CAE5E6" />
    <path d="M880.186 693.334H872.592L868.74 686.566L872.592 679.799H880.186L884.038 686.566L880.186 693.334Z" fill="#CAE5E6" />
    <path d="M880.188 676.414H872.594L868.742 669.534L872.594 662.766H880.188L884.04 669.534L880.188 676.414Z" fill="#CAE5E6" />
    <path d="M877.325 603.213H875.454L874.573 601.521L875.454 599.942H877.325L878.205 601.521L877.325 603.213Z" fill="#CAE5E6" />
    <path d="M880.188 591.371H872.594L868.742 584.604L872.594 577.724H880.188L884.04 584.604L880.188 591.371Z" fill="#CAE5E6" />
    <path d="M879.749 369.621H873.036L869.624 363.643L873.036 357.552H879.749L883.161 363.643L879.749 369.621Z" fill="#CAE5E6" />
    <path d="M878.649 350.559H874.137L871.936 346.611L874.137 342.664H878.649L880.85 346.611L878.649 350.559Z" fill="#CAE5E6" />
    <path d="M877.769 331.948H875.127L873.807 329.58L875.127 327.211H877.769L879.09 329.58L877.769 331.948Z" fill="#CAE5E6" />
    <path d="M879.86 318.752H872.926L869.515 312.548L872.926 306.458H879.86L883.272 312.548L879.86 318.752Z" fill="#CAE5E6" />
    <path d="M877.986 281.417H874.794L873.253 278.597L874.794 275.777H877.986L879.526 278.597L877.986 281.417Z" fill="#CAE5E6" />
    <path d="M880.081 217.126H872.707L868.965 210.584L872.707 204.042H880.081L883.823 210.584L880.081 217.126Z" fill="#CAE5E6" />
    <path d="M880.188 200.431H872.594L868.742 193.551L872.594 186.783H880.188L884.04 193.551L880.188 200.431Z" fill="#CAE5E6" />
    <path d="M880.186 183.4H872.592L868.74 176.632L872.592 169.752H880.186L884.038 176.632L880.186 183.4Z" fill="#CAE5E6" />
    <path d="M880.188 166.367H872.594L868.742 159.6L872.594 152.832H880.188L884.04 159.6L880.188 166.367Z" fill="#CAE5E6" />
    <path d="M880.186 149.45H872.592L868.74 142.569L872.592 135.802H880.186L884.038 142.569L880.186 149.45Z" fill="#CAE5E6" />
    <path d="M880.186 132.419H872.592L868.74 125.651L872.592 118.771H880.186L884.038 125.651L880.186 132.419Z" fill="#CAE5E6" />
    <path d="M894.606 786.839H886.902L883.16 780.071L886.902 773.304H894.606L898.458 780.071L894.606 786.839Z" fill="#CAE5E6" />
    <path d="M894.606 769.808H886.902L883.16 763.04L886.902 756.273H894.606L898.458 763.04L894.606 769.808Z" fill="#CAE5E6" />
    <path d="M894.606 752.891H886.902L883.16 746.01L886.902 739.243H894.606L898.458 746.01L894.606 752.891Z" fill="#CAE5E6" />
    <path d="M894.606 735.855H886.902L883.16 729.088L886.902 722.208H894.606L898.458 729.088L894.606 735.855Z" fill="#CAE5E6" />
    <path d="M894.606 718.825H886.902L883.16 712.057L886.902 705.29H894.606L898.458 712.057L894.606 718.825Z" fill="#CAE5E6" />
    <path d="M894.606 701.907H886.902L883.16 695.027L886.902 688.259H894.606L898.458 695.027L894.606 701.907Z" fill="#CAE5E6" />
    <path d="M894.606 684.876H886.902L883.16 678.108L886.902 671.228H894.606L898.458 678.108L894.606 684.876Z" fill="#CAE5E6" />
    <path d="M892.295 663.783H889.213L887.782 661.076L889.213 658.369H892.295L893.835 661.076L892.295 663.783Z" fill="#CAE5E6" />
    <path d="M894.498 616.637H887.014L883.382 610.095L887.014 603.44H894.498L898.24 610.095L894.498 616.637Z" fill="#CAE5E6" />
    <path d="M894.606 599.83H886.902L883.16 593.062L886.902 586.294H894.606L898.458 593.062L894.606 599.83Z" fill="#CAE5E6" />
    <path d="M891.634 577.723H889.873L888.883 576.031L889.873 574.452H891.634L892.625 576.031L891.634 577.723Z" fill="#CAE5E6" />
    <path d="M893.178 223.44H888.335L885.914 219.041L888.335 214.755H893.178L895.599 219.041L893.178 223.44Z" fill="#CAE5E6" />
    <path d="M894.606 208.893H886.902L883.16 202.125L886.902 195.245H894.606L898.458 202.125L894.606 208.893Z" fill="#CAE5E6" />
    <path d="M894.606 191.861H886.902L883.16 185.093L886.902 178.326H894.606L898.458 185.093L894.606 191.861Z" fill="#CAE5E6" />
    <path d="M894.606 174.942H886.902L883.16 168.062L886.902 161.294H894.606L898.458 168.062L894.606 174.942Z" fill="#CAE5E6" />
    <path d="M894.606 157.909H886.902L883.16 151.142L886.902 144.261H894.606L898.458 151.142L894.606 157.909Z" fill="#CAE5E6" />
    <path d="M894.496 140.765H887.012L883.27 134.11L887.012 127.455H894.496L898.237 134.11L894.496 140.765Z" fill="#CAE5E6" />
    <path d="M908.913 778.381H901.319L897.467 771.5L901.319 764.733H908.913L912.765 771.5L908.913 778.381Z" fill="#CAE5E6" />
    <path d="M908.911 761.346H901.317L897.465 754.578L901.317 747.811H908.911L912.763 754.578L908.911 761.346Z" fill="#CAE5E6" />
    <path d="M908.913 744.317H901.319L897.467 737.55L901.319 730.782H908.913L912.765 737.55L908.913 744.317Z" fill="#CAE5E6" />
    <path d="M908.913 727.398H901.319L897.467 720.518L901.319 713.75H908.913L912.765 720.518L908.913 727.398Z" fill="#CAE5E6" />
    <path d="M908.911 710.365H901.317L897.465 703.597L901.317 696.717H908.911L912.763 703.597L908.911 710.365Z" fill="#CAE5E6" />
    <path d="M908.913 693.334H901.319L897.467 686.566L901.319 679.799H908.913L912.765 686.566L908.913 693.334Z" fill="#CAE5E6" />
    <path d="M908.911 676.414H901.317L897.465 669.534L901.317 662.766H908.911L912.763 669.534L908.911 676.414Z" fill="#CAE5E6" />
    <path d="M908.582 658.597H901.758L898.236 652.619L901.758 646.528H908.582L911.994 652.619L908.582 658.597Z" fill="#CAE5E6" />
    <path d="M906.381 637.728H903.849L902.639 635.584L903.849 633.329H906.381L907.591 635.584L906.381 637.728Z" fill="#CAE5E6" />
    <path d="M908.693 624.869H901.65L898.018 618.552L901.65 612.349H908.693L912.215 618.552L908.693 624.869Z" fill="#CAE5E6" />
    <path d="M908.913 608.402H901.319L897.467 601.522L901.319 594.754H908.913L912.765 601.522L908.913 608.402Z" fill="#CAE5E6" />
    <path d="M908.911 200.318H901.317L897.465 193.551L901.317 186.783H908.911L912.763 193.551L908.911 200.318Z" fill="#CAE5E6" />
    <path d="M908.913 183.4H901.319L897.467 176.632L901.319 169.752H908.913L912.765 176.632L908.913 183.4Z" fill="#CAE5E6" />
    <path d="M908.911 166.367H901.317L897.465 159.6L901.317 152.832H908.911L912.763 159.6L908.911 166.367Z" fill="#CAE5E6" />
    <path d="M908.913 149.45H901.319L897.467 142.569L901.319 135.802H908.913L912.765 142.569L908.913 149.45Z" fill="#CAE5E6" />
    <path d="M921.791 784.133H917.169L914.857 780.073L917.169 776.012H921.791L924.102 780.073L921.791 784.133Z" fill="#CAE5E6" />
    <path d="M923.333 769.808H915.629L911.887 763.04L915.629 756.273H923.333L927.185 763.04L923.333 769.808Z" fill="#CAE5E6" />
    <path d="M923.331 752.891H915.627L911.885 746.01L915.627 739.243H923.331L927.183 746.01L923.331 752.891Z" fill="#CAE5E6" />
    <path d="M923.333 735.855H915.629L911.887 729.088L915.629 722.208H923.333L927.185 729.088L923.333 735.855Z" fill="#CAE5E6" />
    <path d="M923.331 718.825H915.627L911.885 712.057L915.627 705.29H923.331L927.183 712.057L923.331 718.825Z" fill="#CAE5E6" />
    <path d="M923.333 701.907H915.629L911.887 695.027L915.629 688.259H923.333L927.185 695.027L923.333 701.907Z" fill="#CAE5E6" />
    <path d="M922.781 683.975H916.178L912.876 678.109L916.178 672.131H922.781L926.083 678.109L922.781 683.975Z" fill="#CAE5E6" />
    <path d="M923.221 616.637H915.737L911.995 610.095L915.737 603.44H923.221L926.963 610.095L923.221 616.637Z" fill="#CAE5E6" />
    <path d="M920.249 271.376H918.818L918.048 270.136L918.818 268.782H920.249L920.909 270.136L920.249 271.376Z" fill="#CAE5E6" />
    <path d="M923.004 259.42H915.96L912.328 253.104L915.96 246.788H923.004L926.636 253.104L923.004 259.42Z" fill="#CAE5E6" />
    <path d="M923.331 242.842H915.627L911.885 236.074L915.627 229.307H923.331L927.183 236.074L923.331 242.842Z" fill="#CAE5E6" />
    <path d="M923.224 225.699H915.74L912.108 219.044L915.74 212.502H923.224L926.966 219.044L923.224 225.699Z" fill="#CAE5E6" />
    <path d="M921.353 205.397H917.611L915.85 202.126L917.611 198.855H921.353L923.224 202.126L921.353 205.397Z" fill="#CAE5E6" />
    <path d="M923.331 191.861H915.627L911.885 185.093L915.627 178.326H923.331L927.183 185.093L923.331 191.861Z" fill="#CAE5E6" />
    <path d="M923.333 174.942H915.629L911.887 168.062L915.629 161.294H923.333L927.185 168.062L923.333 174.942Z" fill="#CAE5E6" />
    <path d="M923.331 157.909H915.627L911.885 151.142L915.627 144.261H923.331L927.183 151.142L923.331 157.909Z" fill="#CAE5E6" />
    <path d="M923.224 140.652H915.85L912.108 134.11L915.85 127.568H923.224L926.856 134.11L923.224 140.652Z" fill="#CAE5E6" />
    <path d="M936.758 759.768H930.925L928.063 754.58L930.925 749.392H936.758L939.73 754.58L936.758 759.768Z" fill="#CAE5E6" />
    <path d="M937.641 744.317H930.047L926.195 737.55L930.047 730.782H937.641L941.493 737.55L937.641 744.317Z" fill="#CAE5E6" />
    <path d="M937.641 727.17H930.157L926.305 720.515L930.157 713.86H937.641L941.383 720.515L937.641 727.17Z" fill="#CAE5E6" />
    <path d="M936.428 707.998H931.365L928.834 703.599L931.365 699.087H936.428L938.959 703.599L936.428 707.998Z" fill="#CAE5E6" />
    <path d="M935.331 621.146H932.359L930.929 618.552L932.359 615.958H935.331L936.762 618.552L935.331 621.146Z" fill="#CAE5E6" />
    <path d="M937.526 251.077H930.262L926.63 244.648L930.262 238.106H937.526L941.158 244.648L937.526 251.077Z" fill="#CAE5E6" />
    <path d="M937.639 234.267H930.155L926.413 227.612L930.155 220.958H937.639L941.381 227.612L937.639 234.267Z" fill="#CAE5E6" />
    <path d="M937.641 217.349H930.047L926.305 210.581L930.047 203.927H937.641L941.383 210.581L937.641 217.349Z" fill="#CAE5E6" />
    <path d="M937.641 200.431H930.047L926.195 193.551L930.047 186.783H937.641L941.493 193.551L937.641 200.431Z" fill="#CAE5E6" />
    <path d="M937.641 183.4H930.047L926.195 176.632L930.047 169.752H937.641L941.493 176.632L937.641 183.4Z" fill="#CAE5E6" />
    <path d="M937.641 166.367H930.047L926.195 159.6L930.047 152.832H937.641L941.493 159.6L937.641 166.367Z" fill="#CAE5E6" />
    <path d="M937.641 149.45H930.047L926.195 142.569L930.047 135.802H937.641L941.493 142.569L937.641 149.45Z" fill="#CAE5E6" />
    <path d="M935.547 128.583H932.135L930.484 125.65L932.135 122.605H935.547L937.198 125.65L935.547 128.583Z" fill="#CAE5E6" />
    <path d="M949.636 697.621H946.775L945.454 695.027L946.775 692.545H949.636L951.067 695.027L949.636 697.621Z" fill="#CAE5E6" />
    <path d="M949.195 679.799H947.214L946.224 678.107L947.214 676.303H949.195L950.186 678.107L949.195 679.799Z" fill="#CAE5E6" />
    <path d="M952.056 208.891H944.462L940.61 202.123L944.462 195.356H952.056L955.909 202.123L952.056 208.891Z" fill="#CAE5E6" />
    <path d="M952.056 191.861H944.352L940.61 185.093L944.352 178.326H952.056L955.909 185.093L952.056 191.861Z" fill="#CAE5E6" />
    <path d="M952.056 174.942H944.352L940.61 168.062L944.352 161.294H952.056L955.909 168.062L952.056 174.942Z" fill="#CAE5E6" />
    <path d="M952.056 157.909H944.352L940.61 151.142L944.352 144.261H952.056L955.909 151.142L952.056 157.909Z" fill="#CAE5E6" />
    <path d="M952.056 140.877H944.462L940.61 134.109L944.462 127.342H952.056L955.909 134.109L952.056 140.877Z" fill="#CAE5E6" />
    <path d="M963.724 212.614H961.522L960.422 210.583L961.522 208.666H963.724L964.824 210.583L963.724 212.614Z" fill="#CAE5E6" />
    <path d="M966.478 200.431H958.774L954.922 193.551L958.774 186.783H966.478L970.22 193.551L966.478 200.431Z" fill="#CAE5E6" />
    <path d="M966.476 183.4H958.772L954.92 176.632L958.772 169.752H966.476L970.218 176.632L966.476 183.4Z" fill="#CAE5E6" />
    <path d="M966.478 166.367H958.774L954.922 159.6L958.774 152.832H966.478L970.22 159.6L966.478 166.367Z" fill="#CAE5E6" />
    <path d="M966.476 149.45H958.772L954.92 142.569L958.772 135.802H966.476L970.218 142.569L966.476 149.45Z" fill="#CAE5E6" />
    <path d="M980.781 191.861H973.077L969.335 185.093L973.077 178.326H980.781L984.633 185.093L980.781 191.861Z" fill="#CAE5E6" />
    <path d="M980.781 174.942H973.077L969.335 168.062L973.077 161.294H980.781L984.633 168.062L980.781 174.942Z" fill="#CAE5E6" />
    <path d="M980.781 157.909H973.077L969.335 151.142L973.077 144.261H980.781L984.633 151.142L980.781 157.909Z" fill="#CAE5E6" />
    <path d="M977.809 135.69H976.048L975.168 134.111L976.048 132.532H977.809L978.69 134.111L977.809 135.69Z" fill="#CAE5E6" />
    <path d="M992.448 195.468H990.247L989.146 193.55L990.247 191.633H992.448L993.439 193.55L992.448 195.468Z" fill="#CAE5E6" />
    <path d="M993.661 180.692H989.038L986.617 176.631L989.038 172.458H993.661L995.972 176.631L993.661 180.692Z" fill="#CAE5E6" />
    <path d="M995.198 166.367H987.494L983.642 159.6L987.494 152.832H995.198L998.94 159.6L995.198 166.367Z" fill="#CAE5E6" />
    <path d="M994.427 148.097H988.154L985.072 142.57L988.154 137.043H994.427L997.509 142.57L994.427 148.097Z" fill="#CAE5E6" />
    <path d="M1008.3 172.799H1003.01L1000.37 168.062L1003.01 163.437H1008.3L1011.05 168.062L1008.3 172.799Z" fill="#CAE5E6" />
    <path d="M1008.19 155.541H1003.23L1000.7 151.143L1003.23 146.744H1008.19L1010.61 151.143L1008.19 155.541Z" fill="#CAE5E6" />
    <path d="M1022.17 180.354H1017.98L1015.78 176.632L1017.98 172.797H1022.17L1024.26 176.632L1022.17 180.354Z" fill="#CAE5E6" />
    <path d="M1022.27 163.549H1017.87L1015.56 159.602L1017.87 155.654H1022.27L1024.48 159.602L1022.27 163.549Z" fill="#CAE5E6" />
    <path d="M1038.23 786.726H1030.64L1026.9 780.071L1030.64 773.304H1038.23L1041.97 780.071L1038.23 786.726Z" fill="#CAE5E6" />
    <path d="M1035.15 764.395H1033.61L1032.95 763.041L1033.61 761.688H1035.15L1035.92 763.041L1035.15 764.395Z" fill="#CAE5E6" />
    <path d="M1049.9 773.531H1047.7L1046.6 771.5L1047.7 769.583H1049.9L1051 771.5L1049.9 773.531Z" fill="#CAE5E6" />
  </svg>
  );
}

export default ResourceMapSvg;

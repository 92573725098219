import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "../../Assets/Images";
import ButtonFilledColor from "../../Components/ButtonFilledColor";
import RightIconButton from "../../Components/RightIconButton";
import OurSevice from "./OurSevice";
import { useTranslation } from "react-i18next";

const ArabicBenifitFromOurService = ({ onClick }) => {
  const { t, i18n } = useTranslation();

  const Tile = ({ item, isMebel = false }) => {
    return (
      <div
        className="arabic-benifitTileContainer"
        style={{ backgroundColor: "#00578A" }}
      >
        <div className="image_benifit">
          <img src={item?.icon} alt="new" className="img_benifit_over_s" />
        </div>
        <div className="text_benifit_width">
          <h5 className={"arabic-partnershipText"}>{t(item?.heading) || ""}</h5>
        </div>
        <div className="button_benifit" style={{ width: "28%" }}>
          <ButtonFilledColor
            buttonfilledcolorclass={"button_benifitfont"}
            onClick={
              // handleClick('benefit');
              onClick
            }
            title={"enquire_now"}
            btnColor={"#FF6700"}
            height={"3vh"}
            width="100%"
            fontSize={"1vw"}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="rebound_benifit">
      <div className="row_benifit">
        <div className="box_benifit">
          <div className="width_textContainer">
            <div className="text_benifit">
              {t("who_can_benefit_from_our_services_description")}
            </div>
            <div className="text_benifit">{t("if_out_service_sector")}</div>
          </div>
        </div>
      </div>
      <div style={{}}>
        <Container>
          <Row>
            {data.map((item, index) => (
              <Col xs={12} md={6} lg={6} key={item?.id}>
                <Tile
                  item={item}
                  titleLast={true}
                  isMebel={index === 0 ? true : false}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ArabicBenifitFromOurService;

const data = [
  {
    id: 1,
    heading: "who_can_benefit_from_our_services_point1",
    icon: IMAGES.benefit,
  },

  {
    id: 2,
    heading: "who_can_benefit_from_our_services_point2",
    icon: IMAGES.factory,
  },
  {
    id: 3,
    heading: "who_can_benefit_from_our_services_point3",
    icon: IMAGES.benefit2,
  },
  {
    id: 4,
    heading: "who_can_benefit_from_our_services_point4",
    icon: IMAGES.benefit3,
  },
  {
    id: 5,
    heading: "who_can_benefit_from_our_services_point5",
    icon: IMAGES.benefit4,
  },

  {
    id: 6,
    heading: "who_can_benefit_from_our_services_point6",
    icon: IMAGES.benefit5,
  },
  {
    id: 7,
    heading: "who_can_benefit_from_our_services_point7",
    icon: IMAGES.benefit6,
  },
  {
    id: 8,
    heading: "who_can_benefit_from_our_services_point8",
    icon: IMAGES.benefit7,
  },
  {
    id: 9,
    heading: "who_can_benefit_from_our_services_point9",
    icon: IMAGES.benefit8,
  },
  {
    id: 10,
    heading: "who_can_benefit_from_our_services_point10",
    icon: IMAGES.benefit9,
  },
];

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Teamphoto from "Assets/Images/Team Photo.jpeg";
import ArabicTopTabBar from "Components/Arabic_TopTabBar";
import Carousel from "Components/ImageScroll/ArabicCarousel";

import { getCurrentDimension } from "Utils/Helper";
import ArabicTimeLine from "Screens/AboutUs/Arabic-Timeline";
import ArabicWhatWeDo from "Screens/AboutUs/Arabic-WhatWeD0";
import ArabicWorkWithUs from "Screens/AboutUs/ArabicWorkWithUs";

var stopScoll = false;
function Arabic_AboutUs() {
  const dimensions = getCurrentDimension();
  const what_we_doRef = useRef(null);
  const the_visionariesRef = useRef(null);
  const [scroll, setScroll] = useState(0);
  const [state, setState] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Timeline");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      stopScoll = true;
    }, 1000);
  }, [state]);

  useEffect(() => {
    setState(!state);
  }, []);

  useEffect(() => {
    if (dimensions?.width > 768) {
      const onScroll = () => {
        setScroll(window.scrollY);
      };
      window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }

  }, []);

  useEffect(() => {
    if (stopScoll && dimensions?.width > 768) {
      ScrollHandle();
    }
  }, [scroll]);

  const ScrollHandle = () => {
    const timeline = document.querySelector(".second_bae_header");
    const what_we_do = document.querySelector(".we_to_do");
    const the_visionaries = document.querySelector(".work_with_us");

    if (scroll >= timeline?.offsetTop - 100) {
      setSelectedTab("Timeline");
    }
    if (scroll >= what_we_do?.offsetTop - 110) {
      setSelectedTab("What_we_do");
    }
    if (scroll >= the_visionaries?.offsetTop - 120) {
      setSelectedTab("The_visionaries");
    }
  };

  const handleClickTab = (item, isClick) => {
    if (isClick) {
      stopScoll = false;
      setState(!state);
    }
    switch (item) {
      case TAB_DATA3[1].id:
        window.scrollTo(0, 0);
        break;
      case TAB_DATA3[2]?.id:
        what_we_doRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
        break;
      case TAB_DATA3[3]?.id:
        the_visionariesRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
        break;
    }
  };

  return (
    <>
      <div className="container_about_us">
        <div className="second_bae_header">
          <ArabicTopTabBar
            selected_tab={selectedTab}
            tabBarData={TAB_DATA3}
            clickTab={(item, isClick) => handleClickTab(item, isClick)}
          />
        </div>
        {/* <div className="img_abousUs">
          <img className="imgstyle" src={bgi} />
        </div> */}
        <div className="shadowaboutus"></div>

        <div className="Arabic-child">
          <div>
            <div className="Arabic-center-about-us">
              {/* <TextAnimation
              text={'timeline_of_our_journey'}
              classNameText='head1'
            /> */}
              <p className="Arabic-text_top_header_rebound">
                {t("timeline_of_our_journey")}
              </p>
            </div>
          </div>
          <div className="Arabic-textcol uper-description f_regular reboundmainimageheading">
            <p>{t("timeline_description1")}</p>
            <p>{t("timeline_description2")}</p>
          </div>
        </div>
      </div>
      <ArabicTimeLine />
      <div className="we_to_do" ref={what_we_doRef}>
        <ArabicWhatWeDo />
      </div>
      <div className="bottom_image_carr">
        <img src={Teamphoto} className="teamphoto" />
      </div>
      <div className="work_with_us" ref={the_visionariesRef}>
        <ArabicWorkWithUs />
      </div>
      <div className="carouselmaincontainer">
        {/* <img src={IMAGES.sliderImage} className="teamphoto" /> */}
        <Carousel />
      </div>
    </>
  );
}

export default Arabic_AboutUs;
const TAB_DATA3 = [
  {
    id: "About_Us",
    title: "about_us_heading",
    isSelected: false,
    isFirst: true,
  },
  {
    id: "Timeline",
    title: "timeline_heading",
    isSelected: true,
  },
  {
    id: "What_we_do",
    title: "what_we_do_heading",
    isSelected: false,
  },
  {
    id: "The_visionaries",
    title: "the_visionaries_heading1",
    isSelected: false,
  },
];

import React, { useState } from "react";
import Frame from "Assets/Images/Frame .png";
import Frame2 from "Assets/Images/bg_impact.png";
import ImpactTopImage from "Assets/Images/ImpactArabic.png";
import AnimatedNumbers from "react-animated-numbers";
import ImpactMapSvg from "Assets/SVGs/icon/ImpactMapSvg";
import { getCurrentDimension } from "Utils/Helper";
import { useTranslation } from "react-i18next";

function ArabicImpact({ navigation }) {
  const [counterValue, setCounterValue] = useState(8404.28);
  const [countries, setCountries] = useState(60);
  const dimenssion = getCurrentDimension();
  const { t, i18n } = useTranslation();

  const handleCounterUpdate = (increment) => {
    // setCounterValue(0.00)
    // setTimeout(() => {
    //   setCounterValue(8404.28)
    // }, 10)
  };

  const handleCountries = (increment) => {
    // setCountries(0.00)
    // setTimeout(() => {
    //   setCountries(60)
    // }, 10)
  };

  return (
    <div>
      <div className="impect_gradient"></div>

      <div className="impact-top-container">
        <div className="Arabic-impact-linear-container">
          <div
            className="impact_linear"
            style={{ marginLeft: "5%", marginTop: "8%" }}
          >
            <p className="text_top_header_rebound Arabic-Banner1heading text-align-end">
              {t("impact")}
            </p>
            {/* <p className="Banner1text">
                 {t('impact_making_a_difference')}
            </p> */}
            <div
            style={{
              width:'100%',
              display:'flex',
              alignItems:'end',
              justifyContent:'end',
            }}
            >
            <p className=" Arabic-Banner1text text-align-end">{t("impact_making_a_difference")}</p>
            </div>
            {/* <p className="Banner1content">
          {t('impact_commitment_description1')}
          </p> */}
            <div
            style={{
              width:'100%',
              display:'flex',
              alignItems:'end',
              justifyContent:'end',
            }}
            >
            <p className=" Arabic-Banner1content text-align-end">
              {t("impact_commitment_description1")}
            </p>
            </div>
          </div>
        </div>
        <div style={{ height: "100%", width: "50%",display:'contents', }}></div>
        <img src={ImpactTopImage} className="ImpactBanner1container" />
      </div>
      <div className="impact-map-container">
        <div className="impact-map-content-container">
          <div className="banner2detailcontainer">
            <p className="banner2heading">{t("global_reach")}</p>
            <p className="banner2content">{t("globalreach_description")}</p>
          </div>
        </div>
        <div className="impace-map-svg-countries-container">
          <div style={{ width: "80%", position: "relative" }}>
            <ImpactMapSvg />

            <div
              className="Arabic-banner2country"
              onMouseEnter={() => handleCountries(0)}
            >
              <p className="Arabic-banner2text f_extra_bold">
                <AnimatedNumbers
                  animateToNumber={countries}
                  fontStyle={{ fontSize: "7vw" }}
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 200 * (index + 1),
                      friction: 110,
                    };
                  }}
                ></AnimatedNumbers>
              </p>
              <p
                className="banner2countres f_regular"
                style={{ paddingBottom: 12 }}
              >
                {t("countries")}
              </p>
            </div>
          </div>
          <div style={{ width: "20%" }}></div>
        </div>
      </div>
      <div className="banner3maincontainer">
        <div className="banner3headingcontainer">
          <img src={Frame} className="banner3img" />
          <img src={Frame2} className="banner3img3" />
          <div className="banner3heading">
            <div onMouseEnter={() => handleCounterUpdate(0)}>
              <p className="december">
                {/* <AnimatedCounter value={counterValue} color="#FF6700" fontSize="48px" />
                 */}
                <AnimatedNumbers
                  animateToNumber={counterValue}
                  fontStyle={{ fontSize: "4vw" }}
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 200 * (index + 1),
                      friction: 110,
                    };
                  }}
                ></AnimatedNumbers>{" "}
                MT
              </p>
            </div>

            <div>
              <p className="Asof">{t("reducedCarbon_emission")}</p>
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                color: "#fff",
                fontFamily: "nexa",
              }}
            >
              <p className="banner3secondheadingmain tt-none">
                {t("plastics_diverted_from_the_environment")}
              </p>
              <p style={{ fontSize: "1.2vw",textAlign:'center' }}>{t("our_impact_description")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="banner4mainconitainer">
        <p className="banner4paragraph ">{t("thanks_you_text_imapact")}</p>
        <p className="banner4paragraph">
          {t(
            "Together_we_are_giving_plastic_a_second_chance_and_making_a_lasting_difference"
          )}
        </p>
      </div>
    </div>
  );
}

export default ArabicImpact;

import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import Maryam from "Assets/Gallery Arbaic Names/Maryam Al Mansoori - General Manager-arabic.png";
import shabab from "Assets/Gallery Arbaic Names/Shabab Said Mohamed - Operations & Business Development Director.bcae571350ac31789cac-Arabic.png";
import sarah from "Assets/Gallery Arbaic Names/Sarah Safi - Environmental Economist.de29aa68273a96ac1613-Arabic.png";
import reem from "Assets/Gallery Arbaic Names/Reem Bayoud - Projects Coordinator.3011bd88eb91d420c62b-Arabic.png";
import nimisha from "Assets/Gallery Arbaic Names/Nimisha Boney - Polymer Analyst.9040d4f7b131e5eacada-Arabic.png";
import mohd_imran from "Assets/Gallery Arbaic Names/Mohammad Imran -Accountant.5a2123b47e0783807173-Arabic.png";
import loyd from "Assets/Gallery Arbaic Names/Loyd Tenorio - Operations Associate.95327a8ea243dc89384f-Arabic.png";
import alvin from "Assets/Gallery Arbaic Names/Alvin Pereira - Finance & Admin Director.63cef2390da7b6cf15f2_Arabic.png";
import mohd_ismail from "Assets/Gallery Arbaic Names/Mohamed Ismail - IT Manager.8c85668da806b40d8fdc-Arabic.png";
import nick from "Assets/Gallery Arbaic Names/Nick Image Arabic.png";
import tapas from "Assets/Gallery Arbaic Names/Tapas Sharma - Head of Quality Control.d47273f132b46f5df590-Arabic.png";
import yunn from "Assets/Gallery Arbaic Names/Yunn Tuck Choo -Sales Consultant - ASEAN.8ddf9ad50ac0c22ad263-Arabic.png";
import yasmin from "Assets/Gallery Arbaic Names/Yassamin Homayouni - Media & Communications Specialist-Arabic.png";
import tawseef from "Assets/Gallery Arbaic Names/Tawseef Bazaz - Sales Consultant - India-Arabic.png";
import deffrin from "Assets/Gallery Arbaic Names/Deffrin Joseph - Software Developer-Arabic.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IMAGES } from "Assets/Images";
const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // const elems = document.getElementsByClassName('pics');
      // const b = elems[0].className;
      // elems[0].className = elems[1].className;
      // elems[1].className = elems[2].className;
      // elems[2].className = elems[3].className;
      // elems[3].className = elems[4].className;
      // elems[4].className = elems[5].className;
      // elems[5].className = elems[6].className;
      // elems[6].className = elems[7].className;
      // elems[7].className = elems[8].className;
      // elems[8].className = elems[9].className;
      // elems[9].className = elems[10].className;
      // elems[10].className = elems[11].className;
      // elems[11].className = elems[12].className;
      // elems[12].className = elems[13].className;
      // elems[13].className = elems[14].className;
      // elems[14].className = b;
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

 
  const handleNextSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[0].className;
    elems[0].className = elems[1].className;
    elems[1].className = elems[2].className;
    elems[2].className = elems[3].className;
    elems[3].className = elems[4].className;
    elems[4].className = elems[5].className;
    elems[5].className = elems[6].className;
    elems[6].className = elems[7].className;
    elems[7].className = elems[8].className;
    elems[8].className = elems[9].className;
    elems[9].className = elems[10].className;
    elems[10].className = elems[11].className;
    elems[11].className = elems[12].className;
    elems[12].className = elems[13].className;
    elems[13].className = elems[14].className;
    elems[14].className = b;
  };
  const handlePreviousSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[14].className;
    elems[14].className = elems[13].className;
    elems[13].className = elems[12].className;
    elems[12].className = elems[11].className;
    elems[11].className = elems[10].className;
    elems[10].className = elems[9].className;
    elems[9].className = elems[8].className;
    elems[8].className = elems[7].className;
    elems[7].className = elems[6].className;
    elems[6].className = elems[5].className;
    elems[5].className = elems[4].className;
    elems[4].className = elems[3].className;
    elems[3].className = elems[2].className;
    elems[2].className = elems[1].className;
    elems[1].className = elems[0].className;
    elems[0].className = b;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () =>  handlePreviousSlide(),
    onSwipedRight: () => handleNextSlide(),
  });

  return (
    <>
      <div className="container" {...handlers}>
        <div id="carousel">
          <div className="pics pic1">
            <img style={{ height: "316px", width: "271px" }} src={Maryam} />
          </div>
          <div className="pics pic2">
            <img style={{ height: "316px", width: "271px" }} src={shabab} />
          </div>
          <div className="pics pic3">
            <img style={{ height: "316px", width: "271px" }} src={nick} />
          </div>
          <div className="pics pic4">
            <img style={{ height: "316px", width: "271px" }} src={sarah} />
          </div>
          <div className="pics pic5">
            <img style={{ height: "316px", width: "271px" }} src={reem} />
          </div>
          <div className="pics pic6">
            <img style={{ height: "316px", width: "271px" }} src={nimisha} />
          </div>
          <div className="pics pic7">
            <img style={{ height: "316px", width: "271px" }} src={mohd_imran} />
          </div>
          <div className="pics pic8">
            <img style={{ height: "316px", width: "271px" }} src={loyd} />
          </div>
          <div className="pics pic9">
            <img style={{ height: "316px", width: "271px" }} src={tawseef} />
          </div>
          <div className="pics pic10">
            <img
              style={{ height: "316px", width: "271px" }}
              src={mohd_ismail}
            />
          </div>
          {/* <div className="pics pic10">
            <img style={{ height: "316px", width: "271px" }} src={hazem} />
          </div> */}
          <div className="pics pic11">
            <img style={{ height: "316px", width: "271px" }} src={tapas} />
          </div>
          <div className="pics pic12">
            <img style={{ height: "316px", width: "271px" }} src={deffrin} />
          </div>
          <div className="pics pic13">
            <img style={{ height: "316px", width: "271px" }} src={yunn} />
          </div>
          <div className="pics pic14">
            <img style={{ height: "316px", width: "271px" }} src={yasmin} />
          </div>
          <div className="pics pic15">
            <img style={{ height: "316px", width: "271px" }} src={alvin} />
          </div>
        </div>
      </div>
      <div className="controls" style={{}}>
        
        <img
          style={{ height: 48, width: 48, marginLeft: 10, }}
          src={IMAGES.forwordArrow}
          onClick={() => handleNextSlide()}
        />
        <img
          style={{ height: 48, width: 48, marginRight: 10, transform:`rotate(${180}deg)` }}
          src={IMAGES.forwordArrow}
          onClick={() => handlePreviousSlide()}
        />
      </div> 
    </>
  );
};

export default Carousel;

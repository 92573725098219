import React, { useState } from "react";
import BorderButton from "Components/BorderButton";
import ButtonFilledColor from "Components/ButtonFilledColor";
import Events from "Screens/NewsAndMediaGallery/Events";
import News from "Screens/NewsAndMediaGallery/News";
import TextSlideUp from "Components/TextSlideUp";

const NewsAndMediaGallery = ({ navigation }) => {
  const [state, setState] = useState(RBOUND_SET_DATA);
  const [selected, setSelected] = useState("News");

  const handleClick = (selectedItem) => {
    setSelected(selectedItem.id);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem.id ? true : false,
      };
    });
    setState(new_data);
  };
  return (
    <div className={`NewsAndMediaGallery_container ${selected === 'News' ? "backgraung_image_news_gallery2" : "backgraung_image_news_gallery"}`}
    style={{marginBottom:40}}
    >
      {/* <h1 className="textHeadingContectUs" style={{ marginBottom: "3%" }}>
        News And Media Gallery{" "}
      </h1> */}
      <TextSlideUp
      color="black"
      className={'textHeadingContectUs'}
      Heading={'news_&_events'}
      />
      <div className="dispay_flexNews">
        {state.map((item, index) => {
          if (item.isSelected) {
            return (
              <div key={index} className="newsandgallerybuttons" style={{ width: "12%", marginBottom: "2%" }}>
                <ButtonFilledColor
                buttonfilledcolorclass={'newsandgallerybuttonsfont'}
                  title={item.title}
                  width="100%"
                  height={'6vh'}
                  btnColor="#00578A"
                  onClick={() => handleClick(item)}
                />
              </div>
            );
          } else {
            return (
              <div key={index} className="newsandgallerybuttons" style={{ width: "12%", marginBottom: "2%" }}>
                <BorderButton
                borderbuttonclass={'newsandgallerybuttonsfont'}
                  onClick={() => {
                    handleClick(item);
                  }}
                  title={item.title}
                  width="100%"
                  height={'6vh'}
                />
              </div>
            );
          }
        })}
      </div>

      {selected === "News" && <News />}
      {selected === "Events" && <Events />}
    </div>
  );
};

export default NewsAndMediaGallery;

const RBOUND_SET_DATA = [
  {
    id: "News",
    title: "News",
    isSelected: true,
  },
  {
    id: "Events",
    title: "Events",
    isSelected: false,
  },
];

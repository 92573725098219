import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const BorderButton = ({
  title,
  onClick,
  broderColor,
  width = "100%",
  // height = 50,
  fontSize = "1.2vw",
  borderbuttonclass,
  borderbuttonclasscontainer,
  effect = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  return (
    <div
      className={borderbuttonclasscontainer}
      onMouseEnter={() => effect && handleMouseEnter()}
      onMouseLeave={() => effect && handleMouseLeave()}
      style={{
        // height: height,
        // width: width,
        padding: "8px 16px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${broderColor || '#00578A' || "black"}`,
        borderRadius: 50,
        cursor: "pointer",
        background: isHovered ? "#00578A" : "none",
        transition: "background-color 1.3s",
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <p
        className={borderbuttonclass}
        style={{
          margin: 0,
          padding: 0,
          color: isHovered ? "white" : broderColor,
          fontSize: fontSize,
          fontFamily: "nexa-regular",
          textTransform: "capitalize",
          transition: "color 1.3s",
        }}
      >
        {t(title)}
      </p>
    </div>
  );
};

export default BorderButton;

import React, { useEffect, useState } from "react";
import ButtonFilledColor from "Components/ButtonFilledColor";
import BorderButton from "Components/BorderButton";
import { IMAGES } from "Assets/Images";
import { useTranslation } from "react-i18next";
import ArabicPlatformAdnSolutionTopCard from "Components/ArabicPlatformAdnSolutionTopCard";
import ArabicTopTabBar from "Components/Arabic_TopTabBar";
import PlatformOverView from "Screens/ReboundPlatform/PlatformOverView";
import ArabicMaterialsTraded from "Screens/ReboundPlatform/ArabicMaterialTraded";
import ArabicWhatSetUsApart from "Screens/ReboundPlatform/ArabicWhatSetUsApart";




const Arabicreboundplatform = () => {
  const [state, setState] = useState(RBOUND_SET_DATA);
  const [selected, setSelected] = useState("what_set_us_apprt");
  const { t, i18n } = useTranslation();


  const handleClick = (selectedItem) => {
    setSelected(selectedItem.id);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem.id ? true : false,
      };
    });
    setState(new_data);
  };

  return (
    <>

      <div className="reboundplatformoutercontainer backgraung_image_rebound">
        <ArabicPlatformAdnSolutionTopCard
          heading={t('rebound_platform_heading')}
          title1={
            t('rebound_platform_description1')
          }
          title2={
            t('rebound_platform_description2')
          }
          image={IMAGES.platform_Bg}
          height={"80vh"}
        />

        <div className="second_bae_header">
          <ArabicTopTabBar
            tabBarData={TAB_DATA}
            selected_tab={selected}
            clickTab={(item) => handleClick({ id: item })}
          />
        </div>
        <div className="Arabicreboundplatformswitchbutton">
          {state.map((item, index) => {
            if (item.isSelected) {
              return (
                <div className="reboundplatformfilledbuttons" key={index}>
                  <ButtonFilledColor
                    buttonfilledcolorclass={"Arabic-reboundplatformchoicebtn1"}
                    onClick={() => {
                      handleClick(item);
                    }}
                    title={item.title}
                    btnColor={"#00578A"}
                   
                  />
                </div>
              );
            } else {
              return (
                <div className="reboundplatformfilledbuttons" key={index}>
                  <BorderButton
                    borderbuttonclass={"reboundplatformchoicebtn"}
                    onClick={() => {
                      handleClick(item);
                    }}
                    title={item.title}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className="button_contaner_benifit2">
          <ButtonFilledColor
          buttonfilledcolorclasscontainer={'reboundplatformbutton'}
          buttonfilledcolorclass={'reboundplatformbuttonfont'}
          title="sign_in" width="30%" 
          onClick={() => window.open('https://rpx.reboundplasticexchange.com', '_blank')}

          />
        </div>
        {selected === "what_set_us_apprt" && <ArabicWhatSetUsApart />}
        {selected === "materials_traded" && <ArabicMaterialsTraded />}
        {selected === "platform_overview" && <PlatformOverView />}
        
      </div>
    </>
  );
};

export default  Arabicreboundplatform;

const RBOUND_SET_DATA = [
  {
    id: "what_set_us_apprt",
    title: "what_sets_us_apart",
    isSelected: true,
  },
  {
    id: "materials_traded",
    title: "materials_traded",
    isSelected: false,
  },
  {
    id: "platform_overview",
    title: "platform_overview",
    isSelected: false,
  },
];
const TAB_DATA = [
  {
    id: "rebound_platform",
    title: "rebound_platform_heading",
    isSelected: false,
    isFirst: true,
  },
  // {
  //   id: "membership",
  //   title: "Membership",
  //   isSelected: true,
  // },
  {
    id: "what_set_us_apprt",
    title: "what_sets_us_apart",
    isSelected: false,
  },
  {
    id: "materials_traded",
    title: "materials_traded",
    isSelected: false,
  },
  {
    id: "platform_overview",
    title: "platform_overview",
    isSelected: false,
  },
];

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import InputField from "Components/InputField";
import RightIconButton from "Components/RightIconButton";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { errorHadleContactUs } from "Screens/Resources/utilsContactUs";
import { getCurrentDimension } from "Utils/Helper";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Modals({ modalIsOpen, closeModal, subject, setMessage }) {
  const dimensions = getCurrentDimension();
  let subtitle;
  const [modalIsOpens, setIsOpens] = React.useState(false);
  const [state, setState] = useState(RBOUND_SET_DATA);
  const [selected, setSelected] = useState("Register_Now");
  const [token, setToken] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [loder, setLoder] = useState(false);
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: dimensions?.width > 768 ? "50%" : "85%",
    },
  };
  const refrashToken =
    "1000.2f130e251da53ca38cf77739b5772be2.d81c6df38208bf87e7f3fabf89b5a7cd";

  let client_id = "1000.1XBQ634K3QN5IC789VW6AWAK553ZDR"
  let client_secret = "2d32ec126a9b37f839ede3c27c6e9b1148aa730bf6"
  let scope = "ZohoCRM.modules.ALL"
  useEffect(() => {
    if (modalIsOpen) {
      setIsOpens(true)
    }
  }, [modalIsOpen])

  function onRequestClose() {
    setIsOpens(false);
    closeModal()
    setFormData({});
    setErrors({})
  }

  const getToken = async () => {
    try {

      const options = {
        url: "https://accounts.zoho.com/oauth/v2/token",
        method: "post",
        params: {
          refresh_token: refrashToken,
          grant_type: "refresh_token",
          client_id: "1000.Z2R6VQLC88ZIVYELMNOSHLWYEV3QAQ",
          client_secret: "e49c08d4451417efe2def673b1799f45af84c9870f",
          redirect_uri: "https://rebound-bb9b8.web.app/",
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await axios(options);
      const refreshToken = response?.data?.access_token;
      setToken(refreshToken);
      console.log("---response-----", response);

      getData(response.data?.access_token)

    } catch (error) {
      console.log("---response-----", error);

    }
  };

  // useEffect(() => {
  //   getToken();
  // }, []);



  // const addUserToLeads = async (accessToken) => {
  //   const response = await axios.post(
  //     'https://www.zohoapis.com/crm/v2/Leads',
  //     {
  //       data: [{
  //         Company: 'hod',
  //         Last_Name: 'hod',
  //         First_Name: 'testhod',
  //         Email: 'hod@hod.com',
  //       }],
  //       trigger: [
  //         'approval',
  //         'workflow',
  //         'blueprint',
  //       ],
  //     },
  //     {
  //       headers: {
  //         Authorization: `Zoho-oauthtoken ${accessToken}`
  //       },
  //     }
  //   );

  //   console.log("++++++++",response);
  //   }

  const getData = async (accessToken) => {
    const response = await axios.get(
      'https://www.zohoapis.com/crm/v3/Leads?fields=Last_Name,Email&per_page=1',
      {
        headers: {
          'Authorization': `Zoho-oauthtoken ${accessToken}`,
        },
      }
    ).catch((err) => {
      console.log("errrrrrrr", err);
    })
    console.log("response", response);
  };

  function afterOpenModal() {
    // subtitle.style.color = '#f00';
  }

  const handleClick = (selectedItem) => {
    setSelected(selectedItem.id);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem.id ? true : false,
      };
    });
    setState(new_data);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false });
  };

  const submitForm = () => {
    errorHadleContactUs(formData, (data) => {
      if (data?.error) {
        setErrors(data);
      } else {
        // handleServerRequest();
        sendEmail()
      }
    });
  };


  const sendEmail = (e) => {
    setLoder(true)

    const data = {
      service_id: 'service_l2obupq',
      template_id: 'Contact_us',
      user_id: 'mBskkz5nUec6JjyPE',
      template_params: { ...formData, subject: subject }
    };

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    }).then((result) => {
      if (result?.ok) {
        setMessage(true)
        setLoder(false)
        setFormData({})
        setErrors({})
        onRequestClose()
      }
    }, (error) => {
      setLoder(false)
      console.log('error.statusText', error);
    });

  }
  // const handleSubmit = () => {

  //   if (formData?.name) {
  //     if (formData?.companyName) {
  //       if (formData?.email) {
  //         if (formData?.phoneNumber) {
  //           if (formData?.extra_information) {
  //             // onRequestClose()
  //           } else {
  //             setErrors({ ...errors, extra_information: "Please add extra information." })
  //           }
  //         }
  //         else {
  //           setErrors({ ...errors, phoneNumber: "Please add phone number." })
  //         }
  //       } else {
  //         setErrors({ ...errors, email: "Please add email." })
  //       }
  //     } else {
  //       setErrors({ ...errors, companyName: "Pleasae add company name." })
  //     }
  //   } else {
  //     setErrors({ ...errors, name: "Please add your name." })
  //   }
  // }

  const Register_Now = () => {
    return (
      <div className="text_field_Modal">
        <InputField
          label="NAME"
          type="text"
          placeholder="John Doe"
          name={"name"}
          value={formData?.name || ""}
          onChange={handleChange} />
        {errors.name && <span className="Arabic-errText">{(errors.name)}</span>}

        <InputField
          label="Company name"
          placeholder="Company Name"
          name={"company"}
          value={formData?.company || ""}
          onChange={handleChange} />
        {errors.company && <span className="Arabic-errText">{(errors.company)}</span>}

        <InputField
          label="EMAIL"
          name={"email"}
          value={formData?.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
          type="email"
        />
        {errors.email && <span className="Arabic-errText">{(errors.email)}</span>}

        <InputField
          name={"phone"}
          label="Phone number"
          value={formData?.phone || ""}
          type="number"
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="Arabic-errText">{(errors.phone)}</span>}

        <InputField
          name={"extra_information"}
          label="Extra information"
          value={formData?.extra_information || ""}
          placeholder="Add your comments"
          onChange={handleChange} />
        {errors.extra_information && <span className="Arabic-errText">{(errors.extra_information)}</span>}
      </div>
    );
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpens}
        onAfterOpen={afterOpenModal}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          {/* <Register_Now /> */}
          {Register_Now()}
          <div className="margin_top5">
            {loder ? (
              <div className="loder_form">
                <p
                  style={{
                    marginRight: 5,
                    padding: 0,
                    paddingLeft: 5,
                    color: "#fff",
                    fontFamily: "nexa-regular",
                    fontSize: "1.0vw",
                    marginBottom: 0,
                  }}
                >
                  Submit
                </p>
                <Spinner animation="grow" variant="light" />
              </div>
            ) : (
              <RightIconButton
                paraclass={"rebound_button_font"}
                title="Submit"
                onClick={() => {
                  submitForm()
                }}
                scrollTop={false}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default Modals;

const RBOUND_SET_DATA = [
  {
    id: "Register_Now",
    title: "Register Now",
    isSelected: true,
  },
  {
    id: "Join_Our_Team",
    title: "Join Our Team",
    isSelected: false,
  },
  {
    id: "Blog_Entries",
    title: "Blog Entries",
    isSelected: false,
  },

  {
    id: "Case_Studies",
    title: "Case Studies",
    isSelected: false,
  },

  {
    id: "White_Papers",
    title: "White Papers",
    isSelected: false,
  },
  {
    id: "Newsletters",
    title: "Newsletters",
    isSelected: false,
  },
];

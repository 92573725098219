import React from "react";
import { useTranslation } from "react-i18next";

function TextAnimation({text, classNameText  ,style}) {
  const { t, i18n } = useTranslation();

  return (
    <div
    style={{
        ...style,
        width:"100%"
    }}
  >
    <h1 className={`jt_animation ${classNameText}` }>
      <span className="jt__row_animation">
        <span className="jt__text_animation">{t(text)}</span>
      </span>
      <span className="jt__row_animation jt__row--sibling_animation" aria-hidden="true">
        <span className="jt__text_animation">{t(text)}</span>
      </span>
      <span className="jt__row_animation jt__row--sibling_animation" aria-hidden="true">
        <span className="jt__text_animation">{t(text)}</span>
      </span>
      <span className="jt__row_animation jt__row--sibling_animation" aria-hidden="true">
        <span className="jt__text_animation">{t(text)}</span>
      </span>
      <span className="jt__row_animation jt__row--sibling_animation" aria-hidden="true">
        <span className="jt__text_animation">{t(text)}</span>
      </span>
    </h1>
  </div>
  );
}

export default TextAnimation;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonFilledColor from "Components/ButtonFilledColor";
import BorderButton from "Components/BorderButton";
import Blogs from "Screens/Resources/Blogs";
import CaseStudies from "Screens/Resources/CaseStudies";
import Brochures from "Screens/Resources/Brochures";
import WhitePapers from "Screens/Resources/WhitePapers";
import TextSlideUp from "Components/TextSlideUp";
import { useTranslation } from "react-i18next";

const Resources = ({ navigation }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(button);
  const [selected, setSelected] = useState("Blogs");
  const { t, i18n } = useTranslation();

  const handleClick = (selectedItem) => {
    setSelected(selectedItem.id);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem.id ? true : false,
      };
    });
    setState(new_data);
  };

  return (
    <div>
      {/* <Navbar/> */}
      <div className="backgraung_image_news_gallery">
        {/* <h1 className="resourceheading">Resources</h1> */}
        <TextSlideUp
        color="black"
        className={'resourceheading'}
        Heading={'resources'}
        />
        <div
        className="resourcesbuttoncontainer"
        >
          <div
          className="resourcesbuttoninnercontainer">
            {state.map((item, index) => {
              if (item.isSelected) {
                return (
                  <div key={index} className="resourcesbuttoncontainerfont" >
                    <ButtonFilledColor
                    buttonfilledcolorclasscontainer={'resourcesbuttonscontainer'}
                    buttonfilledcolorclass={'resourcesbuttonscontainerfont'}
                      onClick={() => {
                        handleClick(item);
                      }}
                      title={item.name}
                      btnColor={"#00578A"}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="resourcesbuttoncontainerfont">
                    <BorderButton
                    borderbuttonclasscontainer={'resourcesbuttonscontainer'}
                    borderbuttonclass={'resourcesbuttonscontainerfont'}
                      onClick={() => {
                        handleClick(item);
                      }}
                      
                      title={item.name}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        {selected === "Blogs" && <Blogs />}
        {selected === "Case_Studies" && <CaseStudies />}
        {selected === "Brochures" && <Brochures />}
        {selected === "White_Papers" && <WhitePapers />}
      </div>
      {/* <Footers/> */}
    </div>
  );
};

export default Resources;

const button = [
  {
    id: "Blogs",
    name: "blogs",
    isSelected: true,
  },
  {
    id: "Case_Studies",
    name: "case_studies",
    isSelected: false,
  },
  {
    id: "Brochures",
    name: "brochures",
    isSelected: false,
  },
  {
    id: "White_Papers",
    name: "white_papers",
    isSelected: false,
  },
];

import React, { useState } from "react";
import { IMAGES } from "../../Assets/Images";
import { useTranslation } from "react-i18next";


const MaterialsTraded = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="materialtradedmaincontainer">
      <div className="materialtradedinnercontainer">
        <p className="materialtradedheading">
         {t('materials_traded_description')}
        </p>
      </div>
      <div className="materialtradedimgcontainer">
        {
          <div className="test">
            <div
              className="materialtradedimage1 "
            >
            </div>
            <p className="materialtradedimagefont">{t('Bales')}</p>
          </div>
        }
        {
          <div className="test">
            <div
              className="materialtradedimage2"
            >
            </div>
            <p className="materialtradedimagefont">{t('Flakes')}</p>
          </div>
        }
        {
          <div className="test">

            <div
              className="materialtradedimage3"
            >
            </div>
            <p className="materialtradedimagefont">{t('Pellets')}</p>
          </div>
        }
      </div>
    </div>
  );
};

export default MaterialsTraded;

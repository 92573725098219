import React from "react";
import { useTranslation } from "react-i18next";
// import gsap from "gsap-trial";
// import { SplitText } from "gsap-trial/SplitText";
// import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
// gsap.registerPlugin(SplitText, ScrollTrigger);
function TextSlideUp({
Heading,
className = 'split',
paragraph,
lineHeight = 1.0,
width = '100%',
color = 'white',
}) {
  // let mySplitText = new SplitText(".split", { type: "chars" });
  // let chars = mySplitText.chars;

  // gsap.from(
  //   chars, {
  //   yPercent: 130,
  //   stagger: 0.03,
  //   ease: "back.out",
  //   duration: 1.3,
  //   scrollTrigger : {
  //       trigger : ".split",
  //       start : "top 80%",
  //       // markers : true
  //   }
  // });
  
  const { t, i18n } = useTranslation();
  return (
    <div>
    <section>
      <div className={className} style={{
        overflow : 'hidden',
        // paddingTop : '3rem',
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        width : width
      }}>
        <p className={paragraph} style={{
            color : color,
            // fontSize: `clamp(32px, 6vw, 64px)`,
            fontFamily : 'nexa',
            margin : 0,
            lineHeight : lineHeight,
            textTransform:'none',
            textAlign:'center'
            // position:'absolute',
            // bottom:'10px',
            // overflow:"hidden"
        }}>{t(Heading)}</p>
         <p className='split' style={{
          color : color,
          fontSize: `clamp(32px, 6vw, 64px)`,
          fontFamily : 'nexa',
          margin : 0,
          lineHeight : 1.3,
          textTransform:'none',
          // position:'absolute',
          // bottom:'10px',
          // overflow:"hidden"
      }}></p>
        
      </div>
    </section>
    </div>
  );
}

export default TextSlideUp;

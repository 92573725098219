import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import Maryam from "../../Assets/Staff Images/Maryam Al Mansoori - General Manager.png";
import shabab from "../../Assets/Staff Images/Shabab Said Mohamed - Operations & Business Development Director.bcae571350ac31789cac.png";
import sarah from "../../Assets/Staff Images/Sarah Safi - Environmental Economist.de29aa68273a96ac1613.png";
import reem from "../../Assets/Staff Images/Reem Bayoud - Projects Coordinator.3011bd88eb91d420c62b.png";
import nimisha from "../../Assets/Staff Images/Nimisha Boney - Polymer Analyst.9040d4f7b131e5eacada.png";
import mohd_imran from "../../Assets/Staff Images/Mohammad Imran -Accountant.5a2123b47e0783807173.png";
import loyd from "../../Assets/Staff Images/Loyd Tenorio - Operations Associate.95327a8ea243dc89384f.png";
import alvin from "../../Assets/Staff Images/Alvin Pereira - Finance & Admin Director.63cef2390da7b6cf15f2.png";
import mohd_ismail from "../../Assets/Staff Images/Mohamed Ismail - IT Manager.8c85668da806b40d8fdc.png";
import nick from "../../Assets/Staff Images/Nick Image English.png";
import tapas from "../../Assets/Staff Images/Tapas Sharma - Head of Quality Control.d47273f132b46f5df590.png";
import yunn from "../../Assets/Staff Images/Yunn Tuk Choo.jpeg";
import yasmin from "../../Assets/Staff Images/Yassamin.jpeg";
import tawseef from "../../Assets/Staff Images/Tawseef Bazaz - Sales Consultant - India.png";
import deffrin from "../../Assets/Staff Images/Deffrin Joseph - Software Developer.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IMAGES } from "../../Assets/Images";
import { getDatabase, ref, onValue } from 'firebase/database';

const Carousel = () => {
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // const elems = document.getElementsByClassName('pics');
      // const b = elems[0].className;
      // elems[0].className = elems[1].className;
      // elems[1].className = elems[2].className;
      // elems[2].className = elems[3].className;
      // elems[3].className = elems[4].className;
      // elems[4].className = elems[5].className;
      // elems[5].className = elems[6].className;
      // elems[6].className = elems[7].className;
      // elems[7].className = elems[8].className;
      // elems[8].className = elems[9].className;
      // elems[9].className = elems[10].className;
      // elems[10].className = elems[11].className;
      // elems[11].className = elems[12].className;
      // elems[12].className = elems[13].className;
      // elems[13].className = elems[14].className;
      // elems[14].className = b;
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);



  // useEffect(() => {
  //   const database = getDatabase();
  //   const myRef = ref(database, "rebound/value");
  //   onValue(myRef, (snapshot) => {
  //     const data = snapshot.val();
  //     if (!!data) {
  //       setData(data === 'success' ? true : false)
  //       setInterval(()=>{
  //         // handleNextSlide()
  //       },500)
  //     } else {
  //       console.log('Data not found');
  //     }
  //   }, {
  //     onlyOnce: true
  //   });
  // }, []);

  const handleNextSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[0].className;
    elems[0].className = elems[1].className;
    elems[1].className = elems[2].className;
    elems[2].className = elems[3].className;
    elems[3].className = elems[4].className;
    elems[4].className = elems[5].className;
    elems[5].className = elems[6].className;
    elems[6].className = elems[7].className;
    elems[7].className = elems[8].className;
    elems[8].className = elems[9].className;
    elems[9].className = elems[10].className;
    elems[10].className = elems[11].className;
    elems[11].className = elems[12].className;
    elems[12].className = elems[13].className;
    elems[13].className = elems[14].className;
    elems[14].className = b;
  };
  const handlePreviousSlide = () => {
    const elems = document.getElementsByClassName("pics");
    const b = elems[14].className;
    // elems[15].className = elems[14].className;
    elems[14].className = elems[13].className;
    elems[13].className = elems[12].className;
    elems[12].className = elems[11].className;
    elems[11].className = elems[10].className;
    elems[10].className = elems[9].className;
    elems[9].className = elems[8].className;
    elems[8].className = elems[7].className;
    elems[7].className = elems[6].className;
    elems[6].className = elems[5].className;
    elems[5].className = elems[4].className;
    elems[4].className = elems[3].className;
    elems[3].className = elems[2].className;
    elems[2].className = elems[1].className;
    elems[1].className = elems[0].className;
    elems[0].className = b;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handlePreviousSlide(),
    onSwipedRight: () => handleNextSlide(),
  });

  return (
    <>
      <div className="container" {...handlers}>
        <div id="carousel">
          <div className="pics pic1">
            <img style={{ height: "316px", width: "271px" }} src={Maryam} />
          </div>
          <div className="pics pic2">
            <img style={{ height: "316px", width: "271px" }} src={shabab} />
          </div>
          <div className="pics pic3">
            <img style={{ height: "316px", width: "271px" }} src={nick} />
          </div>
          <div className="pics pic4">
            <img style={{ height: "316px", width: "271px" }} src={sarah} />
          </div>
          <div className="pics pic5">
            <img style={{ height: "316px", width: "271px" }} src={reem} />
          </div>
          <div className="pics pic6">
            <img style={{ height: "316px", width: "271px" }} src={nimisha} />
          </div>
          <div className="pics pic7">
            <img style={{ height: "316px", width: "271px" }} src={mohd_imran} />
          </div>
          <div className="pics pic8">
            <img style={{ height: "316px", width: "271px" }} src={loyd} />
          </div>
          <div className="pics pic9">
            <img style={{ height: "316px", width: "271px" }} src={tawseef} />
          </div>
          <div className="pics pic10">
            <img
              style={{ height: "316px", width: "271px" }}
              src={mohd_ismail}
            />
          </div>
          {/* <div className="pics pic10">
            <img style={{ height: "316px", width: "271px" }} src={deffrin} />
          </div> */}
          <div className="pics pic11">
            <img style={{ height: "316px", width: "271px" }} src={tapas} />
          </div>
          <div className="pics pic12">
            <img style={{ height: "316px", width: "271px" }} src={deffrin} />
          </div>
          <div className="pics pic13">
            <img style={{ height: "316px", width: "271px" }} src={yunn} />
          </div>
          <div className="pics pic14">
            <img style={{ height: "316px", width: "271px" }} src={yasmin} />
          </div>
          <div className="pics pic15">
            <img style={{ height: "316px", width: "271px" }} src={alvin} />
          </div>
        </div>
      </div>
      <div className="controls" style={{}}>
        <img
          style={{ height: 48, width: 48, marginRight: 10, transform: `rotate(${180}deg)` }}
          src={IMAGES.forwordArrow}
          onClick={() => handlePreviousSlide()}
        />
        <img
          style={{ height: 48, width: 48, marginLeft: 10, }}
          src={IMAGES.forwordArrow}
          onClick={() => handleNextSlide()}
        />
      </div>
    </>
  );
};

export default Carousel;

export const errorHadleContactUs = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }

    if (!formData.company) {
        validationErrors.company = "Company name is required";
    }

    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (!formData?.extra_information) {
        validationErrors.extra_information = "Extra information is required";
    }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorHadleRegisterNow = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }

    if (!formData.company) {
        validationErrors.company = "Company name is required";
    }

    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (!formData?.extra_information) {
        validationErrors.extra_information = "Extra information is required";
    }
    if (!formData?.industry_sector) {
        validationErrors.industry_sector = "Industry/Sector is required";
    }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorBlogEntries = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }

    if (!formData.company) {
        validationErrors.company = "Company name is required";
    }

    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    } 
    // else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}


export const errorCareers = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }

    if (!formData.headline) {
        validationErrors.headline = "Company name is required";
    }

    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}

export const errorWhitePaper = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }

    if (!formData.submission_topic) {
        validationErrors.submission_topic = "Submission topic is required";
    }
    if (!formData.company) {
        validationErrors.company = "Company name is required";
    }
    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    } 
    // else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}

export const errorNewsLetter = (formData, call_back) => {
    const validationErrors = {};

    if (!formData?.name) {
        validationErrors.name = "Name is required";
    }
    if (!formData.email) {
        validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        validationErrors.email = "Email is not valid";
    }

    if (!formData.phone) {
        validationErrors.phone = "Phone number is required";
    }
    //  else if (!/^[0-9]{10}$/.test(formData.phone)) {
    //     validationErrors.phone = "Phone number is not valid";
    // }

    if (Object.keys(validationErrors).length) {
        call_back({ ...validationErrors, error: true });
    } else {
        call_back({ error: false })
    }
}
import React from "react";
import { LogoRebound } from "./Header&Footer/Navbar";
// import blogpost from "../Assets/Images/blogpost.jpeg";
// import RightIconButton from "../Components/RightIconButton";
// import ellipse10 from "../Assets/Images/Ellipse10.png";
// import { useLocation, useParams } from "react-router-dom";
// import { NavItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCurrentDimension } from "Utils/Helper";
// import { useNavigate } from "react-router-dom";
function TermsAndConditionArabic({ navigation }) {
    const{t}=useTranslation()
    const dimensions = getCurrentDimension();

    return (

        <div className="blogpostmaincontainer terms-and-condition arabic">
            {/* <img src={ellipse10} alt="ellipse10" className="blogpostellipse" /> */}
            <div className="blogpostinnercontainer">
                <div className="blogpostheadingcontainer">
                    <div className="pointer" style={{ marginBottom: 30 }}>
                        <LogoRebound style={{
                            // width:220,
                            // height:45
                            width:dimensions.width<=766? 130: 220,
                            height: dimensions.width <=766? 30: 45

                        }}/>
                    </div>
                    <p className="blogpostheadingfont" style={{ color: '#325ea8', fontSize: 25 }}>{t('TermsAndCondition')}</p>
                </div>

                <p>هذا الموقع مملوك ويُشغل من قِبل شركة ريباوند المحدودة، والتي تم تأسيسها بموجب قوانين سوق أبوظبي العالمي، أبوظبي، الإمارات العربية المتحدة، برقم شركة 000006694. في ريباوند، نحن على مهمة للحفاظ على البلاستيك خارج البيئة وضمن الاقتصاد، كوننا جزءًا من الحل لتقليل تلوث البلاستيك. من خلال منصة التداول الخاصة بنا، منصة ريباوند، بالإضافة إلى خدماتنا الاستشارية والاستشارات، حلول ريباوند، نتصرف كوسيط محايد لمعالجة التحديات والعقبات في سلسلة القيمة، مما يزيد الشفافية ويحدث تأثيراً كبيراً في مجالات إعادة تدوير نفايات البلاستيك، إدارة نفايات البلاستيك، ومبادرات الاستدامة.</p>
                <p>هذه الوثيقة والوثائق الأخرى التي نشير إليها أدناه تشكل الشروط والأحكام التي تحكم استخدامك للخدمات (جماعيًا، "الشروط"). الشروط هي عقد ملزم قانونياً بينك وبين ريباوند. باختصار، استخدامك لخدماتنا يعني موافقتك على هذه الشروط ونيتك للالتزام بها.
                </p>

                

                <p class="title">
                    <div>1)</div>
                    <div className="title-column-two">	تعريفات- الكلمات التالية، عند استخدامها في هذه الشروط، لها المعاني التالية:</div>
                </p>

                <ol>
                    <li>	الاتفاقية" تعني الاتفاق بينك وبين ريباوند لتقديم الخدمات وتشمل هذه الشروط (بما في ذلك جميع الوثائق المشار إليها في هذه الشروط).</li>
                    <li>	المحتوى" يعني أي معلومات تقوم بتحميلها أو نشرها على الخدمات وأي معلومات تقدمها لريباوند فيما يتعلق بالخدمات، بما في ذلك، دون حصر، المعلومات الشخصية.</li>
                    <li>	الملكية الفكرية" تعني جميع الأنظمة، التطبيقات، شفرة البرمجيات (بأي شكل، بما في ذلك شفرة المصدر، الشفرة التنفيذية أو شفرة الكائن)، الخوارزميات، التكنولوجيا، البرامج، قواعد البيانات، التصاميم، الرسوم البيانية، الوثائق، الرسومات، الجداول، الأفكار، الاختراعات، العلامات التجارية (سواء كانت مسجلة أم لا)، أسماء العلامات التجارية، الشعارات، الشعارات، الطرق، التقنيات، النماذج، الإجراءات، العمليات، وتشمل جميع حقوق النشر، الحقوق المتعلقة بأعمال المؤلف، حقوق العلامات التجارية، حقوق اسم التجارة، حقوق الأسرار التجارية، حقوق براءات الاختراع وحقوق الملكية الصناعية، وغيرها من حقوق الملكية في كل ما سبق.</li>
                    <li>	المعلومات الشخصية" تعني أي معلومات عن فرد قابل للتعريف، بما في ذلك، دون حصر، البيانات أو المعلومات التي يتم إدخالها في الخدمات بواسطتك أو بواسطة أي شخص مخول من قبلك لاستخدام حسابك للخدمات (على سبيل المثال، اسمك، اسم المشتري أو البائع، رقم الهاتف، العنوان، عنوان البريد الإلكتروني، والتقييم).</li>
                    <li>	ريباوند" أو "نحن" أو "لنا" تعني أو تشير إلى شركة ريباوند المحدودة.</li>
                    <li>	الخدمات" تعني كل ما يلي: موقع reboundplastic.com الإلكتروني، منصة التداول الإلكترونية التي يمكن الوصول إليها من موقع reboundplastic.com، أي موقع إلكتروني آخر تتحكم به ريباوند، وأي خدمات أخرى</li>
                    
                </ol>
                <p className="title">
                    <div>2)</div>
                    <div className="title-column-two">	الاستخدام والترخيص. ستستخدم الخدمات فقط وفقًا للقواعد التالية:</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">2.1</div>
                        <div className="terms-column-two">	ستستخدم الخدمات فقط للشركة التي أنت مخول قانونيًا للقيام بذلك.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.2</div>
                        <div className="terms-column-two">	لن تستخدم الخدمات كخدمة لأي طرف ثالث.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.3</div>
                        <div className="terms-column-two">	ترخيصك لاستخدام الخدمات محدود. لا يُمنح لك أي ترخيص أو حق في استخدام، نسخ، ترجمة، إعادة ترتيب، تعديل، تحسين، عرض، بيع، تأجير، منح تراخيص فرعية أو توزيع أو نقل أو التخلص من الخدمات، بالكامل أو جزء منها، إلا كما هو مصرح به صراحةً بموجب هذه الاتفاقية.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.4</div>
                        <div className="terms-column-two">	لن تقوم بتعديل، نسخ، إنشاء أعمال مشتقة من، هندسة عكسية، فك تشفير أو تفكيك الخدمات. لن تقوم بـ"زحف"، "كشط"، أو "تتبع" أي صفحة من الخدمات أو محاولة الحصول على شفرة المصدر للخدمات. لن تتدخل أو تحاول تعطيل الخدمات، على سبيل المثال بتوزيع فيروس أو رمز حاسوبي ضار آخر.
                        </div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.5</div>
                        <div className="terms-column-two">	لن تقوم بتحميل أو استخدام الخدمات (وإذا تم تحميلها ستقوم فورًا بإلغاء تثبيت وتدمير الخدمات) إذا كان هذا التحميل، التثبيت أو استخدام الخدمات محظورًا بموجب القانون المعمول به.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.6</div>
                        <div className="terms-column-two">	لن تنتهك أي قوانين فيما يتعلق باستخدامك للخدمات.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">2.7</div>
                        <div className="terms-column-two">	أنت مسؤول عن دفع جميع الرسوم التي تدين بها لريباوند. أنت أيضًا المسؤول الوحيد عن جمع و/أو دفع أي ضرائب مطبقة على أي مشتريات أو مبيعات تقوم بها من خلال خدماتنا.</div>
                    </li>
                </ul>
                <p className="title">
                    <div>3)</div>
                    <div className="title-column-two">خصوصيتك.</div>
                </p>
                <p>
                تفاصيل سياسة الخصوصية الخاصة بنا وكيفية استخدام ومعالجة معلوماتك عند استخدامك للخدمات. باستخدام الخدمات، فإنك توافق على أننا يمكن أن نستخدم ونعالج. يقوم كلا من ريباوند والأعضاء (بما في ذلك وليس محصوراً بالبائعين/المشترين) بمعالجة معلومات الأشخاص الشخصية الخاصة بمستخدمي الخدمات وبالتالي يعتبرون مسؤولين بيانات منفصلين ومستقلين تحت قانون الاتحاد الأوروبي. وهذا يعني أن كل طرف مسؤول عن المعلومات الشخصية التي يعالجها في تقديم الخدمات. على سبيل المثال، إذا كشف بائع بطريقة خاطئة عن اسم وعنوان بريد إلكتروني لمشترٍ عند تنفيذ طلب مشترٍ آخر، فإن البائع، وليس ريباوند، سيكون مسؤولاً عن ذلك الكشف غير المصرح به. إذا، ومع ذلك، وُجد أن ريباوند والبائعين يشكلون مسؤولي بيانات مشتركين لمعلومات الأشخاص الشخصية للمشترين، وإذا تم رفع دعوى قضائية ضد ريباوند أو تغريمها أو تكبدها نفقات بسبب شيء قمت به كمسؤول بيانات مشترك للمعلومات الشخصية، فإنك توافق على تعويض ريباوند عن النفقات (بما في ذلك الرسوم القانونية) التي تتكبدها فيما يتعلق بمعالجتك للمعلومات الشخصية.
                </p>

                <p className="title">
                    <div>4)</div>
                    <div className="title-column-two">	مسؤولياتك.</div>
                </p>
                <p>
                أنت مسؤول عن جميع استخداماتك والوصول إلى الخدمات، بما في ذلك الاستخدام و/أو الوصول من قبل الآخرين الذين تم تخويلهم من قبلك لاستخدام الخدمات. أنت تقر وتوافق:
                </p>

                <ol>
                    <li>	أنك تبلغ من العمر 18 عامًا أو أكثر. إذا كنت دون سن 18 عامًا، فلست مخولًا باستخدام الخدمات.</li>
                    <li>	اتخاذ إجراءات معقولة لمنع الوصول غير المصرح به أو استخدام الخدمات، وإبلاغ ريباوند فوراً بأي وصول أو استخدام غير مصرح به.</li>
                    <li>	تقديم معلومات كاملة ودقيقة عن نفسك.</li>

                    <li>	أنت مسؤول عن حسابك وأي نشاط على حسابك. إذا كنت تشارك حساباً مع أشخاص آخرين، فإن الشخص الذي تظهر معلومات الفوترة الخاصة به على الحساب سيكون في النهاية مسؤولاً عن كل النشاط. إذا كنت مسجلاً ككيان تجاري، فأنت تضمن شخصياً أن لديك السلطة للموافقة على الشروط نيابة عن العمل.</li>
                    <li>	حساباتك غير قابلة للتحويل.</li>

                    <li>	أنت مسؤول عن جميع المحتويات. جميع المحتويات تخصك، بما في ذلك أي شيء تنشره باستخدام الخدمات (مثل الأسماء، صور الملف الشخصي، صور الإعلانات، وصف الإعلانات، التقييمات، التعليقات، الفيديوهات، أسماء المستخدمين، والمعلومات الشخصية). أنت تُعلن أن لديك كل الحقوق اللازمة لمحتوياتك وأنك لا تنتهك أو تخالف حقوق أي طرف ثالث بنشرها.</li>
                    <li>	حماية كلمة(ات) المرور الخاصة بك.</li>
                    <li>	الامتثال لجميع القوانين المحلية والولائية والإقليمية والفدرالية والأجنبية المطبقة عند استخدام الخدمات، بما في ذلك دون حصر جميع قوانين الخصوصية وقوانين حماية المعلومات الشخصية.</li>
                    <li>	عدم الانخراط في الاحتيال، السرقة، السلوك المنافسة السلبية، السلوك التهديدي، أو أي أفعال غير قانونية أخرى أو جرائم ضد ريباوند، مستخدم ريباوند آخر، أو طرف ثالث.</li>
                    <li>	عدم استخدام الخدمات للانخراط في أي أنشطة تسويقية خادعة، مضللة، غير قانونية أو غير أخلاقية أو أنشطة قد تكون ضارة بريباوند. </li>
                    <li>	عدم جمع أو استخدام أو الكشف عن أي معلومات شخصية فيما يتعلق بالخدمات، ما لم تكن قد حصلت على جميع الموافقات اللازمة بموجب جميع القوانين المطبقة للقيام بذلك.</li>
                    <li>	عدم محاولة الوصول غير المصرح به إلى الخدمات.</li>
                    <li>	عدم تحميل أو تخزين أي مواد كاذبة، مضللة، مخالفة، فاحشة، تهديدية، تشهيرية، احتيالية، مسيئة، أو غير قانونية أو مادة تعسفية ضمن الخدمات، بما في ذلك المواد الضارة بالأطفال أو التي تنتهك حقوق الخصوصية للطرف الثالث.</li>
                </ol>

                <p className="title">
                    <div>5)</div>
                    <div style={{paddingLeft: '5px'}}>	العناصر / الأقاليم المحظورة.</div>
                </p>

                <p>لن تستخدم الخدمات لبيع أي شيء يخالف القوانين، أو لبيع أي من أنواع العناصر المحظورة التالية:</p>

                <ol>
                    <li>	العناصر غير القانونية أو العناصر التي تروج للنشاط غير القانوني.</li>
                    <li>	الكحول، التبغ، المخدرات أو معدات تعاطي المخدرات.</li>
                    <li>	المواد الخطرة، العناصر المسترجعة والأسلحة.</li>
                    <li>	المواد الإباحية والمحتوى الناضج.</li>
                    <li>	العناصر الخطيرة.</li>
                    <li>	العناصر التي تحض على الكراهية، مثل العناصر التي تروج أو تدعم أو تمجد الكراهية.</li>
                    <li>	منتجات الحيوانات ورفات البشر.</li>
                    <li>	العناصر المقيدة دوليًا.</li>
                    <li>	العناصر العنيفة والعناصر التي تروج أو تدعم أو تمجد العنف.</li>
                </ol>

                <p>القائمة أعلاه ليست مخصصة لتكون شاملة. نحن نأخذ في الاعتبار العديد من العوامل قبل الوصول إلى قرار بشأن ما هو الأفضل لمستخدمينا ومجتمعنا. ومع ذلك، في نهاية المطاف، القرار بشأن ما إذا كان عنصرًا محظورًا أم لا يرجع إلينا.
لن تقوم ريباوند بالتعامل أو تقديم أي خدمات أو منتجات إلى أي من البلدان الخاضعة لعقوبات مكتب مراقبة الأصول الأجنبية (OFAC) وفقاً لقانون الإمارات العربية المتحدة.
</p>

              
                <p className="title">
                    <div>6)</div>
                    <div className="title-column-two">
                     الملكية.
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">6.1</div>
                        <div className="terms-column-two">محتواك.
 بينما أنت تملك محتواك، بنشر محتواك من خلال الخدمات، تمنح ريباوند ترخيصاً غير حصرياً، عالمياً، بدون حقوق ملكية، لا يمكن إلغاؤه، قابل للترخيص الفرعي، دائمًا لاستخدام، عرض، تحرير، تعديل، نسخ، توزيع، تخزين، وإعداد أعمال مشتقة من محتواك، سواء لتقديم الخدمات أو لترويج ريباوند أو الخدمات بشكل عام، بأي صيغ ومن خلال أي قنوات، بما في ذلك عبر أي خدمات أخرى أو موقع ويب لطرف ثالث أو وسيلة إعلانية.
</div>
                    </li>
                    <li>
                        <div className="terms-column-one">6.2</div>
                        <div className="terms-column-two">
                            <div>ملكيتنا.</div>
                            <ul className="terms-ul">
                                <li>
                                    <div className="terms-column-one-2">6.2.1</div>
                                    <div className="terms-column-two">الخدمات وجميع الملكية الفكرية في الخدمات أو المتاحة أو المكشوفة لك في تقديم الخدمات هي وتظل الملكية الوحيدة والحصرية لريباوند وباستثناء الترخيص المحدود لاستخدام الملكية الفكرية كجزء من استخدامك للخدمات وفقًا لهذه الشروط (الترخيص)، لا يمنح أي حق، عنوان، أو مصلحة في الملكية الفكرية. ريباوند وشركاتها التابعة ستمتلك جميع الحقوق، العنوان والمصلحة، بما في ذلك جميع الملكية الفكرية، في وإلى أي تحسينات على الخدمات أو أي برامج جديدة، ترقيات، تعديلات أو تحسينات عليها، حتى عندما تنتج هذه التحسينات والتحسنات من طلباتك أو تعليقاتك.</div>
                                </li>
                                <li>
                                    <div className="terms-column-one-2">6.2.2</div>
                                    <div className="terms-column-two">أسماء "ريباوند" و"ريباوند بلاستيك"، وغيرها من علامات ريباوند التجارية، العبارات، الشعارات، القصص، العلامات، الشعارات، والتصاميم التي نستخدمها فيما يتعلق بالخدمات، هي علامات تجارية لريباوند (جماعيًا، "علاماتنا"). ليس مسموحًا لك استخدام علاماتنا بأي طريقة إلا بموافقة صريحة منا، حسب تقديرنا الخاص. في جميع الحالات، قد لا تستخدم علاماتنا إلا لأغراض متوافقة مع قيمنا الأساسية، وهي، زيادة الوعي العام بأهمية استخدام المنتجات المصنعة بالبلاستيك المعاد تدويره القادم من البلدان النامية. علاوة على ذلك، لن تمثل بأي شكل من الأشكال أن لديك أي مصلحة ملكية في علاماتنا.</div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>

                <p className="title">
                    <div>7)</div>
                    <div className="title-column-two">	الإبلاغ عن المحتوى غير المصرح به.</div>
                </p>
                <p>إذا تم نشر محتوى تملكه أو لديك حقوق عليه على الخدمات دون إذنك وترغب في إزالته، يجب عليك إعلامنا وسنستخدم جهودًا معقولة تجاريًا لإزالة هذا المحتوى. إذا كان محتواك ينتهك ملكية فكرية لشخص آخر، أو إذا كنا نعتقد بشكل معقول أن محتواك قد ينتهك ملكية فكرية لشخص آخر، نحتفظ بالحق في إزالته. سنستخدم جهودًا معقولة لإخطارك إذا حدث ذلك.</p>

                <p className="title">
                    <div>8)</div>
                    <div className="title-column-two">
                    الإنهاء. 
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">8.1</div>
                        <div className="terms-column-two">الإنهاء من قبلك. يمكنك إنهاء حسابك مع ريباوند في أي وقت. إنهاء حسابك قد لا يؤثر على توفر بعض محتواك الذي نشرته من خلال الخدمات قبل الإنهاء.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">8.2</div>
                        <div className="terms-column-two"><div>الإنهاء من قبلنا. يمكننا:</div>
                            <ol>
                                <li>إنهاء أو تعليق حسابك (وأي حسابات ذات صلة) ووصولك إلى الخدمات في أي وقت، لأي سبب، وبدون إخطار مسبق.</li>
                                <li>إنهاء أو تعليق حسابك (وأي حسابات ذات صلة) ووصولك إلى الخدمات في أي وقت، لأي سبب، وبدون إخطار مسبق.</li>
                                <li>تغيير، تعليق، أو إيقاف أي من الخدمات في أي وقت، لأي سبب ولن نكون مسؤولين أمامك عن تأثير أي تغييرات على الخدمات قد تحدث عليك، بما في ذلك دخلك أو قدرتك على توليد الإيرادات من خلال الخدمات.
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <div className="terms-column-one">8.3</div>
                        <div className="terms-column-two"> آثار الإنهاء. إذا قمت أنت أو ريباوند بإنهاء حسابك، قد تفقد أي معلومات مرتبطة بحسابك، بما في ذلك محتواك. علاوة على ذلك، ستظل الشروط سارية حتى بعد إنهاء وصولك إلى الخدمات، أو انتهاء استخدامك للخدمات.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>9)</div>
                    <div className="title-column-two">رسوم العضوية . </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">9.1</div>
                        <div className="terms-column-two">	يقدم سوق التداول عبر الإنترنت ريباوند خطط عضوية متنوعة مع رسوم مرتبطة بها. بتحديد خطة عضوية، فإنك توافق على دفع الرسوم المحددة وفقًا لشروط الفوترة المقدمة.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.2</div>
                        <div className="terms-column-two">	رسوم العضوية غير قابلة للإرجاع إلا إذا تم تحديد ذلك خطيًا. سيتم الاسترداد فقط من خلال طريقة الدفع الأصلية. تحتفظ ريباوند بالحق في تغيير رسوم العضوية مع إشعار مسبق.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.3</div>
                        <div className="terms-column-two">	عدم دفع رسوم العضوية قد يؤدي إلى تعليق أو إنهاء عضويتك.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">9.4</div>
                        <div className="terms-column-two">	تقبل ريباوند الدفعات عبر الإنترنت باستخدام بطاقات الائتمان/الخصم من فيزا وماستركارد بالدولار الأمريكي (أو أي عملة أخرى متفق عليها). لن يتم تخزين تفاصيل بطاقات الائتمان/الخصم والمعلومات الشخصية التي يمكن تحديدها لأي طرف ثالث، بيعها، مشاركتها، تأجيرها أو استئجارها.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>10)</div>
                    <div className="title-column-two">الضمانات وتحديد المسؤولية . </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">10.1</div>
                        <div className="terms-column-two"> العناصر التي تشتريها. أنت تفهم وتوافق على أن ريباوند لا تصنع أو تخزن أيًا من العناصر المباعة من خلال خدماتنا. نحن نوفر المكان فقط، والعناصر في أسواقنا تُنتج وتُدرج وتُباع مباشرةً بواسطة بائعين مستقلين. لذا، لا يمكن لريباوند ولا تقدم أي ضمانات بخصوص جودتها، أمانها، أو حتى شرعيتها. أي مطالبة قانونية متعلقة بعنصر اشتريته يجب أن تُقدم مباشرة ضد البائع. أنت تبرئ ريباوند من أي مطالبات متعلقة بالعناصر المباعة من خلال خدماتنا، بما في ذلك العناصر المعيبة، التمثيلات الخاطئة من قبل البائعين، أو العناصر التي تسببت في إصابة جسدية (مثل مطالبات المسؤولية عن المنتجات).</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.2</div>
                        <div className="terms-column-two"> المحتوى الذي تصل إليه. قد تصادف مواد تجدها مسيئة أو غير لائقة أثناء استخدام خدماتنا. نحن لا نقدم أي تمثيلات بخصوص أي محتوى ينشره المستخدمون من خلال الخدمات. ريباوند ليست مسؤولة عن دقة، الامتثال لحقوق النشر، الشرعية، أو اللياقة الأخلاقية للمحتوى الذي ينشره المستخدمون الذين تصل إليهم من خلال الخدمات. أنت تبرئنا من كل المسؤولية المتعلقة بهذا المحتوى.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.3</div>
                        <div className="terms-column-two">الأشخاص الذين تتفاعل معهم. إذا كنت تستخدم الخدمات للتفاعل مع أفراد آخرين، سواء عبر الإنترنت أو شخصيًا، فأنت تفعل ذلك مع العلم أننا لا نفحص مستخدمي خدماتنا، وأنت تبرئنا من كل المسؤولية المتعلقة بتفاعلاتك مع مستخدمين آخرين.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.4</div>
                        <div className="terms-column-two">خدمات الطرف الثالث. قد تحتوي خدماتنا على روابط إلى مواقع ويب أو خدمات تابعة لأطراف ثالثة لا نملكها أو نتحكم بها. قد تحتاج أيضًا إلى استخدام منتج أو خدمة لطرف ثالث من أجل استخدام بعض خدماتنا. عندما تصل إلى هذه الخدمات التابعة لأطراف ثالثة، فأنت تفعل ذلك على مسؤوليتك الخاصة. قد يتطلب منك الأطراف الثالثة قبول شروط الاستخدام الخاصة بهم. ريباوند ليست طرفًا في تلك الاتفاقيات؛ هي بينك وبين الطرف الثالث فقط.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.5</div>
                        <div className="terms-column-two"> مكافآت ريباوند، قسائم وعروض ترويجية. قد تقدم ريباوند عروض ترويجية خاصة، مكافآت، قسائم، أو غيرها من العروض الترويجية (جماعيًا، "المكافآت"). أنت تقر بأن ريباوند لا تقدم أي ضمانات بخصوص مكافآتك وليست مسؤولة عن أي وصول غير مصرح به، أو تغيير، سرقة، أو تدمير للمكافآت أو أكواد المكافآت الناتجة عن أي فعل منك أو من طرف ثالث. أنت تقر أيضًا بأننا قد نعلق أو نحظر استخدام مكافآتك إذا تم الإبلاغ عن حسابك أو وُضع علامة عليه كمخترق أو مسروق، أو إذا كنا نعتقد أن حسابك يستخدم بشكل مشبوه، احتيالي، أو بطريقة غير مصرح بها. بمشاركتك في عرض خاص أو ترويج، أنت توافق على أنك لا يمكن أن تدعي لاحقًا أن قواعد ذلك العرض الخاص أو الترويج كانت غامضة.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.6</div>
                        <div className="terms-column-two">الضمانات. أنت تفهم وتوافق على أن الخدمات مقدمة "كما هي" وبدون أي نوع من الضمانات (صريحة أو ضمنية). نحن نخلي صراحةً أي ضمانات للملكية، عدم الانتهاك، قابلية التسويق، والملاءمة لغرض معين، وكذلك أي ضمانات ضمنية بموجب مسار الأداء، مسار التعامل، أو استخدام التجارة. نحن لا نضمن أن: (i) الخدمات ستكون آمنة أو متاحة في أي وقت أو مكان معين؛ (ii) سيتم تصحيح أي عيوب أو أخطاء؛ (iii) الخدمات ستكون خالية من الفيروسات أو غيرها من المواد الضارة؛ أو (iv) نتائج استخدام الخدمات ستلبي توقعاتك. أنت تستخدم الخدمات على مسؤوليتك الخاصة. في الولايات القضائية التي لا تسمح بتحديد الضمانات الضمنية، قد لا تنطبق القيود أعلاه عليك.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">10.7</div>
                        <div className="terms-column-two">تحديد المسؤولية. إلى أقصى حد يسمح به القانون، لا تكون ريباوند، ولا موظفيها، ومديريها، وضباطها، ومساهميها، أو مقاوليها مسؤولين أمامك عن أي أرباح أو إيرادات ضائعة، أو عن أي أضرار تبعية، عرضية، غير مباشرة، خاصة، أو عقابية ناتجة عن أو فيما يتعلق بالخدمات أو هذه الشروط. في أي حال من الأحوال، لا تتجاوز المسؤولية الإجمالية لريباوند عن أي أضرار المبلغ الأكبر من مائة (100) دولار أو المبلغ الذي دفعته لريباوند في الاثني عشر شهرًا الماضية. في الولايات القضائية التي لا تسمح بتحديد الأضرار العرضية أو التبعية، قد لا تنطبق القيود أعلاه عليك.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>11)</div>
                    <div className="title-column-two">التعويض.. </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">11.1</div>
                        <div className="terms-column-two"> أنت توافق على الدفاع عنا وتعويضنا ضد أي مطالبات من أي أطراف ثالثة ناتجة عن شيء قمت به. ستدافع عن ريباوند (بما في ذلك أي من موظفينا) وتحمينا من أي مطالبة قانونية أو طلب (بما في ذلك رسوم المحاماة المعقولة) الناتجة عن أفعالك، استخدامك (أو سوء استخدامك) لخدماتنا، خرقك للشروط، أو تعدي حسابك على حقوق شخص آخر.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">11.2</div>
                        <div className="terms-column-two"> نحتفظ بالحق في التعامل مع دفاعنا القانوني كما نراه مناسبًا، حتى لو كنت تعوضنا، وفي هذه الحالة، أنت توافق على التعاون معنا حتى نتمكن من تنفيذ استراتيجيتنا. النزاعات مع مستخدمين آخرين. إذا وجدت نفسك في نزاع مع مستخدم آخر لخدماتنا أو طرف ثالث، نشجعك على التواصل مع الطرف الآخر ومحاولة حل النزاع بطريقة ودية. المشترين والبائعين الذين لا يستطيعون حل نزاع متعلق بمعاملة على مواقعنا الإلكترونية قد يشاركون في نظام الشكاوى لدينا. ستحاول ريباوند مساعدتك على حل النزاعات بحسن نية وبناءً فقط على تفسيرنا لسياساتنا، حسب تقديرنا الخاص؛ لن نقوم بإصدار أحكام بخصوص قضايا قانونية أو مطالبات. ريباوند ليست ملزمة بحل أي نزاعات. أنت تبرئ ريباوند من أي مطالبات، مطالب، وأضرار ناتجة عن نزاعات مع مستخدمين آخرين أو أطراف.</div>
                    </li>
                </ul>

                <p className="title">
                    <div>12)</div>
                    <div className="title-column-two">
                    النزاعات مع ريباوند. 
                    </div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">12.1</div>
                        <div className="terms-column-two"> القانون الحاكم والمنتدى. تخضع الشروط للقوانين الفيدرالية لأسواق أبوظبي العالمية (ADGM)، أبوظبي، الإمارات العربية المتحدة، دون النظر إلى قواعد تضارب القوانين. ستطبق هذه القوانين بغض النظر عن المكان الذي تعيش فيه في العالم.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">12.2</div>
                        <div className="terms-column-two"> التحكيم، لا دعوى جماعية، ولا هيئة محلفين. يجب حل أي نزاع أو مطالبة ناشئة من أو متعلقة بالشروط نهائيًا بالتحكيم النهائي والملزم، باستخدام اللغة الإنجليزية، وفقًا لقانون التحكيم [RSBC 1996] الفصل 55 (القانون "التحكيم"). سيتم التعامل مع التحكيم بواسطة محكم واحد. يمكن إدخال حكم على جائزة التحكيم في أي محكمة لها الاختصاص. أي تحكيم بموجب الشروط سيتم على أساس فردي ولن يتم السماح بالتحكيم الجماعي والدعاوى الجماعية. أنت تفهم أنه بموافقتك على الشروط، أنت وريباوند كلاكما تتنازلان عن الحق في المحاكمة بواسطة هيئة محلفين أو المشاركة في دعوى جماعية أو تحكيم جماعي. على الرغم من ما سبق، يحق لكل طرف أن يقيم دعوى في محكمة ذات اختصاص مناسب للحصول على إجراءات تحفظية أو تعويضية أو أخرى عادلة، في انتظار قرار نهائي من المحكم.
                        </div>
                    </li>
                </ul>

                <p className="title">
                    <div>13)</div>
                    <div className="title-column-two">تغييرات في الشروط. قد نقوم</div>
                </p>
                <p>
                حسب تقديرنا بتحديث أو استبدال أو مراجعة أي جزء من هذه الشروط من وقت لآخر. ستدخل أي تغييرات تم إجراؤها على هذه الشروط حيز التنفيذ فور نشرها على هذا الموقع الإلكتروني. من مسؤوليتك التحقق من هذه الشروط بانتظام لأي تغييرات أو تحديثات. استمرار استخدامك لهذا الموقع الإلكتروني بعد تعديل هذه الشروط يشكل قبولك لتلك التغييرات. لا يُفهم من هنا أنه يقيد حق ريباوند في تحذير، تعليق، أو إنهاء الوصول إلى الموقع الإلكتروني لأي مستخدم يخرق هذه الشروط، يقدم توثيقًا غير كافٍ للهوية، أو تقرر ريباوند، حسب تقديرها، أن أنشطة المستخدمين قد تشكل خطرًا قانونيًا أو تجاريًا محتملًا لأي شخص، بما في ذلك ريباوند.
                </p>
                    

                <p className="title">
                    <div>14)</div>
                    <div className="title-column-two">عام</div>
                </p>
                <ul className="terms-ul">
                    <li>
                        <div className="terms-column-one">14.1</div>
                        <div className="terms-column-two"> علاقتنا. لا تُنشئ هذه الشروط أي علاقة وكالة، شراكة، مشروع مشترك، توظيف، أو امتياز بينك وبين ريباوند. لن تمثل نفسك كمن لديه أي سلطة للتصرف نيابةً عن ريباوند أو تحمل أي التزام نيابةً عنها.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.2</div>
                        <div className="terms-column-two"> الاتفاقية كاملة. تحل الشروط، بما في ذلك جميع السياسات التي تشكل جزءًا من الشروط، محل أي اتفاق آخر بينك وبين ريباوند بخصوص الخدمات. إذا وُجد أن أي جزء من الشروط غير قابل للتنفيذ، فسيُقتصر هذا الجزء إلى الحد الأدنى الضروري بحيث تظل الشروط سارية بكامل قوتها وتأثيرها. فشلنا في فرض أي جزء من الشروط ليس تنازلاً عن حقنا في فرض ذلك الجزء أو أي جزء آخر من الشروط في وقت لاحق.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.3</div>
                        <div className="terms-column-two"> التنازل. يمكننا التنازل عن أي من حقوقنا والتزاماتنا بموجب الشروط. لا يجوز نقل هذه الاتفاقية أو التنازل عنها من قبلك بدون موافقتنا الكتابية المسبقة، حسب تقديرنا الوحيد.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.4</div>
                        <div className="terms-column-two"> الاستمرارية. تستمر شروط وأحكام هذه الاتفاقية لصالح الأطراف الموقعة عليها وخلفائهم المسموح بهم والمتنازل لهم.</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.5</div>
                        <div className="terms-column-two"> الإشعارات ومعلومات الاتصال. إذا كانت لديك أي أسئلة حول الشروط، يرجى مراسلتنا عبر البريد الإلكتروني info@reboundplastic.com
قد تزودك ريباوند ببعض المعلومات القانونية كتابةً. أنت توافق على أننا يمكن أن نرسل لك المعلومات إلكترونيًا (عبر البريد الإلكتروني، إلخ) بدلاً من إرسال نسخ ورقية لك، وأن موافقتك الإلكترونية تعادل توقيعك على الورق.
</div>
                    </li>
                    <li>
                        <div className="terms-column-one">14.6</div>
                        <div className="terms-column-two"> العناوين. العناوين المستخدمة في هذه الاتفاقية هي للراحة والإشارة فقط ولا يجب أن تؤثر على تفسير أو تأويل هذه الاتفاقية.</div>
                    </li>
                </ul>


            </div>
        </div>
    );
}

export default TermsAndConditionArabic;

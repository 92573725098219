
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonFilledColor from "../Components/ButtonFilledColor";
import BorderButton from "../Components/BorderButton";
import Rebound from "../Assets/Images/rebound.png";

import bgimg from '../Assets/Images/Ellipse10.png'

function Drawer({ state, setState }) {
  const [color, setColor] = useState("#fff");
  const navigate = useNavigate();
  return (
    <div className="drawer">

     <div>

     <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <img
          src={Rebound}
          style={{
            height: "100%",
            // width: "auto",
            maxWidth: "70%",
            margin: 'auto',
            marginBottom: 50
          }}
        />
      </div>

      <div>

        {Data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="drawercontainer  ">
                {/* <p>
            {item.name}
          </p> */}
                <BorderButton
                  title={item.name}
                  broderColor={"orange"}
                  onClick={() => {
                    navigate(item.path);
                    setState(!state);
                  }}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>

     </div>

      <div className="search drwawerIcons">
        <div className="searchcontainerMobile">
          <svg
            width="20"
            height="20"
            viewBox="0 0 17 17"

            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.431 14.1792L12.889 11.6577C13.8757 10.4274 14.3536 8.86576 14.2243 7.29395C14.095 5.72214 13.3684 4.2596 12.1939 3.20706C11.0193 2.15452 9.4862 1.59198 7.90967 1.63512C6.33314 1.67826 4.83305 2.3238 3.71786 3.43899C2.60267 4.55418 1.95713 6.05427 1.91399 7.6308C1.87085 9.20733 2.43339 10.7405 3.48593 11.915C4.53846 13.0895 6.00101 13.8161 7.57282 13.9454C9.14463 14.0747 10.7062 13.5968 11.9366 12.6101L14.458 15.1316C14.5217 15.1958 14.5975 15.2468 14.681 15.2816C14.7645 15.3163 14.8541 15.3343 14.9445 15.3343C15.035 15.3343 15.1245 15.3163 15.208 15.2816C15.2915 15.2468 15.3673 15.1958 15.431 15.1316C15.5545 15.0038 15.6235 14.8331 15.6235 14.6554C15.6235 14.4777 15.5545 14.3069 15.431 14.1792ZM8.09267 12.6101C7.14405 12.6101 6.21674 12.3288 5.42799 11.8018C4.63924 11.2748 4.02449 10.5257 3.66147 9.64926C3.29845 8.77285 3.20347 7.80848 3.38853 6.87809C3.5736 5.9477 4.0304 5.09308 4.70118 4.42231C5.37195 3.75153 6.22657 3.29473 7.15696 3.10966C8.08735 2.9246 9.05172 3.01958 9.92813 3.3826C10.8045 3.74562 11.5536 4.36037 12.0806 5.14912C12.6077 5.93787 12.889 6.86518 12.889 7.8138C12.889 9.08586 12.3836 10.3058 11.4842 11.2053C10.5847 12.1048 9.36473 12.6101 8.09267 12.6101Z"
              fill="black"
            />
          </svg>
          <svg
            width="30"
            height="32"
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="pointer"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.7921 5.91385C20.6522 6.14492 21.3325 6.82528 21.5636 7.68535C21.9872 9.25147 22.0001 12.4992 22.0001 12.4992C22.0001 12.4992 22.0001 15.7598 21.5764 17.3131C21.3454 18.1732 20.665 18.8535 19.8049 19.0846C18.2517 19.5082 12.0001 19.5082 12.0001 19.5082C12.0001 19.5082 5.74846 19.5082 4.19518 19.0846C3.33511 18.8535 2.65475 18.1732 2.42368 17.3131C2.00006 15.747 2.00006 12.4992 2.00006 12.4992C2.00006 12.4992 2.00006 9.25147 2.41084 7.69819C2.64191 6.83812 3.32227 6.15776 4.18235 5.92669C5.73562 5.50307 11.9872 5.49023 11.9872 5.49023C11.9872 5.49023 18.2388 5.49023 19.7921 5.91385ZM15.1836 12.4992L9.99749 15.5031V9.49537L15.1836 12.4992Z"
              fill="black"
            />
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="pointer"
          >
            <path
              d="M18.1156 6.50894C17.0383 5.80651 16.2607 4.68262 16.0181 3.37246C15.9657 3.0894 15.937 2.79807 15.937 2.5H12.4985L12.493 16.28C12.4352 17.8231 11.1652 19.0616 9.60822 19.0616C9.12431 19.0616 8.66866 18.9406 8.26748 18.7295C7.34749 18.2454 6.71789 17.281 6.71789 16.1713C6.71789 14.5775 8.01456 13.2808 9.60822 13.2808C9.90571 13.2808 10.1911 13.3299 10.461 13.4145V9.90424C10.1817 9.8662 9.89784 9.84242 9.60822 9.84242C6.11848 9.84242 3.27948 12.6816 3.27948 16.1713C3.27948 18.3124 4.34923 20.2072 5.98126 21.353C7.00925 22.0747 8.25966 22.5 9.60822 22.5C13.098 22.5 15.937 19.661 15.937 16.1713V9.18367C17.2855 10.1516 18.9378 10.722 20.7207 10.722V7.2836C19.7603 7.2836 18.8658 6.99808 18.1156 6.50894Z"
              fill="black"
            />
          </svg>
          <div className="verticalline" />
          <form>
            <select
              className="reponsiveDropdown "
              style={{
                color: "black",
              }}
            >
              <option>
                <p> English</p>
              </option>
              <option value="Arab"> Arabic</option>
            </select>
          </form>
        </div>
      </div>
      
      
        <img  className="bgsvgimg" src={bgimg}/>

      

    </div>

  );
}

export default Drawer;

const Data = [
  { name: "About Us", path: "/about-us" },
  { name: "rebound platform", path: "/rebound-platform" },
  { name: "rebound solution", path: "/rebound-solution" },
  { name: "News and Events", path: "/news-and-media-gallery" },
  { name: "resources", path: "/resources" },
  { name: "impact", path: "/impact" },
  { name: "careers", path: "/careers" },
  { name: "contact us", path: "/contact-us" },
];

import React from "react";
import ButtonFilledColor from "../../Components/ButtonFilledColor";
import blogImg from "../../Assets/Images/blogImg.png";
import RightIconButton from "../../Components/RightIconButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {BlogPosts} from './BlogPosts';

const Blogs = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let sentences = document.querySelectorAll(".sentence");
  let characterCount = 0;
  const navinate = useNavigate();
  for (let i = 0; i < sentences.length; i++) {
    let sentence = sentences[i];
    let newContent = "";

    // go through all characters of the sentence
    for (let j = 0; j < sentence.textContent.length; j++) {
      let substring = sentence.textContent.substr(j, 1);
      // if we have a character, wrap it
      if (substring !== " ") {
        newContent += `<span style="--animation-order: ${characterCount};">${substring}</span>`;
      } else {
        newContent += substring;
      }
      characterCount++;
    }
    sentence.innerHTML = newContent;
  }

  const randerHeight = (i) => {
    if (i === 0) {
      return "40vh";
    } else if (i === 1) {
      return "40vh";
    } else if (i === 4) {
      return "40vh";
    } else if (i === 2) {
      return "40vh";
    } else {
      return "40vh";
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="Container" style={{ width: "100%" }}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 600: 2, 1000: 3 }}
          style={{ width: "100%" }}
        >
          <Masonry gutter="1%">
            {BlogPosts.map((item, i) =>
              i !== 4 ? (
                <div
                  key={i}
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={() => {
                    navinate("/blog/" + item.slug);
                    scrollToTop();
                  }}
                >
                  <img
                    className="blogimage"
                    key={i}
                    src={item.image}
                    alt="image"
                    style={{
                      height: randerHeight(i),
                      display: "block",
                      width: "100%",
                      marginBottom: 10,
                      borderRadius: 5,
                    }}
                  />
                  <p className="titleBlogs_image">{t(item.title)}</p>
                </div>
              ) : (
                <></>
              )
            )}
          </Masonry>
        </ResponsiveMasonry>
        {/* <img src={blogImg} alt="" style={{ width: "100%", height: "auto" }} /> */}

        {/* <div className="textOverlay"> */}

        <div
          style={{
            backgroundImage: `url(${blogImg})`,
            marginBottom: 3,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="blogs_container"
        >
          <h1 className="textBlogsHeading">
            {t("share_your_voice_on_our_blog")}
          </h1>

          <div className="textBlogs">
            <p className="textBlogs2">{t("blogs_description1")}</p>

            <p className="textBlogs2">{t("blogs_description2")}</p>
            <div className="margin-top30">
              <RightIconButton
                width="105%"
                title="share_your_voice_on_our_blog"
                classname={"blogsbutton"}
                paraclass={"blogsbuttonfont"}
                onClick={() =>
                  navigate("/contact-us", { state: "Blog_Entries" })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
import React from "react";

const color1 = "#ff6700"
const color3 = "black"
const color2 = "blue"

function ResourceMapRigthSvg() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 280 778" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M278.337 166.369H270.633L266.891 159.602L270.633 152.834H278.337L282.189 159.602L278.337 166.369Z" fill="#CAE5E6" />
    <path d="M278.337 183.4H270.633L266.891 176.632L270.633 169.752H278.337L282.189 176.632L278.337 183.4Z" fill="#CAE5E6" />
    <path d="M278.337 200.431H270.633L266.891 193.551L270.633 186.783H278.337L282.189 193.551L278.337 200.431Z" fill="#CAE5E6" />
    <path d="M278.337 217.351H270.633L266.891 210.584L270.633 203.816H278.337L282.189 210.584L278.337 217.351Z" fill="#CAE5E6" />
    <path d="M278.337 234.381H270.633L266.891 227.613L270.633 220.846H278.337L282.189 227.613L278.337 234.381Z" fill="#CAE5E6" />
    <path d="M278.337 251.413H270.633L266.891 244.646L270.633 237.766H278.337L282.189 244.646L278.337 251.413Z" fill="#CAE5E6" />
    <path d="M278.337 268.445H270.633L266.891 261.564L270.633 254.797H278.337L282.189 261.564L278.337 268.445Z" fill="#CAE5E6" />
    <path d="M278.337 285.365H270.633L266.891 278.598L270.633 271.83H278.337L282.189 278.598L278.337 285.365Z" fill="#CAE5E6" />
    <path d="M278.337 302.396H270.633L266.891 295.628L270.633 288.748H278.337L282.189 295.628L278.337 302.396Z" fill="#CAE5E6" />
    <path d="M278.337 319.431H270.633L266.891 312.551L270.633 305.783H278.337L282.189 312.551L278.337 319.431Z" fill="#CAE5E6" />
    <path d="M278.337 336.35H270.633L266.891 329.582L270.633 322.814H278.337L282.189 329.582L278.337 336.35Z" fill="#CAE5E6" />
    <path d="M278.337 353.379H270.633L266.891 346.611L270.633 339.844H278.337L282.189 346.611L278.337 353.379Z" fill="#CAE5E6" />
    <path d="M278.337 370.41H270.633L266.891 363.642L270.633 356.762H278.337L282.189 363.642L278.337 370.41Z" fill="#CAE5E6" />
    <path d="M278.337 387.326H270.633L266.891 380.559L270.633 373.791H278.337L282.189 380.559L278.337 387.326Z" fill="#CAE5E6" />
    <path d="M278.337 404.359H270.633L266.891 397.592L270.633 390.824H278.337L282.189 397.592L278.337 404.359Z" fill="#CAE5E6" />
    <path d="M277.348 419.702H271.625L268.764 414.626L271.625 409.438H277.348L280.21 414.626L277.348 419.702Z" fill="#CAE5E6" />
    <path d="M275.477 467.3H273.606L272.615 465.608L273.606 463.916H275.477L276.357 465.608L275.477 467.3Z" fill="#CAE5E6" />
    <path d="M278.337 489.295H270.743L266.891 482.528L270.743 475.873H278.337L282.079 482.528L278.337 489.295Z" fill="#CAE5E6" />
    <path d="M275.586 144.487H273.385L272.395 142.57L273.385 140.652H275.586L276.687 142.57L275.586 144.487Z" fill="#CAE5E6" />
    <path d="M264.029 157.91H256.325L252.473 151.142L256.325 144.262H264.029L267.771 151.142L264.029 157.91Z" fill="#CAE5E6" />
    <path d="M264.029 174.943H256.325L252.473 168.062L256.325 161.295H264.029L267.771 168.062L264.029 174.943Z" fill="#CAE5E6" />
    <path d="M264.029 191.861H256.325L252.473 185.094L256.325 178.326H264.029L267.771 185.094L264.029 191.861Z" fill="#CAE5E6" />
    <path d="M264.029 208.894H256.325L252.473 202.126L256.325 195.246H264.029L267.771 202.126L264.029 208.894Z" fill="#CAE5E6" />
    <path d="M264.029 225.923H256.325L252.473 219.043L256.325 212.275H264.029L267.771 219.043L264.029 225.923Z" fill="#CAE5E6" />
    <path d="M264.029 242.842H256.325L252.473 236.074L256.325 229.307H264.029L267.771 236.074L264.029 242.842Z" fill="#CAE5E6" />
    <path d="M264.029 259.875H256.325L252.473 253.107L256.325 246.34H264.029L267.771 253.107L264.029 259.875Z" fill="#CAE5E6" />
    <path d="M264.029 276.904H256.325L252.473 270.136L256.325 263.256H264.029L267.771 270.136L264.029 276.904Z" fill="#CAE5E6" />
    <path d="M264.029 293.937H256.325L252.473 287.057L256.325 280.289H264.029L267.771 287.057L264.029 293.937Z" fill="#CAE5E6" />
    <path d="M264.029 310.855H256.325L252.473 304.088L256.325 297.32H264.029L267.771 304.088L264.029 310.855Z" fill="#CAE5E6" />
    <path d="M264.029 327.888H256.325L252.473 321.121L256.325 314.24H264.029L267.771 321.121L264.029 327.888Z" fill="#CAE5E6" />
    <path d="M264.029 344.919H256.325L252.473 338.039L256.325 331.271H264.029L267.771 338.039L264.029 344.919Z" fill="#CAE5E6" />
    <path d="M264.029 361.836H256.325L252.473 355.068L256.325 348.301H264.029L267.771 355.068L264.029 361.836Z" fill="#CAE5E6" />
    <path d="M264.029 378.869H256.325L252.473 372.102L256.325 365.334H264.029L267.771 372.102L264.029 378.869Z" fill="#CAE5E6" />
    <path d="M264.029 395.9H256.325L252.473 389.132L256.325 382.252H264.029L267.771 389.132L264.029 395.9Z" fill="#CAE5E6" />
    <path d="M264.029 412.933H256.325L252.473 406.053L256.325 399.285H264.029L267.771 406.053L264.029 412.933Z" fill="#CAE5E6" />
    <path d="M264.029 429.851H256.325L252.473 423.084L256.325 416.316H264.029L267.771 423.084L264.029 429.851Z" fill="#CAE5E6" />
    <path d="M264.029 446.884H256.325L252.473 440.117L256.325 433.236H264.029L267.771 440.117L264.029 446.884Z" fill="#CAE5E6" />
    <path d="M264.029 463.913H256.325L252.473 457.033L256.325 450.266H264.029L267.771 457.033L264.029 463.913Z" fill="#CAE5E6" />
    <path d="M264.029 480.832H256.325L252.473 474.064L256.325 467.297H264.029L267.771 474.064L264.029 480.832Z" fill="#CAE5E6" />
    <path d="M260.949 492.565H259.298L258.527 491.099L259.298 489.633H260.949L261.829 491.099L260.949 492.565Z" fill="#CAE5E6" />
    <path d="M278.337 132.419H270.633L266.891 125.652L270.633 118.771H278.337L282.189 125.652L278.337 132.419Z" fill="#CAE5E6" />
    <path d="M263.479 139.864H256.876L253.574 134.111L256.876 128.246H263.479L266.671 134.111L263.479 139.864Z" fill="#CAE5E6" />
    <path d="M249.284 148.772H242.35L238.828 142.569L242.35 136.365H249.284L252.806 142.569L249.284 148.772Z" fill="#CAE5E6" />
    <path d="M249.612 166.367H241.908L238.166 159.6L241.908 152.832H249.612L253.464 159.6L249.612 166.367Z" fill="#CAE5E6" />
    <path d="M249.612 183.4H241.908L238.166 176.632L241.908 169.752H249.612L253.464 176.632L249.612 183.4Z" fill="#CAE5E6" />
    <path d="M249.612 200.431H241.908L238.166 193.551L241.908 186.783H249.612L253.464 193.551L249.612 200.431Z" fill="#CAE5E6" />
    <path d="M249.612 217.351H241.908L238.166 210.584L241.908 203.816H249.612L253.464 210.584L249.612 217.351Z" fill="#CAE5E6" />
    <path d="M249.612 234.381H241.908L238.166 227.613L241.908 220.846H249.612L253.464 227.613L249.612 234.381Z" fill="#CAE5E6" />
    <path d="M249.612 251.413H241.908L238.166 244.646L241.908 237.766H249.612L253.464 244.646L249.612 251.413Z" fill="#CAE5E6" />
    <path d="M249.612 268.445H241.908L238.166 261.564L241.908 254.797H249.612L253.464 261.564L249.612 268.445Z" fill="#CAE5E6" />
    <path d="M249.612 285.365H241.908L238.166 278.598L241.908 271.83H249.612L253.464 278.598L249.612 285.365Z" fill="#CAE5E6" />
    <path d="M249.612 302.396H241.908L238.166 295.628L241.908 288.748H249.612L253.464 295.628L249.612 302.396Z" fill="#CAE5E6" />
    <path d="M249.612 319.429H241.908L238.166 312.549L241.908 305.781H249.612L253.464 312.549L249.612 319.429Z" fill="#CAE5E6" />
    <path d="M249.612 336.35H241.908L238.166 329.582L241.908 322.814H249.612L253.464 329.582L249.612 336.35Z" fill="#CAE5E6" />
    <path d="M249.612 353.379H241.908L238.166 346.611L241.908 339.844H249.612L253.464 346.611L249.612 353.379Z" fill="#CAE5E6" />
    <path d="M249.612 370.41H241.908L238.166 363.642L241.908 356.762H249.612L253.464 363.642L249.612 370.41Z" fill="#CAE5E6" />
    <path d="M249.612 387.326H241.908L238.166 380.559L241.908 373.791H249.612L253.464 380.559L249.612 387.326Z" fill="#CAE5E6" />
    <path d="M249.612 404.359H241.908L238.166 397.592L241.908 390.824H249.612L253.464 397.592L249.612 404.359Z" fill="#CAE5E6" />
    <path d="M249.612 421.39H241.908L238.166 414.623L241.908 407.742H249.612L253.464 414.623L249.612 421.39Z" fill="#CAE5E6" />
    <path d="M249.612 438.423H241.908L238.166 431.543L241.908 424.775H249.612L253.464 431.543L249.612 438.423Z" fill="#CAE5E6" />
    <path d="M249.612 455.344H241.908L238.166 448.576L241.908 441.809H249.612L253.464 448.576L249.612 455.344Z" fill="#CAE5E6" />
    <path d="M249.612 472.374H241.908L238.166 465.607L241.908 458.727H249.612L253.464 465.607L249.612 472.374Z" fill="#CAE5E6" />
    <path d="M247.63 485.798H243.998L242.127 482.527L243.998 479.369H247.63L249.391 482.527L247.63 485.798Z" fill="#CAE5E6" />
    <path d="M278.337 115.387H270.743L266.891 108.619L270.743 101.852H278.337L282.079 108.619L278.337 115.387Z" fill="#CAE5E6" />
    <path d="M264.029 123.956H256.325L252.473 117.076L256.325 110.309H264.029L267.771 117.076L264.029 123.956Z" fill="#CAE5E6" />
    <path d="M249.17 131.627H242.347L239.045 125.649L242.347 119.559H249.17L252.582 125.649L249.17 131.627Z" fill="#CAE5E6" />
    <path d="M232.335 135.689H230.574L229.693 134.11L230.574 132.531H232.335L233.215 134.11L232.335 135.689Z" fill="#CAE5E6" />
    <path d="M235.306 157.91H227.602L223.75 151.142L227.602 144.262H235.306L239.048 151.142L235.306 157.91Z" fill="#CAE5E6" />
    <path d="M235.308 174.943H227.604L223.752 168.062L227.604 161.295H235.308L239.05 168.062L235.308 174.943Z" fill="#CAE5E6" />
    <path d="M235.306 191.861H227.602L223.75 185.094L227.602 178.326H235.306L239.048 185.094L235.306 191.861Z" fill="#CAE5E6" />
    <path d="M235.308 208.894H227.604L223.752 202.126L227.604 195.246H235.308L239.05 202.126L235.308 208.894Z" fill="#CAE5E6" />
    <path d="M235.308 225.923H227.604L223.752 219.043L227.604 212.275H235.308L239.05 219.043L235.308 225.923Z" fill="#CAE5E6" />
    <path d="M235.306 242.842H227.602L223.75 236.074L227.602 229.307H235.306L239.048 236.074L235.306 242.842Z" fill="#CAE5E6" />
    <path d="M235.308 259.875H227.604L223.752 253.107L227.604 246.34H235.308L239.05 253.107L235.308 259.875Z" fill="#CAE5E6" />
    <path d="M235.306 276.904H227.602L223.75 270.136L227.602 263.256H235.306L239.048 270.136L235.306 276.904Z" fill="#CAE5E6" />
    <path d="M235.308 293.937H227.604L223.752 287.057L227.604 280.289H235.308L239.05 287.057L235.308 293.937Z" fill="#CAE5E6" />
    <path d="M235.306 310.855H227.602L223.75 304.088L227.602 297.32H235.306L239.048 304.088L235.306 310.855Z" fill="#CAE5E6" />
    <path d="M235.308 327.888H227.604L223.752 321.121L227.604 314.24H235.308L239.05 321.121L235.308 327.888Z" fill="#CAE5E6" />
    <path d="M235.308 344.919H227.604L223.752 338.039L227.604 331.271H235.308L239.05 338.039L235.308 344.919Z" fill="#CAE5E6" />
    <path d="M235.306 361.836H227.602L223.75 355.068L227.602 348.301H235.306L239.048 355.068L235.306 361.836Z" fill="#CAE5E6" />
    <path d="M235.308 378.869H227.604L223.752 372.102L227.604 365.334H235.308L239.05 372.102L235.308 378.869Z" fill="#CAE5E6" />
    <path d="M235.306 395.9H227.602L223.75 389.132L227.602 382.252H235.306L239.048 389.132L235.306 395.9Z" fill="#CAE5E6" />
    <path d="M235.308 412.933H227.604L223.752 406.053L227.604 399.285H235.308L239.05 406.053L235.308 412.933Z" fill="#CAE5E6" />
    <path d="M235.308 429.851H227.604L223.752 423.084L227.604 416.316H235.308L239.05 423.084L235.308 429.851Z" fill="#CAE5E6" />
    <path d="M235.308 446.884H227.604L223.752 440.117L227.604 433.236H235.308L239.05 440.117L235.308 446.884Z" fill="#CAE5E6" />
    <path d="M235.197 463.801H227.603L223.861 457.034L227.603 450.379H235.197L238.939 457.034L235.197 463.801Z" fill="#CAE5E6" />
    <path d="M232.992 476.886H229.911L228.26 474.066L229.911 471.359H232.992L234.533 474.066L232.992 476.886Z" fill="#CAE5E6" />
    <path d="M277.237 96.3242H271.845L269.203 91.5869L271.845 86.8496H277.237L279.879 91.5869L277.237 96.3242Z" fill="#CAE5E6" />
    <path d="M263.919 106.925H256.325L252.473 100.158L256.325 93.2773H263.919L267.771 100.158L263.919 106.925Z" fill="#CAE5E6" />
    <path d="M249.612 115.387H241.908L238.166 108.619L241.908 101.852H249.612L253.464 108.619L249.612 115.387Z" fill="#CAE5E6" />
    <path d="M232.33 118.77H230.459L229.469 117.079L230.459 115.387H232.33L233.321 117.079L232.33 118.77Z" fill="#CAE5E6" />
    <path d="M220.884 149.451H213.179L209.438 142.57L213.179 135.803H220.884L224.736 142.57L220.884 149.451Z" fill="#CAE5E6" />
    <path d="M220.884 166.367H213.179L209.438 159.6L213.179 152.832H220.884L224.736 159.6L220.884 166.367Z" fill="#CAE5E6" />
    <path d="M220.884 183.4H213.179L209.438 176.632L213.179 169.752H220.884L224.736 176.632L220.884 183.4Z" fill="#CAE5E6" />
    <path d="M220.884 200.431H213.179L209.438 193.551L213.179 186.783H220.884L224.736 193.551L220.884 200.431Z" fill="#CAE5E6" />
    <path d="M220.884 217.351H213.179L209.438 210.584L213.179 203.816H220.884L224.736 210.584L220.884 217.351Z" fill="#CAE5E6" />
    <path d="M220.884 234.381H213.179L209.438 227.613L213.179 220.846H220.884L224.736 227.613L220.884 234.381Z" fill="#CAE5E6" />
    <path d="M220.884 251.413H213.179L209.438 244.646L213.179 237.766H220.884L224.736 244.646L220.884 251.413Z" fill="#CAE5E6" />
    <path d="M220.884 268.445H213.179L209.438 261.564L213.179 254.797H220.884L224.736 261.564L220.884 268.445Z" fill="#CAE5E6" />
    <path d="M220.884 285.365H213.179L209.438 278.598L213.179 271.83H220.884L224.736 278.598L220.884 285.365Z" fill="#CAE5E6" />
    <path d="M220.884 302.396H213.179L209.438 295.628L213.179 288.748H220.884L224.736 295.628L220.884 302.396Z" fill="#CAE5E6" />
    <path d="M220.884 319.429H213.179L209.438 312.549L213.179 305.781H220.884L224.736 312.549L220.884 319.429Z" fill="#CAE5E6" />
    <path d="M220.884 336.35H213.179L209.438 329.582L213.179 322.814H220.884L224.736 329.582L220.884 336.35Z" fill="#CAE5E6" />
    <path d="M220.884 353.379H213.179L209.438 346.611L213.179 339.844H220.884L224.736 346.611L220.884 353.379Z" fill="#CAE5E6" />
    <path d="M220.884 370.41H213.179L209.438 363.642L213.179 356.762H220.884L224.736 363.642L220.884 370.41Z" fill="#CAE5E6" />
    <path d="M220.884 387.326H213.179L209.438 380.559L213.179 373.791H220.884L224.736 380.559L220.884 387.326Z" fill="#CAE5E6" />
    <path d="M220.884 404.359H213.179L209.438 397.592L213.179 390.824H220.884L224.736 397.592L220.884 404.359Z" fill="#CAE5E6" />
    <path d="M220.884 421.39H213.179L209.438 414.623L213.179 407.742H220.884L224.736 414.623L220.884 421.39Z" fill="#CAE5E6" />
    <path d="M220.667 437.974H213.513L209.881 431.545L213.513 425.229H220.667L224.188 431.545L220.667 437.974Z" fill="#CAE5E6" />
    <path d="M275.477 76.2495H273.606L272.615 74.5576L273.606 72.9785H275.477L276.357 74.5576L275.477 76.2495Z" fill="#CAE5E6" />
    <path d="M261.83 86.0625H258.529L256.768 83.1299L258.529 80.1973H261.83L263.481 83.1299L261.83 86.0625Z" fill="#CAE5E6" />
    <path d="M249.504 98.1288H242.13L238.498 91.5869L242.13 85.0449H249.504L253.136 91.5869L249.504 98.1288Z" fill="#CAE5E6" />
    <path d="M235.308 106.925H227.604L223.752 100.158L227.604 93.2773H235.308L239.05 100.158L235.308 106.925Z" fill="#CAE5E6" />
    <path d="M220.884 115.387H213.179L209.438 108.619L213.179 101.852H220.884L224.736 108.619L220.884 115.387Z" fill="#CAE5E6" />
    <path d="M204.93 138.06H200.528L198.217 134.112L200.528 130.164H204.93L207.132 134.112L204.93 138.06Z" fill="#CAE5E6" />
    <path d="M206.582 157.91H198.877L195.025 151.142L198.877 144.262H206.582L210.324 151.142L206.582 157.91Z" fill="#CAE5E6" />
    <path d="M206.582 174.943H198.877L195.025 168.062L198.877 161.295H206.582L210.324 168.062L206.582 174.943Z" fill="#CAE5E6" />
    <path d="M206.582 191.861H198.877L195.025 185.094L198.877 178.326H206.582L210.324 185.094L206.582 191.861Z" fill="#CAE5E6" />
    <path d="M206.582 208.894H198.877L195.025 202.126L198.877 195.246H206.582L210.324 202.126L206.582 208.894Z" fill="#CAE5E6" />
    <path d="M206.582 225.923H198.877L195.025 219.043L198.877 212.275H206.582L210.324 219.043L206.582 225.923Z" fill="#CAE5E6" />
    <path d="M206.582 242.844H198.877L195.025 236.076L198.877 229.309H206.582L210.324 236.076L206.582 242.844Z" fill="#CAE5E6" />
    <path d="M206.582 259.875H198.877L195.025 253.107L198.877 246.34H206.582L210.324 253.107L206.582 259.875Z" fill="#CAE5E6" />
    <path d="M206.582 276.904H198.877L195.025 270.136L198.877 263.256H206.582L210.324 270.136L206.582 276.904Z" fill="#CAE5E6" />
    <path d="M206.582 293.937H198.877L195.025 287.057L198.877 280.289H206.582L210.324 287.057L206.582 293.937Z" fill="#CAE5E6" />
    <path d="M206.582 310.855H198.877L195.025 304.088L198.877 297.32H206.582L210.324 304.088L206.582 310.855Z" fill="#CAE5E6" />
    <path d="M206.582 327.888H198.877L195.025 321.121L198.877 314.24H206.582L210.324 321.121L206.582 327.888Z" fill="#CAE5E6" />
    <path d="M206.582 344.919H198.877L195.025 338.039L198.877 331.271H206.582L210.324 338.039L206.582 344.919Z" fill="#CAE5E6" />
    <path d="M206.582 361.84H198.877L195.025 355.072L198.877 348.305H206.582L210.324 355.072L206.582 361.84Z" fill="#CAE5E6" />
    <path d="M206.582 378.869H198.877L195.025 372.102L198.877 365.334H206.582L210.324 372.102L206.582 378.869Z" fill="#CAE5E6" />
    <path d="M206.582 395.9H198.877L195.025 389.132L198.877 382.252H206.582L210.324 389.132L206.582 395.9Z" fill="#CAE5E6" />
    <path d="M206.468 412.706H198.984L195.242 406.051L198.984 399.396H206.468L210.21 406.051L206.468 412.706Z" fill="#CAE5E6" />
    <path d="M206.03 428.949H199.427L196.125 423.084L199.427 417.219H206.03L209.222 423.084L206.03 428.949Z" fill="#CAE5E6" />
    <path d="M205.478 445.08H199.865L197.004 440.117L199.865 435.041H205.478L208.34 440.117L205.478 445.08Z" fill="#CAE5E6" />
    <path d="M232.773 85.4966H230.022L228.701 83.1279L230.022 80.6465H232.773L234.204 83.1279L232.773 85.4966Z" fill="#CAE5E6" />
    <path d="M219.017 94.9707H215.165L213.184 91.5869L215.165 88.2031H219.017L220.888 91.5869L219.017 94.9707Z" fill="#CAE5E6" />
    <path d="M192.161 149.451H184.457L180.715 142.57L184.457 135.803H192.161L196.013 142.57L192.161 149.451Z" fill="#CAE5E6" />
    <path d="M192.161 166.369H184.457L180.715 159.602L184.457 152.834H192.161L196.013 159.602L192.161 166.369Z" fill="#CAE5E6" />
    <path d="M192.161 183.4H184.457L180.715 176.632L184.457 169.752H192.161L196.013 176.632L192.161 183.4Z" fill="#CAE5E6" />
    <path d="M192.161 200.431H184.457L180.715 193.551L184.457 186.783H192.161L196.013 193.551L192.161 200.431Z" fill="#CAE5E6" />
    <path d="M192.161 217.351H184.457L180.715 210.584L184.457 203.816H192.161L196.013 210.584L192.161 217.351Z" fill="#CAE5E6" />
    <path d="M192.161 234.381H184.457L180.715 227.613L184.457 220.846H192.161L196.013 227.613L192.161 234.381Z" fill="#CAE5E6" />
    <path d="M192.161 251.413H184.457L180.715 244.646L184.457 237.766H192.161L196.013 244.646L192.161 251.413Z" fill="#CAE5E6" />
    <path d="M192.161 268.445H184.457L180.715 261.564L184.457 254.797H192.161L196.013 261.564L192.161 268.445Z" fill="#CAE5E6" />
    <path d="M192.161 285.365H184.457L180.715 278.598L184.457 271.83H192.161L196.013 278.598L192.161 285.365Z" fill="#CAE5E6" />
    <path d="M192.161 302.396H184.457L180.715 295.628L184.457 288.748H192.161L196.013 295.628L192.161 302.396Z" fill="#CAE5E6" />
    <path d="M192.161 319.431H184.457L180.715 312.551L184.457 305.783H192.161L196.013 312.551L192.161 319.431Z" fill="#CAE5E6" />
    <path d="M192.161 336.35H184.457L180.715 329.582L184.457 322.814H192.161L196.013 329.582L192.161 336.35Z" fill="#CAE5E6" />
    <path d="M192.161 353.379H184.457L180.715 346.611L184.457 339.844H192.161L196.013 346.611L192.161 353.379Z" fill="#CAE5E6" />
    <path d="M192.161 370.41H184.457L180.715 363.642L184.457 356.762H192.161L196.013 363.642L192.161 370.41Z" fill="#CAE5E6" />
    <path d="M192.161 387.326H184.457L180.715 380.559L184.457 373.791H192.161L196.013 380.559L192.161 387.326Z" fill="#CAE5E6" />
    <path d="M192.161 404.359H184.457L180.715 397.592L184.457 390.824H192.161L196.013 397.592L192.161 404.359Z" fill="#CAE5E6" />
    <path d="M191.944 420.939H184.79L181.158 414.623L184.79 408.193H191.944L195.466 414.623L191.944 420.939Z" fill="#CAE5E6" />
    <path d="M176.425 138.509H171.472L169.051 134.11L171.472 129.711H176.425L178.956 134.11L176.425 138.509Z" fill="#CAE5E6" />
    <path d="M177.745 157.91H170.151L166.299 151.142L170.151 144.262H177.745L181.597 151.142L177.745 157.91Z" fill="#CAE5E6" />
    <path d="M177.745 174.943H170.151L166.299 168.062L170.151 161.295H177.745L181.597 168.062L177.745 174.943Z" fill="#CAE5E6" />
    <path d="M177.745 191.861H170.151L166.299 185.094L170.151 178.326H177.745L181.597 185.094L177.745 191.861Z" fill="#CAE5E6" />
    <path d="M177.745 208.894H170.151L166.299 202.126L170.151 195.246H177.745L181.597 202.126L177.745 208.894Z" fill="#CAE5E6" />
    <path d="M177.745 225.923H170.151L166.299 219.043L170.151 212.275H177.745L181.597 219.043L177.745 225.923Z" fill="#CAE5E6" />
    <path d="M177.745 242.842H170.151L166.299 236.074L170.151 229.307H177.745L181.597 236.074L177.745 242.842Z" fill="#CAE5E6" />
    <path d="M177.745 259.875H170.151L166.299 253.107L170.151 246.34H177.745L181.597 253.107L177.745 259.875Z" fill="#CAE5E6" />
    <path d="M177.745 276.904H170.151L166.299 270.136L170.151 263.256H177.745L181.597 270.136L177.745 276.904Z" fill="#CAE5E6" />
    <path d="M177.745 293.937H170.151L166.299 287.057L170.151 280.289H177.745L181.597 287.057L177.745 293.937Z" fill="#CAE5E6" />
    <path d="M177.745 310.855H170.151L166.299 304.088L170.151 297.32H177.745L181.597 304.088L177.745 310.855Z" fill="#CAE5E6" />
    <path d="M177.745 327.888H170.151L166.299 321.121L170.151 314.24H177.745L181.597 321.121L177.745 327.888Z" fill="#CAE5E6" />
    <path d="M177.745 344.919H170.151L166.299 338.039L170.151 331.271H177.745L181.597 338.039L177.745 344.919Z" fill="#CAE5E6" />
    <path d="M177.745 361.836H170.151L166.299 355.068L170.151 348.301H177.745L181.597 355.068L177.745 361.836Z" fill="#CAE5E6" />
    <path d="M177.745 378.869H170.151L166.299 372.102L170.151 365.334H177.745L181.597 372.102L177.745 378.869Z" fill="#CAE5E6" />
    <path d="M175.104 391.164H172.793L171.582 389.133L172.793 386.99H175.104L176.315 389.133L175.104 391.164Z" fill="#CAE5E6" />
    <path d="M163.438 149.451H155.734L151.992 142.57L155.734 135.803H163.438L167.29 142.57L163.438 149.451Z" fill="#CAE5E6" />
    <path d="M163.438 166.367H155.734L151.992 159.6L155.734 152.832H163.438L167.29 159.6L163.438 166.367Z" fill="#CAE5E6" />
    <path d="M163.438 183.4H155.734L151.992 176.632L155.734 169.752H163.438L167.29 176.632L163.438 183.4Z" fill="#CAE5E6" />
    <path d="M163.438 200.431H155.734L151.992 193.551L155.734 186.783H163.438L167.29 193.551L163.438 200.431Z" fill="#CAE5E6" />
    <path d="M163.438 217.351H155.734L151.992 210.584L155.734 203.816H163.438L167.29 210.584L163.438 217.351Z" fill="#CAE5E6" />
    <path d="M163.438 234.381H155.734L151.992 227.613L155.734 220.846H163.438L167.29 227.613L163.438 234.381Z" fill="#CAE5E6" />
    <path d="M163.216 251.077H155.952L152.32 244.647L155.952 238.105H163.216L166.848 244.647L163.216 251.077Z" fill="#CAE5E6" />
    <path d="M162.445 266.529H156.832L153.971 261.566L156.832 256.604H162.445L165.197 261.566L162.445 266.529Z" fill="#CAE5E6" />
    <path d="M161.016 281.078H158.265L156.834 278.597L158.265 276.115H161.016L162.337 278.597L161.016 281.078Z" fill="#CAE5E6" />
    <path d="M160.465 297.095H158.814L157.934 295.628L158.814 294.162H160.465L161.235 295.628L160.465 297.095Z" fill="#CAE5E6" />
    <path d="M160.797 314.801H158.375L157.055 312.545L158.375 310.402H160.797L162.117 312.545L160.797 314.801Z" fill="#CAE5E6" />
    <path d="M161.016 332.061H158.265L156.834 329.579L158.265 327.098H161.016L162.337 329.579L161.016 332.061Z" fill="#CAE5E6" />
    <path d="M162.336 351.574H156.833L154.082 346.611L156.833 341.648H162.336L165.198 346.611L162.336 351.574Z" fill="#CAE5E6" />
    <path d="M161.016 366.01H158.265L156.834 363.642L158.265 361.16H161.016L162.337 363.642L161.016 366.01Z" fill="#CAE5E6" />
    <path d="M148.028 138.96H142.525L139.773 134.11L142.525 129.26H148.028L150.779 134.11L148.028 138.96Z" fill="#CAE5E6" />
    <path d="M149.016 157.91H141.422L137.57 151.142L141.422 144.262H149.016L152.868 151.142L149.016 157.91Z" fill="#CAE5E6" />
    <path d="M149.018 174.943H141.424L137.572 168.062L141.424 161.295H149.018L152.87 168.062L149.018 174.943Z" fill="#CAE5E6" />
    <path d="M149.016 191.861H141.422L137.57 185.094L141.422 178.326H149.016L152.868 185.094L149.016 191.861Z" fill="#CAE5E6" />
    <path d="M149.018 208.894H141.424L137.572 202.126L141.424 195.246H149.018L152.87 202.126L149.018 208.894Z" fill="#CAE5E6" />
    <path d="M149.018 225.923H141.424L137.572 219.043L141.424 212.275H149.018L152.87 219.043L149.018 225.923Z" fill="#CAE5E6" />
    <path d="M148.251 241.374H142.198L139.227 236.073L142.198 230.771H148.251L151.223 236.073L148.251 241.374Z" fill="#CAE5E6" />
    <path d="M131.631 126.893H130.2L129.43 125.652L130.2 124.299H131.631L132.291 125.652L131.631 126.893Z" fill="#CAE5E6" />
    <path d="M134.712 149.451H127.008L123.266 142.57L127.008 135.803H134.712L138.564 142.57L134.712 149.451Z" fill="#CAE5E6" />
    <path d="M134.712 166.367H127.008L123.266 159.6L127.008 152.832H134.712L138.564 159.6L134.712 166.367Z" fill="#CAE5E6" />
    <path d="M134.712 183.4H127.008L123.266 176.632L127.008 169.752H134.712L138.564 176.632L134.712 183.4Z" fill="#CAE5E6" />
    <path d="M134.712 200.431H127.008L123.266 193.551L127.008 186.783H134.712L138.564 193.551L134.712 200.431Z" fill="#CAE5E6" />
    <path d="M134.712 217.351H127.008L123.266 210.584L127.008 203.816H134.712L138.564 210.584L134.712 217.351Z" fill="#CAE5E6" />
    <path d="M133.612 232.466H128.109L125.357 227.616L128.109 222.766H133.612L136.363 227.616L133.612 232.466Z" fill="#CAE5E6" />
    <path d="M120.294 140.877H112.7L108.848 134.109L112.7 127.342H120.294L124.146 134.109L120.294 140.877Z" fill="#CAE5E6" />
    <path d="M120.294 157.91H112.7L108.848 151.142L112.7 144.262H120.294L124.146 151.142L120.294 157.91Z" fill="#CAE5E6" />
    <path d="M120.294 174.943H112.7L108.848 168.062L112.7 161.295H120.294L124.146 168.062L120.294 174.943Z" fill="#CAE5E6" />
    <path d="M120.294 191.861H112.7L108.848 185.094L112.7 178.326H120.294L124.146 185.094L120.294 191.861Z" fill="#CAE5E6" />
    <path d="M120.294 208.894H112.7L108.848 202.126L112.7 195.246H120.294L124.146 202.126L120.294 208.894Z" fill="#CAE5E6" />
    <path d="M117.542 220.848H115.561L114.57 219.043L115.561 217.352H117.542L118.532 219.043L117.542 220.848Z" fill="#CAE5E6" />
    <path d="M104.115 129.147H100.153L98.1719 125.65L100.153 122.041H104.115L106.206 125.65L104.115 129.147Z" fill="#CAE5E6" />
    <path d="M105.988 149.451H98.2837L94.4316 142.57L98.2837 135.803H105.988L109.84 142.57L105.988 149.451Z" fill="#CAE5E6" />
    <path d="M105.988 166.369H98.2837L94.4316 159.602L98.2837 152.834H105.988L109.84 159.602L105.988 166.369Z" fill="#CAE5E6" />
    <path d="M105.988 183.4H98.2837L94.4316 176.632L98.2837 169.752H105.988L109.84 176.632L105.988 183.4Z" fill="#CAE5E6" />
    <path d="M105.988 200.431H98.2837L94.4316 193.551L98.2837 186.783H105.988L109.84 193.551L105.988 200.431Z" fill="#CAE5E6" />
    <path d="M105.988 217.351H98.2837L94.4316 210.584L98.2837 203.816H105.988L109.84 210.584L105.988 217.351Z" fill="#CAE5E6" />
    <path d="M88.7065 118.658H86.9456L85.9551 117.079L86.9456 115.5H88.7065L89.587 117.079L88.7065 118.658Z" fill="#CAE5E6" />
    <path d="M91.5711 140.877H83.9771L80.125 134.109L83.9771 127.342H91.5711L95.4231 134.109L91.5711 140.877Z" fill="#CAE5E6" />
    <path d="M91.5711 157.91H83.9771L80.125 151.142L83.9771 144.262H91.5711L95.4231 151.142L91.5711 157.91Z" fill="#CAE5E6" />
    <path d="M91.5711 174.943H83.9771L80.125 168.062L83.9771 161.295H91.5711L95.4231 168.062L91.5711 174.943Z" fill="#CAE5E6" />
    <path d="M91.5711 191.861H83.9771L80.125 185.094L83.9771 178.326H91.5711L95.4231 185.094L91.5711 191.861Z" fill="#CAE5E6" />
    <path d="M91.5711 208.894H83.9771L80.125 202.126L83.9771 195.246H91.5711L95.4231 202.126L91.5711 208.894Z" fill="#CAE5E6" />
    <path d="M91.5711 225.923H83.9771L80.125 219.043L83.9771 212.275H91.5711L95.4231 219.043L91.5711 225.923Z" fill="#CAE5E6" />
    <path d="M91.5711 242.844H83.9771L80.125 236.076L83.9771 229.309H91.5711L95.4231 236.076L91.5711 242.844Z" fill="#CAE5E6" />
    <path d="M76.7093 131.403H70.1058L66.9141 125.65L70.1058 119.785H76.7093L79.901 125.65L76.7093 131.403Z" fill="#CAE5E6" />
    <path d="M77.2593 149.451H69.5552L65.7031 142.57L69.5552 135.803H77.2593L81.1113 142.57L77.2593 149.451Z" fill="#CAE5E6" />
    <path d="M77.2593 166.367H69.5552L65.7031 159.6L69.5552 152.832H77.2593L81.1113 159.6L77.2593 166.367Z" fill="#CAE5E6" />
    <path d="M77.2593 183.4H69.5552L65.7031 176.632L69.5552 169.752H77.2593L81.1113 176.632L77.2593 183.4Z" fill="#CAE5E6" />
    <path d="M77.2593 200.431H69.5552L65.7031 193.551L69.5552 186.783H77.2593L81.1113 193.551L77.2593 200.431Z" fill="#CAE5E6" />
    <path d="M77.2593 217.351H69.5552L65.7031 210.584L69.5552 203.816H77.2593L81.1113 210.584L77.2593 217.351Z" fill="#CAE5E6" />
    <path d="M77.1485 234.268H69.6646L65.8125 227.613L69.6646 220.846H77.1485L81.0006 227.613L77.1485 234.268Z" fill="#CAE5E6" />
    <path d="M76.9309 250.85H69.8871L66.3652 244.646L69.8871 238.33H76.9309L80.4527 244.646L76.9309 250.85Z" fill="#CAE5E6" />
    <path d="M62.8445 140.877H55.2505L51.3984 134.109L55.2505 127.342H62.8445L66.6966 134.109L62.8445 140.877Z" fill="#CAE5E6" />
    <path d="M62.4066 157.118H55.6931L52.2812 151.14L55.6931 145.162H62.4066L65.8185 151.14L62.4066 157.118Z" fill="#CAE5E6" />
    <path d="M62.8445 174.943H55.2505L51.3984 168.062L55.2505 161.295H62.8445L66.6966 168.062L62.8445 174.943Z" fill="#CAE5E6" />
    <path d="M62.8445 191.861H55.2505L51.3984 185.094L55.2505 178.326H62.8445L66.6966 185.094L62.8445 191.861Z" fill="#CAE5E6" />
    <path d="M62.8445 208.894H55.2505L51.3984 202.126L55.2505 195.246H62.8445L66.6966 202.126L62.8445 208.894Z" fill="#CAE5E6" />
    <path d="M62.8445 225.923H55.2505L51.3984 219.043L55.2505 212.275H62.8445L66.6966 219.043L62.8445 225.923Z" fill="#CAE5E6" />
    <path d="M62.8445 242.842H55.2505L51.3984 236.074L55.2505 229.307H62.8445L66.6966 236.074L62.8445 242.842Z" fill="#CAE5E6" />
    <path d="M45.3424 126.779H44.0217L43.3613 125.651L44.0217 124.41H45.3424L46.0027 125.651L45.3424 126.779Z" fill="#CAE5E6" />
    <path d="M46.4449 145.728H42.923L41.1621 142.57L42.923 139.412H46.4449L48.3159 142.57L46.4449 145.728Z" fill="#CAE5E6" />
    <path d="M48.4291 166.255H40.9451L37.2031 159.6L40.9451 152.945H48.4291L52.2812 159.6L48.4291 166.255Z" fill="#CAE5E6" />
    <path d="M47.6557 181.936H41.7125L38.6309 176.635L41.7125 171.221H47.6557L50.7373 176.635L47.6557 181.936Z" fill="#CAE5E6" />
    <path d="M48.5347 200.431H40.8306L36.9785 193.551L40.8306 186.783H48.5347L52.3867 193.551L48.5347 200.431Z" fill="#CAE5E6" />
    <path d="M48.5347 217.351H40.8306L36.9785 210.584L40.8306 203.816H48.5347L52.3867 210.584L48.5347 217.351Z" fill="#CAE5E6" />
    <path d="M48.5347 234.381H40.8306L36.9785 227.613L40.8306 220.846H48.5347L52.3867 227.613L48.5347 234.381Z" fill="#CAE5E6" />
    <path d="M48.5379 251.413H40.8338L37.0918 244.646L40.8338 237.766H48.5379L52.2799 244.646L48.5379 251.413Z" fill="#CAE5E6" />
    <path d="M31.5877 153.284H29.0563L27.8457 151.141L29.0563 148.885H31.5877L32.7983 151.141L31.5877 153.284Z" fill="#CAE5E6" />
    <path d="M34.118 174.943H26.5239L22.6719 168.062L26.5239 161.295H34.118L37.97 168.062L34.118 174.943Z" fill="#CAE5E6" />
    <path d="M30.9276 186.223H29.717L29.0566 185.095L29.717 183.967H30.9276L31.588 185.095L30.9276 186.223Z" fill="#CAE5E6" />
    <path d="M34.118 208.894H26.5239L22.6719 202.126L26.5239 195.246H34.118L37.97 202.126L34.118 208.894Z" fill="#CAE5E6" />
    <path d="M34.118 225.923H26.5239L22.6719 219.043L26.5239 212.275H34.118L37.97 219.043L34.118 225.923Z" fill="#CAE5E6" />
    <path d="M32.0263 239.12H28.6145L26.8535 236.075L28.6145 233.029H32.0263L33.7872 236.075L32.0263 239.12Z" fill="#CAE5E6" />
    <path d="M32.4693 256.829H28.1771L26.0859 253.107L28.1771 249.385H32.4693L34.5604 253.107L32.4693 256.829Z" fill="#CAE5E6" />
    <path d="M33.3458 275.553H27.2926L24.2109 270.139L27.2926 264.725H33.3458L36.4274 270.139L33.3458 275.553Z" fill="#CAE5E6" />
    <path d="M18.3791 163.889H13.5365L11.1152 159.603L13.5365 155.316H18.3791L20.8004 159.603L18.3791 163.889Z" fill="#CAE5E6" />
    <path d="M18.9291 198.965H12.9859L9.9043 193.551L12.9859 188.25H18.9291L22.0107 193.551L18.9291 198.965Z" fill="#CAE5E6" />
    <path d="M19.812 217.351H12.1079L8.25586 210.584L12.1079 203.816H19.812L23.6641 210.584L19.812 217.351Z" fill="#CAE5E6" />
    <path d="M18.9291 232.917H12.9859L9.9043 227.616L12.9859 222.314H18.9291L22.0107 227.616L18.9291 232.917Z" fill="#CAE5E6" />
    <path d="M16.8367 263.143H15.0758L14.1953 261.564L15.0758 260.098H16.8367L17.7172 261.564L16.8367 263.143Z" fill="#CAE5E6" />
    <path d="M18.2696 282.654H13.6472L11.3359 278.594L13.6472 274.533H18.2696L20.5809 278.594L18.2696 282.654Z" fill="#CAE5E6" />
    <path d="M2.42129 203.476H0.77041L0 202.123L0.77041 200.656H2.42129L3.1917 202.123L2.42129 203.476Z" fill="#CAE5E6" />
    <path d="M2.42129 271.494H0.77041L0 270.14L0.77041 268.674H2.42129L3.1917 270.14L2.42129 271.494Z" fill="#CAE5E6" />
    <path d="M2.31055 288.409H0.879785L0.109375 287.055L0.879785 285.814H2.31055L3.08096 287.055L2.31055 288.409Z" fill="#CAE5E6" />
  </svg>
  );
}

export default ResourceMapRigthSvg;

import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "Assets/Images";
import ButtonFilledColor from "Components/ButtonFilledColor";
import { useTranslation } from "react-i18next";

const ArabicOurSevice = ({ item, isMebel = false, onClick }) => {
  const [state, setState] = useState();
  const ref = useRef(null);
  const { t, i18n } = useTranslation();

  const Tile = ({ item }) => {
    return (
      <Container
        className={
          state === item?.id && !item?.height
            ? "container_tile_over_service2"
            : item?.height && state === item?.id
            ? "container_tile_over_service3"
            : "container_tile_over_service"
        }
        style={{
          width: item?.height ? "63%" : "0px",
        }}
      >
        <Row style={{}} className="arabic-row-revers">
          <Col xs={2}>
            {" "}
            <img src={item?.icon} alt="new" className="col-img" />
          </Col>
          <Col xs={7}>
            <h5 className={"heading_ourSrvice arabic-text_align"}>
              {t(item?.heading) || ""}
            </h5>
            <p
              className={
                state === item?.id
                  ? "text_tile_srvice2 arabic-text_align"
                  : "text_tile_srvice arabic-text_align"
              }
              id="element"
            >
              {" "}
              {t(item?.title) || ""}
              <ul className="OurServicesPointfont arabic-text_align arabic-list-decoration">
                <li className="OurServicesPointfont">
                  &nbsp; &bull; {t(item.point_1)}{" "}
                </li>
                <li className="OurServicesPointfont">
                  &nbsp; &bull; {t(item.point_2)}
                </li>
                <li className="OurServicesPointfont">
                  &nbsp; &bull; {t(item.point_3)}{" "}
                </li>
                {item.point_4 && (
                  <li className="OurServicesPointfont arabic-text_align">
                    {t(item.point_4)}
                  </li>
                )}
                {/* <li className="OurServicesPointfont">{t(item.point_4)}</li> */}
              </ul>
            </p>
            {state === item?.id ? (
              <div className="arabic-row_read_text" onClick={() => setState()}>
                <p
                  className={"text_tile_srvice3 mt-3"}
                  style={{ textDecoration: "underline" }}
                >
                  {t("Read_less")}
                </p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17.0002 14.8303C16.8128 15.0166 16.5594 15.1211 16.2952 15.1211C16.031 15.1211 15.7776 15.0166 15.5902 14.8303L12.0002 11.2903L8.46019 14.8303C8.27283 15.0166 8.01938 15.1211 7.75519 15.1211C7.49101 15.1211 7.23756 15.0166 7.05019 14.8303C6.95646 14.7373 6.88207 14.6267 6.8313 14.5049C6.78053 14.383 6.75439 14.2523 6.75439 14.1203C6.75439 13.9883 6.78053 13.8576 6.8313 13.7357C6.88207 13.6139 6.95646 13.5033 7.05019 13.4103L11.2902 9.1703C11.3832 9.07657 11.4938 9.00218 11.6156 8.95141C11.7375 8.90064 11.8682 8.8745 12.0002 8.8745C12.1322 8.8745 12.2629 8.90064 12.3848 8.95141C12.5066 9.00218 12.6172 9.07657 12.7102 9.1703L17.0002 13.4103C17.0939 13.5033 17.1683 13.6139 17.2191 13.7357C17.2699 13.8576 17.296 13.9883 17.296 14.1203C17.296 14.2523 17.2699 14.383 17.2191 14.5049C17.1683 14.6267 17.0939 14.7373 17.0002 14.8303Z"
                    fill="white"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="arabic-row_read_text"
                onClick={() => setState(item?.id)}
              >
                <p
                  className={"text_tile_srvice3 mt-3"}
                  style={{ textDecoration: "underline" }}
                >
                  {t("Read_More")}
                </p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.0003 9.1697C7.18766 8.98345 7.44111 8.87891 7.7053 8.87891C7.96948 8.87891 8.22293 8.98345 8.4103 9.1697L12.0003 12.7097L15.5403 9.1697C15.7277 8.98345 15.9811 8.87891 16.2453 8.87891C16.5095 8.87891 16.7629 8.98345 16.9503 9.1697C17.044 9.26266 17.1184 9.37326 17.1692 9.49512C17.22 9.61698 17.2461 9.74769 17.2461 9.8797C17.2461 10.0117 17.22 10.1424 17.1692 10.2643C17.1184 10.3861 17.044 10.4967 16.9503 10.5897L12.7103 14.8297C12.6173 14.9234 12.5067 14.9978 12.3849 15.0486C12.263 15.0994 12.1323 15.1255 12.0003 15.1255C11.8683 15.1255 11.7376 15.0994 11.6157 15.0486C11.4939 14.9978 11.3833 14.9234 11.2903 14.8297L7.0003 10.5897C6.90657 10.4967 6.83217 10.3861 6.7814 10.2643C6.73064 10.1424 6.7045 10.0117 6.7045 9.8797C6.7045 9.74769 6.73064 9.61698 6.7814 9.49512C6.83217 9.37326 6.90657 9.26266 7.0003 9.1697Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </Col>

          <Col xs={3}>
            <div className="button_over_sevice" style={{ width: "59%" }}>
              <ButtonFilledColor
                onClick={() => onClick && onClick(item?.subject)}
                fontSize={"1.5vh"}
                title={"enquire_now"}
                btnColor={"#FF6700"}
                height={"2.5vw"}
                width="43%"
                buttonfilledcolorclasscontainer={"ourservicesbutton"}
                buttonfilledcolorclass={"ourservicesbuttonfont"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div className="spaceBetweenRow">
      <p className="text_OuverService">{t("our_services_description1")}</p>
      <p className="text_OuverService">{t("our_services_description2")}</p>
      <p className="text_OuverService">{t("our_services_description3")}</p>
      <div className="over_service_contaner">
        {data.map((item, index) => (
          <Tile item={item} />
        ))}
      </div>
    </div>
  );
};

export default ArabicOurSevice;

const data = [
  {
    id: 1,
    heading: "partnership_projects_heading",
    title: "partnership_projects_description",
    point_1: "partnership_projects_point1",
    point_2: "partnership_projects_point2",
    point_3: "partnership_projects_point3",
    subject: "Partnership Projects",
    icon: IMAGES.Frame_2,
  },
  {
    id: 2,
    heading: "feasibility_&_market_assessment_studies_heading",
    title: "feasibility_&_market_assessment_studies_description",
    point_1: "feasibility_&_market_assessment_studies_point1",
    point_2: "feasibility_&_market_assessment_studies_point2",
    point_3: "feasibility_&_market_assessment_studies_point3",
    point_4: "feasibility_&_market_assessment_studies_point4",
    subject: "Feasibility & Market Assessment Studies",
    icon: IMAGES.Frame2,
  },
  {
    id: 3,
    heading: "market_research_insights_&_pricing_reports_heading",
    title: "market_research_insights_&_pricing_reports_description",
    point_1: "market_research_insights_&_pricing_reports_point1",
    point_2: "market_research_insights_&_pricing_reports_point2",
    point_3: "market_research_insights_&_pricing_reports_point3",
    point_4: "market_research_insights_&_pricing_reports_point4",
    subject: "Market Research Insights & Pricing Reports",
    icon: IMAGES.Frame1,
  },
  {
    id: 4,
    heading: "technical_recycling_operations_advice_heading",
    title: "technical_recycling_operations_advice_description",
    point_1: "technical_recycling_operations_advice_point1",
    point_2: "technical_recycling_operations_advice_point2",
    point_3: "technical_recycling_operations_advice_point3",
    point_4: "technical_recycling_operations_advice_point4",
    subject: "Technical Recycling Operations Advice",
    icon: IMAGES.Frame3,
    height: true,
  },
  {
    id: 5,
    heading: "awareness_&_educational_sessions_heading",
    title: "awareness_&_educational_sessions_description",
    point_1: "awareness_&_educational_sessions_point1",
    point_2: "awareness_&_educational_sessions_point2",
    point_3: "awareness_&_educational_sessions_point3",
    subject: "Awareness & Educational Sessions",
    icon: IMAGES.Frame,
  },
  {
    id: 6,
    heading:
      "policies_and_regulations_compliance_and_public_private_partnerships_heading",
    title:
      "policies_and_regulations_compliance_and_public_private_partnerships_description",
    point_1:
      "policies_and_regulations_compliance_and_public_private_partnerships_point1",
    point_2:
      "policies_and_regulations_compliance_and_public_private_partnerships_point2",
    point_3:
      "policies_and_regulations_compliance_and_public_private_partnerships_point3",
    icon: IMAGES.Frame,
    height: true,
    subject:
      "Policies and Regulations Compliance and Public-Private Partnerships",
  },
];

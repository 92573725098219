import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpArrow from "../Assets/Images/u_arrow-up-right.png";
const RightIconButton = ({
  title = "Send Email",
  onClick,
  btnColor = "#FF6700",
  width = "100%",
  height = 50,
  icon = UpArrow,
  padding = 10,
  styleText,
  fontSize = "1.0vw",
  classname,
  paraclass,
  type,
  style,
  scrollTop = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="apply_now"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      type={type}
      // className={classname}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // height: height,
        // width: width,
        padding: "8px 10px",
        position: "relative",
        background: isHovered ? "white" : btnColor,
        border: `1px solid ${btnColor}`,
        borderRadius: 50,
        // transition: "background-color 1.3s",
        cursor: "pointer",
        zIndex: 123,
        ...style,
      }}
      onClick={() => {
        // onClick && onClick();
        onClick ? onClick() : Navigate("/contact-us");
        scrollTop && scrollToTop();
      }}
    >
      <p
        className={paraclass}
        style={{
          margin: 0,
          padding: 0,
          paddingLeft: 5,
          color: "#fff",
          ...styleText,
          color: isHovered ? btnColor : "white",
          textAlign: "center",
          fontFamily: "nexa-regular",
          fontSize: fontSize,
          // transition: "color 1.3s",
        }}
      >
        {t(title)}
      </p>

      {
        <div
          style={{
            border: isHovered ? `1px solid ${btnColor}` : "none",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // transition: "border 0.1s",
            background: "white",
            maxHeight: 36,
            padding: 5,
            maxWidth: 36,
            marginLeft: 10,
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1363 6.0961C15.0552 5.90062 14.8998 5.74528 14.7043 5.6641C14.6082 5.6231 14.5049 5.60136 14.4003 5.6001H6.40034C6.18817 5.6001 5.98469 5.68438 5.83466 5.83441C5.68463 5.98444 5.60034 6.18792 5.60034 6.4001C5.60034 6.61227 5.68463 6.81575 5.83466 6.96578C5.98469 7.11581 6.18817 7.2001 6.40034 7.2001H12.4723L5.83234 13.8321C5.75736 13.9065 5.69784 13.9949 5.65723 14.0924C5.61661 14.1899 5.5957 14.2945 5.5957 14.4001C5.5957 14.5057 5.61661 14.6103 5.65723 14.7078C5.69784 14.8052 5.75736 14.8937 5.83234 14.9681C5.90671 15.0431 5.99519 15.1026 6.09268 15.1432C6.19017 15.1838 6.29473 15.2047 6.40034 15.2047C6.50595 15.2047 6.61052 15.1838 6.708 15.1432C6.80549 15.1026 6.89397 15.0431 6.96834 14.9681L13.6003 8.3281V14.4001C13.6003 14.6123 13.6846 14.8158 13.8347 14.9658C13.9847 15.1158 14.1882 15.2001 14.4003 15.2001C14.6125 15.2001 14.816 15.1158 14.966 14.9658C15.1161 14.8158 15.2003 14.6123 15.2003 14.4001V6.4001C15.1991 6.29556 15.1773 6.19228 15.1363 6.0961Z"
              fill="url(#paint0_linear_1_18539)"
            />
            <path
              d="M15.1363 6.0961C15.0552 5.90062 14.8998 5.74528 14.7043 5.6641C14.6082 5.6231 14.5049 5.60136 14.4003 5.6001H6.40034C6.18817 5.6001 5.98469 5.68438 5.83466 5.83441C5.68463 5.98444 5.60034 6.18792 5.60034 6.4001C5.60034 6.61227 5.68463 6.81575 5.83466 6.96578C5.98469 7.11581 6.18817 7.2001 6.40034 7.2001H12.4723L5.83234 13.8321C5.75736 13.9065 5.69784 13.9949 5.65723 14.0924C5.61661 14.1899 5.5957 14.2945 5.5957 14.4001C5.5957 14.5057 5.61661 14.6103 5.65723 14.7078C5.69784 14.8052 5.75736 14.8937 5.83234 14.9681C5.90671 15.0431 5.99519 15.1026 6.09268 15.1432C6.19017 15.1838 6.29473 15.2047 6.40034 15.2047C6.50595 15.2047 6.61052 15.1838 6.708 15.1432C6.80549 15.1026 6.89397 15.0431 6.96834 14.9681L13.6003 8.3281V14.4001C13.6003 14.6123 13.6846 14.8158 13.8347 14.9658C13.9847 15.1158 14.1882 15.2001 14.4003 15.2001C14.6125 15.2001 14.816 15.1158 14.966 14.9658C15.1161 14.8158 15.2003 14.6123 15.2003 14.4001V6.4001C15.1991 6.29556 15.1773 6.19228 15.1363 6.0961Z"
              fill="url(#paint1_radial_1_18539)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_18539"
                x1="7.4672"
                y1="6.51819"
                x2="13.8762"
                y2="13.4921"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF2F00" />
                <stop offset="1" stopColor="#FF7050" />
              </linearGradient>
              <radialGradient
                id="paint1_radial_1_18539"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(11.4397 11.038) rotate(123.871) scale(3.10457 2.92826)"
              >
                <stop stopColor="white" stopOpacity="0.3" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      }
    </div>
  );
};

export default RightIconButton;

import React from "react";
import RightIconButton from "../../Components/RightIconButton";
import { IMAGES } from "../../Assets/Images";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function WorkWithUs() {
  const emailAddress = 'info@reboundplastic.com';
  const { t, i18n } = useTranslation();


  const openEmailClient = () => {
    const emailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}`;
    window.open(emailLink, '_blank');
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: 70 }}>
      <div className="workwithuscontainer">
        <div className="workwithusheading">
          <div className="aboutusshadow">
            <img
              src={IMAGES.aboutUsShadow}
              alt="Image"
              layout="responsive"
              className="aboutUsShadowImage"
            />
          </div>
          <p className="f_extra_btext64">{t('the_visionaries_heading2')}</p>
        </div>
        <div className="workwithusdetail">
          <p className="workwithusparagraph">
            {t('the_visionaries_description1')}
          </p>
          <p className="workwithusparagraph">
          {t('the_visionaries_description2')}
          </p>
          <p className="workwithusparagraph">
            {t('the_visionaries_description3')}
            <span className="color_tango text-decoration-underline"
              onClick={()=>{
                scrollToTop();
              }}
            ><a href="mailto:info@reboundplastic.com" className="color_tango">{t('info@reboundplastic_com')}</a></span>
          </p>
          <div style={{ display: 'flex', flexDirection:'row' }}>
            <RightIconButton
            paraclass={"aboutusbutton"}
              title="Join our team"
              // width="25%"
              // padding={"2px"} 
              onClick={() => navigate("/contact-us",{state:"Register_Now"})}
              // classname={'workwithusbutton'}
              // paraclass={'workwithusbuttonfont'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkWithUs;

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BorderButton from "Components/BorderButton";
import ButtonFilledColor from "Components/ButtonFilledColor";
import InputField from "Components/InputField";
import RightIconButton from "Components/RightIconButton";
import TextSlideUp from "Components/TextSlideUp";
import { useTranslation } from "react-i18next";
import { Alert, Spinner, Toast, ToastContainer } from "react-bootstrap";
import {
  errorBlogEntries,
  errorCareers,
  errorHadleContactUs,
  errorHadleRegisterNow,
  errorNewsLetter,
  errorWhitePaper,
} from "Screens/Resources/utilsContactUs";
import { getCurrentDimension } from "Utils/Helper";
import { uploadImageFireBase, uploadImageS3 } from "Utils/firebase";


const ContactUs = ({ navigation }) => {
  const params = useLocation();
  const imagsesRef = useRef();
  const resumeRef = useRef();
  const whitePapersRef = useRef();
  const blogFileRef = useRef();
  const coverLetterRef = useRef();
  const dimensions = getCurrentDimension();

  const navigate = useNavigate();
  const [state, setState] = useState(RBOUND_SET_DATA);
  const [selected, setSelected] = useState("Contact_us");
  const [loder, setLoder] = useState(false);
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState({});
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (params?.state === "Rebound_Platform") {
      handleClick({ id: "Register_Now" });
    }
    if (params?.state === "Blog_Entries") {
      handleClick({ id: "Blog_Entries" });
    }
    if (params?.state === "White_Paper") {
      handleClick({ id: "White_Paper" });
    }
    if (params?.state === "Register_Now") {
      handleClick({ id: "Careers" });
    }
    if (params?.state === "Newsletters") {
      handleClick({ id: "Newsletters" });
    }
    if (params?.state === "Rebound_solutions") {
      handleClick({ id: "Register_Now" });
    }
    if (params?.state === "Careers") {
      handleClick({ id: "Careers" });
    }
  }, [params?.state]);

  useEffect(() => {
    setTimeout(() => {
      setMessage(false);
    }, 3000);
  }, [message]);


  // ________ Handle Image change ________
  const onChangeImage = (e) => {
    e.preventDefault();
    const name = e?.target?.name;
    const file = e.target.files[0];
    console.log("___name___", name);
    if (name == "blog_file") {
      setImages({
        ...images,
        blog_file: file,
      });
      setErrors({
        ...errors,
        blog_file: false,
      });
    } else if (name == "images") {
      setImages({
        ...images,
        images: file,
      });
      setErrors({
        ...errors,
        images: false,
      });
    } else if (name == "resume") {
      setImages({
        ...images,
        resume: file,
      });
      setErrors({
        ...errors,
        resume: false,
      });
    } else if (name == "cover_letter") {
      setImages({
        ...images,
        cover_letter: file,
      });
      setErrors({
        ...errors,
        cover_letter: false,
      });
    } else if (name == "white_papers_file") {
      setImages({
        ...images,
        white_papers_file: file,
      });
      setErrors({
        ...errors,
        white_papers_file: false,
      });
    }
  };


  // _________Handle  text OnChange _____________
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false });
  };

  //_____ Handle tab change ______________
  const handleClick = (selectedItem) => {
    setSelected(selectedItem.id);
    let new_data = state.map((item) => {
      return {
        ...item,
        isSelected: item.id === selectedItem.id ? true : false,
      };
    });
    setState(new_data);
    setFormData({});
    setErrors({});
    setImages();
  };

  //______ handle Submit All Form  _________
  const handleFormSubmit = () => {
    if (selected === "Contact_us") {
      submitContect_us();
    }
    if (selected === "Register_Now") {
      submit_Register_now();
    }
    if (selected === "Blog_Entries") {
      submit_Blog_Entries();
    }
    if (selected === "Careers") {
      submit_careers();
    }
    if (selected === "White_Paper") {
      submit_white_Paper();
    }
    if (selected === "Newsletters") {
      submit_newsletters();
    }
  };

  //______ Contect_us Error Handle
  const submitContect_us = () => {
    errorHadleContactUs(formData, (data) => {
      if (data?.error) {
        setErrors(data);
      } else {
        handleServerRequest();
      }
    });
  };

  //______ Register Now Error Handle _________
  const submit_Register_now = () => {
    errorHadleRegisterNow(formData, (data) => {
      if (data?.error) {
        setErrors(data);
      } else {
        handleServerRequest();
      }
    });
  };

  //______ Blog Entries Error Handle _________
  const submit_Blog_Entries = () => {
    errorBlogEntries(formData, (data) => {
      let error = data;
      if (data?.error) {
        if (!images?.blog_file) {
          error.blog_file = "blog_required_Error";
        }
        if (!images?.images) {
          error.images = "images_is_required";
        }
        setErrors(error);
      } else if (!images?.blog_file || !images?.photo) {
        if (!images?.blog_file) {
          setErrors({
            ...errors,
            blog_file: 'blog_required_Error"',
          });
        }
        if (!images?.images) {
          setErrors({
            ...errors,
            images: 'photo_is_required"',
          });
        } else {
          setLoder(true);
          uploadImageS3(
            images?.blog_file,
            (res) => {
              handelImagesUplod(res);
            },
            (per) => {
              console.log("persent", per);
            }
          );
        }
      }
    });
  };
  const handelImagesUplod = (res) => {
    let file = {
      blog_file: res,
    };
    uploadImageS3(
      images?.images,
      (res) => {
        file.images = res;
        handleServerRequest(file);
      },
      (per) => {
        console.log("persent", per);
      }
    );
  };

  //______ Careers Error Handle _________
  const submit_careers = () => {
    errorCareers(formData, (data) => {
      let error = data;
      if (data?.error) {
        if (!images?.resume?.name) {
          error.resume = "resume_is_required";
        }
        if (!images?.cover_letter?.name) {
          error.cover_letter = "cover_letter_required";
        }
        setErrors(error);
      } else if (!images?.resume?.name || !images?.cover_letter?.name) {
        if (!images?.resume?.name) {
          setErrors({
            ...errors,
            resume: "resume_is_required",
          });
        }
        if (!images?.cover_letter?.name) {
          setErrors({
            ...errors,
            cover_letter: 'cover_letter_required"',
          });
        }
      } else {
        let file = {};
        setLoder(true);
        uploadImageS3(
          images?.resume,
          (res) => {
            file.resume = res;
            uploadImageS3(
              images?.cover_letter,
              (res) => {
                file.cover_letter = res;
                handleServerRequest(file);
              },
              (per) => {
                console.log("persent", per);
              }
            );
          },
          (per) => {
            console.log("persent", per);
          }
        );
      }
    });
  };

  //______ White_Paper Error Handle _________
  const submit_white_Paper = () => {
    errorWhitePaper(formData, (data) => {
      let error = data;
      if (data?.error) {
        if (!images?.white_papers_file?.name) {
          error.white_papers_file = "white_paper_is_required";
        }
        if (!images?.images?.name) {
          error.images = "images_is_required";
        }
        setErrors(error);
      } else if (!images?.white_papers_file?.name || !images?.images?.name) {
        if (!images?.white_papers_file?.name) {
          setErrors({
            ...errors,
            white_papers_file: "white_paper_is_required",
          });
        }
        if (!images?.images?.name) {
          setErrors({
            ...errors,
            images: "images_is_required",
          });
        }
      } else {
        let file = {};
        setLoder(true);
        uploadImageS3(
          images?.white_papers_file,
          (res) => {
            file.white_papers_file = res;
            uploadImageS3(
              images?.images,
              (res) => {
                file.images = res;
                handleServerRequest(file);
              },
              (per) => {
                console.log("persent", per);
              }
            );
          },
          (per) => {
            console.log("persent", per);
          }
        );
      }
    });
  };

  //______ newsLetters  Error Handle _________
  const submit_newsletters = () => {
    errorNewsLetter(formData, (data) => {
      if (data?.error) {
        setErrors(data);
      } else {
        handleServerRequest();
      }
    });
  };

  // ______ Handle Server Request __________
  // const handleServerRequest = async (images) => {
  //   setLoder(true);
  //   const response = await fetch(
  //     "https://rebound-bb9b8-default-rtdb.firebaseio.com/Records.json",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ...formData,
  //         type: selected,
  //         ...(images && { ...images }),
  //       }),
  //     }
  //   );
  //   console.log(response);

  //   if (response?.status === 200) {
  //     setMessage(true);
  //     setFormData({});
  //     setErrors({});
  //     setLoder(false);
  //     setImages();
  //   } else {
  //     alert("Something went wrong, please try again.");
  //   }
  // };


  const handleServerRequest = (images) => {
    setLoder(true)
    
    const data = {
      service_id: 'service_b18mb3j',
      template_id: selected,
      user_id: 'dnCnRWi9h9jNRkXby',
      template_params: {
        ...formData,
        subject: getTopics(),
        ...(images && { ...images })
      }
    };

    fetch('https://admin.reboundsolution.com/api/handle-submission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    }).then((result) => {
      if (result?.ok) {
        setMessage(true);
        setFormData({});
        setErrors({});
        setLoder(false);
        setImages();
      }
    }, (error) => {
      setLoder(false)
      console.log('error.statusText', error);
    });

  }
  // ______ Render Topcs ___________
  const getTopics = () => {
    let selected_obj = state.find((x) => x.isSelected);
    let topics_obj = TOPIC_DATA.find((x) => x.id === selected_obj.id);
    return topics_obj?.title || "";
  };

  //______ Contect_us Form
  const Contect_us = () => {
    return (
      <div className="text_field">
        <InputField
          type="text"
          name="name"
          label="NAME"
          placeholder="John Doe"
          value={formData?.name || ""}
          onChange={handleChange}
        />
        {errors.name && <span className="errText">{t(errors.name)}</span>}
        <InputField
          type="text"
          label="Company name"
          name="company"
          placeholder="Company name"
          value={formData.company || ""}
          onChange={handleChange}
        />
        {errors.company && <span className="errText">{t(errors.company)}</span>}
        <InputField
          name="email"
          label="EMAIL"
          value={formData.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
        />
        {errors.email && <span className="errText">{t(errors.email)}</span>}
        <InputField
          type="number"
          name="phone"
          label="Phone number"
          value={formData.phone || ""}
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{t(errors.phone)}</span>}
        <InputField
          type="text"
          name="extra_information"
          value={formData.extra_information || ""}
          label="Extra information"
          placeholder="Extra information"
          onChange={handleChange}
        />
        {errors.extra_information && (
          <span className="errText">{t(errors.extra_information)}</span>
        )}
      </div>
    );
  };

  //______ Register Now Form _________
  const Register_Now = () => {
    return (
      <div className="text_field">
        <InputField
          label="NAME"
          placeholder="Name"
          name="name"
          onChange={handleChange}
          value={formData?.name || ""}
        />
        {errors.name && <span className="errText">{t(errors.name)}</span>}

        <InputField
          type="text"
          label="Company name"
          name="company"
          placeholder="Company Name"
          value={formData.company || ""}
          onChange={handleChange}
        />
        {errors.company && <span className="errText">{t(errors.company)}</span>}

        <InputField
          label="EMAIL"
          placeholder="Doe@gmail.com"
          name="email"
          type="email"
          onChange={handleChange}
          value={formData?.email || ""}
        />
        {errors.email && <span className="errText">{t(errors.email)}</span>}

        <InputField
          label="Phone number"
          type="number"
          placeholder="+971 0000 00000"
          name="phone"
          onChange={handleChange}
          value={formData?.phone || ""}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{t(errors.phone)}</span>}

        <InputField
          type="text"
          name="industry_sector"
          value={formData.industry_sector || ""}
          label="Industry/Sector"
          placeholder="Enter Industry/Sector"
          onChange={handleChange}
        />
        {errors.industry_sector && (
          <span className="errText">{t(errors.industry_sector)}</span>
        )}

        <InputField
          type="text"
          name="extra_information"
          value={formData.extra_information || ""}
          label="Extra information"
          placeholder="Extra information"
          onChange={handleChange}
        />
        {errors.extra_information && (
          <span className="errText">{t(errors.extra_information)}</span>
        )}
      </div>
    );
  };

  //______ Blog Entries Form _________
  const Blog_Entries = () => {
    return (
      <div className="text_field">
        <InputField
          type="text"
          name="name"
          label="NAME"
          placeholder="John Doe"
          value={formData.name || ""}
          onChange={handleChange}
        />
        {errors.name && <span className="errText">{t(errors.name)}</span>}

        <InputField
          type="text"
          label="Company name"
          name="company"
          placeholder="Company Name"
          value={formData.company || ""}
          onChange={handleChange}
        />
        {errors.company && <span className="errText">{t(errors.company)}</span>}

        <InputField
          name="email"
          label="EMAIL"
          type="email"
          value={formData.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
        />
        {errors.email && <span className="errText">{t(errors.email)}</span>}

        <InputField
          type="number"
          name="phone"
          label="Phone number"
          value={formData.phone || ""}
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{t(errors.phone)}</span>}

        <div className="Upload_img">
          <div className="textLebel">{"Blog File"}</div>
          {images?.blog_file ? (
            <FileSvg />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => blogFileRef.current.click()}
            >
              {"Upload Blog File"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.blog_file && (
          <span className="errText text_err_m">{t(errors?.blog_file)}</span>
        )}

        <div className="Upload_img">
          <div className="textLebel">{"Images (if any) "}</div>
          {images?.images ? (
            <FileSvg />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => imagsesRef.current.click()}
            >
              {"Upload Images"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.images && (
          <span className="errText text_err_m">{t(errors?.images)}</span>
        )}

        <input
          type="file"
          onChange={onChangeImage}
          ref={blogFileRef}
          name="blog_file"
          style={{ display: "none" }}
        />
        <input
          type="file"
          onChange={onChangeImage}
          ref={imagsesRef}
          name="images"
          style={{ display: "none" }}
        />
      </div>
    );
  };

  //______ Careers Form _________
  const Careers = () => {
    return (
      <div className="text_field">
        <InputField
          type="text"
          name="name"
          label="NAME"
          placeholder="John Doe"
          value={formData.name || ""}
          onChange={handleChange}
        />
        {errors.name && <span className="errText">{t(errors.name)}</span>}

        <InputField
          name="email"
          label="EMAIL"
          value={formData.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
          type="email"
        />
        {errors.email && <span className="errText">{t(errors.email)}</span>}

        <InputField
          type="number"
          name="phone"
          label="Phone number"
          value={formData.phone || ""}
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{t(errors.phone)}</span>}

        <InputField
          label="Headline"
          placeholder="Headline"
          name="headline"
          onChange={handleChange}
          value={formData?.headline || ""}
        />
        {errors.headline && <span className="errText">{t(errors.headline)}</span>}

        <div className="Upload_img">
          <div className="textLebel">{"Resume"}</div>
          {images?.resume ? (
            <FileSvg margin="14%" />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => resumeRef.current.click()}
            >
              {"Upload Resume"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.resume && (
          <span className="errText text_err_m">{t(errors?.resume)}</span>
        )}

        <div className="Upload_img">
          <div className="textLebel">{"Cover Letter"}</div>
          {images?.cover_letter ? (
            <FileSvg margin="14%" />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => coverLetterRef.current.click()}
            >
              {"Upload Cover Letter"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.cover_letter && (
          <span className="errText text_err_m">{t(errors?.cover_letter)}</span>
        )}

        <input
          type="file"
          onChange={onChangeImage}
          ref={resumeRef}
          name="resume"
          style={{ display: "none" }}
        />
        <input
          type="file"
          onChange={onChangeImage}
          ref={coverLetterRef}
          name="cover_letter"
          style={{ display: "none" }}
        />
      </div>
    );
  };

  //______ White Paper Form _________
  const White_Paper = () => {
    return (
      <div className="text_field">
        <InputField
          type="text"
          name="name"
          label="NAME"
          placeholder="John Doe"
          value={formData.name || ""}
          onChange={handleChange}
        />
        {errors.name && <span className="errText">{errors.name}</span>}

        <InputField
          type="text"
          label="Company name"
          name="company"
          placeholder="Company Name"
          value={formData.company || ""}
          onChange={handleChange}
        />
        {errors.company && <span className="errText">{errors.company}</span>}

        <InputField
          name="email"
          label="EMAIL"
          type="email"
          value={formData.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
        />
        {errors.email && <span className="errText">{errors.email}</span>}

        <InputField
          type="number"
          name="phone"
          label="Phone number"
          value={formData.phone || ""}
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{errors.phone}</span>}

        <InputField
          type="text"
          name="submission_topic"
          label="Submission Topic"
          value={formData.submission_topic || ""}
          placeholder="Enter your topic"
          onChange={handleChange}
        />
        {errors.submission_topic && (
          <span className="errText">{errors.submission_topic}</span>
        )}

        <div className="Upload_img">
          <div className="textLebel">{"White Paper"}</div>
          {images?.white_papers_file ? (
            <FileSvg margin="12%" />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => whitePapersRef.current.click()}
            >
              {"Upload White Paper file"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.white_papers_file && (
          <span className="errText text_err_m">
            {errors?.white_papers_file}
          </span>
        )}

        <div className="Upload_img">
          <div className="textLebel">{"Images (if any) "}</div>
          {images?.images ? (
            <FileSvg margin="12%" />
          ) : (
            <div
              className="text_upload_img"
              onClick={() => imagsesRef.current.click()}
            >
              {"Upload Images"}
            </div>
          )}
        </div>
        <div className="border_bottom_input" />
        {errors?.images && (
          <span className="errText text_err_m">{errors?.images}</span>
        )}

        <input
          type="file"
          onChange={onChangeImage}
          ref={whitePapersRef}
          name="white_papers_file"
          style={{ display: "none" }}
        />

        <input
          type="file"
          onChange={onChangeImage}
          ref={imagsesRef}
          name="images"
          style={{ display: "none" }}
        />
      </div>
    );
  };
  //______ NewsLetter Form _________
  const NewsLetter = () => {
    return (
      <div className="text_field">
        <InputField
          type="text"
          name="name"
          label="NAME"
          placeholder="John Doe"
          value={formData.name || ""}
          onChange={handleChange}
        />
        {errors.name && <span className="errText">{t(errors.name)}</span>}

        <InputField
          name="email"
          label="EMAIL"
          type="email"
          value={formData.email || ""}
          placeholder="Doe@gmail.com"
          onChange={handleChange}
        />
        {errors.email && <span className="errText">{t(errors.email)}</span>}

        <InputField
          type="number"
          name="phone"
          label="Phone number"
          value={formData.phone || ""}
          placeholder="+971 0000 00000"
          onChange={handleChange}
          min="0"
          inputMode="numeric"
          pattern="[0-9]*"
          title="Non-negative integral number"
        />
        {errors.phone && <span className="errText">{t(errors.phone)}</span>}
      </div>
    );
  };

  // _____ Main View ___________
  return (
    <div className="background_img_contactUs">
      {/* <h1 className="textHeadingContectUs">Get in Touch  with Rebound</h1> */}
      <TextSlideUp
        color="black"
        className={"textHeadingContectUs"}
        Heading={"get_in_touch_with_rebound"}
      />
      <div className="contectUsContainer ">
        <div className=" contaner_maps">
          <div className="mobil_container">
            <div className="dispay_flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12 2.5C9.87827 2.5 7.84344 3.34285 6.34315 4.84315C4.84285 6.34344 4 8.37827 4 10.5C4 15.9 11.05 22 11.35 22.26C11.5311 22.4149 11.7616 22.5001 12 22.5001C12.2384 22.5001 12.4689 22.4149 12.65 22.26C13 22 20 15.9 20 10.5C20 8.37827 19.1571 6.34344 17.6569 4.84315C16.1566 3.34285 14.1217 2.5 12 2.5ZM12 20.15C9.87 18.15 6 13.84 6 10.5C6 8.9087 6.63214 7.38258 7.75736 6.25736C8.88258 5.13214 10.4087 4.5 12 4.5C13.5913 4.5 15.1174 5.13214 16.2426 6.25736C17.3679 7.38258 18 8.9087 18 10.5C18 13.84 14.13 18.16 12 20.15ZM12 6.5C11.2089 6.5 10.4355 6.7346 9.77772 7.17412C9.11992 7.61365 8.60723 8.23836 8.30448 8.96927C8.00173 9.70017 7.92252 10.5044 8.07686 11.2804C8.2312 12.0563 8.61216 12.769 9.17157 13.3284C9.73098 13.8878 10.4437 14.2688 11.2196 14.4231C11.9956 14.5775 12.7998 14.4983 13.5307 14.1955C14.2616 13.8928 14.8864 13.3801 15.3259 12.7223C15.7654 12.0645 16 11.2911 16 10.5C16 9.43913 15.5786 8.42172 14.8284 7.67157C14.0783 6.92143 13.0609 6.5 12 6.5ZM12 12.5C11.6044 12.5 11.2178 12.3827 10.8889 12.1629C10.56 11.9432 10.3036 11.6308 10.1522 11.2654C10.0009 10.8999 9.96126 10.4978 10.0384 10.1098C10.1156 9.72186 10.3061 9.36549 10.5858 9.08579C10.8655 8.80608 11.2219 8.6156 11.6098 8.53843C11.9978 8.46126 12.3999 8.50087 12.7654 8.65224C13.1308 8.80362 13.4432 9.05996 13.6629 9.38886C13.8827 9.71776 14 10.1044 14 10.5C14 11.0304 13.7893 11.5391 13.4142 11.9142C13.0391 12.2893 12.5304 12.5 12 12.5Z"
                  fill="white"
                />
              </svg>
              <div
                className="heading_maps pointer"
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/place/Rebound+Ltd./@24.5020691,54.3879619,18.77z/data=!4m14!1m7!3m6!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!2sRebound+Ltd.!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr!3m5!1s0x3e5e676ff70e6627:0x35d49c3a065a1bea!8m2!3d24.5020971!4d54.3887213!16s%2Fg%2F11sfbf2yfr?entry=ttu",

                    "_blank"
                  );
                }}
              >
                {t("company_address")}
              </div>
            </div>

            <div className="dispay_flex" style={{ marginTop: "5%" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M19 4.5H5C4.20435 4.5 3.44129 4.81607 2.87868 5.37868C2.31607 5.94129 2 6.70435 2 7.5V17.5C2 18.2956 2.31607 19.0587 2.87868 19.6213C3.44129 20.1839 4.20435 20.5 5 20.5H19C19.7956 20.5 20.5587 20.1839 21.1213 19.6213C21.6839 19.0587 22 18.2956 22 17.5V7.5C22 6.70435 21.6839 5.94129 21.1213 5.37868C20.5587 4.81607 19.7956 4.5 19 4.5ZM5 6.5H19C19.2652 6.5 19.5196 6.60536 19.7071 6.79289C19.8946 6.98043 20 7.23478 20 7.5L12 12.38L4 7.5C4 7.23478 4.10536 6.98043 4.29289 6.79289C4.48043 6.60536 4.73478 6.5 5 6.5ZM20 17.5C20 17.7652 19.8946 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H5C4.73478 18.5 4.48043 18.3946 4.29289 18.2071C4.10536 18.0196 4 17.7652 4 17.5V9.78L11.48 14.35C11.632 14.4378 11.8045 14.484 11.98 14.484C12.1555 14.484 12.328 14.4378 12.48 14.35L20 9.78V17.5Z"
                  fill="white"
                />
              </svg>

              <span
                className="heading_maps pointer"
                onClick={() => {
                  // scrollToTop();
                }}
              >
                <a
                  href="mailto:info@reboundplastic.com"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  {t('info@reboundplastic_com')}
                </a>
              </span>
            </div>

            {/* <div className="dispay_flex" id="in-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z"
                  fill="white"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM8.2 13.3V24H11.6V13.3H8.2ZM8 9.9C8 11 8.8 11.8 9.9 11.8C11 11.8 11.8 11 11.8 9.9C11.8 8.8 11 8 9.9 8C8.9 8 8 8.8 8 9.9ZM20.6 24H23.8V17.4C23.8 14.1 21.8 13 19.9 13C18.2 13 17 14.1 16.7 14.8V13.3H13.5V24H16.9V18.3C16.9 16.8 17.9 16 18.9 16C19.9 16 20.6 16.5 20.6 18.2V24Z"
                  fill="white"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 0C7.16345 0 0 7.16344 0 16C0 24.8366 7.16345 32 16 32C24.8365 32 32 24.8366 32 16C32 7.16344 24.8365 0 16 0ZM19.1182 8C19.1182 8.23775 19.1404 8.4719 19.1813 8.69851C19.3781 9.74606 19.998 10.645 20.8592 11.2059C21.4605 11.5997 22.1732 11.8263 22.9415 11.8263L22.9413 12.4393V14.5753C21.516 14.5753 20.1946 14.1184 19.1182 13.3457V18.9366C19.1182 21.7265 16.8466 24 14.0591 24C12.9827 24 11.9805 23.6581 11.1602 23.0824C9.85367 22.1648 9 20.6491 9 18.9366C9 16.143 11.2679 13.8732 14.0554 13.8769C14.2892 13.8769 14.5157 13.8955 14.7384 13.9252V14.5753L14.7302 14.5792L14.7383 14.579V16.7337C14.5231 16.6668 14.2929 16.6259 14.0554 16.6259C12.7823 16.6259 11.7467 17.6624 11.7467 18.9366C11.7467 19.8245 12.2515 20.5934 12.9864 20.9835C12.9933 20.9929 13.0002 21.0023 13.0072 21.0116L13.0195 21.0278C13.0111 21.0115 13.0013 20.9955 12.9901 20.9798C13.313 21.1507 13.6768 21.2473 14.0628 21.2473C15.3062 21.2473 16.3233 20.2554 16.3678 19.0221L16.3715 8H19.1182Z"
                  fill="white"
                />
              </svg>
            </div> */}
            <div className="maps_container">
              {/* <Maps /> */}
              <div style={{
                height: '475px'
              }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.519447867193!2d54.38614637493373!3d24.502101959504365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e676ff70e6627%3A0x35d49c3a065a1bea!2sRebound%20Ltd.!5e0!3m2!1sen!2sin!4v1699516233467!5m2!1sen!2sin"
                  width="100%"
                  height={"100%"}
                  style={{ borderRadius: 8 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-top-navigation-by-user-activation"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="contaner_contectUs">
          <div className="buttom_Container">
            {state.map((item, index) => {
              if (item.isSelected) {
                return (
                  <div key={index} className="buttom_contact">
                    <ButtonFilledColor
                      buttonfilledcolorclasscontainer={
                        "contactusbuttonfilledcontainer"
                      }
                      buttonfilledcolorclass={"contactusbuttonfilledfont"}
                      title={item.title}
                      width="85%"
                      height={40}
                      btnColor="#00578A"
                      onClick={() => handleClick(item)}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className="buttom_contact">
                    <BorderButton
                      borderbuttonclasscontainer={
                        "contactusbuttonfilledcontainer"
                      }
                      borderbuttonclass={"contactusbuttonfilledfont"}
                      onClick={() => {
                        handleClick(item);
                      }}
                      title={item.title}
                      width="85%"
                      height={40}
                    />
                  </div>
                );
              }
            })}
          </div>
          <p style={{ fontSize: 24, fontFamily: "nexa", textAlign: "left" }}>
            {getTopics()}
          </p>

          {selected === "Contact_us"
            ? Contect_us()
            : selected === "Register_Now"
              ? Register_Now()
              : selected === "White_Paper"
                ? White_Paper()
                : selected === "Careers"
                  ? Careers()
                  : selected === "Newsletters"
                    ? NewsLetter()
                    : Blog_Entries()}

          <div className="margin_top5">
            {loder ? (
              <div className="loder_form">
                <p
                  style={{
                    marginRight: 5,
                    padding: 0,
                    paddingLeft: 5,
                    color: "#fff",
                    fontFamily: "nexa-regular",
                    fontSize: "1.0vw",
                    marginBottom: 0,
                  }}
                >
                  Submit
                </p>
                <Spinner animation="grow" variant="light" />
              </div>
            ) : (
              <RightIconButton
                type="submit"
                width="80%"
                title="Submit"
                classname={"blogsbutton"}
                paraclass={"blogsbuttonfont"}
                scrollTop={false}
                onClick={() => {
                  handleFormSubmit();
                }}
              />
            )}
          </div>
        </div>
      </div>

      <ToastContainer position="top-end" className="p-3" style={{ zIndex: 9999999, top: '60px !important', position: 'fixed' }}>
        <Toast 
          onClose={() => setMessage(false)} 
          show={message} 
          delay={3000}
          style={{
            background: "#FF6700",
            color: "#fff",
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Thank you for your submission. We have successfully received your details!</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* {message && (
        <div className="message_alert" style={{ width: "100%" }}>
          <Alert
            dismissible
            style={{
              background: "#FF6700",
              color: "#fff",
              width: '40%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: '1.7vw',
            }}
            className='messageAlert'
            onClose={() => setMessage(false)}
          >
            {
              "“Thank you for your submission. We have successfully received your details.”"
            }
          </Alert>
        </div>
      )} */}
    </div>
  );
};

export default ContactUs;

const RBOUND_SET_DATA = [
  {
    id: "Contact_us",
    title: "Contact Us",
    isSelected: true,
  },
  {
    id: "Register_Now",
    title: "Register Now",
    // title: "Join Our Team",
    isSelected: false,
  },
  {
    id: "Blog_Entries",
    title: "Blog Entries",
    isSelected: false,
  },
  {
    id: "Careers",
    title: "Careers",
    isSelected: false,
  },
  {
    id: "White_Paper",
    title: "White Papers",
    isSelected: false,
  },
  {
    id: "Newsletters",
    title: "Newsletter",
    isSelected: false,
  },
];

const checkError = (data, type) => {
  if (type === "") {
  }
};

let TOPIC_DATA = [
  {
    id: "Contact_us",
    title: "Contact Us",
  },
  {
    id: "Register_Now",
    title: "Register to the Rebound Solutions",
  },
  {
    id: "Blog_Entries",
    title: "Share your voice with our audience",
  },
  {
    id: "White_Paper",
    title: "Submit your white papers",
  },
  {
    id: "Careers",
    title: "Join our Team",
  },
  {
    id: "Newsletters",
    title: "Sign up to our Newsletter",
  },
];

const FileSvg = ({ margin }) => (
  <div
    style={{
      marginLeft: margin ? margin : "10%",
    }}
  >
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="30"
      viewBox="0 0 122.88 95.04"
    >
      <defs></defs>
      <title>pictures</title>
      <path
        class="cls-1"
        d="M93.22,33.05a9.46,9.46,0,1,1-9.47,9.46,9.45,9.45,0,0,1,9.47-9.46Zm7.49-22.22v-3H7.86V74.32h4.43v7.86H6.89a6.87,6.87,0,0,1-4.86-2,6.81,6.81,0,0,1-2-4.85V6.89A6.9,6.9,0,0,1,6.89,0h94.79a6.9,6.9,0,0,1,6.89,6.89v3.94Zm9.16,76.36L93.47,61a3.76,3.76,0,0,0-6.37,0L79.36,73.47l8.42,13.72H84.32l-23-36.68a4.37,4.37,0,0,0-7.4,0L31.47,87.19H28.32V26.83H115V87.19ZM116,95H27.35a6.91,6.91,0,0,1-6.89-6.88V25.86A6.91,6.91,0,0,1,27.35,19H116a6.9,6.9,0,0,1,6.88,6.88v62.3A6.91,6.91,0,0,1,116,95Z"
        fill="#ff6700"
      />
    </svg>
  </div>
);
